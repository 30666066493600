import {
  ChartGrid,
  ChartGridItem,
  LineChart,
  useXLabelMaxTicksLimit,
} from '@orbiapp/components';

import { BuyBoosterCard } from '../../../../../components/insights';
import { useOrbiBusinessCalendar } from '../../../../../helpers';
import {
  InsightsTimeSeriesByProductSelector,
  InsightsTimeSeriesConfigByProductSelector,
  ModuleSettingsSelector,
  useSelector,
} from '../../../../../store';
import { getLabelsAndDatasets } from '../../../../../utils';

function ViewsChart() {
  const viewsData = useSelector(
    InsightsTimeSeriesByProductSelector.selectViews,
  );
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigByProductSelector.selectRange,
  );
  const insightsDataStatus = useSelector(
    InsightsTimeSeriesByProductSelector.selectStatus,
  );
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    viewsData,
    insightsDataRange,
  );

  const isLocked = insightsTier !== 't2';

  return (
    <LineChart
      placeholderIcon={isLocked ? 'lock-closed-solid' : undefined}
      placeholderTitleTx={
        isLocked
          ? 'label.insights.upgrade-account-to-view-the-data'
          : 'label.insights.no-data-available'
      }
      placeholderSubtitleTx={
        isLocked ? undefined : 'label.insights.try-updating-date-range'
      }
      placeholderButtonTx="label.connect.contact-sales"
      placeholderButtonHref={orbiBusinessCalendarUrl}
      placeholderButtonVariant="secondary"
      legendPosition="bottom"
      isLoading={insightsDataStatus === 'pending'}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="100%"
      descriptionTx="label.insights.total-views-description"
      titleTx="label.insights.views"
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
    />
  );
}

function EngagementChart() {
  const engagementData = useSelector(
    InsightsTimeSeriesByProductSelector.selectEngagement,
  );
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigByProductSelector.selectRange,
  );
  const insightsDataStatus = useSelector(
    InsightsTimeSeriesByProductSelector.selectStatus,
  );
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    engagementData,
    insightsDataRange,
  );

  const isLocked = insightsTier !== 't2';

  return (
    <LineChart
      placeholderIcon={isLocked ? 'lock-closed-solid' : undefined}
      placeholderTitleTx={
        isLocked
          ? 'label.insights.upgrade-account-to-view-the-data'
          : 'label.insights.no-data-available'
      }
      placeholderSubtitleTx={
        isLocked ? undefined : 'label.insights.try-updating-date-range'
      }
      placeholderButtonTx="label.connect.contact-sales"
      placeholderButtonHref={orbiBusinessCalendarUrl}
      placeholderButtonVariant="secondary"
      legendPosition="bottom"
      isLoading={insightsDataStatus === 'pending'}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="100%"
      titleTx="label.insights.engagement"
      descriptionTx="label.insights.total-engagements-description"
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
    />
  );
}

function ImpressionsChart() {
  const impressionsData = useSelector(
    InsightsTimeSeriesByProductSelector.selectImpressions,
  );
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigByProductSelector.selectRange,
  );
  const insightsDataStatus = useSelector(
    InsightsTimeSeriesByProductSelector.selectStatus,
  );
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    impressionsData,
    insightsDataRange,
  );

  const isLocked = insightsTier !== 't2';

  return (
    <LineChart
      placeholderIcon={isLocked ? 'lock-closed-solid' : undefined}
      placeholderTitleTx={
        isLocked
          ? 'label.insights.upgrade-account-to-view-the-data'
          : 'label.insights.no-data-available'
      }
      placeholderSubtitleTx={
        isLocked ? undefined : 'label.insights.try-updating-date-range'
      }
      placeholderButtonTx="label.connect.contact-sales"
      placeholderButtonHref={orbiBusinessCalendarUrl}
      placeholderButtonVariant="secondary"
      legendPosition="bottom"
      isLoading={insightsDataStatus === 'pending'}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="100%"
      titleTx="label.insights.impressions"
      descriptionTx="label.insights.total-impressions-description"
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
    />
  );
}

export function Charts() {
  return (
    <ChartGrid>
      <ChartGridItem>
        <ViewsChart />
      </ChartGridItem>

      <ChartGridItem>
        <EngagementChart />
      </ChartGridItem>

      <ChartGridItem>
        <ImpressionsChart />
      </ChartGridItem>

      <ChartGridItem>
        <BuyBoosterCard />
      </ChartGridItem>
    </ChartGrid>
  );
}

import { OfficeLocation } from '../../models';

type SetupOfficeLocationsReturnTypeItem =
  | {
      type: 'update';
      data: OfficeLocation;
    }
  | {
      type: 'delete';
      officeLocationKey: string;
    }
  | {
      type: 'add';
      data: OfficeLocation;
    }
  | {
      type: 'none';
    };

export function isSameOfficeLocation(
  location1: OfficeLocation,
  location2: OfficeLocation,
): boolean {
  return (
    location1.officeLocationKey === location2.officeLocationKey &&
    location1.label === location2.label &&
    location1.latitude === location2.latitude &&
    location1.longitude === location2.longitude &&
    location1.placeId === location2.placeId &&
    location1.address === location2.address
  );
}

export function setupOfficeLocations(
  currentState: OfficeLocation[],
  newState: OfficeLocation[],
): SetupOfficeLocationsReturnTypeItem[] {
  const newLocations = newState.map(
    (newLocation): SetupOfficeLocationsReturnTypeItem => {
      const existingLocation = currentState.find(
        (location) =>
          location.officeLocationKey === newLocation.officeLocationKey,
      );

      if (
        existingLocation &&
        isSameOfficeLocation(existingLocation, newLocation)
      ) {
        return {
          type: 'none',
        };
      }

      if (existingLocation) {
        return {
          type: 'update',
          data: newLocation,
        };
      }

      return {
        type: 'add',
        data: newLocation,
      };
    },
  );

  const deletedLocations = currentState
    .filter(
      (location) =>
        !newState.some(
          (newLocation) =>
            newLocation.officeLocationKey === location.officeLocationKey,
        ),
    )
    .map(
      (location): SetupOfficeLocationsReturnTypeItem => ({
        type: 'delete',
        officeLocationKey: location.officeLocationKey,
      }),
    );

  return [...newLocations, ...deletedLocations];
}

import { Box, FormHeader, IconButton, Text } from '@orbiapp/components';

import { InsightsMetric } from '../../../../../models';
import { getInsightsMetricTx } from '../../../../../utils';

export function Header(props: { metric: InsightsMetric }) {
  const { metric } = props;

  return (
    <FormHeader flexAlign="end" flex flexJustify="between" gap={16} flexWrap>
      <Box flex gap={8}>
        <IconButton icon="chevron-left" to="/insights" />
        <Text
          color="pageTitle"
          variant="titleMd"
          tx={getInsightsMetricTx(metric)}
          as="h1"
        />
      </Box>
    </FormHeader>
  );
}

import {
  Base64File,
  Box,
  LAYOUT_Z_INDEX,
  Text,
  constructBase64,
} from '@orbiapp/components';
import styled, { css } from 'styled-components';

import { assets } from '../../../../assets';

const Preview = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      box-shadow: 0px 4px 16px 2px rgba(11, 11, 13, 0.08);

      color: ${theme.colors.profilePreviewText};

      cursor: default;
    `;
  }}
`;

const CoverImage = styled(Box)<{ base64File: Base64File | null }>`
  ${(props) => {
    const { base64File } = props;

    return css`
      aspect-ratio: 16/10;
      border-radius: 0 0 8px 8px;

      background-image: url(${base64File
        ? constructBase64(base64File)
        : assets.noImageFound});

      background-position: center;
      background-size: cover;

      max-width: 100%;
      min-width: fit-content;

      pointer-events: none;

      button {
        opacity: 0.9;
      }
    `;
  }}
`;

const FollowButton = styled(Box)`
  button {
    min-width: 83px;
    height: 29px;
  }
`;

const VideoIframe = styled('iframe')`
  border: unset;
  height: 100%;
  width: 100%;

  pointer-events: none;

  padding-bottom: 16px;
`;

const Locaton = styled(Box)<{ highlighted: boolean }>`
  ${(props) => {
    const { theme, highlighted } = props;

    return css`
      ${highlighted &&
      css`
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px dashed ${theme.colors.profilePreviewLocationBorder};
      `}
    `;
  }}
`;

const LocationsMapPreview = styled(Box)`
  border-radius: 8px;

  p {
    backdrop-filter: blur(10px);
    padding: 10px;
  }

  img {
    filter: blur(2px);
  }
`;

const About = styled(Box)`
  p:first-child {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  p:nth-child(2) {
    visibility: hidden;
  }
`;

const HiddenBox = styled(Box)`
  visibility: hidden;
`;

const PlaceholderRow = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border-radius: 999px;
      height: 16px;

      background-color: ${theme.colors.profilePreviewPlaceholderRow};
    `;
  }}
`;

const PlaceholderArea = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border-radius: 8px;
      background-color: ${theme.colors.profilePreviewPlaceholderArea};
    `;
  }}
`;

const ToggleButton = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      cursor: pointer;

      border-radius: 4px 0 0 4px;

      color: ${theme.colors.profilePreviewSidebarToggleButton};
      transition: right ${theme.transitions.fast};
      border: 1px solid
        ${theme.colors.profilePreviewSidebarToggleButtonBackground};

      background-color: ${theme.colors
        .profilePreviewSidebarToggleButtonBackground};

      :hover {
        border-color: ${theme.colors
          .profilePreviewSidebarToggleButtonBorderHover};
      }

      :active {
        color: ${theme.colors.profilePreviewSidebarToggleButtonBackground};

        border-color: ${theme.colors
          .profilePreviewSidebarToggleButtonBackground};

        background-color: ${theme.colors.profilePreviewSidebarToggleButton};
      }

      p {
        writing-mode: vertical-rl;
      }

      top: 50%;
      transform: translateY(-50%);
      z-index: ${LAYOUT_Z_INDEX.contentSidebar + 1};
    `;
  }}
`;

const WordBreakText = styled(Text)`
  word-wrap: break-word;
  overflow: hidden;
`;

export const Styled = {
  About,
  CoverImage,
  FollowButton,
  HiddenBox,
  PlaceholderArea,
  PlaceholderRow,
  Preview,
  Locaton,
  LocationsMapPreview,
  ToggleButton,
  VideoIframe,
  WordBreakText,
};

import Joi from 'joi';

import { PHONE_MAX_LENGTH } from './phone.constants';

const PHONE_NUMBER_REGEXP = new RegExp(
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
);

export const PhoneSchema = Joi.string()
  .regex(PHONE_NUMBER_REGEXP)
  .max(PHONE_MAX_LENGTH)
  .required()
  .messages({
    'string.empty': 'errors.phone.empty',
    'string.max': 'errors.phone.max',
    'string.pattern.base': 'errors.phone.invalid',
    'any.required': 'errors.phone.empty',
  });

import * as Sentry from '@sentry/react';
import React from 'react';
import useHotjar from 'react-use-hotjar';

import { ENV, HOTJAR_ID, HOTJAR_VERSION_NUMBER } from '../config';
import {
  AccountSelector,
  AuthStateSelector,
  CompanySelector,
  useSelector,
} from '../store';

export function useTracking() {
  const isSuperAdmin = useSelector(AuthStateSelector.selectIsSuperAdmin);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const email = useSelector(AccountSelector.selectEmail);

  const companyName = useSelector(CompanySelector.selectCompanyName);
  const isEmulating = useSelector(AuthStateSelector.selectIsEmulating);
  const role = useSelector(AccountSelector.selectRole);
  const fullName = useSelector(AccountSelector.selectFullName);
  const companyKey = useSelector(CompanySelector.selectCompanyKey);

  const { initHotjar, identifyHotjar } = useHotjar();

  React.useEffect(() => {
    if (ENV !== 'production' || isSuperAdmin) {
      return;
    }

    initHotjar(HOTJAR_ID, HOTJAR_VERSION_NUMBER, false);

    const userAttributes: Record<string, any> = userKey
      ? {
          companyName,
          email,
          fullName,
          isEmulating,
          role,
          companyKey,
        }
      : { isEmulating: false };

    if (userKey) {
      identifyHotjar(userKey, userAttributes);
    }

    Sentry.setTags(userAttributes);
  }, [
    initHotjar,
    identifyHotjar,
    userKey,
    email,
    companyName,
    isEmulating,
    isSuperAdmin,
    role,
    fullName,
    companyKey,
  ]);
}

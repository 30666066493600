import { INITIAL_STATUS_EXTENSION_STATE } from '../store.constants';
import {
  subjectAreaAdapter,
  subjectAreaCategoriesAdapter,
} from './subject-areas.adapter';
import { SubjectAreaState } from './subject-areas.types';

export const initialSubjectAreasState: SubjectAreaState = {
  getSubjectAreaCategoriesStatus: INITIAL_STATUS_EXTENSION_STATE,
  subjectAreaCategoriesState: subjectAreaCategoriesAdapter.getInitialState(),
  subjectAreaState: subjectAreaAdapter.getInitialState(),
};

import {
  Box,
  ControlledSelect,
  DangerIconButton,
  DragDrop,
  DragDropItem,
  DragDropItemHandle,
  FormSection,
  FormSectionHeader,
  GetSelectOverrides,
  Link,
  OptionProps,
  Radio,
  SolidIconButton,
  Text,
  Tooltip,
  getUID,
  isTxString,
  translate,
  useDragDropItem,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  CreateIndexedOfficeLocation,
  CreateJobForm,
  UpdateJobDraftSchema,
  WorkModel,
} from '../../../../../models';
import { OfficeLocationsSelector, useSelector } from '../../../../../store';
import { useSaveField } from '../create-job.helpers';

function SelectWorkModel() {
  const officeLocationKeys = useSelector(
    OfficeLocationsSelector.selectOfficeLocationKeys,
  );
  const formContext = useFormContext<CreateJobForm>();

  const saveLocationData = useSaveField('locationData', {
    schema: UpdateJobDraftSchema.locationData({ officeLocationKeys }),
    defaultValue: formContext.getValues('locationData'),
  });

  const workModel = useWatch({
    control: formContext.control,
    name: 'locationData.workModel',
  });

  const handleRadioClick = (workModel: WorkModel) => () => {
    const locations = formContext.getValues('locationData.officeLocations');

    formContext.setValue('locationData.workModel', workModel, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });

    if (workModel === 'remote' && !locations?.length) {
      formContext.setValue('locationData.officeLocations', null, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
    }

    if (formContext.formState.isSubmitted) {
      formContext.trigger('locationData.officeLocations');
    }

    saveLocationData();
  };

  return (
    <Box gap={16} flexAlign="start" flex>
      <Radio
        checked={workModel === 'onsite'}
        dotPlacement="left"
        onChange={handleRadioClick('onsite')}
        tx="label.work-models.onsite"
      />

      <Radio
        checked={workModel === 'hybrid'}
        dotPlacement="left"
        onChange={handleRadioClick('hybrid')}
        tx="label.work-models.hybrid"
      />

      <Radio
        checked={workModel === 'remote'}
        dotPlacement="left"
        onChange={handleRadioClick('remote')}
        subtitleTx="label.job-form.locations-are-optional"
        tx="label.work-models.remote"
      />
    </Box>
  );
}

function AddLocationButton() {
  const formContext = useFormContext<CreateJobForm>();

  const addLocation = () => {
    const locations = formContext.getValues('locationData.officeLocations');

    const newState = [
      ...(locations ?? []),
      {
        officeLocationKey: getUID(),
        index: locations?.length ?? 0,
      },
    ];

    formContext.setValue('locationData.officeLocations', newState, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  return (
    <Tooltip placement="left" titleTx="label.tooltip.add">
      <SolidIconButton onClick={addLocation} icon="plus-circle-outline" />
    </Tooltip>
  );
}

const LocationListItem = (props: {
  index: number;
  officeLocationKey: string;
}) => {
  const { index, officeLocationKey } = props;

  const companyLocations = useSelector(
    OfficeLocationsSelector.selectOfficeLocations,
  );
  const officeLocationKeys = useSelector(
    OfficeLocationsSelector.selectOfficeLocationKeys,
  );

  const formContext = useFormContext<CreateJobForm>();

  const saveLocationData = useSaveField('locationData', {
    schema: UpdateJobDraftSchema.locationData({ officeLocationKeys }),
    defaultValue: formContext.getValues('locationData'),
  });

  const locations = useWatch({
    control: formContext.control,
    name: 'locationData.officeLocations',
  });

  const locationKeys = new Set(
    locations?.map((location) => location.officeLocationKey),
  );

  const { isDragging, isSorting } = useDragDropItem();

  const selectBoxRef = React.useRef<HTMLDivElement>(null);

  const removeLocation = () => {
    const newState: CreateIndexedOfficeLocation[] = [];

    locations?.forEach((location) => {
      if (location.index === index) return;

      if (location.index > index) {
        newState.push({ ...location, index: location.index - 1 });
        return;
      }

      newState.push(location);
    });

    formContext.setValue(
      'locationData.officeLocations',
      newState.length ? newState : null,
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    formContext.clearErrors(`locationData.officeLocations.${index}`);

    saveLocationData();
  };

  const validateIfSubmitted = async () => {
    const ok = await formContext.trigger('locationData.officeLocations');
    if (ok) {
      saveLocationData();
    }
  };

  const unusedLocations = companyLocations.filter((location) => {
    return (
      location.officeLocationKey === officeLocationKey ||
      !locationKeys.has(location.officeLocationKey)
    );
  });

  const options: OptionProps[] = unusedLocations.length
    ? unusedLocations.map((companyLocation) => ({
        text: companyLocation.label,
        value: companyLocation.officeLocationKey,
        icon: 'map-pin-outline',
        subtitle: companyLocation.address,
      }))
    : [
        {
          notPickable: true,
          tx: 'label.job-form.no-locations-available',
          value: ' ',
        },
      ];

  const selectedOption = companyLocations.find(
    (location) => location.officeLocationKey === officeLocationKey,
  );

  const getOverrides: GetSelectOverrides = () => ({
    label: selectedOption?.label ?? translate('label.job-form.location'),
    value:
      selectedOption?.address ?? translate('label.job-form.select-location'),
  });

  const handleSelectClose = () => {
    const isValidLocation = officeLocationKeys.includes(
      formContext.getValues(`locationData.officeLocations.${index}`)
        .officeLocationKey,
    );

    if (!isValidLocation) {
      formContext.trigger(`locationData.officeLocations.${index}`);
    } else {
      formContext.clearErrors(`locationData.officeLocations.${index}`);
    }
  };

  React.useEffect(() => {
    if (selectedOption) return;

    selectBoxRef.current?.scrollIntoView({
      inline: 'start',
      block: 'center',
    });
  }, [selectedOption]);

  return (
    <DragDropItem id={officeLocationKey} ref={selectBoxRef} flex gap={16}>
      <Box mt={16}>
        <Tooltip placement="right" titleTx="label.tooltip.move">
          <DragDropItemHandle disabled={locationKeys.size === 1} />
        </Tooltip>
      </Box>

      <ControlledSelect
        hideInputHelper={isDragging || isSorting}
        defaultIsOpen={!selectedOption}
        getOverrides={getOverrides}
        name={`locationData.officeLocations.${index}.officeLocationKey`}
        onChange={validateIfSubmitted}
        onClose={handleSelectClose}
        options={options}
        placeholderTx="label.job-form.select-location"
        width="100%"
      />

      <Box mt={16}>
        <Tooltip placement="left" titleTx="label.tooltip.remove">
          <DangerIconButton
            icon="minus-circle-outline"
            onClick={removeLocation}
          />
        </Tooltip>
      </Box>
    </DragDropItem>
  );
};

function renderLocation(location: CreateIndexedOfficeLocation) {
  return (
    <LocationListItem
      key={`location-item-${location.officeLocationKey}`}
      index={location.index}
      officeLocationKey={location.officeLocationKey}
    />
  );
}

function LocationItems() {
  const officeLocationKeys = useSelector(
    OfficeLocationsSelector.selectOfficeLocationKeys,
  );

  const formContext = useFormContext<CreateJobForm>();

  const saveLocationData = useSaveField('locationData', {
    schema: UpdateJobDraftSchema.locationData({ officeLocationKeys }),
    defaultValue: formContext.getValues('locationData'),
  });

  const locations = useWatch({
    control: formContext.control,
    name: 'locationData.officeLocations',
  });

  const items = (locations ?? []).map((item) => {
    return { ...item, id: item.officeLocationKey };
  });

  const handleChange = (newState: typeof items) => {
    formContext.setValue(
      'locationData.officeLocations',
      newState.map(
        (location, index) => ({
          officeLocationKey: location.officeLocationKey,
          index,
        }),
        {
          shouldValidate: formContext.formState.isSubmitted,
          shouldDirty: true,
        },
      ),
    );

    saveLocationData();
  };

  return (
    <DragDrop items={items} onChange={handleChange}>
      {locations?.map(renderLocation)}
    </DragDrop>
  );
}

function _Locations() {
  const formMethods = useFormContext<CreateJobForm>();

  const errorTx =
    formMethods.formState.errors.locationData?.officeLocations?.root?.message ||
    formMethods.formState.errors.locationData?.officeLocations?.message;

  return (
    <FormSection>
      <FormSectionHeader
        flexDirection="row"
        flexAlign="start"
        flexJustify="between"
      >
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.job-form.steps.4.title"
            variant="bodyMdBold"
            color="formSectionHeader"
          />

          <Box flexWrap gap={4} flex>
            <Text
              tx="label.job-form.steps.4.subtitle"
              color="formSectionDescription"
              variant="bodyMd"
            />
            <Link
              to="/settings/company"
              tx="label.job-form.steps.4.link"
              variant="secondary"
            />
          </Box>

          {isTxString(errorTx) && (
            <Box mt={16}>
              <Text tx={errorTx} variant="bodyMd" color="errorLabel" />
            </Box>
          )}
        </Box>

        <AddLocationButton />
      </FormSectionHeader>

      <SelectWorkModel />

      <LocationItems />
    </FormSection>
  );
}

_Locations.displayName = 'Locations';
export const Locations = React.memo(_Locations);

import dayjs from 'dayjs';

import { PartialTalentPoolUser } from '../connect';

export const insightsMetrics = ['views', 'engagement', 'impressions'] as const;
export type InsightsMetric = (typeof insightsMetrics)[number];

export const insightsInterval = [
  'custom',
  'today',
  'last_7_days',
  'last_30_days',
  'last_90_days',
  'last_365_days',
  'previous_month',
  'previous_quarter',
  'previous_year',
] as const;

export type InsightsInterval = (typeof insightsInterval)[number];

export const insightsCompareInterval = [
  'previous_period',
  'previous_year',
] as const;

export type InsightsCompareInterval = (typeof insightsCompareInterval)[number];

type InsightsCustomRange = {
  interval: Extract<(typeof insightsInterval)[number], 'custom'>;

  compare: InsightsCompareInterval | null;

  fromDate: number;
  toDate: number;
};

type InsightsPreDefinedRange = {
  interval: Exclude<(typeof insightsInterval)[number], 'custom'>;

  compare: InsightsCompareInterval | null;
};

export type InsightsRange = InsightsPreDefinedRange | InsightsCustomRange;

export interface LiveInsights {
  companyProfiles: {
    live: number;
    draft: number;
  };

  jobs: {
    live: number;
    scheduled: number;
    draft: number;
    closed: number;
  };

  companyAds: {
    live: number;
    scheduled: number;
    past: number;
  };

  talentPool: {
    userCount: number;
    users: PartialTalentPoolUser[];
  };

  offers: {
    closed: number;
    draft: number;
    live: number;
    scheduled: number;
  };
}

export interface GetTimeBasedInsights {
  mainPeriod: dayjs.Dayjs[];
  comparedPeriod: dayjs.Dayjs[] | null;

  metrics: ('engagement' | 'views' | 'impressions')[];
  showUnique: boolean;
  items:
    | (
        | { type: 'total' }
        | { type: 'job'; jobKey: string }
        | { type: 'company_profile'; companyProfileKey: string }
        | { type: 'company_ad'; companyAdKey: string }
        | { type: 'offer'; offerKey: string }
      )[]
    | 'all';

  include: ('time_series' | 'table_breakdown')[];
}

export type InsightsItem =
  | {
      type: 'job';
      jobKey: string;
      label: string;
    }
  | {
      type: 'company_profile';
      companyProfileKey: string;
      label: string;
    }
  | {
      type: 'company_ad';
      companyAdKey: string;
      label: string;
    }
  | {
      type: 'offer';
      offerKey: string;
      label: string;
    };

export type TimeSeriesInsightsItem = {
  points: { timestamp: number; value: number }[];
} & (InsightsItem | { type: 'total' });

export interface TimeSeriesInsightsItems {
  metric: InsightsMetric;
  items: TimeSeriesInsightsItem[];
  itemsCompared: TimeSeriesInsightsItem[] | null;
}

type TableInsightsItem = Partial<
  Record<InsightsMetric | 'engagementRate', number>
>;

export type TableInsightsItems = {
  values: TableInsightsItem;
  valuesCompared: TableInsightsItem | null;
} & (InsightsItem | { type: 'total'; label: string });

export type SelectedInsightsItem = Exclude<
  GetTimeBasedInsights['items'][number],
  string
>;

export interface TimeBasedInsights {
  timeSeries: TimeSeriesInsightsItems[];
  tableEntries: TableInsightsItems[];
}

export interface TimeSeriesConfig {
  selectedRange: InsightsRange;
  selectedItems: SelectedInsightsItem[];
  showUnique: boolean;
}

import { CreateCompanyParams } from '../../../../../models';
import { CreateCompanyDto } from './company.dto';

export const createCompanyParamsMapper = (
  createCompanyParams: CreateCompanyParams,
): CreateCompanyDto => ({
  companyInvitationKey: createCompanyParams.companyInvitationKey,
  employeeCount: createCompanyParams.employeeCount,
  industry: createCompanyParams.industry,
  logo: createCompanyParams.logo,
  name: createCompanyParams.name,
});

export namespace JobErrors {
  export const contactName = {
    'string.empty': 'errors.name.empty',
  };

  export const coverImage = {
    'any.required': 'errors.cover-image.empty',
    'object.base': 'errors.cover-image.empty',
    'any.only': 'errors.cover-image.empty',
  };

  export const contactPhone = {
    'string.empty': 'errors.phone.empty',
    'string.max': 'errors.phone.max',
    'string.pattern.base': 'errors.phone.invalid',
    'any.required': 'errors.phone.empty',
  };

  export const dailyBudget = {
    'number.min': 'errors.daily-budget.min',
    'number.max': 'errors.daily-budget.max',
    'any.ref': 'errors.daily-budget.ref',
    'number.base': 'errors.daily-budget.min',
    'number.unsafe': 'errors.daily-budget.max',
  };

  export const description = {
    'string.empty': 'errors.description.empty',
  };

  export const employmentType = {};

  export const endDate = {
    'number.min': 'errors.end-date.before-start',
    'number.base': 'errors.end-date.min',
    'any.ref': 'errors.end-date.before-start',
  };

  export const officeLocations = {
    'array.includesRequiredUnknowns': 'errors.office-locations.empty',
    'array.base': 'errors.office-locations.empty',
  };

  export const originalJobUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const officeLocationSchema = {
    'any.only': 'errors.select.none-selected',
  };

  export const officeLocationKey = {
    'any.only': 'errors.select.none-selected',
  };

  export const qualifications = {};

  export const startDate = {
    'number.max': 'errors.start-date.max',
    'number.min': 'errors.start-date.min',
    'number.base': 'errors.start-date.min',
  };

  export const studyLevels = {
    'array.includesRequiredUnknowns': 'errors.study-levels.empty',
  };

  export const subjectAreaKeys = {
    'array.includesRequiredUnknowns': 'errors.subject-area-keys.empty',
  };

  export const title = {
    'string.empty': 'errors.title.empty',
  };

  export const totalBudget = {
    'number.max': 'errors.total-budget.max',
    'number.min': 'errors.total-budget.min',
    'number.base': 'errors.total-budget.min',
    'number.unsafe': 'errors.total-budget.max',
  };

  export const workModel = {};
}

export namespace PickProfileErrors {
  export const companyProfileKey = {
    'string.empty': 'errors.select.none-selected',
  };
}

import { INITIAL_STATUS_EXTENSION_STATE } from '../store.constants';
import { companyProfileDraftsAdapter } from './company-profile-drafts.adapter';
import { CompanyProfileDraftsState } from './company-profile-drafts.types';

export const initialCompanyProfileDraftsState =
  companyProfileDraftsAdapter.getInitialState<CompanyProfileDraftsState>({
    createCompanyProfileDraftStatus: INITIAL_STATUS_EXTENSION_STATE,
    deleteCompanyProfileDraftStatus: INITIAL_STATUS_EXTENSION_STATE,
    getCompanyProfileDraftsStatus: INITIAL_STATUS_EXTENSION_STATE,
    getCompanyProfileDraftStatus: INITIAL_STATUS_EXTENSION_STATE,
    updateCompanyProfileDraftStatus: INITIAL_STATUS_EXTENSION_STATE,
  });

import Joi from 'joi';

import { EmailSchema } from '../email';
import { AuthErrors } from './auth.errors';

export namespace AuthSchema {
  export const email = EmailSchema.required();

  export const password = Joi.string().required().messages(AuthErrors.password);
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  offerCategoriesAdapter,
  partialOfferDraftsAdapter,
  partialOffersAdapter,
} from './offers.adapter';

export namespace CreateOfferDraftSelector {
  const selectSelf = (state: RootState) => state.offers.createOfferDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

export namespace OfferDraftsSelector {
  const offerDraftsSelectors = partialOfferDraftsAdapter.getSelectors(
    (state: RootState) => state.offers.offerDrafts.data,
  );

  const selectSelf = (state: RootState) => state.offers.offerDrafts;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = offerDraftsSelectors.selectAll;
}

export namespace OfferDraftSelector {
  const selectSelf = (state: RootState) => state.offers.offerDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);

  export const selectOfferDraftKey = createSelector(
    selectSelf,
    (state) => state.data?.offerDraftKey ?? null,
  );

  export const selectEditMeta = createSelector(
    selectSelf,
    (state) => state.data?.editMeta ?? null,
  );
}

export namespace UpdateOfferDraftSelector {
  const selectSelf = (state: RootState) => state.offers.updateOfferDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace DeleteOfferDraftSelector {
  const selectSelf = (state: RootState) => state.offers.deleteOfferDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace CreateOfferSelector {
  const selectSelf = (state: RootState) => state.offers.createOffer;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

export namespace OfferSelector {
  const selectSelf = (state: RootState) => state.offers.offer;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);

  export const selectOfferKey = createSelector(
    selectSelf,
    (state) => state.data?.offerKey ?? null,
  );

  export const selectUpdatedAt = createSelector(
    selectSelf,
    (state) => state.data?.editMeta.updatedAt ?? null,
  );

  export const selectEditMeta = createSelector(
    selectSelf,
    (state) => state.data?.editMeta ?? null,
  );

  export const selectStartDate = createSelector(
    selectSelf,
    (state) => state.data?.startDate ?? null,
  );

  export const selectHasBeenPublished = createSelector(selectSelf, (state) =>
    Boolean(state.data?.startDate && state.data?.startDate < Date.now()),
  );

  export const selectEndDate = createSelector(
    selectSelf,
    (state) => state.data?.endDate ?? null,
  );

  export const selectDisabledAt = createSelector(
    selectSelf,
    (state) => state.data?.disabledAt ?? null,
  );

  export const selectTitle = createSelector(
    selectSelf,
    (state) => state.data?.title ?? null,
  );
}

export namespace OffersSelector {
  const offersSelectors = partialOffersAdapter.getSelectors(
    (state: RootState) => state.offers.offers.data,
  );

  const selectSelf = (state: RootState) => state.offers.offers;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = offersSelectors.selectAll;

  export const selectPagination = createSelector(
    selectSelf,
    (state) => state.pagination,
  );

  export const selectSearch = createSelector(
    selectSelf,
    (state) => state.search,
  );
}

export namespace UpdateOfferSelector {
  const selectSelf = (state: RootState) => state.offers.updateOffer;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace ToggleIsDisabledSelector {
  const selectSelf = (state: RootState) => state.offers.toggleIsDisabled;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace DuplicateOfferSelector {
  const selectSelf = (state: RootState) => state.offers.duplicateOffer;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

export namespace OfferCategoriesSelector {
  const offerCategoriesSelectors = offerCategoriesAdapter.getSelectors(
    (state: RootState) => state.offers.offerCategories.data,
  );

  const selectSelf = (state: RootState) => state.offers.offerCategories;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = offerCategoriesSelectors.selectAll;

  export const selectById = offerCategoriesSelectors.selectById;
}

export namespace OffersLinkSelector {
  const selectSelf = (state: RootState) => state.offers.offersLink;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

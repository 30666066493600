import { sortIndexesAndPreserveOrder } from '../../utils';
import {
  createCompanyProfileThunk,
  deleteCompanyProfileThunk,
  getCompanyProfileThunk,
  getCompanyProfilesThunk,
  updateCompanyProfileIndexesThunk,
  updateCompanyProfileThunk,
} from './company-profiles.actions';
import { companyProfilesAdapter } from './company-profiles.adapter';
import { CompanyProfilesBuilder } from './company-profiles.types';

const createCompanyProfileBuild = (builder: CompanyProfilesBuilder) => {
  return builder
    .addCase(createCompanyProfileThunk.fulfilled, (state, action) => {
      companyProfilesAdapter.addOne(state, action.payload);

      state.createCompanyProfileStatus.status = 'idle';
    })
    .addCase(createCompanyProfileThunk.pending, (state) => {
      state.createCompanyProfileStatus.status = 'pending';
      state.createCompanyProfileStatus.error = null;
    })
    .addCase(createCompanyProfileThunk.rejected, (state, action) => {
      state.createCompanyProfileStatus.status = 'idle';
      state.createCompanyProfileStatus.error = action.error;
    });
};

const getCompanyProfilesBuild = (builder: CompanyProfilesBuilder) => {
  return builder
    .addCase(getCompanyProfilesThunk.fulfilled, (state, action) => {
      companyProfilesAdapter.upsertMany(state, action.payload);

      state.getCompanyProfilesStatus.status = 'idle';
    })
    .addCase(getCompanyProfilesThunk.pending, (state) => {
      state.getCompanyProfilesStatus.status = 'pending';
      state.getCompanyProfilesStatus.error = null;
    })
    .addCase(getCompanyProfilesThunk.rejected, (state, action) => {
      state.getCompanyProfilesStatus.status = 'idle';
      state.getCompanyProfilesStatus.error = action.error;
    });
};

const getCompanyProfileBuild = (builder: CompanyProfilesBuilder) => {
  return builder
    .addCase(getCompanyProfileThunk.fulfilled, (state, action) => {
      companyProfilesAdapter.upsertOne(state, action.payload);

      state.getCompanyProfileStatus.status = 'idle';
    })
    .addCase(getCompanyProfileThunk.pending, (state) => {
      state.getCompanyProfileStatus.status = 'pending';
      state.getCompanyProfileStatus.error = null;
    })
    .addCase(getCompanyProfileThunk.rejected, (state, action) => {
      state.getCompanyProfileStatus.status = 'idle';
      state.getCompanyProfileStatus.error = action.error;
    });
};

const updateCompanyProfileBuild = (builder: CompanyProfilesBuilder) => {
  return builder
    .addCase(updateCompanyProfileThunk.fulfilled, (state, action) => {
      companyProfilesAdapter.updateOne(state, {
        changes: action.payload,
        id: action.meta.arg.companyProfileKey,
      });

      state.updateCompanyProfileStatus.status = 'idle';
    })
    .addCase(updateCompanyProfileThunk.pending, (state) => {
      state.updateCompanyProfileStatus.status = 'pending';
      state.updateCompanyProfileStatus.error = null;
    })
    .addCase(updateCompanyProfileThunk.rejected, (state, action) => {
      state.updateCompanyProfileStatus.status = 'idle';
      state.updateCompanyProfileStatus.error = action.error;
    });
};

const deleteCompanyProfileBuild = (builder: CompanyProfilesBuilder) => {
  return builder
    .addCase(deleteCompanyProfileThunk.fulfilled, (state, action) => {
      companyProfilesAdapter.removeOne(state, action.meta.arg);

      const selectors = companyProfilesAdapter.getSelectors();
      const profiles = selectors.selectAll(state);

      companyProfilesAdapter.setAll(
        state,
        sortIndexesAndPreserveOrder(profiles),
      );

      state.deleteCompanyProfileStatus.status = 'idle';
    })
    .addCase(deleteCompanyProfileThunk.pending, (state) => {
      state.deleteCompanyProfileStatus.status = 'pending';
      state.deleteCompanyProfileStatus.error = null;
    })
    .addCase(deleteCompanyProfileThunk.rejected, (state, action) => {
      state.deleteCompanyProfileStatus.status = 'idle';
      state.deleteCompanyProfileStatus.error = action.error;
    });
};

const updateCompanyProfileIndexesBuild = (builder: CompanyProfilesBuilder) => {
  return builder
    .addCase(updateCompanyProfileIndexesThunk.fulfilled, (state, action) => {
      companyProfilesAdapter.updateMany(
        state,
        action.meta.arg.companyProfiles.map(({ companyProfileKey, index }) => ({
          id: companyProfileKey,
          changes: { index },
        })),
      );

      state.updateCompanyProfileIndexesStatus.status = 'idle';
    })
    .addCase(updateCompanyProfileIndexesThunk.pending, (state) => {
      state.updateCompanyProfileIndexesStatus.status = 'pending';
      state.updateCompanyProfileIndexesStatus.error = null;
    })
    .addCase(updateCompanyProfileIndexesThunk.rejected, (state, action) => {
      state.updateCompanyProfileIndexesStatus.status = 'idle';
      state.updateCompanyProfileIndexesStatus.error = action.error;
    });
};

export const extraReducers = (builder: CompanyProfilesBuilder) => {
  createCompanyProfileBuild(builder);
  getCompanyProfilesBuild(builder);
  getCompanyProfileBuild(builder);
  updateCompanyProfileBuild(builder);
  deleteCompanyProfileBuild(builder);
  updateCompanyProfileIndexesBuild(builder);
};

import {
  BreadcrumbsToolbar,
  ContentContainer,
  InnerContentContainerAlt,
  InnerPageContainer,
  PageContainer,
} from '@orbiapp/components';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { isCompanyProfileDraftAndNotPartialCompanyProfileDraft } from '../../../../models';
import {
  CompanyProfileDraftsSelector,
  getCompanyProfileDraftThunk,
  getOfficeLocationsThunk,
  getPerkCategoriesThunk,
  getSubjectAreaCategoriesThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import {
  ProfileForm,
  ProfileHeader,
  ProfileProvider,
  ProfileSidebar,
} from '../components';

export function CreateProfile() {
  const params = useParams<{ companyProfileDraftKey: string }>();

  const profileDraft = useSelector((state) =>
    CompanyProfileDraftsSelector.selectById(
      state,
      params.companyProfileDraftKey ?? '',
    ),
  );

  const error = useSelector(
    CompanyProfileDraftsSelector.getCompanyProfileDraftError,
  );

  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    dispatch(getPerkCategoriesThunk());
    dispatch(getSubjectAreaCategoriesThunk());
    dispatch(getOfficeLocationsThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (isLoaded) return;

    if (isCompanyProfileDraftAndNotPartialCompanyProfileDraft(profileDraft)) {
      setIsLoaded(true);
      return;
    }

    (async () => {
      if (!params.companyProfileDraftKey) {
        setIsLoaded(true);
        return;
      }

      await dispatch(
        getCompanyProfileDraftThunk(params.companyProfileDraftKey),
      );

      setIsLoaded(true);
    })();
  }, [dispatch, params.companyProfileDraftKey, isLoaded, profileDraft]);

  if (isLoaded && error) {
    return <Navigate to="/profiles" />;
  }

  return (
    <ProfileProvider
      formMode="createProfile"
      isLoaded={isLoaded}
      profile={profileDraft}
    >
      <PageContainer>
        <BreadcrumbsToolbar
          breadcrumbListItems={[
            { to: '/profiles', tx: 'label.breadcrumbs.profiles.profiles' },
            {
              to: `/profiles/create-profile/${profileDraft?.companyProfileDraftKey}`,
              tx: 'label.breadcrumbs.profiles.update-profile-draft',
            },
          ]}
        />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainerAlt>
              <ProfileHeader />

              <ProfileForm />
            </InnerContentContainerAlt>
          </ContentContainer>

          <ProfileSidebar />
        </InnerPageContainer>
      </PageContainer>
    </ProfileProvider>
  );
}

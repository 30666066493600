import {
  getFileFromUrl,
  isUndefined,
  stringHasLengthOrNull,
} from '@orbiapp/components';

import {
  OfferDraft,
  PartialOfferDraft,
  UpdateOfferDraft,
} from '../../../../../../models';
import {
  OfferDraftDto,
  PartialOfferDraftDto,
  UpdateOfferDraftDto,
} from './drafts.dto';

export async function offerDraftMapper(
  offerDraftDto: OfferDraftDto,
): Promise<OfferDraft> {
  const coverImageBase64 = offerDraftDto.coverImage
    ? await getFileFromUrl(offerDraftDto.coverImage.original.url)
    : null;

  const base = {
    offerDraftKey: offerDraftDto.offerDraftKey,

    editMeta: offerDraftDto.editMeta,

    offerCategory: offerDraftDto.offerCategory,
    title: offerDraftDto.title,
    description: offerDraftDto.description,
    coverImage: offerDraftDto.coverImage,
    coverImageBase64,

    endDate: offerDraftDto.endDate,
    contactName: offerDraftDto.contactName,
    contactEmail: offerDraftDto.contactEmail,

    locations: offerDraftDto.locations,
    requireStudentVerification: offerDraftDto.requireStudentVerification,

    countryKeys: offerDraftDto.countryKeys,
  };

  if (offerDraftDto.type === 'online') {
    return {
      ...base,
      type: 'online',
      link: offerDraftDto.link,
      code: offerDraftDto.code,
    };
  }

  return {
    ...base,
    type: 'consumable',
    consumeWaitMinutes: offerDraftDto.consumeWaitMinutes,
  };
}

export function partialOfferDraftMapper(
  partialOfferDraftDto: PartialOfferDraftDto,
): PartialOfferDraft {
  return {
    contactName: partialOfferDraftDto.contactName,
    editMeta: partialOfferDraftDto.editMeta,
    endDate: partialOfferDraftDto.endDate,
    title: partialOfferDraftDto.title,
    offerDraftKey: partialOfferDraftDto.offerDraftKey,
  };
}

export function updateOfferDraftMapper(
  updateOfferDraftForm: UpdateOfferDraft,
): UpdateOfferDraftDto {
  const updateOfferDraftDto: UpdateOfferDraftDto = {};

  if (!isUndefined(updateOfferDraftForm.offerCategoryKey)) {
    updateOfferDraftDto.offerCategoryKey = stringHasLengthOrNull(
      updateOfferDraftForm.offerCategoryKey,
    );
  }

  if (!isUndefined(updateOfferDraftForm.title)) {
    updateOfferDraftDto.title = stringHasLengthOrNull(
      updateOfferDraftForm.title,
    );
  }

  if (!isUndefined(updateOfferDraftForm.description)) {
    updateOfferDraftDto.description = stringHasLengthOrNull(
      updateOfferDraftForm.description,
    );
  }

  if (!isUndefined(updateOfferDraftForm.coverImage)) {
    updateOfferDraftDto.coverImage = updateOfferDraftForm.coverImage;
  }

  if (!isUndefined(updateOfferDraftForm.endDate)) {
    updateOfferDraftDto.endDate = updateOfferDraftForm.endDate;
  }

  if (!isUndefined(updateOfferDraftForm.contactName)) {
    updateOfferDraftDto.contactName = stringHasLengthOrNull(
      updateOfferDraftForm.contactName,
    );
  }

  if (!isUndefined(updateOfferDraftForm.contactEmail)) {
    updateOfferDraftDto.contactEmail = stringHasLengthOrNull(
      updateOfferDraftForm.contactEmail,
    );
  }

  if (!isUndefined(updateOfferDraftForm.code)) {
    updateOfferDraftDto.code = stringHasLengthOrNull(updateOfferDraftForm.code);
  }

  if (!isUndefined(updateOfferDraftForm.link)) {
    updateOfferDraftDto.link = stringHasLengthOrNull(updateOfferDraftForm.link);
  }

  if (!isUndefined(updateOfferDraftForm.files)) {
    updateOfferDraftDto.files = updateOfferDraftForm.files;
  }

  if (!isUndefined(updateOfferDraftForm.isConsumable)) {
    updateOfferDraftDto.isConsumable = updateOfferDraftForm.isConsumable;
  }

  if (!isUndefined(updateOfferDraftForm.consumeWaitMinutes)) {
    updateOfferDraftDto.consumeWaitMinutes =
      updateOfferDraftForm.consumeWaitMinutes;
  }

  if (!isUndefined(updateOfferDraftForm.locations)) {
    updateOfferDraftDto.locations = updateOfferDraftForm.locations;
  }

  if (!isUndefined(updateOfferDraftForm.requireStudentVerification)) {
    updateOfferDraftDto.requireStudentVerification =
      updateOfferDraftForm.requireStudentVerification;
  }

  if (!isUndefined(updateOfferDraftForm.countryKeys)) {
    updateOfferDraftDto.countryKeys = updateOfferDraftForm.countryKeys;
  }

  return updateOfferDraftDto;
}

import Joi from 'joi';
import React from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

import { CreateJobForm, UpdateJobDraftForm } from '../../../../models';
import {
  JobDraftSelector,
  updateJobDraftThunk,
  useDispatch,
  useSelector,
} from '../../../../store';

function getValueBeforeSave(value: any) {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return null;
  }

  return value;
}

export function useSaveField<T extends Joi.Schema, K>(
  name: FieldPath<UpdateJobDraftForm>,
  options: {
    schema: T;
    triggerNames?: FieldPath<UpdateJobDraftForm>[];
    defaultValue: K;
  },
) {
  const jobDraftKey = useSelector(JobDraftSelector.selectJobDraftKey);

  const lastErrorRef = React.useRef<Joi.ValidationError | null>(null);
  const lastSavedValueRef = React.useRef<any>(
    JSON.stringify(options.defaultValue),
  );

  const formContext = useFormContext<CreateJobForm>();

  const dispatch = useDispatch();

  const saveField = React.useCallback(async () => {
    if (!jobDraftKey) return;

    const value = formContext.getValues(name);

    if (lastSavedValueRef.current === JSON.stringify(value)) {
      return;
    } else {
      lastSavedValueRef.current = JSON.stringify(value);
    }

    const valueToSave = getValueBeforeSave(value);

    if (!valueToSave && lastErrorRef.current) {
      formContext.clearErrors(options?.triggerNames ?? name);
      lastErrorRef.current = null;
    }

    if (valueToSave) {
      const { error } = options.schema.validate(value);

      if (error) {
        formContext.trigger(options.triggerNames ?? name);
        lastErrorRef.current = error;
        return;
      }

      if (lastErrorRef.current) {
        formContext.clearErrors(options.triggerNames ?? name);
        lastErrorRef.current = null;
      }
    }

    dispatch(updateJobDraftThunk({ jobDraftKey, [name]: valueToSave }));
  }, [
    dispatch,
    formContext,
    jobDraftKey,
    name,
    options?.schema,
    options?.triggerNames,
  ]);

  return saveField;
}

export const industryList = [
  'accounting',
  'airlines_or_aviation',
  'alternative_dispute_resolution',
  'alternative_medicine',
  'animation',
  'apparel_and_fashion',
  'architecture_and_planning',
  'arts_and_crafts',
  'automotive',
  'aviation_and_aerospace',
  'banking',
  'biotechnology',
  'broadcast_media',
  'building_materials',
  'business_supplies_and_equipment',
  'capital_markets',
  'chemicals',
  'civic_and_social_organization',
  'civil_engineering',
  'commercial_real_estate',
  'computer_and_network_security',
  'computer_games',
  'computer_hardware',
  'computer_networking',
  'computer_software',
  'construction',
  'consumer_electronics',
  'consumer_goods',
  'consumer_services',
  'cosmetics',
  'dairy',
  'defense_and_space',
  'design',
  'e_learning',
  'education_management',
  'electrical_and_electronic_manufacturing',
  'entertainment',
  'environmental_services',
  'events_services',
  'executive_office',
  'facilities_services',
  'farming',
  'financial_services',
  'fine_art',
  'fishery',
  'food_and_beverages',
  'food_production',
  'fundraising',
  'furniture',
  'gambling_and_casinos',
  'glass_ceramics_and_concrete',
  'government_administration',
  'government_relations',
  'graphic_design',
  'health_wellness_and_fitness',
  'higher_education',
  'hospital_and_health_care',
  'hospitality',
  'human_resources',
  'import_and_export',
  'individual_and_family_services',
  'industrial_automation',
  'information_services',
  'information_technology_and_services',
  'insurance',
  'international_affairs',
  'international_trade_and_development',
  'internet',
  'investment_banking',
  'investment_management',
  'judiciary',
  'law_enforcement',
  'law_practice',
  'legal_services',
  'legislative_office',
  'leisure_travel_and_tourism',
  'libraries',
  'logistics_and_supply_chain',
  'luxury_goods_and_jewelry',
  'machinery',
  'management_consulting',
  'maritime',
  'market_research',
  'marketing_and_advertising',
  'mechanical_or_industrial_engineering',
  'media_production',
  'medical_device',
  'medical_practice',
  'mental_health_care',
  'military',
  'mining_and_metals',
  'mobile_games',
  'motion_pictures_and_film',
  'museums_and_institutions',
  'music',
  'nanotechnology',
  'newspapers',
  'nonprofit_organization_management',
  'oil_and_energy',
  'online_media',
  'outsourcing_or_offshoring',
  'package_or_freight_delivery',
  'packaging_and_containers',
  'paper_and_forest_products',
  'performing_arts',
  'pharmaceuticals',
  'philanthropy',
  'photography',
  'plastics',
  'political_organization',
  'primary_or_secondary_education',
  'printing',
  'professional_training_and_coaching',
  'program_development',
  'public_policy',
  'public_relations_and_communications',
  'public_safety',
  'publishing',
  'railroad_manufacture',
  'ranching',
  'real_estate',
  'recreational_facilities_and_services',
  'religious_institutions',
  'renewables_and_environment',
  'research',
  'restaurants',
  'retail',
  'security_and_investigations',
  'semiconductors',
  'shipbuilding',
  'sporting_goods',
  'sports',
  'staffing_and_recruiting',
  'supermarkets',
  'telecommunications',
  'textiles',
  'think_tanks',
  'tobacco',
  'translation_and_localization',
  'transportation_or_trucking_or_railroad',
  'utilities',
  'venture_capital_and_private_equity',
  'veterinary',
  'warehousing',
  'wholesale',
  'wine_and_spirits',
  'wireless',
  'writing_and_editing',
] as const;

export type Industry = (typeof industryList)[number];

export function formatIndustry(industry?: Industry) {
  if (!industry) return '';

  return `${industry.charAt(0).toUpperCase()}${industry
    .slice(1)
    .replaceAll('_', ' ')}`;
}

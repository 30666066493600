import { initialTeamState } from './team.initial';
import { TeamState } from './team.types';

function reset(state: TeamState) {
  Object.assign(state, initialTeamState);
}

export const reducers = {
  reset,
};

import { createEntityAdapter } from '@reduxjs/toolkit';

import { SubjectArea, SubjectAreaCategory } from '../../models';

export const subjectAreaCategoriesAdapter = createEntityAdapter<
  SubjectAreaCategory,
  string
>({
  selectId: (subjectAreaCategory) => subjectAreaCategory.subjectAreaCategoryKey,
});

export const subjectAreaAdapter = createEntityAdapter<SubjectArea, string>({
  selectId: (subjectArea) => subjectArea.subjectAreaKey,
});

import { JobDraft } from './job-draft.model';

export function getDraftDefaultValues(
  options: Pick<JobDraft, 'editMeta' | 'jobDraftKey'>,
): JobDraft {
  return {
    budgetData: {
      budgetType: 'both',
      dailyBudget: null,
      totalBudget: null,
    },
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    coverImage: null,
    coverImageBase64: null,
    description: '',
    editMeta: options.editMeta,
    employmentType: 'fulltime',
    endDate: Date.now(),
    jobDraftKey: options.jobDraftKey,
    locationData: {
      officeLocations: null,
      workModel: 'onsite',
    },
    originalJobUrl: '',
    qualifications: null,
    startDate: Date.now(),
    studyLevels: null,
    subjectAreas: null,
    title: '',
  };
}

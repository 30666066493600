import {
  getCreateJobFormDefaultValues,
  getJobEditMeta,
  jobToPartialJobMapper,
} from '../../utils';
import {
  closeJobThunk,
  createJobDraftThunk,
  createJobThunk,
  deleteJobDraftThunk,
  duplicateJobThunk,
  getDraftThunk,
  getDraftsThunk,
  getJobThunk,
  getJobsThunk,
  updateJobDraftThunk,
  updateJobThunk,
} from './jobs.actions';
import { partialJobDraftsAdapter, partialJobsAdapter } from './jobs.adapter';
import { JobsBuilder } from './jobs.types';

function deleteDraftBuild(builder: JobsBuilder) {
  return builder
    .addCase(deleteJobDraftThunk.pending, (state) => {
      state.deleteJobDraft.status = 'pending';
      state.deleteJobDraft.error = null;
    })
    .addCase(deleteJobDraftThunk.fulfilled, (state, action) => {
      state.deleteJobDraft.status = 'idle';
      partialJobDraftsAdapter.removeOne(state.jobDrafts.data, action.meta.arg);
    })
    .addCase(deleteJobDraftThunk.rejected, (state, action) => {
      state.deleteJobDraft.status = 'idle';
      state.deleteJobDraft.error = action.error;
    });
}

function updateDraftBuild(builder: JobsBuilder) {
  return builder
    .addCase(updateJobDraftThunk.pending, (state) => {
      state.updateJobDraft.status = 'pending';
      state.updateJobDraft.error = null;
    })
    .addCase(updateJobDraftThunk.fulfilled, (state) => {
      state.updateJobDraft.status = 'idle';

      if (state.jobDraft.data) {
        state.jobDraft.data.editMeta.updatedAt = Date.now();
      }
    })
    .addCase(updateJobDraftThunk.rejected, (state, action) => {
      state.updateJobDraft.status = 'idle';
      state.updateJobDraft.error = action.error;
    });
}

function createDraftBuild(builder: JobsBuilder) {
  return builder
    .addCase(createJobDraftThunk.pending, (state) => {
      state.createJobDraft.status = 'pending';
      state.createJobDraft.error = null;
    })
    .addCase(createJobDraftThunk.fulfilled, (state, action) => {
      state.createJobDraft.status = 'idle';
      state.createJobDraft.data = action.payload.jobDraftKey;
      state.jobDraft.data = action.payload;
      state.createJobDefaultValues = getCreateJobFormDefaultValues(
        action.payload,
      );
      partialJobDraftsAdapter.addOne(state.jobDrafts.data, {
        editMeta: action.payload.editMeta,
        employmentType: action.payload.employmentType,
        endDate: action.payload.endDate,
        jobDraftKey: action.payload.jobDraftKey,
        title: action.payload.title,
      });
    })
    .addCase(createJobDraftThunk.rejected, (state, action) => {
      state.createJobDraft.status = 'idle';
      state.createJobDraft.error = action.error;
    });
}

function getDraftBuild(builder: JobsBuilder) {
  return builder
    .addCase(getDraftThunk.pending, (state) => {
      state.jobDraft.status = 'pending';
      state.jobDraft.error = null;
    })
    .addCase(getDraftThunk.fulfilled, (state, action) => {
      state.jobDraft.status = 'idle';
      state.jobDraft.data = action.payload;
      state.createJobDefaultValues = getCreateJobFormDefaultValues(
        action.payload,
      );
    })
    .addCase(getDraftThunk.rejected, (state, action) => {
      state.jobDraft.status = 'idle';
      state.jobDraft.error = action.error;
    });
}

function getDraftsBuild(builder: JobsBuilder) {
  return builder
    .addCase(getDraftsThunk.pending, (state) => {
      state.jobDrafts.status = 'pending';
      state.jobDrafts.error = null;
    })
    .addCase(getDraftsThunk.fulfilled, (state, action) => {
      state.jobDrafts.status = 'idle';
      partialJobDraftsAdapter.setAll(state.jobDrafts.data, action.payload);
    })
    .addCase(getDraftsThunk.rejected, (state, action) => {
      state.jobDrafts.status = 'idle';
      state.jobDrafts.error = action.error;
    });
}

function updateJobBuild(builder: JobsBuilder) {
  return builder
    .addCase(updateJobThunk.pending, (state) => {
      state.updateJob.status = 'pending';
      state.updateJob.error = null;
    })
    .addCase(updateJobThunk.fulfilled, (state, action) => {
      const { jobKey: id, ...changes } = jobToPartialJobMapper(action.payload);

      partialJobsAdapter.updateOne(state.jobs.data, { changes, id });

      state.updateJob.status = 'idle';
      state.job.data = action.payload;
    })
    .addCase(updateJobThunk.rejected, (state, action) => {
      state.updateJob.status = 'idle';
      state.updateJob.error = action.error;
    });
}

function createJobBuild(builder: JobsBuilder) {
  return builder
    .addCase(createJobThunk.pending, (state) => {
      state.createJob.status = 'pending';
      state.createJob.error = null;
    })
    .addCase(createJobThunk.fulfilled, (state, action) => {
      const jobForm = action.meta.arg;
      const { jobKey, account } = action.payload;

      if (state.jobs.data.ids.length > 0 && account) {
        partialJobsAdapter.addOne(state.jobs.data, {
          dailyBudgetReachedAt: null,
          editMeta: getJobEditMeta(account),
          employmentType: jobForm.employmentType,
          endDate: jobForm.endDate,
          isManuallyClosed: false,
          jobKey,
          startDate: jobForm.startDate,
          title: jobForm.title,
          totalBudgetReachedAt: null,
        });
      }

      state.createJob.status = 'idle';
      state.createJob.data = action.payload.jobKey;
    })
    .addCase(createJobThunk.rejected, (state, action) => {
      state.createJob.status = 'idle';
      state.createJob.error = action.error;
    });
}

function getJobBuild(builder: JobsBuilder) {
  return builder
    .addCase(getJobThunk.pending, (state) => {
      state.job.status = 'pending';
      state.job.error = null;
    })
    .addCase(getJobThunk.fulfilled, (state, action) => {
      state.job.status = 'idle';
      state.job.data = action.payload;
    })
    .addCase(getJobThunk.rejected, (state, action) => {
      state.job.status = 'idle';
      state.job.error = action.error;
    });
}

function getJobsBuild(builder: JobsBuilder) {
  return builder
    .addCase(getJobsThunk.pending, (state, action) => {
      state.jobs.status = 'pending';
      state.jobs.error = null;
      state.jobs.pagination = action.meta.arg;
    })
    .addCase(getJobsThunk.fulfilled, (state, action) => {
      state.jobs.status = 'idle';
      partialJobsAdapter.setMany(state.jobs.data, action.payload);
    })
    .addCase(getJobsThunk.rejected, (state, action) => {
      state.jobs.status = 'idle';
      state.jobs.error = action.error;
    });
}

function closeJobBuild(builder: JobsBuilder) {
  return builder
    .addCase(closeJobThunk.pending, (state) => {
      state.closeJob.status = 'pending';
      state.closeJob.error = null;
    })
    .addCase(closeJobThunk.fulfilled, (state, action) => {
      state.closeJob.status = 'idle';

      if (state.job.data) {
        state.job.data.isManuallyClosed = true;
      }

      partialJobsAdapter.updateOne(state.jobs.data, {
        id: action.meta.arg,
        changes: {
          isManuallyClosed: true,
        },
      });
    })
    .addCase(closeJobThunk.rejected, (state, action) => {
      state.closeJob.status = 'idle';
      state.closeJob.error = action.error;
    });
}

function duplicateJobBuild(builder: JobsBuilder) {
  return builder
    .addCase(duplicateJobThunk.pending, (state) => {
      state.duplicateJob.status = 'pending';
      state.duplicateJob.error = null;
    })
    .addCase(duplicateJobThunk.fulfilled, (state, action) => {
      state.duplicateJob.status = 'idle';
      state.createJobDraft.data = action.payload.jobDraftKey;
      state.jobDraft.data = action.payload;
      state.createJobDefaultValues = getCreateJobFormDefaultValues(
        action.payload,
      );
      partialJobDraftsAdapter.addOne(state.jobDrafts.data, {
        editMeta: action.payload.editMeta,
        employmentType: action.payload.employmentType,
        endDate: action.payload.endDate,
        jobDraftKey: action.payload.jobDraftKey,
        title: action.payload.title,
      });
    })
    .addCase(duplicateJobThunk.rejected, (state, action) => {
      state.duplicateJob.status = 'idle';
      state.duplicateJob.error = action.error;
    });
}

export function extraReducers(builder: JobsBuilder) {
  deleteDraftBuild(builder);
  updateDraftBuild(builder);
  createDraftBuild(builder);
  getDraftBuild(builder);
  getDraftsBuild(builder);

  duplicateJobBuild(builder);
  closeJobBuild(builder);
  updateJobBuild(builder);
  createJobBuild(builder);
  getJobBuild(builder);
  getJobsBuild(builder);
}

import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const InsightsInnerContentContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      padding: 16px;
      gap: 16px;

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 32px;
        gap: 32px;
      }
    `;
  }}
`;

export const Styled = {
  InsightsInnerContentContainer,
};

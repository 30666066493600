import { Box, Card } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const StatsCard = styled(Card)`
  width: 100%;

  @media (min-width: 648px) {
    width: 300px;
  }
`;

const InfoHeader = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      flex-direction: column;

      @media (min-width: ${theme.breakpoints.md}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
      }
    `;
  }}
`;

export const Styled = { StatsCard, InfoHeader };

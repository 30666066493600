import { PayloadAction } from '@reduxjs/toolkit';

import { InsightsRange, SelectedInsightsItem } from '../../models';
import { initialInsightsState } from './insights.initial';
import { InsightsState } from './insights.types';

function reset(state: InsightsState) {
  Object.assign(state, initialInsightsState);
}

function setInsightsTimeSeriesConfigRange(
  state: InsightsState,
  action: PayloadAction<InsightsRange>,
) {
  state.timeSeriesConfig.selectedRange = action.payload;
}

function setInsightsTimeSeriesConfigItems(
  state: InsightsState,
  action: PayloadAction<SelectedInsightsItem[]>,
) {
  state.timeSeriesConfig.selectedItems = action.payload;
}

function setInsightsTimeSeriesShowUnique(
  state: InsightsState,
  action: PayloadAction<boolean>,
) {
  state.timeSeriesConfig.showUnique = action.payload;
}

function setInsightsTimeSeriesConfigByProductRange(
  state: InsightsState,
  action: PayloadAction<InsightsRange>,
) {
  state.timeSeriesConfigByProduct.selectedRange = action.payload;
}

function setInsightsTimeSeriesByProductShowUnique(
  state: InsightsState,
  action: PayloadAction<boolean>,
) {
  state.timeSeriesConfigByProduct.showUnique = action.payload;
}

export const reducers = {
  reset,

  setInsightsTimeSeriesConfigItems,
  setInsightsTimeSeriesConfigRange,
  setInsightsTimeSeriesShowUnique,

  setInsightsTimeSeriesConfigByProductRange,
  setInsightsTimeSeriesByProductShowUnique,
};

import { Account, Pagination, formatPublishDate } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateJobForm,
  Job,
  JobDraft,
  JobsOrderByKey,
  PartialJob,
  PartialJobDraft,
  UpdateJobDraftForm,
  UpdateJobForm,
  getDraftDefaultValues,
} from '../../models';
import { OrbiApi, getPageAndNextPage, v2 } from '../../services';
import {
  getUpdateJobDraftValuesFromJob,
  getUpdateJobReturnValue,
} from '../../utils';
import { officeLocationsAdapter } from '../company';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { subjectAreaAdapter } from '../subject-areas';
import { partialJobsAdapter } from './jobs.adapter';

export const createJobDraftThunk = createAsyncThunk<
  JobDraft,
  undefined,
  ThunkApiConfig
>('jobs/drafts/create-draft', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  if (!state.account.account.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.jobs.drafts.createDraft, undefined);

  if (res.kind === 'ok') {
    const now = Date.now();

    return getDraftDefaultValues({
      jobDraftKey: res.data,
      editMeta: {
        createdAt: now,
        updatedAt: now,
        createdBy: {
          firstName: state.account.account.data.firstName,
          lastName: state.account.account.data.lastName,
          userKey: state.account.account.data.userKey,
          profilePicture: state.account.account.data.profilePicture,
        },
        updatedBy: {
          firstName: state.account.account.data.firstName,
          lastName: state.account.account.data.lastName,
          userKey: state.account.account.data.userKey,
          profilePicture: state.account.account.data.profilePicture,
        },
      },
    });
  }

  return thunkAPI.rejectWithValue(res);
});

export const deleteJobDraftThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('jobs/drafts/delete-draft', async (jobDraftKey, thunkAPI) => {
  const res = await OrbiApi.call(v2.jobs.drafts.deleteDraft, jobDraftKey);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('delete-job-draft:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('delete-job-draft:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateJobDraftThunk = createAsyncThunk<
  void,
  UpdateJobDraftForm,
  ThunkApiConfig
>('jobs/drafts/update-draft', async (updateJobDraftForm, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.jobs.drafts.updateDraft,
    updateJobDraftForm,
  );

  if (res.kind === 'ok') {
    return;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getDraftsThunk = createAsyncThunk<
  PartialJobDraft[],
  undefined,
  ThunkApiConfig
>('jobs/drafts/get-drafts', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.jobs.drafts.getDrafts, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getDraftThunk = createAsyncThunk<JobDraft, string, ThunkApiConfig>(
  'jobs/drafts/get-draft',
  async (jobDraftKey, thunkAPI) => {
    const res = await OrbiApi.call(v2.jobs.drafts.getDraft, jobDraftKey);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const createJobThunk = createAsyncThunk<
  { jobKey: string; account: Account | null },
  CreateJobForm,
  ThunkApiConfig
>('jobs/create-job', async (createJobForm, thunkAPI) => {
  const res = await OrbiApi.call(v2.jobs.createJob, createJobForm);
  const state = thunkAPI.getState();

  if (res.kind === 'ok') {
    thunkAPI.dispatch(
      setAlert('publish-job:success', {
        publishDate: formatPublishDate(createJobForm.startDate).toLowerCase(),
      }),
    );
    return { jobKey: res.data, account: state.account.account.data };
  }

  thunkAPI.dispatch(setAlert('publish-job:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateJobThunk = createAsyncThunk<
  Job,
  UpdateJobForm,
  ThunkApiConfig
>('jobs/update-job', async (updateJobForm, thunkAPI) => {
  const state = thunkAPI.getState();
  const jobKey = state.jobs.job.data?.jobKey;

  if (!jobKey) {
    thunkAPI.dispatch(setAlert('update-job:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.jobs.updateJob, {
    updateJobForm,
    jobKey,
  });

  if (!state.jobs.job.data || !state.company.officeLocations) {
    thunkAPI.dispatch(setAlert('update-job:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-job:success'));
    return getUpdateJobReturnValue(
      updateJobForm,
      state.jobs.job.data,
      officeLocationsAdapter
        .getSelectors()
        .selectAll(state.company.officeLocations.data),
      subjectAreaAdapter
        .getSelectors()
        .selectAll(state.subjectAreas.subjectAreaState),
      state.account.account.data,
    );
  }

  thunkAPI.dispatch(setAlert('update-job:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getJobsThunk = createAsyncThunk<
  PartialJob[],
  Pagination<JobsOrderByKey>,
  ThunkApiConfig
>('jobs/get-jobs', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    partialJobsAdapter.getSelectors().selectAll(state.jobs.jobs.data),
    v2.jobs.getJobs,
    {
      pagination,
      show: state.jobs.jobs.show,
    },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const getJobThunk = createAsyncThunk<Job, string, ThunkApiConfig>(
  'jobs/get-job',
  async (jobKey, thunkAPI) => {
    const res = await OrbiApi.call(v2.jobs.getJob, jobKey);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const closeJobThunk = createAsyncThunk<void, string, ThunkApiConfig>(
  'jobs/close-job',
  async (jobKey, thunkAPI) => {
    const res = await OrbiApi.call(v2.jobs.closeJob, jobKey);

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('close-job:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('close-job:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const duplicateJobThunk = createAsyncThunk<
  JobDraft,
  Job | undefined,
  ThunkApiConfig
>('jobs/duplicate-job', async (jobData, thunkAPI) => {
  const state = thunkAPI.getState();

  const job = state.jobs.job.data || jobData;

  if (!job || !state.account.account.data) {
    thunkAPI.dispatch(setAlert('duplicate-job:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const createDraftRes = await OrbiApi.call(
    v2.jobs.drafts.createDraft,
    undefined,
  );
  if (createDraftRes.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('duplicate-job:error'));
    return thunkAPI.rejectWithValue(createDraftRes);
  }

  const updateDraftValues = getUpdateJobDraftValuesFromJob(
    job,
    createDraftRes.data,
  );
  const updateDraftRes = await OrbiApi.call(
    v2.jobs.drafts.updateDraft,
    updateDraftValues,
  );

  if (updateDraftRes.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('duplicate-job:error'));
    await OrbiApi.call(v2.jobs.drafts.deleteDraft, createDraftRes.data);
    return thunkAPI.rejectWithValue(updateDraftRes);
  }

  const now = Date.now();

  thunkAPI.dispatch(setAlert('duplicate-job:success'));

  return getDraftDefaultValues({
    jobDraftKey: createDraftRes.data,
    editMeta: {
      createdAt: now,
      updatedAt: now,
      createdBy: {
        firstName: state.account.account.data.firstName,
        lastName: state.account.account.data.lastName,
        userKey: state.account.account.data.userKey,
        profilePicture: state.account.account.data.profilePicture,
      },
      updatedBy: {
        firstName: state.account.account.data.firstName,
        lastName: state.account.account.data.lastName,
        userKey: state.account.account.data.userKey,
        profilePicture: state.account.account.data.profilePicture,
      },
    },
  });
});

import { Box, BoxProps } from '../box';
import { Styled } from './chart-grid.styled';

export function ChartGrid(props: BoxProps) {
  return <Styled.ChartGrid {...props} />;
}

export function ChartGridItem(props: BoxProps) {
  return <Box width="100%" minWidth="100%" {...props} />;
}

import {
  fromScu,
  getFileFromUrl,
  isUndefined,
  toScu,
} from '@orbiapp/components';

import {
  JobDraft,
  PartialJobDraft,
  UpdateJobDraftForm,
} from '../../../../../../models';
import { getJobBudgetType } from '../../../../../../utils';
import {
  JobDraftDto,
  PartialJobDraftDto,
  UpdateJobDraftDto,
} from './drafts.dto';

export async function jobDraftMapper(
  jobDraftDto: JobDraftDto,
): Promise<JobDraft> {
  const coverImageBase64 = jobDraftDto.coverImage?.original.url
    ? await getFileFromUrl(jobDraftDto.coverImage.original.url)
    : null;

  return {
    budgetData: {
      dailyBudget: jobDraftDto.budgetData.dailyBudget
        ? fromScu(jobDraftDto.budgetData.dailyBudget)
        : null,
      totalBudget: jobDraftDto.budgetData.totalBudget
        ? fromScu(jobDraftDto.budgetData.totalBudget)
        : null,
      budgetType: getJobBudgetType({
        totalBudget: jobDraftDto.budgetData.totalBudget,
        dailyBudget: jobDraftDto.budgetData.dailyBudget,
      }),
    },
    contactEmail: jobDraftDto.contactEmail,
    contactName: jobDraftDto.contactName,
    contactPhone: jobDraftDto.contactPhone,
    coverImage: jobDraftDto.coverImage,
    coverImageBase64,
    description: jobDraftDto.description,
    editMeta: jobDraftDto.editMeta,
    employmentType: jobDraftDto.employmentType,
    endDate: jobDraftDto.endDate,
    jobDraftKey: jobDraftDto.jobDraftKey,
    locationData: {
      officeLocations: jobDraftDto.locationData.officeLocations,
      workModel: jobDraftDto.locationData.workModel,
    },
    originalJobUrl: jobDraftDto.originalJobUrl,
    qualifications: jobDraftDto.qualifications,
    startDate: jobDraftDto.startDate,
    studyLevels: jobDraftDto.studyLevels,
    subjectAreas: jobDraftDto.subjectAreas,
    title: jobDraftDto.title,
  };
}

export function partialJobDraftMapper(
  partialJobDraftDto: PartialJobDraftDto,
): PartialJobDraft {
  return {
    endDate: partialJobDraftDto.endDate,
    employmentType: partialJobDraftDto.employmentType,
    editMeta: partialJobDraftDto.editMeta,
    jobDraftKey: partialJobDraftDto.jobDraftKey,
    title: partialJobDraftDto.title,
  };
}

export function updateJobDraftMapper(
  jobDraftForm: UpdateJobDraftForm,
): UpdateJobDraftDto {
  const updateJobDraftDto: UpdateJobDraftDto = {};

  if (!isUndefined(jobDraftForm.contactEmail)) {
    updateJobDraftDto.contactEmail = jobDraftForm.contactEmail;
  }
  if (!isUndefined(jobDraftForm.contactName)) {
    updateJobDraftDto.contactName = jobDraftForm.contactName;
  }
  if (!isUndefined(jobDraftForm.contactPhone)) {
    updateJobDraftDto.contactPhone = jobDraftForm.contactPhone;
  }
  if (!isUndefined(jobDraftForm.coverImage)) {
    updateJobDraftDto.coverImage = jobDraftForm.coverImage;
  }

  if (!isUndefined(jobDraftForm.description)) {
    updateJobDraftDto.description = jobDraftForm.description;
  }
  if (!isUndefined(jobDraftForm.employmentType)) {
    updateJobDraftDto.employmentType = jobDraftForm.employmentType;
  }
  if (!isUndefined(jobDraftForm.endDate)) {
    updateJobDraftDto.endDate = jobDraftForm.endDate;
  }
  if (!isUndefined(jobDraftForm.locationData)) {
    updateJobDraftDto.locationData = {
      workModel: jobDraftForm.locationData.workModel,
      officeLocations:
        jobDraftForm.locationData.officeLocations?.map((officeLocation) => ({
          index: officeLocation.index,
          officeLocationKey: officeLocation.officeLocationKey,
        })) ?? null,
    };
  }
  if (!isUndefined(jobDraftForm.originalJobUrl)) {
    updateJobDraftDto.originalJobUrl = jobDraftForm.originalJobUrl;
  }
  if (!isUndefined(jobDraftForm.qualifications)) {
    updateJobDraftDto.qualifications = jobDraftForm.qualifications;
  }
  if (!isUndefined(jobDraftForm.startDate)) {
    updateJobDraftDto.startDate = jobDraftForm.startDate;
  }
  if (!isUndefined(jobDraftForm.studyLevels)) {
    updateJobDraftDto.studyLevels = jobDraftForm.studyLevels;
  }
  if (!isUndefined(jobDraftForm.subjectAreaKeys)) {
    updateJobDraftDto.subjectAreaKeys = jobDraftForm.subjectAreaKeys;
  }
  if (!isUndefined(jobDraftForm.title)) {
    updateJobDraftDto.title = jobDraftForm.title;
  }

  if (!isUndefined(jobDraftForm.budgetData)) {
    jobDraftForm.budgetData.dailyBudget = parseInt(
      `${jobDraftForm.budgetData.dailyBudget ?? 0}`,
    );
    jobDraftForm.budgetData.totalBudget = parseInt(
      `${jobDraftForm.budgetData.totalBudget ?? 0}`,
    );

    updateJobDraftDto.budgetData = {
      dailyBudget: jobDraftForm.budgetData.dailyBudget
        ? toScu(jobDraftForm.budgetData.dailyBudget)
        : null,
      totalBudget: jobDraftForm.budgetData.totalBudget
        ? toScu(jobDraftForm.budgetData.totalBudget)
        : null,
    };
  }

  return updateJobDraftDto;
}

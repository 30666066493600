import { Box, FormHeader, IconButton, Text } from '@orbiapp/components';

export function Header() {
  return (
    <FormHeader flexAlign="end" flex flexJustify="between" gap={16} flexWrap>
      <Box flex gap={8}>
        <IconButton icon="chevron-left" to="/insights" />
        <Text
          color="pageTitle"
          variant="titleMd"
          tx="title.insights.full-breakdown"
          as="h1"
        />
      </Box>
    </FormHeader>
  );
}

import { INITIAL_STATUS_EXTENSION_STATE } from '../store.constants';
import { companyProfilesAdapter } from './company-profiles.adapter';
import { CompanyProfilesState } from './company-profiles.types';

export const initialCompanyProfilesState =
  companyProfilesAdapter.getInitialState<CompanyProfilesState>({
    createCompanyProfileStatus: INITIAL_STATUS_EXTENSION_STATE,
    deleteCompanyProfileStatus: INITIAL_STATUS_EXTENSION_STATE,
    getCompanyProfilesStatus: INITIAL_STATUS_EXTENSION_STATE,
    getCompanyProfileStatus: INITIAL_STATUS_EXTENSION_STATE,
    updateCompanyProfileIndexesStatus: INITIAL_STATUS_EXTENSION_STATE,
    updateCompanyProfileStatus: INITIAL_STATUS_EXTENSION_STATE,

    profilesSortState: { orderByKey: 'priority', sortOrder: 'asc' },
  });

import {
  Card,
  EmptyState,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  reduceDecimals,
} from '@orbiapp/components';

import { MetricBreakdownTableCell } from '../../../../../components/insights';
import { useOrbiBusinessCalendar } from '../../../../../helpers';
import {
  InsightsTimeSeriesByProductSelector,
  useSelector,
} from '../../../../../store';

const TABLE_COLUMN_WIDTHS = {
  metric: 'auto',
  amount: 200,
};

const tableHeader = (
  <TableHeader>
    <TableRow>
      <TableHead tx="label.metrics-breakdown.metric" />
      <TableHead tx="label.metrics-breakdown.amount" />
    </TableRow>
  </TableHeader>
);

function MetricsBreakdownTable() {
  const tableEntries = useSelector(
    InsightsTimeSeriesByProductSelector.selectTableEntries,
  );
  const insightsTimeSeriesStatus = useSelector(
    InsightsTimeSeriesByProductSelector.selectStatus,
  );

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const data = tableEntries[0];

  if (insightsTimeSeriesStatus === 'pending') {
    return (
      <Table>
        {tableHeader}
        <TableBody>
          <TablePlaceholderRows
            rowCount={5}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (!data) {
    return (
      <EmptyState
        buttonTx="label.connect.contact-sales"
        titleTx="label.breakdown-by-profile-locked"
        href={orbiBusinessCalendarUrl}
      />
    );
  }

  return (
    <Table>
      {tableHeader}
      <TableBody>
        <TableRow>
          <TableCell
            width={TABLE_COLUMN_WIDTHS.metric}
            tx="label.insights.engagement-rate"
          />
          <MetricBreakdownTableCell
            width={TABLE_COLUMN_WIDTHS.amount}
            value={reduceDecimals(data.values.engagementRate ?? 0)}
            comparedValue={reduceDecimals(data.valuesCompared?.engagementRate)}
            unit="%"
          />
        </TableRow>
        <TableRow>
          <TableCell
            width={TABLE_COLUMN_WIDTHS.metric}
            tx="label.insights.engagement"
          />
          <MetricBreakdownTableCell
            width={TABLE_COLUMN_WIDTHS.amount}
            value={data.values.engagement ?? 0}
            comparedValue={data.valuesCompared?.engagement}
          />
        </TableRow>
        <TableRow>
          <TableCell
            width={TABLE_COLUMN_WIDTHS.metric}
            tx="label.insights.views"
          />
          <MetricBreakdownTableCell
            width={TABLE_COLUMN_WIDTHS.amount}
            value={data.values.views ?? 0}
            comparedValue={data.valuesCompared?.views}
          />
        </TableRow>
        <TableRow>
          <TableCell
            width={TABLE_COLUMN_WIDTHS.metric}
            tx="label.insights.impressions"
          />
          <MetricBreakdownTableCell
            width={TABLE_COLUMN_WIDTHS.amount}
            value={data.values.impressions ?? 0}
            comparedValue={data.valuesCompared?.impressions}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
}

export function MetricsBreakdown() {
  return (
    <Card relative>
      <Text variant="bodyMdBold" tx="label.metrics-breakdown.title" />

      <MetricsBreakdownTable />
    </Card>
  );
}

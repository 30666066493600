import {
  ControlledSelect,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CreateJobForm,
  UpdateJobDraftSchema,
  employmentTypes,
} from '../../../../../models';
import { getEmploymentTypeTx } from '../../../../../utils';
import { useSaveField } from '../create-job.helpers';

function _TypeOfPositions() {
  const formContext = useFormContext<CreateJobForm>();

  const saveEmploymentType = useSaveField('employmentType', {
    schema: UpdateJobDraftSchema.employmentType,
    defaultValue: formContext.getValues('employmentType'),
  });

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.5.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.5.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <ControlledSelect
        onChange={saveEmploymentType}
        options={employmentTypes.map((employmentType) => ({
          tx: getEmploymentTypeTx(employmentType),
          value: employmentType,
        }))}
        name="employmentType"
        labelTx="label.job-form.employment-type"
      />
    </FormSection>
  );
}

_TypeOfPositions.displayName = 'TypeOfPositions';
export const TypeOfPositions = React.memo(_TypeOfPositions);

import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import {
  CompanyProfile,
  CompanyProfileDraft,
  CompanyProfileForm,
  CreateCompanyProfile,
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
  UpdateCompanyProfile,
  UpdateCompanyProfileDraft,
  isCompanyProfileOrCompanyProfileDraft,
} from '../models';

export function sortIndexesAndPreserveOrder<
  T extends (CompanyProfile | PartialCompanyProfile)[],
>(profiles: T) {
  const arrayOrder = new Map(
    profiles.map((profile, i) => [profile.companyProfileKey, i]),
  );

  const newProfiles = profiles
    .sort((a, b) => a.index - b.index)
    .map((profile, i) => ({ ...profile, index: i }))
    .sort((a, b) => {
      const aIndex = arrayOrder.get(a.companyProfileKey)!;
      const bIndex = arrayOrder.get(b.companyProfileKey)!;

      return aIndex - bIndex;
    });

  return newProfiles;
}

const emptyCompanyProfilForm: Omit<CompanyProfileForm, 'draft'> = {
  about: null,
  contactEmail: null,
  contactPhone: null,
  coverImageBase64: null,

  employmentTypes: null,
  funFacts: null,
  name: null,
  oneliner: null,

  perks: null,
  subjectAreaKeys: null,

  videoUrl: null,
  websiteUrl: null,
  locationData: null,
};

export const getCompanyProfilForm = (
  draft: boolean,
  profile?:
    | CompanyProfileDraft
    | PartialCompanyProfileDraft
    | CompanyProfile
    | PartialCompanyProfile,
): CompanyProfileForm => {
  if (!profile) {
    return { ...emptyCompanyProfilForm, draft };
  }

  if (isCompanyProfileOrCompanyProfileDraft(profile)) {
    return {
      about: profile.about,
      contactEmail: profile.contactEmail,
      contactPhone: profile.contactPhone,
      coverImageBase64: profile.coverImageBase64,
      employmentTypes: profile.employmentTypes,
      funFacts: profile.funFacts,
      name: profile.name,

      oneliner: profile.oneliner,
      perks:
        profile.perks?.map((perk) => ({
          description: perk.description,
          index: perk.index,
          perkKey: perk.perkKey,
        })) ?? null,
      subjectAreaKeys:
        profile.subjectAreas?.flatMap(
          (subjectArea) => subjectArea.subjectAreaKey,
        ) ?? null,
      videoUrl: profile.videoUrl,
      websiteUrl: profile.websiteUrl,

      locationData: profile.locationData
        ? {
            workModels: profile.locationData.workModels,
            officeLocations:
              profile.locationData.officeLocations?.map((officeLocation) => ({
                index: officeLocation.index,
                officeLocationKey: officeLocation.officeLocationKey,
              })) ?? null,
          }
        : null,
      draft,
    };
  }

  return {
    ...emptyCompanyProfilForm,
    name: profile.name,
    subjectAreaKeys:
      profile.subjectAreas?.flatMap(
        (subjectArea) => subjectArea.subjectAreaKey,
      ) ?? null,
    draft,
  };
};

export const setCompanyProfile = <
  T = UpdateCompanyProfileDraft | UpdateCompanyProfile,
>(params: {
  getValues: UseFormGetValues<CompanyProfileForm>;
  field: keyof CompanyProfileForm;
}) => {
  const { getValues, field } = params;

  const updateProfile: UpdateCompanyProfileDraft | UpdateCompanyProfile = {};

  switch (field) {
    case 'about':
    case 'contactEmail':
    case 'contactPhone':
    case 'name':
    case 'oneliner':
    case 'videoUrl':
    case 'websiteUrl': {
      updateProfile[field] = getValues(field);

      break;
    }

    case 'coverImageBase64': {
      updateProfile[field] = getValues(field);

      break;
    }

    case 'employmentTypes': {
      updateProfile[field] = getValues(field);

      break;
    }

    case 'funFacts':
    case 'subjectAreaKeys': {
      updateProfile[field] = getValues(field);

      break;
    }

    case 'perks': {
      updateProfile[field] = getValues(field);

      break;
    }

    case 'locationData': {
      updateProfile[field] = getValues(field);

      break;
    }
  }

  return updateProfile as T;
};

export const trimField = (params: {
  field: keyof CompanyProfileForm;
  getValues: UseFormGetValues<CompanyProfileForm>;
  setValue: UseFormSetValue<CompanyProfileForm>;
}) => {
  const { field, getValues, setValue } = params;

  switch (field) {
    case 'about':
    case 'contactEmail':
    case 'contactPhone':
    case 'name':
    case 'oneliner':
    case 'videoUrl':
    case 'websiteUrl': {
      const string = getValues(field);

      if (string !== null) {
        const _string = string.trim();

        setValue(field, _string?.length ? _string : null, {
          shouldDirty: true,
        });
      }

      break;
    }

    case 'employmentTypes':
    case 'funFacts':
    case 'subjectAreaKeys': {
      const stringArray = getValues(field);

      if (stringArray !== null) {
        const _funFacts = stringArray.map((funFact) => funFact.trim());

        setValue(field, _funFacts?.length ? _funFacts : null, {
          shouldDirty: true,
        });
      }

      break;
    }

    case 'perks': {
      const perks = getValues(field);

      if (perks !== null) {
        const _perks = perks.map((perk) => ({
          ...perk,
          description: perk.description.trim(),
        }));

        setValue(field, _perks?.length ? _perks : null, {
          shouldDirty: true,
        });
      }

      break;
    }

    case 'locationData': {
      const locationData = getValues(field);

      if (locationData?.officeLocations) {
        setValue(
          field,
          {
            officeLocations:
              locationData.officeLocations.length === 0 &&
              locationData.workModels.length === 1 &&
              locationData.workModels[0] === 'remote'
                ? null
                : locationData.officeLocations,
            workModels: locationData.workModels,
          },
          {
            shouldDirty: true,
          },
        );
      }

      break;
    }
  }
};

export const getCreateCompanyProfile = (
  companyProfilForm: CompanyProfileForm,
  companyProfileDraftKey: string | null,
) => {
  if (
    companyProfilForm.about === null ||
    companyProfilForm.contactEmail === null ||
    companyProfilForm.coverImageBase64 === null ||
    companyProfilForm.employmentTypes === null ||
    companyProfilForm.name === null ||
    companyProfilForm.oneliner === null ||
    companyProfilForm.perks === null ||
    companyProfilForm.subjectAreaKeys === null ||
    companyProfilForm.websiteUrl === null ||
    companyProfilForm.locationData === null
  ) {
    return null;
  }

  const createCompanyProfile: CreateCompanyProfile = {
    about: companyProfilForm.about,
    contactEmail: companyProfilForm.contactEmail,
    contactPhone: companyProfilForm.contactPhone,
    coverImageBase64: companyProfilForm.coverImageBase64,
    employmentTypes: companyProfilForm.employmentTypes,
    funFacts: companyProfilForm.funFacts,
    locationData: companyProfilForm.locationData,
    name: companyProfilForm.name,
    oneliner: companyProfilForm.oneliner,
    perks: companyProfilForm.perks,
    subjectAreaKeys: companyProfilForm.subjectAreaKeys,
    videoUrl: companyProfilForm.videoUrl,
    websiteUrl: companyProfilForm.websiteUrl,
    companyProfileDraftKey,
  };

  return createCompanyProfile;
};

export const getIsDirtyField = (params: {
  field: keyof CompanyProfileForm;
  values: CompanyProfileForm;
  defaultValues: CompanyProfileForm;
}): boolean => {
  const { field, values, defaultValues } = params;

  switch (field) {
    case 'about':
    case 'contactEmail':
    case 'contactPhone':
    case 'name':
    case 'oneliner':
    case 'videoUrl':
    case 'websiteUrl': {
      return values[field] !== defaultValues[field];
    }

    case 'employmentTypes':
    case 'funFacts':
    case 'subjectAreaKeys': {
      const stringArray = values[field];
      const stringArraySaved = defaultValues[field];

      if (!stringArray || !stringArraySaved) {
        return stringArray !== stringArraySaved;
      }

      if (stringArray.length !== stringArraySaved.length) {
        return true;
      }

      return stringArray.some(
        (string, index) => string !== stringArraySaved[index],
      );
    }

    case 'perks': {
      const perks = values[field];
      const perksSaved = defaultValues[field];

      if (!perks || !perksSaved) {
        return perks !== perksSaved;
      }

      if (perks.length !== perksSaved.length) {
        return true;
      }

      return perks.some(
        (perk, index) =>
          perk.description !== perksSaved[index].description ||
          perk.index !== perksSaved[index].index ||
          perk.perkKey !== perksSaved[index].perkKey,
      );
    }

    case 'locationData': {
      const locationData = values[field];
      const locationDataSaved = defaultValues[field];

      if (!locationData || !locationDataSaved) {
        return locationData !== locationDataSaved;
      }

      if (
        locationData.workModels.length !== locationDataSaved.workModels.length
      ) {
        return true;
      }

      const workModelsIsDirty = locationData.workModels.some(
        (workModel, index) => workModel !== locationDataSaved.workModels[index],
      );

      if (workModelsIsDirty) {
        return true;
      }

      if (!locationData.officeLocations || !locationDataSaved.officeLocations) {
        return (
          locationData.officeLocations !== locationDataSaved.officeLocations
        );
      }

      if (
        locationData.officeLocations.length !==
        locationDataSaved.officeLocations.length
      ) {
        return true;
      }

      return locationData.officeLocations.some(
        (officeLocation, index) =>
          officeLocation.index !==
            locationDataSaved.officeLocations?.[index].index ||
          officeLocation.officeLocationKey !==
            locationDataSaved.officeLocations[index].officeLocationKey,
      );
    }

    default: {
      return false;
    }
  }
};

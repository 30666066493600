import { getAuth } from '@firebase/auth';
import {
  EmulateUserSessionStorage,
  Time,
  WorkspacesSessionStorage,
  getUID,
} from '@orbiapp/components';
import { ApisauceConfig, create } from 'apisauce';

import { API_URL } from '../../config';
import { firebase } from '../firebase';
import { Logger } from '../logger';
import { getApiLogExtras } from './api.utils';

const apisauceConfig: ApisauceConfig = {
  baseURL: API_URL,
  timeout: 10 * Time.Second,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
};

export const apisauce = create(apisauceConfig);

apisauce.addAsyncRequestTransform(async (req) => {
  const event_id = getUID();

  Logger.addBreadcrumb('info', 'api', 'api call', {
    event_id,
    meta: { url: req.url, headers: req.headers },
  });

  const token = await getAuth(firebase).currentUser?.getIdToken();
  const userKey = EmulateUserSessionStorage.getUserKey();
  const companyKey = WorkspacesSessionStorage.getWorkspaceKey();

  req.headers = req.headers ?? {};

  req.headers['x-orb-trace'] = event_id;

  if (userKey) {
    req.headers['x-emulate-user'] = userKey;
  }

  if (companyKey) {
    req.headers['x-orb-company'] = companyKey;
  }

  if (token) {
    req.headers['Authorization'] = `Bearer ${token}`;
  }
});

apisauce.addResponseTransform((res) => {
  if (!res.ok) {
    Logger.warning(res.originalError.message, getApiLogExtras(res));
  }
});

const call = <ReturnValue, Params>(
  endpoint: (params: Params) => ReturnValue,
  params: Params,
): ReturnValue => endpoint(params);

export const OrbiApi = { call };

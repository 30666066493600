import {
  Box,
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  Callout,
  ContentContainer,
  InnerPageContainer,
  PageContainer,
  ResponsiveBox,
} from '@orbiapp/components';
import React from 'react';

import {
  ControlsContainer,
  ExportDataButton,
  InsightsInnerContentContainer,
  SelectAndCompareRange,
  ToggleUniqueMetrics,
} from '../../../../components/insights';
import { InsightsMetric, InsightsRange } from '../../../../models';
import {
  InsightsTimeSeriesConfigSelector,
  ModuleSettingsSelector,
  getTimeBasedInsightsThunk,
  insightsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { Header } from './header';
import { MetricsBreakdown } from './metrics-breakdown';

const INSIGHTS_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/insights', tx: 'label.breadcrumbs.insights.insights' },
  {
    to: '/insights/full-breakdown',
    tx: 'label.breadcrumbs.insights.full-breakdown',
  },
];

const metrics = ['engagement', 'impressions', 'views'] as InsightsMetric[];

export function Fullbreakdown() {
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const showUnique = useSelector(
    InsightsTimeSeriesConfigSelector.selectShowUnique,
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (insightsTier !== 't2') return;

    dispatch(getTimeBasedInsightsThunk({ metrics, all: true }));
  }, [dispatch, insightsTier]);

  const setInsightsDataRange = async (range: InsightsRange) => {
    dispatch(insightsActions.setInsightsTimeSeriesConfigRange(range));

    dispatch(getTimeBasedInsightsThunk({ metrics, all: true }));
  };

  const toggleShowUnique = () => {
    dispatch(insightsActions.setInsightsTimeSeriesShowUnique(!showUnique));

    dispatch(getTimeBasedInsightsThunk({ metrics, all: true }));
  };

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={INSIGHTS_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer backgroundColor="formContainerBackground">
          <Box flexGrow={1} overflow="auto">
            <Header />

            <InsightsInnerContentContainer>
              <ControlsContainer flexWrap>
                <ToggleUniqueMetrics
                  onToggle={toggleShowUnique}
                  isActive={showUnique}
                />
                <SelectAndCompareRange
                  defaultValue={insightsDataRange}
                  onApply={setInsightsDataRange}
                  menuPlacement="middle"
                  isLocked={insightsTier !== 't2'}
                />

                <ResponsiveBox
                  xs={
                    <Callout
                      flexAlign="center"
                      width={320}
                      variant="info"
                      subtitleTx="label.insights.full-breakdown-callout"
                    />
                  }
                >
                  <Callout
                    flexAlign="center"
                    width="100%"
                    variant="info"
                    subtitleTx="label.insights.full-breakdown-callout"
                  />
                </ResponsiveBox>

                <ExportDataButton
                  insightsDataRange={insightsDataRange}
                  items="all"
                  metrics={metrics}
                  showUnique={showUnique}
                />
              </ControlsContainer>

              <MetricsBreakdown />
            </InsightsInnerContentContainer>
          </Box>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

import {
  Box,
  FormSection,
  FormSectionHeader,
  Icon,
  IconButton,
  InputChip,
  InputChips,
  InputRefContext,
  InputRefProvider,
  LeadingInputBox,
  Menu,
  MenuItem,
  Radio,
  Text,
  Tooltip,
  TrailingInputBox,
  createSearchContext,
  isTxString,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateOfferForm, OfferCategory } from '../../../../../models';
import { OfferCategoriesSelector, useSelector } from '../../../../../store';

const OfferCategorySearchContext = createSearchContext<OfferCategory>();

const CATEGORY_ITEM_HEIGHT = 42;
const MENU_MAX_HEIGHT = CATEGORY_ITEM_HEIGHT * 9;

function OfferCategoryMenuItem({
  offerCategory,
  selectOfferCategory,
}: {
  offerCategory: OfferCategory;
  selectOfferCategory: (offerCategoryKey: string) => void;
}) {
  const formMethods = useFormContext<CreateOfferForm>();
  const selectedOfferCategoryKey = formMethods.watch('offerCategoryKey');
  const isChecked = selectedOfferCategoryKey === offerCategory.offerCategoryKey;

  const _selectOfferCategory = () =>
    selectOfferCategory(offerCategory.offerCategoryKey);

  return (
    <MenuItem onClick={_selectOfferCategory} gap={8} flex flexAlign="center">
      <Radio checked={isChecked} onChange={_selectOfferCategory} />
      <Text
        as="span"
        text={`${offerCategory.emoji} ${offerCategory.name}`}
        variant="bodyMd"
      />
    </MenuItem>
  );
}

function OfferCategoryInputChips() {
  const formMethods = useFormContext<CreateOfferForm>();

  const selectedOfferCategoryKey = formMethods.watch('offerCategoryKey');
  const selectedOfferCategory = useSelector((state) =>
    OfferCategoriesSelector.selectById(state, selectedOfferCategoryKey),
  );

  const searchContext = React.useContext(
    OfferCategorySearchContext.SearchContext,
  );

  const [searchString, setSearchString] = React.useState('');

  const inputRef = React.useContext(InputRefContext);
  const clickOutsideRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu({ clickOutsideRef });

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.search(e.target.value);

    setSearchString(e.target.value);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<string>[] = selectedOfferCategory
    ? [
        {
          id: selectedOfferCategory.offerCategoryKey,
          text: `${selectedOfferCategory.emoji} ${selectedOfferCategory.name}`,
          maxWidth: 'unset',
        },
      ]
    : [];

  const removeChip = () => {
    formMethods.setValue('offerCategoryKey', '', {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    });
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const menuIsOpen = menuState.isOpen && !!searchContext.result.length;

  const items = React.useMemo(() => {
    return searchContext.result
      .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
      .map((offerCategory) => offerCategory.item);
  }, [searchContext.result]);

  const selectOfferCategory = (offerCategoryKey: string) => {
    if (selectedOfferCategoryKey !== offerCategoryKey) {
      formMethods.setValue('offerCategoryKey', offerCategoryKey, {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      });
    }

    handleToggleMenu();
    searchContext.search('');
    setSearchString('');
  };

  return (
    <Box ref={clickOutsideRef}>
      <InputChips
        hideErrorMessage={menuState.isOpen}
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={100}
        value={searchString}
        ref={inputRef}
        showInput={menuState.isOpen || !!searchString}
        chips={chips}
        errorTx={
          isTxString(formMethods.formState.errors.offerCategoryKey?.message)
            ? formMethods.formState.errors.offerCategoryKey?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            <Tooltip
              placement="left"
              titleTx={
                menuState.isOpen
                  ? 'label.tooltip.collapse'
                  : 'label.tooltip.expand'
              }
            >
              <IconButton
                onClick={handleToggleMenu}
                icon={menuIsOpen ? 'chevron-up' : 'chevron-down'}
              />
            </Tooltip>
          </TrailingInputBox>
        }
        labelTx="label.offer-form.offerCategory"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            maxHeight={MENU_MAX_HEIGHT}
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
          >
            {items.map((item) => (
              <OfferCategoryMenuItem
                key={item.offerCategoryKey}
                offerCategory={item}
                selectOfferCategory={selectOfferCategory}
              />
            ))}
          </Menu>
        }
      />
    </Box>
  );
}

export function OfferCategories() {
  const offerCategories = useSelector(OfferCategoriesSelector.selectData);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.category"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: 3 }}
        />
      </FormSectionHeader>

      <OfferCategorySearchContext.Provider
        keys={['name']}
        defaultValue={offerCategories}
      >
        <InputRefProvider>
          <OfferCategoryInputChips />
        </InputRefProvider>
      </OfferCategorySearchContext.Provider>
    </FormSection>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CompanyProfile,
  CreateCompanyProfile,
  PartialCompanyProfile,
  UpdateCompanyProfileIndexes,
  UpdateCompanyProfileParams,
  isCompanyProfileAndNotPartialCompanyProfile,
} from '../../models';
import { OrbiApi, v2 } from '../../services';
import {
  indexedOfficeLocationsMapper,
  indexedPerksMapper,
  subjectAreasMapper,
} from '../company-profile-drafts/company-profile-drafts.mappers';
import { officeLocationsAdapter } from '../company/company.adapter';
import { setAlert } from '../global-ui-state';
import { PerksSelector } from '../perks';
import { ThunkApiConfig } from '../store.types';
import { SubjectAreasSelector } from '../subject-areas';
import { companyProfilesAdapter } from './company-profiles.adapter';

export const createCompanyProfileThunk = createAsyncThunk<
  CompanyProfile,
  CreateCompanyProfile,
  ThunkApiConfig
>(
  'companyProfiles/createCompanyProfileThunk',
  async (createCompanyProfile, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.createCompanyProfile,
      createCompanyProfile,
    );

    if (res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('profile-published:error'));
      return thunkAPI.rejectWithValue(res);
    }

    const _res = await OrbiApi.call(
      v2.companyProfiles.getCompanyProfile,
      res.data,
    );

    if (_res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('profile-published:error'));
      return thunkAPI.rejectWithValue(_res);
    }

    thunkAPI.dispatch(setAlert('profile-published:success'));
    return _res.data;
  },
);

export const getCompanyProfilesThunk = createAsyncThunk<
  PartialCompanyProfile[],
  undefined,
  ThunkApiConfig
>('companyProfiles/getCompanyProfilesThunk', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.companyProfiles.getCompanyProfiles,
    undefined,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getCompanyProfileThunk = createAsyncThunk<
  CompanyProfile,
  string,
  ThunkApiConfig
>(
  'companyProfiles/getCompanyProfileThunk',
  async (companyProfileKey, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.getCompanyProfile,
      companyProfileKey,
    );

    if (res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(res);
    }

    return res.data;
  },
);

export const updateCompanyProfileThunk = createAsyncThunk<
  Partial<CompanyProfile>,
  UpdateCompanyProfileParams,
  ThunkApiConfig
>(
  'companyProfiles/updateCompanyProfileThunk',
  async (updateCompanyProfileParams, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.updateCompanyProfile,
      updateCompanyProfileParams,
    );

    if (res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('update-profile:error'));
      return thunkAPI.rejectWithValue(res);
    }

    const state = thunkAPI.getState();

    const companyProfile = companyProfilesAdapter
      .getSelectors()
      .selectById(
        state.companyProfiles,
        updateCompanyProfileParams.companyProfileKey,
      );

    const updatedBy = state.account.account.data
      ? {
          firstName: state.account.account.data.firstName,
          lastName: state.account.account.data.lastName,
          profilePicture: null,
          userKey: state.account.account.data.userKey,
        }
      : null;

    if (
      updateCompanyProfileParams.updateCompanyProfile.coverImageBase64 ===
        undefined &&
      updatedBy &&
      companyProfile &&
      isCompanyProfileAndNotPartialCompanyProfile(companyProfile)
    ) {
      const {
        locationData,
        perks,
        subjectAreaKeys,
        ...updateCompanyProfileDraftRest
      } = updateCompanyProfileParams.updateCompanyProfile;

      const newCompanyProfile: Partial<CompanyProfile> = {
        editMeta: {
          ...companyProfile.editMeta,
          updatedAt: Date.now(),
          updatedBy,
        },
        ...updateCompanyProfileDraftRest,
      };

      if (locationData !== undefined) {
        newCompanyProfile.locationData = {
          workModels: locationData.workModels,
          officeLocations: locationData.officeLocations
            ? indexedOfficeLocationsMapper(
                locationData.officeLocations,
                officeLocationsAdapter
                  .getSelectors()
                  .selectAll(state.company.officeLocations.data),
              )
            : null,
        };
      }

      if (perks !== undefined) {
        newCompanyProfile.perks = indexedPerksMapper(
          perks,
          PerksSelector.selectAll(state),
        );
      }

      if (subjectAreaKeys !== undefined) {
        newCompanyProfile.subjectAreas = subjectAreasMapper(
          subjectAreaKeys,
          SubjectAreasSelector.selectAll(state),
        );
      }

      thunkAPI.dispatch(setAlert('update-profile:success'));
      return newCompanyProfile;
    }

    const _res = await OrbiApi.call(
      v2.companyProfiles.getCompanyProfile,
      updateCompanyProfileParams.companyProfileKey,
    );

    if (_res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('update-profile:error'));
      return thunkAPI.rejectWithValue(_res);
    }

    thunkAPI.dispatch(setAlert('update-profile:success'));
    return _res.data;
  },
);

export const deleteCompanyProfileThunk = createAsyncThunk<
  undefined,
  string,
  ThunkApiConfig
>(
  'companyProfiles/deleteCompanyProfileThunk',
  async (companyProfileKey, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.deleteCompanyProfile,
      companyProfileKey,
    );

    if (res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('delete-profile:error'));
      return thunkAPI.rejectWithValue(res);
    }

    thunkAPI.dispatch(setAlert('delete-profile:success'));
    return;
  },
);

export const updateCompanyProfileIndexesThunk = createAsyncThunk<
  undefined,
  UpdateCompanyProfileIndexes,
  ThunkApiConfig
>(
  'companyProfiles/updateCompanyProfileIndexesThunk',
  async (updateCompanyProfileIndexes, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.updateCompanyProfileIndexes,
      updateCompanyProfileIndexes,
    );

    if (res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('update-priority-level:error'));
      return thunkAPI.rejectWithValue(res);
    }

    thunkAPI.dispatch(setAlert('update-priority-level:success'));
    return;
  },
);

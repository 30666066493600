import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './search.build';
import { initialSearchState } from './search.initial';
import { reducers } from './search.reducers';

const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers,
  extraReducers,
});

export const searchReducer = searchSlice.reducer;

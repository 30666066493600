import { INITIAL_DATA_STATE } from '../store.constants';
import { officeLocationsAdapter } from './company.adapter';
import { CompanyState } from './company.types';

export const initialCompanyState: CompanyState = {
  company: INITIAL_DATA_STATE,
  createCompany: INITIAL_DATA_STATE,
  updateCompany: INITIAL_DATA_STATE,

  officeLocations: {
    ...INITIAL_DATA_STATE,
    data: officeLocationsAdapter.getInitialState(),
  },
};

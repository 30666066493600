import { getAddressesThunk, getCoordinatesThunk } from './search.actions';
import { addressesAdapter } from './search.adapter';
import { SearchBuilder } from './search.types';

function coordinatesBuild(builder: SearchBuilder) {
  return builder
    .addCase(getCoordinatesThunk.pending, (state) => {
      state.coordinates.status = 'pending';
      state.coordinates.error = null;
    })
    .addCase(getCoordinatesThunk.fulfilled, (state, action) => {
      state.coordinates.status = 'idle';
      state.coordinates.data = action.payload;
    })
    .addCase(getCoordinatesThunk.rejected, (state, action) => {
      state.coordinates.status = 'idle';
      state.coordinates.error = action.error;
    });
}

function addressesBuild(builder: SearchBuilder) {
  return builder
    .addCase(getAddressesThunk.pending, (state) => {
      state.addresses.status = 'pending';
      state.addresses.error = null;
    })
    .addCase(getAddressesThunk.fulfilled, (state, action) => {
      state.addresses.status = 'idle';
      addressesAdapter.setAll(state.addresses.data, action.payload);
    })
    .addCase(getAddressesThunk.rejected, (state, action) => {
      state.addresses.status = 'idle';
      state.addresses.error = action.error;
    });
}

export const extraReducers = (builder: SearchBuilder) => {
  coordinatesBuild(builder);
  addressesBuild(builder);
};

import { colors, palette } from '@orbiapp/theme';

export const companyDashboardColors = {
  ...colors,

  /*
   * Onboarding page
   */
  onboardingHeaderTextColor: palette.primary[300],

  /*
   * Signed out pages page
   */
  signedOutPagesTitleColor: palette.primary[300],
  signedOutPagesTextColor: palette.neutral[100],

  /*
   * Employment type chips
   */
  fulltimeChipBackground: palette.dataVisualisation[1],
  fulltimeChipLabel: palette.neutral[100],
  fulltimeChipBorder: palette.transparent,

  parttimeChipBackground: palette.dataVisualisation[3],
  parttimeChipLabel: palette.neutral[100],
  parttimeChipBorder: palette.transparent,

  seasonalChipBackground: palette.dataVisualisation[5],
  seasonalChipLabel: palette.neutral[100],
  seasonalChipBorder: palette.transparent,

  thesisChipBackground: palette.dataVisualisation[7],
  thesisChipLabel: palette.neutral.white,
  thesisChipBorder: palette.transparent,

  internshipChipBackground: palette.dataVisualisation[9],
  internshipChipLabel: palette.neutral.white,
  internshipChipBorder: palette.transparent,

  apprenticeshipChipBackground: palette.dataVisualisation[11],
  apprenticeshipChipLabel: palette.neutral.white,
  apprenticeshipChipBorder: palette.transparent,

  /*
   * Update priority modal
   */
  updatePriorityModalListBorder: palette.neutral[800],
  updatePriorityModalDescription: palette.neutral[100],

  /*
   * Duplicate profile modal
   */
  duplicateProfileModalListBorder: palette.neutral[800],
  duplicateProfileModalListItemHeader: palette.neutral[100],
  duplicateProfileModalListItemDescription: palette.neutral[400],
  duplicateProfileModalDescription: palette.neutral[100],

  /*
   * Profiles form
   */
  perksFieldSelect: palette.neutral[400],
  perksFieldSelectOptionCategory: palette.neutral[100],

  /**
   * Talent pool users stats card
   */
  talentPoolUsersStatsCardRowHeader: palette.neutral[400],
  talentPoolUsersStatsCardIcon: palette.neutral[500],

  /**
   * Insights filter header
   */
  insightsFilterHeaderBackground: palette.neutral[900],
  insightsFilterHeaderUniqueMetricsIcon: palette.neutral[500],

  /**
   * Select insights date range
   */
  selectInsightsDateRangeIcon: palette.primary[300],
  selectInsightsDateRangeChevron: palette.primary[300],

  /**
   * Profile preview
   */
  profilePreviewSidebarBackground: palette.neutral[1000],
  profilePreviewSidebarToggleButton: palette.neutral[1000],
  profilePreviewSidebarToggleButtonBackground: palette.neutral[100],
  profilePreviewSidebarToggleButtonBorderHover: palette.primary[100],
  profilePreviewText: palette.neutral[100],
  profilePreviewDetailIcons: palette.neutral[500],
  profilePreviewLinks: palette.secondary[100],
  profilePreviewLocationBorder: palette.neutral[700],
  profilePreviewLocationIcon: palette.neutral[500],
  profilePreviewLocationLabel: palette.neutral[100],
  profilePreviewLocationAddress: palette.neutral[400],
  profilePreviewViewAllText: palette.neutral[400],
  profilePreviewViewAllIcon: palette.neutral[500],
  profilePreviewPerksBorder: palette.neutral[800],
  profilePreviewPlaceholderRow: palette.neutral[900],
  profilePreviewPlaceholderArea: palette.neutral[800],
  profilePreviewDuplicateIconButtonBackground: palette.neutral[100],

  /**
   * Create connect qr code
   */
  connectQrCodeCreatedBySubtitle: palette.neutral[400],

  /**
   * Exhibitions view user sidebar
   */
  connectViewUserIcon: palette.neutral[500],
  connectViewUserEmail: palette.secondary[100],
  connectViewUserQrCodeText: palette.secondary[100],
  connectViewUserDateAddedText: palette.neutral[400],
  connectViewUserConnectedViaText: palette.neutral[400],
  connectViewUserListItemTitle: palette.neutral[400],

  connectViewUserStudyLevelBorder: palette.transparent,
  connectViewUserStudyLevelBackground: palette.dataVisualisation[1],
  connectViewUserStudyLevelText: palette.neutral[100],

  connectTalentPoolUserStarredIcon: palette.neutral[500],
  connectTalentPoolUserStarredIconStarred: palette.primary[300],

  /**
   * Connect talent pool status
   */
  connectTalentPoolStatusColor: palette.neutral[200],
  connectTalentPoolStatusIcon: palette.information[200],

  /**
   * Talent pool blocked card
   */
  talentPoolBlockedCardBackground: palette.neutral[1000],

  /**
   * Metrics breakdown compared value
   */
  metricsBreakdownComparedValue: palette.neutral[400],

  /**
   * Company ads - empty table state
   */
  companyAdsEmptyTableStateTitleLeft: palette.primary[300],
  companyAdsEmptyTableStateTitleRight: palette.neutral[100],
  companyAdsEmptyTableStateText: palette.neutral[300],

  /**
   * Internal preview
   */
  internalPreView: palette.neutral.white,
  internalPreViewBackground: palette.neutral[100],
};

import { GlobalAlert, Time, TxArgs } from '@orbiapp/components';

import { AlertType } from '../models';

export function getAlert(
  type: AlertType,
  subtitleTxArgs?: TxArgs,
): GlobalAlert {
  switch (type) {
    case 'unknown':
      return {
        variant: 'error',
        subtitleTx: 'action.general.subtitle.error',
      };

    case 'image-too-large-size':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-large-size',
        subtitleTxArgs,
      };

    case 'image-too-small-width':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-small-width',
        subtitleTxArgs,
      };

    case 'image-too-small-height':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-small-height',
        subtitleTxArgs,
      };

    case 'image-too-large-height':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-large-height',
        subtitleTxArgs,
      };

    case 'image-too-large-width':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-large-width',
        subtitleTxArgs,
      };

    case 'image-type-invalid':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.invalid-image-type',
      };

    case 'missing-file':
      return {
        variant: 'info',
        subtitleTx: 'errors.upload.missing-file',
      };

    case 'file-type-not-allowed':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.file-type-not-allowed',
      };

    case 'unknown-upload-error':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.general',
      };

    case 'error-in-form':
      return {
        variant: 'error',
        subtitleTx: 'errors.general.error-in-form',
      };

    case 'close-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.close-job.success.subtitle',
      };

    case 'close-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.close-job.error.subtitle',
      };

    case 'publish-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.publish-job.success.subtitle',
        subtitleTxArgs,
      };

    case 'publish-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.publish-job.error.subtitle',
      };

    case 'update-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-job.success.subtitle',
      };

    case 'update-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-job.error.subtitle',
      };

    case 'delete-job-draft:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-job-draft.success.subtitle',
      };

    case 'delete-job-draft:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-job-draft.error.subtitle',
      };

    case 'duplicate-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.duplicate-job.error.subtitle',
      };

    case 'duplicate-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.duplicate-job.success.subtitle',
      };

    case 'publish-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.publish-offer.success.subtitle',
        subtitleTxArgs,
      };

    case 'publish-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.publish-offer.error.subtitle',
      };

    case 'update-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-offer.success.subtitle',
      };

    case 'update-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-offer.error.subtitle',
      };

    case 'pause-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.pause-offer.success.subtitle',
      };

    case 'pause-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.pause-offer.error.subtitle',
      };

    case 'unpause-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.unpause-offer.success.subtitle',
      };

    case 'unpause-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.unpause-offer.error.subtitle',
      };

    case 'delete-offer-draft:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-offer-draft.success.subtitle',
      };

    case 'delete-offer-draft:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-offer-draft.error.subtitle',
      };

    case 'duplicate-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.duplicate-offer.error.subtitle',
      };

    case 'duplicate-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.duplicate-offer.success.subtitle',
      };

    case 'copy-subject-areas:success':
      return {
        variant: 'success',
        subtitleTx: 'action.copy-subject-areas.success.subtitle',
      };

    case 'delete-profile:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-profile.error.subtitle',
      };

    case 'delete-profile:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-profile.success.subtitle',
      };

    case 'update-priority-level:error':
      return {
        variant: 'error',
        subtitleTx: 'action.priority-level-updated.error.subtitle',
      };

    case 'update-priority-level:success':
      return {
        variant: 'success',
        subtitleTx: 'action.priority-level-updated.success.subtitle',
      };

    case 'create-invitation:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-invitation.error.subtitle',
      };

    case 'create-invitation:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-invitation.success.subtitle',
      };

    case 'create-invitations:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-invitations.error.subtitle',
      };

    case 'create-invitations:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-invitations.success.subtitle',
      };

    case 'create-office-location:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-office-location.error.subtitle',
      };

    case 'create-office-location:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-office-location.success.subtitle',
      };

    case 'delete-invitation:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-invitation.error.subtitle',
      };

    case 'delete-invitation:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-invitation.success.subtitle',
      };

    case 'delete-office-location:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-office-location.error.subtitle',
      };

    case 'delete-office-location:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-office-location.success.subtitle',
      };

    case 'remove-user:error':
      return {
        variant: 'error',
        subtitleTx: 'action.remove-user.error.subtitle',
      };

    case 'remove-user:success':
      return {
        variant: 'success',
        subtitleTx: 'action.remove-user.success.subtitle',
      };

    case 'update-company:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-company.error.subtitle',
      };

    case 'update-company:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-company.success.subtitle',
      };

    case 'update-office-location:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-office-location.error.subtitle',
      };

    case 'update-office-location:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-office-location.success.subtitle',
      };

    case 'update-user:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-user.error.subtitle',
      };

    case 'update-user:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-user.success.subtitle',
      };

    case 'profile-published:error':
      return {
        variant: 'error',
        subtitleTx: 'action.profile-published.error.subtitle',
      };

    case 'profile-published:success':
      return {
        variant: 'success',
        subtitleTx: 'action.profile-published.success.subtitle',
      };

    case 'update-profile:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-profile.error.subtitle',
      };

    case 'update-profile:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-profile.success.subtitle',
      };

    case 'delete-profile-draft:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-profile-draft.error.subtitle',
      };

    case 'delete-profile-draft:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-profile-draft.success.subtitle',
      };

    case 'create-company-qr-code:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-company-qr-code.error.subtitle',
      };

    case 'create-company-qr-code:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-company-qr-code.success.subtitle',
      };

    case 'update-user-notes:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-user-notes.error.subtitle',
      };

    case 'update-user-notes:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-user-notes.success.subtitle',
      };

    case 'create-company-qr-code:limit-reached': {
      return {
        variant: 'error',
        subtitleTx: 'action.create-company-qr-code.limit-reached.subtitle',
      };
    }

    case 'update-company-qr-code:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-company-qr-code.error.subtitle',
      };

    case 'update-company-qr-code:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-company-qr-code.success.subtitle',
      };

    case 'star-user:error':
      return {
        variant: 'error',
        subtitleTx: 'action.star-user.error.subtitle',
        subtitleTxArgs,
      };

    case 'unstar-user:error':
      return {
        variant: 'error',
        subtitleTx: 'action.unstar-user.error.subtitle',
        subtitleTxArgs,
      };

    case 'sign-in':
      return {
        variant: 'error',
        subtitleTx: 'errors.email-or-password',
      };

    case 'reset-password:success':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'success',
        subtitleTx: 'action.reset-password.success.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:error':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.error.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:email-not-found':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.email-not-found.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:email-badly-formatted':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.email-badly-formatted.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:too-many-requests':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.too-many-requests.subtitle',
        subtitleTxArgs,
      };

    case 'send-email-verification:success':
      return {
        variant: 'success',
        subtitleTx: 'action.send-email-verification.success.subtitle',
        subtitleTxArgs,
      };

    case 'send-email-verification:error':
      return {
        variant: 'error',
        subtitleTx: 'action.send-email-verification.error.subtitle',
      };

    case 'send-email-verification:too-many-requests':
      return {
        variant: 'error',
        subtitleTx: 'action.send-email-verification.too-many-requests.subtitle',
      };

    case 'firebase/network-request-failed':
      return {
        variant: 'error',
        subtitleTx: 'action.firebase.network-request-failed',
      };

    case 'firebase/too-many-requests':
      return {
        variant: 'error',
        subtitleTx: 'action.firebase.too-many-requests',
      };

    case 'firebase/user-disabled':
      return {
        variant: 'error',
        subtitleTx: 'action.firebase.user-disabled',
      };
  }
}

import { AsyncState, StatusExtension } from '../models';

export const INITIAL_DATA_STATE: AsyncState<any> = {
  data: null,
  error: null,
  status: 'idle',
};

export const INITIAL_STATUS_EXTENSION_STATE: StatusExtension = {
  error: null,
  status: 'idle',
};

import {
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  InnerPageContainer,
  PageContainer,
} from '@orbiapp/components';
import { Outlet } from 'react-router';

const SETTINGS_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/settings/company', tx: 'label.breadcrumbs.settings.settings' },
];

const SETTINGS_TAB_BUTTONS: BreadcrumbListItem[] = [
  {
    tx: 'label.tab-buttons.settings.your-profile',
    to: '/settings/account',
  },
  {
    tx: 'label.tab-buttons.settings.company-settings',
    to: '/settings/company',
  },
  {
    tx: 'label.tab-buttons.settings.team-settings',
    to: '/settings/team',
  },
];

export function SettingsOutlet() {
  return (
    <PageContainer>
      <BreadcrumbsToolbar
        flexGrow={0.8}
        breadcrumbListItems={SETTINGS_BREADCRUMBS}
        tabButtonListItems={SETTINGS_TAB_BUTTONS}
      />

      <InnerPageContainer>
        <Outlet />
      </InnerPageContainer>
    </PageContainer>
  );
}

import {
  Box,
  ControlledInput,
  DangerIconButton,
  DragDrop,
  DragDropItem,
  DragDropItemHandle,
  FormSection,
  FormSectionHeader,
  FormSectionHeaderRow,
  IconButton,
  SolidIconButton,
  Status,
  Text,
  Tooltip,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CompanyProfileForm,
  PROFILE_FUN_FACTS_MAX_COUNT,
  PROFILE_FUN_FACT_MAX_LENGTH,
} from '../../../../../models';
import { getOptionalLabelText } from '../../../../../utils';
import { SaveFieldOnClickOutside } from '../save-field-on-click-outside';
import { ProfileContext } from './profile-form.context';

const FUNFACT_INPUT_HEIGHT = 58;

function FunFactPlaceholder(props: {
  addFunFact: () => void;
  removeFunFact: () => void;
}) {
  const { addFunFact, removeFunFact } = props;

  return (
    <Box flex gap={8} minHeight={88}>
      <Box height={FUNFACT_INPUT_HEIGHT} flex flexAlign="center">
        <IconButton disabled icon="drag" />
      </Box>

      <ControlledInput
        labelTx="label.profile.fun-facts.inputs.fun-fact"
        onClick={addFunFact}
        name=""
      />

      <Box height={FUNFACT_INPUT_HEIGHT} flex flexAlign="center">
        <Tooltip placement="top" titleTx="label.tooltip.remove">
          <DangerIconButton
            icon="minus-circle-outline"
            onClick={removeFunFact}
          />
        </Tooltip>
      </Box>
    </Box>
  );
}

function FunFactItems(props: {
  addFunFact: () => void;
  funFacts: string[];
  removePlaceholder: () => void;
}) {
  const { addFunFact, funFacts, removePlaceholder } = props;

  const { formMode, saveField } = React.useContext(ProfileContext);
  const isDraft = formMode === 'createProfile';

  const { setValue } = useFormContext<CompanyProfileForm>();

  const [items, setItems] = React.useState<{ id: string; funFact: string }[]>(
    [],
  );

  const handleChange = (newState: typeof items) => {
    setItems(newState);

    setValue(
      'funFacts',
      newState.map((item) => item.funFact),
      {
        shouldDirty: true,
      },
    );

    if (isDraft) {
      saveField('funFacts');
    }
  };

  const renderFunFact = (_: string, index: number) => {
    const removeFunFact = () => {
      const _funFacts = funFacts.filter((_, _index) => _index !== index) ?? [];

      setValue('funFacts', _funFacts.length === 0 ? null : _funFacts, {
        shouldDirty: true,
      });

      removePlaceholder();
    };

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (
      event: React.KeyboardEvent,
    ) => {
      if (event.key === 'Enter') {
        addFunFact();
      }
    };

    return (
      <DragDropItem
        flex
        gap={16}
        id={`${index}`}
        key={`fun-fact-item-${index}`}
      >
        <Box height={FUNFACT_INPUT_HEIGHT} flex flexAlign="center">
          <Tooltip placement="top" titleTx="label.tooltip.move">
            <DragDropItemHandle />
          </Tooltip>
        </Box>

        <ControlledInput
          labelTx="label.profile.fun-facts.inputs.fun-fact"
          maxLength={PROFILE_FUN_FACT_MAX_LENGTH}
          name={`funFacts.${index}`}
          onKeyUp={handleKeyPress}
        />

        <Box height={FUNFACT_INPUT_HEIGHT} flex flexAlign="center">
          <Tooltip placement="top" titleTx="label.tooltip.remove">
            <DangerIconButton
              icon="minus-circle-outline"
              onClick={removeFunFact}
            />
          </Tooltip>
        </Box>
      </DragDropItem>
    );
  };

  React.useEffect(() => {
    setItems(
      funFacts?.map((funFact, index) => ({ id: `${index}`, funFact })) ?? [],
    );
  }, [funFacts]);

  return (
    <DragDrop items={items} onChange={handleChange}>
      {funFacts.map(renderFunFact)}
    </DragDrop>
  );
}

export function FunFacts() {
  const { formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const { setFocus, setValue, watch } = useFormContext<CompanyProfileForm>();

  const funFacts = watch('funFacts') ?? [];

  const [focusItem, setFocusItem] = React.useState<number | null>(null);

  const [funFactPlaceholderIsVisible, setFunFactPlaceholderIsVisible] =
    React.useState(isDraft && funFacts.length === 0);

  const removePlaceholder = () => setFunFactPlaceholderIsVisible(false);

  React.useEffect(() => {
    if (focusItem !== null) {
      setFocus(`funFacts.${focusItem}`);
      setFocusItem(null);
    }
  }, [focusItem, setFocus]);

  const addIsDisabled = funFacts.length >= PROFILE_FUN_FACTS_MAX_COUNT;

  const addFunFact = () => {
    if (addIsDisabled) {
      return;
    }

    setValue('funFacts', [...funFacts, ''], {
      shouldDirty: true,
    });

    setFocusItem(funFacts.length);
  };

  return (
    <SaveFieldOnClickOutside field="funFacts" disabled={!isDraft}>
      <FormSection>
        <FormSectionHeaderRow>
          <FormSectionHeader>
            <Text
              text={getOptionalLabelText('label.profile.fun-facts.header')}
              variant="bodyMdBold"
              color="formSectionHeader"
            />

            <Text
              tx="label.profile.fun-facts.description"
              color="formSectionDescription"
              variant="bodyMd"
            />
          </FormSectionHeader>

          <Status
            ml="auto"
            variant="warning"
            tx="label.profile.fun-facts.counter-status"
            txArgs={{
              length: funFacts.length,
              max: PROFILE_FUN_FACTS_MAX_COUNT,
            }}
          />

          <Tooltip
            placement="top"
            titleTx="label.tooltip.add"
            disabled={addIsDisabled}
          >
            <SolidIconButton
              onClick={addFunFact}
              icon="plus-circle-outline"
              disabled={addIsDisabled}
            />
          </Tooltip>
        </FormSectionHeaderRow>

        <Box flex flexDirection="column" gap={8}>
          {funFacts.length ? (
            <FunFactItems
              addFunFact={addFunFact}
              funFacts={funFacts}
              removePlaceholder={removePlaceholder}
            />
          ) : (
            funFactPlaceholderIsVisible && (
              <FunFactPlaceholder
                addFunFact={addFunFact}
                removeFunFact={removePlaceholder}
              />
            )
          )}
        </Box>
      </FormSection>
    </SaveFieldOnClickOutside>
  );
}

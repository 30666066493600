import { Box, InputProps, Text } from '@orbiapp/components';
import React from 'react';

import { Styled } from './title-input.styled';

function _TitleInput(
  props: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { error, errorTx, errorTxArgs, ...rest } = props;

  return (
    <Box gap={8} flex flexDirection="column">
      <Styled.TitleInput ref={ref} {...rest} />

      {(error || errorTx) && (
        <Text
          color="errorLabel"
          text={error}
          tx={errorTx}
          txArgs={errorTxArgs}
          variant="bodyMd"
        />
      )}
    </Box>
  );
}

export const TitleInput = React.forwardRef(_TitleInput);

export namespace CompanyErrors {
  export const companyName = {
    'string.empty': 'errors.company-name.empty',
  };

  export const employeeCount = {
    'any.required': 'errors.employee-count.empty',
  };

  export const industry = {
    'any.required': 'errors.industry.empty',
  };

  export const logo = {
    'string.empty': 'errors.logo.empty',
    'any.required': 'errors.logo.empty',
  };

  export const tags = {
    'array.unique': 'errors.tags.duplicate',
    'array.max': 'errors.tags.max',
  };
}

interface LocationExistsOnProfileError {
  code: 0;
  message: string;
  metadata: { companyProfileKey: string };
}

export type DeleteLocationErrorResponse =
  | string
  | LocationExistsOnProfileError
  | undefined;

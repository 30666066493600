import { Box, Menu } from '@orbiapp/components';
import styled, { css } from 'styled-components';

import { SelectAndCompareRangeProps } from './select-and-compare-menu.types';

const SheetList = styled(Box)`
  list-style-type: none;

  li:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

const SelectRangeMenu = styled(Menu)<{
  menuPlacement: SelectAndCompareRangeProps['menuPlacement'];
}>`
  ${(props) => {
    const { menuPlacement } = props;

    switch (menuPlacement) {
      case 'left':
        return css`
          left: 0;
        `;

      case 'middle':
        return css`
          left: 50%;
          transform: translateX(-50%);
        `;

      case 'right':
        return css`
          right: 0;
        `;
    }
  }}
`;

export const Styled = { SheetList, SelectRangeMenu };

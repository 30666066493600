import {
  borderRadii,
  breakpoints,
  elevation,
  spacing,
  textVariants,
  transitions,
} from '@orbiapp/theme';

import { companyDashboardColors } from './colors';

export interface Theme {
  borderRadii: typeof borderRadii;
  breakpoints: typeof breakpoints;
  colors: typeof companyDashboardColors;
  elevation: typeof elevation;
  spacing: typeof spacing;
  textVariants: typeof textVariants;
  transitions: typeof transitions;
}

export const theme: Theme = {
  borderRadii,
  breakpoints,
  colors: companyDashboardColors,
  elevation,
  spacing,
  textVariants,
  transitions,
};

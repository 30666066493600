import { INITIAL_DATA_STATE } from '../store.constants';
import {
  offerCategoriesAdapter,
  partialOfferDraftsAdapter,
  partialOffersAdapter,
} from './offers.adapter';
import { OffersState } from './offers.types';

export const initialOffersState: OffersState = {
  createOfferDraft: INITIAL_DATA_STATE,
  offerDraft: INITIAL_DATA_STATE,
  offerDrafts: {
    ...INITIAL_DATA_STATE,
    data: partialOfferDraftsAdapter.getInitialState(),
  },
  updateOfferDraft: INITIAL_DATA_STATE,
  deleteOfferDraft: INITIAL_DATA_STATE,

  createOffer: INITIAL_DATA_STATE,
  offer: INITIAL_DATA_STATE,
  offers: {
    ...INITIAL_DATA_STATE,
    data: partialOffersAdapter.getInitialState(),
    pagination: {
      limit: 25,
      offset: 0,
      orderBy: 'createdAt',
      sortOrder: 'desc',
    },
    search: '',
  },
  updateOffer: INITIAL_DATA_STATE,
  toggleIsDisabled: INITIAL_DATA_STATE,
  duplicateOffer: INITIAL_DATA_STATE,
  offerCategories: {
    ...INITIAL_DATA_STATE,
    data: offerCategoriesAdapter.getInitialState(),
  },

  offersLink: INITIAL_DATA_STATE,
};

import { createSearchContext } from '@orbiapp/components';
import { breakpoints } from '@orbiapp/theme';
import React from 'react';

import { SubjectArea } from '../../../../../models';
import { ProfileContextType } from './profile-form.types';

export const ProfileContext = React.createContext<ProfileContextType>({
  closeSidebar: () => {},
  openSidebar: () => {},
  sidebarIsOpen: window.innerWidth > breakpoints.md,

  closeConfirmDeleteProfile: () => {},
  openConfirmDeleteProfile: () => {},
  deleteProfileIsOpen: false,

  profile: undefined,
  publishProfile: () => {},

  dirtyFields: [],
  resetField: () => {},
  saveField: () => {},

  isLoaded: false,

  formMode: 'createProfile',
});

export const SubjectAreaSearchContext = createSearchContext<SubjectArea>();

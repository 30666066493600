import {
  CompanyProfile,
  CompanyProfileDraft,
  CompanyProfileForm,
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
} from '../../../../../models';

type FormMode = 'createProfile' | 'viewProfile';

export interface ProfileContextType {
  closeSidebar: () => void;
  openSidebar: () => void;
  sidebarIsOpen: boolean;

  closeConfirmDeleteProfile: () => void;
  openConfirmDeleteProfile: () => void;
  deleteProfileIsOpen: boolean;

  profile?:
    | CompanyProfile
    | CompanyProfileDraft
    | PartialCompanyProfile
    | PartialCompanyProfileDraft;
  publishProfile: () => void;

  dirtyFields: (keyof CompanyProfileForm)[];
  resetField: (field: keyof CompanyProfileForm) => void;

  saveField: (
    field: keyof CompanyProfileForm,
    afterDone?: () => void,
    forceIsDirty?: boolean,
  ) => void;

  isLoaded: boolean;

  formMode: FormMode;
}

export const checkForDirtyFields: (keyof CompanyProfileForm)[] = [
  'about',
  'contactEmail',
  'contactPhone',
  'employmentTypes',
  'funFacts',
  'locationData',
  'name',
  'oneliner',
  'perks',
  'subjectAreaKeys',
  'videoUrl',
  'websiteUrl',
];

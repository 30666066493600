import {
  CompanyInvitation,
  PendingCompanyInvitation,
  User,
} from '../../../../../models';
import {
  CompanyInvitationDto,
  PendingCompanyInvitationDto,
  UserDto,
} from './team.dto';

export function pendingCompanyInvitationMapper(
  pendingCompanyInvitationDto: PendingCompanyInvitationDto,
): PendingCompanyInvitation {
  return {
    companyInvitationKey: pendingCompanyInvitationDto.companyInvitationKey,
    email: pendingCompanyInvitationDto.email,
    expiresAt: pendingCompanyInvitationDto.expiresAt,
    role: pendingCompanyInvitationDto.role,
  };
}

export function userMapper(userDto: UserDto): User {
  return {
    userKey: userDto.userKey,

    email: userDto.email,

    firstName: userDto.firstName,
    lastName: userDto.lastName,

    role: userDto.role,

    profilePicture: userDto.profilePicture,
  };
}

export function companyInvitationMapper(
  companyInvitationDto: CompanyInvitationDto,
): CompanyInvitation {
  return {
    companyInvitationKey: companyInvitationDto.companyInvitationKey,

    companyDetails: companyInvitationDto.companyDetails,

    email: companyInvitationDto.email,
    role: companyInvitationDto.role,

    consumedAt: companyInvitationDto.consumedAt,
    expiresAt: companyInvitationDto.expiresAt,

    userExists: companyInvitationDto.userExists,
  };
}

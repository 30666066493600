import { getPasswordStrength } from '@orbiapp/components';
import Joi from 'joi';

import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_NUMBERS,
  PASSWORD_MIN_SPECIAL_CHARACTERS,
} from './account.constants';
import { AccountErrors } from './account.errors';

export namespace AccountSchema {
  export const firstName = Joi.string()
    .required()
    .messages(AccountErrors.firstName);

  export const lastName = Joi.string()
    .required()
    .messages(AccountErrors.lastName);

  export const password = Joi.string()
    .custom((value, helpers) => {
      const strength = getPasswordStrength(value, {
        minCharacters: PASSWORD_MIN_LENGTH,
        minNumbers: PASSWORD_MIN_NUMBERS,
        minSpecialCharacters: PASSWORD_MIN_SPECIAL_CHARACTERS,
      });

      if (strength.length < PASSWORD_MIN_LENGTH) {
        return helpers.error('min-length');
      }

      if (strength.numbers < PASSWORD_MIN_NUMBERS) {
        return helpers.error('min-numbers');
      }

      if (strength.specialCharacters < PASSWORD_MIN_SPECIAL_CHARACTERS) {
        return helpers.error('min-special-characters');
      }

      return value;
    })
    .required()
    .messages(AccountErrors.password);

  export const loginPassword = Joi.string()
    .required()
    .messages(AccountErrors.password);
}

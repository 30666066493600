import React from 'react';

import { Box } from '../../components';
import { Styled } from './content-sidebar-fixed.styled';
import { ContentSidebarFixedProps } from './content-sidebar.types';

export function ContentSidebarFixed(props: ContentSidebarFixedProps) {
  const { children, isOpen, onClose, width, ...rest } = props;

  return (
    <React.Fragment>
      <Styled.ContentSidebarBackdrop isOpen={isOpen} onClick={onClose} />

      <Styled.ContentSidebar sidebarWidth={width} height="100%" isOpen={isOpen}>
        <Styled.ContentSidebarContentContainer
          height="100%"
          isOpen={isOpen}
          sidebarWidth={width}
          {...rest}
        >
          <Box overflow="hidden" height="100%" flexDirection="column" flex>
            {children}
          </Box>
        </Styled.ContentSidebarContentContainer>
      </Styled.ContentSidebar>
    </React.Fragment>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './company-ads.build';
import { initialCompanyAdsState } from './company-ads.initial';
import { reducers } from './company-ads.reducers';

const slice = createSlice({
  name: 'company-ads',
  initialState: initialCompanyAdsState,
  reducers,
  extraReducers,
});

export const companyAdsActions = slice.actions;
export const companyAdsReducer = slice.reducer;

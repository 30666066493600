import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './perks.build';
import { initialPerksState } from './perks.initial';
import { reducers } from './perks.reducers';

const perksSlice = createSlice({
  name: 'perks',
  initialState: initialPerksState,
  reducers,
  extraReducers,
});

export const perksReducer = perksSlice.reducer;

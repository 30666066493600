import Joi from 'joi';

import {
  CreateIndexedOfficeLocation,
  CreateIndexedPerk,
  employmentTypes as _employmentTypes,
  workModelRemote,
} from '../shared';
import { workModels as _workModels } from '../shared';
import { CompanyProfileErrors } from './company-profile.errors';
import { CompanyProfileForm } from './company-profile.model';

export namespace CompanyProfileSchema {
  export const about = Joi.string()
    .trim()
    .required()
    .messages(CompanyProfileErrors.about);

  export const coverImage = Joi.object()
    .required()
    .messages(CompanyProfileErrors.coverImage);

  export const employmentTypes = Joi.array()
    .items(
      Joi.string()
        .valid(..._employmentTypes)
        .required(),
    )
    .required()
    .messages(CompanyProfileErrors.employmentTypes);

  export const funFacts = Joi.array()
    .items(Joi.string().trim().required())
    .unique((a: string, b: string) => a === b)
    .required()
    .messages(CompanyProfileErrors.funFacts);

  export const name = Joi.string()
    .trim()
    .required()
    .messages(CompanyProfileErrors.name);

  export const oneliner = Joi.string()
    .trim()
    .required()
    .messages(CompanyProfileErrors.oneliner);

  export const perks = Joi.array()
    .items(
      Joi.object<CreateIndexedPerk>({
        index: Joi.number().required(),
        perkKey: Joi.string()
          .required()
          .messages(CompanyProfileErrors.perkPerkKey),
        description: Joi.string().trim().required(),
      }).required(),
    )
    .required()
    .messages(CompanyProfileErrors.perks);

  export const subjectAreaKeys = Joi.array()
    .items(Joi.string().required())
    .required()
    .messages(CompanyProfileErrors.subjectAreaKeys);

  const officeLocationsSchema = Joi.array()
    .items(
      Joi.object<CreateIndexedOfficeLocation>({
        index: Joi.number().required(),
        officeLocationKey: Joi.string().required(),
      }).required(),
    )
    .required()
    .messages(CompanyProfileErrors.officeLocation);

  export const locationData = Joi.object<CompanyProfileForm['locationData']>({
    officeLocations: Joi.when('workModels', {
      is: Joi.array()
        .items(Joi.string().valid(workModelRemote).required())
        .required(),
      then: officeLocationsSchema.allow(null),
      otherwise: officeLocationsSchema,
    }).messages(CompanyProfileErrors.officeLocations),

    workModels: Joi.array()
      .items(
        Joi.string()
          .valid(..._workModels)
          .required(),
      )
      .required()
      .messages(CompanyProfileErrors.workModels),
  })
    .required()
    .messages(CompanyProfileErrors.locationData);
}

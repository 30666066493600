import { createCompanyProfileThunk } from '../company-profiles';
import {
  createCompanyProfileDraftThunk,
  deleteCompanyProfileDraftThunk,
  getCompanyProfileDraftThunk,
  getCompanyProfileDraftsThunk,
  updateCompanyProfileDraftThunk,
} from './company-profile-drafts.actions';
import { companyProfileDraftsAdapter } from './company-profile-drafts.adapter';
import { CompanyProfileDraftsBuilder } from './company-profile-drafts.types';

const createCompanyProfileDraftBuild = (
  builder: CompanyProfileDraftsBuilder,
) => {
  return builder
    .addCase(createCompanyProfileDraftThunk.fulfilled, (state) => {
      state.createCompanyProfileDraftStatus.status = 'idle';
    })
    .addCase(createCompanyProfileDraftThunk.pending, (state) => {
      state.createCompanyProfileDraftStatus.status = 'pending';
      state.createCompanyProfileDraftStatus.error = null;
    })
    .addCase(createCompanyProfileDraftThunk.rejected, (state, action) => {
      state.createCompanyProfileDraftStatus.status = 'idle';
      state.createCompanyProfileDraftStatus.error = action.error;
    });
};

const getCompanyProfileDraftsBuild = (builder: CompanyProfileDraftsBuilder) => {
  return builder
    .addCase(getCompanyProfileDraftsThunk.fulfilled, (state, action) => {
      companyProfileDraftsAdapter.upsertMany(state, action.payload);

      state.getCompanyProfileDraftsStatus.status = 'idle';
    })
    .addCase(getCompanyProfileDraftsThunk.pending, (state) => {
      state.getCompanyProfileDraftsStatus.status = 'pending';
      state.getCompanyProfileDraftsStatus.error = null;
    })
    .addCase(getCompanyProfileDraftsThunk.rejected, (state, action) => {
      state.getCompanyProfileDraftsStatus.status = 'idle';
      state.getCompanyProfileDraftsStatus.error = action.error;
    });
};

const getCompanyProfileDraftBuild = (builder: CompanyProfileDraftsBuilder) => {
  return builder
    .addCase(getCompanyProfileDraftThunk.fulfilled, (state, action) => {
      companyProfileDraftsAdapter.upsertOne(state, action.payload);

      state.getCompanyProfileDraftStatus.status = 'idle';
    })
    .addCase(getCompanyProfileDraftThunk.pending, (state) => {
      state.getCompanyProfileDraftStatus.status = 'pending';
      state.getCompanyProfileDraftStatus.error = null;
    })
    .addCase(getCompanyProfileDraftThunk.rejected, (state, action) => {
      state.getCompanyProfileDraftStatus.status = 'idle';
      state.getCompanyProfileDraftStatus.error = action.error;
    });
};

const updateCompanyProfileDraftBuild = (
  builder: CompanyProfileDraftsBuilder,
) => {
  return builder
    .addCase(updateCompanyProfileDraftThunk.fulfilled, (state, action) => {
      companyProfileDraftsAdapter.updateOne(state, {
        changes: action.payload,
        id: action.meta.arg.companyProfileDraftKey,
      });

      state.updateCompanyProfileDraftStatus.status = 'idle';
    })
    .addCase(updateCompanyProfileDraftThunk.pending, (state) => {
      state.updateCompanyProfileDraftStatus.status = 'pending';
      state.updateCompanyProfileDraftStatus.error = null;
    })
    .addCase(updateCompanyProfileDraftThunk.rejected, (state, action) => {
      state.updateCompanyProfileDraftStatus.status = 'idle';
      state.updateCompanyProfileDraftStatus.error = action.error;
    });
};

const deleteCompanyProfileDraftBuild = (
  builder: CompanyProfileDraftsBuilder,
) => {
  return builder
    .addCase(deleteCompanyProfileDraftThunk.fulfilled, (state, action) => {
      companyProfileDraftsAdapter.removeOne(state, action.meta.arg);

      state.deleteCompanyProfileDraftStatus.status = 'idle';
    })
    .addCase(deleteCompanyProfileDraftThunk.pending, (state) => {
      state.deleteCompanyProfileDraftStatus.status = 'pending';
      state.deleteCompanyProfileDraftStatus.error = null;
    })
    .addCase(deleteCompanyProfileDraftThunk.rejected, (state, action) => {
      state.deleteCompanyProfileDraftStatus.status = 'idle';
      state.deleteCompanyProfileDraftStatus.error = action.error;
    });
};

const createCompanyProfileBuild = (builder: CompanyProfileDraftsBuilder) => {
  return builder.addCase(
    createCompanyProfileThunk.fulfilled,
    (state, action) => {
      if (action.meta.arg.companyProfileDraftKey) {
        companyProfileDraftsAdapter.removeOne(
          state,
          action.meta.arg.companyProfileDraftKey,
        );
      }
    },
  );
};

export const extraReducers = (builder: CompanyProfileDraftsBuilder) => {
  createCompanyProfileDraftBuild(builder);
  getCompanyProfileDraftsBuild(builder);
  getCompanyProfileDraftBuild(builder);
  updateCompanyProfileDraftBuild(builder);
  deleteCompanyProfileDraftBuild(builder);
  createCompanyProfileBuild(builder);
};

import {
  Base64File,
  Base64FileDto,
  EditMeta,
  EditedByMeta,
  ImageData,
  ImageUrl,
  getFileFromUrl,
} from '@orbiapp/components';

import {
  CompanyProfile,
  CompanyProfileDraft,
  CreateCompanyProfile,
  CreateIndexedOfficeLocation,
  CreateIndexedPerk,
  EmploymentType,
  IndexedOfficeLocation,
  IndexedPerk,
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
  SubjectArea,
  UpdateCompanyProfile,
  UpdateCompanyProfileDraft,
  UpdateCompanyProfileIndexes,
  WorkModel,
} from '../../../../../models';
import {
  CompanyProfileDraftDto,
  CompanyProfileDto,
  CreateCompanyProfileDraftResponseDto,
  CreateCompanyProfileDto,
  CreateCompanyProfileResponseDto,
  CreateIndexedOfficeLocationDto,
  CreateIndexedPerkDto,
  PartialCompanyProfileDraftDto,
  PartialCompanyProfileDto,
  UpdateCompanyProfileDraftDto,
  UpdateCompanyProfileDto,
  UpdateCompanyProfileIndexesDto,
} from './company-profiles.dto';

const stringMapper = (string: string): string => string;

const base64FileMapper = (base64File: Base64File): Base64FileDto => ({
  base64: base64File.base64,
  fileName: base64File.fileName,
  fileType: base64File.fileType,
});

const editMetaMapper = (editMeta: EditMeta): EditMeta => ({
  createdAt: editMeta.createdAt,
  createdBy:
    editMeta.createdBy === null
      ? editMeta.createdBy
      : editedByMetaMapper(editMeta.createdBy),
  updatedAt: editMeta.updatedAt,
  updatedBy:
    editMeta.updatedBy === null
      ? editMeta.updatedBy
      : editedByMetaMapper(editMeta.updatedBy),
});

const editedByMetaMapper = (editedByMeta: EditedByMeta): EditedByMeta => ({
  firstName: editedByMeta.firstName,
  lastName: editedByMeta.lastName,
  profilePicture:
    editedByMeta.profilePicture === null
      ? editedByMeta.profilePicture
      : imageDataMapper(editedByMeta.profilePicture),
  userKey: editedByMeta.userKey,
});

const imageDataMapper = (imageData: ImageData): ImageData => ({
  blurHash: imageData.blurHash,
  original: imageUrlMapper(imageData.original),
  thumbnail1024: imageUrlMapper(imageData.thumbnail1024),
  thumbnail256: imageUrlMapper(imageData.thumbnail256),
  thumbnail512: imageUrlMapper(imageData.thumbnail512),
  thumbnail64: imageUrlMapper(imageData.thumbnail64),
});

const imageUrlMapper = (image: ImageUrl): ImageUrl => ({
  url: image.url,
});

const employmentTypeMapper = (employmentType: EmploymentType): EmploymentType =>
  employmentType;

const workModelMappers = (workModel: WorkModel): WorkModel => workModel;

const indexedOfficeLocationMapper = (
  indexedOfficeLocation: IndexedOfficeLocation,
  index: number,
): IndexedOfficeLocation => ({
  address: indexedOfficeLocation.address,
  index,
  label: indexedOfficeLocation.label,
  latitude: indexedOfficeLocation.latitude,
  longitude: indexedOfficeLocation.longitude,
  officeLocationKey: indexedOfficeLocation.officeLocationKey,
  placeId: indexedOfficeLocation.placeId,
});

const createIndexedOfficeLocationMapper = (
  createIndexedOfficeLocation: CreateIndexedOfficeLocation,
  index: number,
): CreateIndexedOfficeLocationDto => ({
  index,
  officeLocationKey: createIndexedOfficeLocation.officeLocationKey,
});

const indexedPerkMapper = (indexedPerk: IndexedPerk): IndexedPerk => ({
  description: indexedPerk.description,
  index: indexedPerk.index,
  name: indexedPerk.name,
  emoji: indexedPerk.emoji,
  perkCategoryKey: indexedPerk.perkCategoryKey,
  perkKey: indexedPerk.perkKey,
  emojiAndName: `${indexedPerk.emoji} ${indexedPerk.name}`,
});

const createIndexedPerkMapper = (
  createIndexedPerk: CreateIndexedPerk,
): CreateIndexedPerkDto => ({
  index: createIndexedPerk.index,
  description: createIndexedPerk.description,
  perkKey: createIndexedPerk.perkKey,
});

const subjectAreaMapper = (subjectArea: SubjectArea): SubjectArea => ({
  name: subjectArea.name,
  subjectAreaCategoryKey: subjectArea.subjectAreaCategoryKey,
  subjectAreaKey: subjectArea.subjectAreaKey,
});

export const createCompanyProfileDraftResponseMapper = (
  createCompanyProfileDraftResponseDto: CreateCompanyProfileDraftResponseDto,
): string => createCompanyProfileDraftResponseDto.companyProfileDraftKey;

export const partialCompanyProfileDraftMapper = (
  partialCompanyProfileDraftDto: PartialCompanyProfileDraftDto,
): PartialCompanyProfileDraft => ({
  companyProfileDraftKey: partialCompanyProfileDraftDto.companyProfileDraftKey,
  editMeta: editMetaMapper(partialCompanyProfileDraftDto.editMeta),
  name: partialCompanyProfileDraftDto.name,
  subjectAreas: partialCompanyProfileDraftDto.subjectAreas,
});

export const getCompanyProfileDraftResponseMapper = async (
  companyProfileDraftDto: CompanyProfileDraftDto,
): Promise<CompanyProfileDraft | undefined> => {
  let coverImageBase64: Base64File | null = null;

  if (companyProfileDraftDto.coverImage) {
    const res = await getFileFromUrl(
      companyProfileDraftDto.coverImage.original.url,
    );

    if (!res) {
      return;
    }

    coverImageBase64 = res;
  }

  return {
    companyProfileDraftKey: companyProfileDraftDto.companyProfileDraftKey,
    editMeta: editMetaMapper(companyProfileDraftDto.editMeta),
    about: companyProfileDraftDto.about,
    contactEmail: companyProfileDraftDto.contactEmail,
    contactPhone: companyProfileDraftDto.contactPhone,
    coverImage: companyProfileDraftDto.coverImage
      ? imageDataMapper(companyProfileDraftDto.coverImage)
      : companyProfileDraftDto.coverImage,
    coverImageBase64,
    employmentTypes: companyProfileDraftDto.employmentTypes
      ? companyProfileDraftDto.employmentTypes.map(employmentTypeMapper)
      : companyProfileDraftDto.employmentTypes,
    funFacts: companyProfileDraftDto.funFacts
      ? companyProfileDraftDto.funFacts.map(stringMapper)
      : companyProfileDraftDto.funFacts,
    name: companyProfileDraftDto.name,

    oneliner: companyProfileDraftDto.oneliner,
    perks: companyProfileDraftDto.perks
      ? companyProfileDraftDto.perks.map(indexedPerkMapper)
      : companyProfileDraftDto.perks,
    subjectAreas: companyProfileDraftDto.subjectAreas
      ? companyProfileDraftDto.subjectAreas.map(subjectAreaMapper)
      : companyProfileDraftDto.subjectAreas,
    videoUrl: companyProfileDraftDto.videoUrl,
    websiteUrl: companyProfileDraftDto.websiteUrl,
    locationData: companyProfileDraftDto.locationData
      ? {
          workModels:
            companyProfileDraftDto.locationData.workModels.map(
              workModelMappers,
            ),

          officeLocations: companyProfileDraftDto.locationData.officeLocations
            ? companyProfileDraftDto.locationData.officeLocations.map(
                indexedOfficeLocationMapper,
              )
            : companyProfileDraftDto.locationData.officeLocations,
        }
      : companyProfileDraftDto.locationData,
  };
};

export const updateCompanyProfileDraftParamsMapper = (
  updateCompanyProfileDraft: UpdateCompanyProfileDraft,
): UpdateCompanyProfileDraftDto => ({
  about: updateCompanyProfileDraft.about,
  contactEmail: updateCompanyProfileDraft.contactEmail,
  contactPhone: updateCompanyProfileDraft.contactPhone,
  coverImage: updateCompanyProfileDraft.coverImageBase64
    ? base64FileMapper(updateCompanyProfileDraft.coverImageBase64)
    : updateCompanyProfileDraft.coverImageBase64,
  employmentTypes: updateCompanyProfileDraft.employmentTypes
    ? updateCompanyProfileDraft.employmentTypes.map(employmentTypeMapper)
    : updateCompanyProfileDraft.employmentTypes,
  funFacts: updateCompanyProfileDraft.funFacts
    ? updateCompanyProfileDraft.funFacts.map(stringMapper)
    : updateCompanyProfileDraft.funFacts,
  name: updateCompanyProfileDraft.name,
  oneliner: updateCompanyProfileDraft.oneliner,
  perks: updateCompanyProfileDraft.perks
    ? updateCompanyProfileDraft.perks.map(createIndexedPerkMapper)
    : updateCompanyProfileDraft.perks,
  subjectAreaKeys: updateCompanyProfileDraft.subjectAreaKeys
    ? updateCompanyProfileDraft.subjectAreaKeys.map(stringMapper)
    : updateCompanyProfileDraft.subjectAreaKeys,
  videoUrl: updateCompanyProfileDraft.videoUrl,
  websiteUrl: updateCompanyProfileDraft.websiteUrl,
  locationData: updateCompanyProfileDraft.locationData
    ? {
        workModels:
          updateCompanyProfileDraft.locationData.workModels.map(
            workModelMappers,
          ),

        officeLocations: updateCompanyProfileDraft.locationData.officeLocations
          ? updateCompanyProfileDraft.locationData.officeLocations.map(
              createIndexedOfficeLocationMapper,
            )
          : updateCompanyProfileDraft.locationData.officeLocations,
      }
    : updateCompanyProfileDraft.locationData,
});

export const createCompanyProfileParamsMapper = (
  createCompanyProfile: CreateCompanyProfile,
): CreateCompanyProfileDto => ({
  about: createCompanyProfile.about,
  contactEmail: createCompanyProfile.contactEmail,
  contactPhone: createCompanyProfile.contactPhone,
  coverImage: base64FileMapper(createCompanyProfile.coverImageBase64),
  employmentTypes:
    createCompanyProfile.employmentTypes.map(employmentTypeMapper),
  funFacts: createCompanyProfile.funFacts
    ? createCompanyProfile.funFacts.map(stringMapper)
    : createCompanyProfile.funFacts,
  name: createCompanyProfile.name,

  oneliner: createCompanyProfile.oneliner,
  perks: createCompanyProfile.perks.map(createIndexedPerkMapper),
  subjectAreaKeys: createCompanyProfile.subjectAreaKeys.map(stringMapper),
  videoUrl: createCompanyProfile.videoUrl,
  websiteUrl: createCompanyProfile.websiteUrl,
  companyProfileDraftKey: createCompanyProfile.companyProfileDraftKey,
  locationData: {
    workModels:
      createCompanyProfile.locationData.workModels.map(workModelMappers),
    officeLocations: createCompanyProfile.locationData.officeLocations
      ? createCompanyProfile.locationData.officeLocations.map(
          createIndexedOfficeLocationMapper,
        )
      : createCompanyProfile.locationData.officeLocations,
  },
});

export const createCompanyProfileResponseMapper = (
  createCompanyProfileResponseDto: CreateCompanyProfileResponseDto,
): string => createCompanyProfileResponseDto.companyProfileKey;

export const partialCompanyProfileMapper = (
  partialCompanyProfileDto: PartialCompanyProfileDto,
): PartialCompanyProfile => ({
  companyProfileKey: partialCompanyProfileDto.companyProfileKey,
  editMeta: editMetaMapper(partialCompanyProfileDto.editMeta),
  index: partialCompanyProfileDto.index,
  about: partialCompanyProfileDto.about,
  name: partialCompanyProfileDto.name,
  subjectAreas: partialCompanyProfileDto.subjectAreas,
});

export const getCompanyProfileResponseMapper = async (
  companyProfileDto: CompanyProfileDto,
): Promise<CompanyProfile | undefined> => {
  const res = await getFileFromUrl(companyProfileDto.coverImage.original.url);

  if (!res) {
    return;
  }

  return {
    companyProfileKey: companyProfileDto.companyProfileKey,
    editMeta: editMetaMapper(companyProfileDto.editMeta),
    about: companyProfileDto.about,
    contactEmail: companyProfileDto.contactEmail,
    contactPhone: companyProfileDto.contactPhone,
    coverImage: imageDataMapper(companyProfileDto.coverImage),
    coverImageBase64: res,
    employmentTypes:
      companyProfileDto.employmentTypes.map(employmentTypeMapper),
    funFacts: companyProfileDto.funFacts
      ? companyProfileDto.funFacts.map(stringMapper)
      : companyProfileDto.funFacts,
    index: companyProfileDto.index,
    name: companyProfileDto.name,
    oneliner: companyProfileDto.oneliner,
    perks: companyProfileDto.perks.map(indexedPerkMapper),
    subjectAreas: companyProfileDto.subjectAreas.map(subjectAreaMapper),
    videoUrl: companyProfileDto.videoUrl,
    websiteUrl: companyProfileDto.websiteUrl,
    locationData: {
      workModels:
        companyProfileDto.locationData.workModels.map(workModelMappers),
      officeLocations: companyProfileDto.locationData.officeLocations
        ? companyProfileDto.locationData.officeLocations.map(
            indexedOfficeLocationMapper,
          )
        : companyProfileDto.locationData.officeLocations,
    },
  };
};

export const updateCompanyProfileParamsMapper = (
  updateCompanyProfile: UpdateCompanyProfile,
): UpdateCompanyProfileDto => ({
  about: updateCompanyProfile.about,
  contactEmail: updateCompanyProfile.contactEmail,
  contactPhone: updateCompanyProfile.contactPhone,
  coverImage: updateCompanyProfile.coverImageBase64
    ? base64FileMapper(updateCompanyProfile.coverImageBase64)
    : updateCompanyProfile.coverImageBase64,
  employmentTypes: updateCompanyProfile.employmentTypes
    ? updateCompanyProfile.employmentTypes.map(employmentTypeMapper)
    : updateCompanyProfile.employmentTypes,
  funFacts: updateCompanyProfile.funFacts
    ? updateCompanyProfile.funFacts.map(stringMapper)
    : updateCompanyProfile.funFacts,
  name: updateCompanyProfile.name,
  oneliner: updateCompanyProfile.oneliner,
  perks: updateCompanyProfile.perks
    ? updateCompanyProfile.perks.map(createIndexedPerkMapper)
    : updateCompanyProfile.perks,
  subjectAreaKeys: updateCompanyProfile.subjectAreaKeys
    ? updateCompanyProfile.subjectAreaKeys.map(stringMapper)
    : updateCompanyProfile.subjectAreaKeys,
  videoUrl: updateCompanyProfile.videoUrl,
  websiteUrl: updateCompanyProfile.websiteUrl,
  locationData: updateCompanyProfile.locationData
    ? {
        workModels: updateCompanyProfile.locationData.workModels
          ? updateCompanyProfile.locationData.workModels.map(workModelMappers)
          : updateCompanyProfile.locationData.workModels,

        officeLocations: updateCompanyProfile.locationData.officeLocations
          ? updateCompanyProfile.locationData.officeLocations.map(
              createIndexedOfficeLocationMapper,
            )
          : updateCompanyProfile.locationData.officeLocations,
      }
    : updateCompanyProfile.locationData,
});

export const updateCompanyProfileIndexesParamsMapper = (
  updateCompanyProfileIndexes: UpdateCompanyProfileIndexes,
): UpdateCompanyProfileIndexesDto => ({
  companyProfiles: updateCompanyProfileIndexes.companyProfiles.map(
    (companyProfileDto) => ({
      companyProfileKey: companyProfileDto.companyProfileKey,
      index: companyProfileDto.index,
    }),
  ),
});

import {
  exportInsightsThunk,
  getEstimatedValueThunk,
  getInsightsItemsThunk,
  getLiveInsightsThunk,
  getTimeBasedInsightsByProductThunk,
  getTimeBasedInsightsThunk,
} from './insights.actions';
import { InsightsBuilder } from './insights.types';

function getLiveInsightsBuild(builder: InsightsBuilder) {
  return builder
    .addCase(getLiveInsightsThunk.pending, (state) => {
      state.liveInsights.status = 'pending';
      state.liveInsights.error = null;
    })
    .addCase(getLiveInsightsThunk.fulfilled, (state, action) => {
      state.liveInsights.status = 'idle';
      state.liveInsights.data = action.payload;
    })
    .addCase(getLiveInsightsThunk.rejected, (state, action) => {
      state.liveInsights.status = 'idle';
      state.liveInsights.error = action.error;
    });
}

function getInsightsItemsBuild(builder: InsightsBuilder) {
  return builder
    .addCase(getInsightsItemsThunk.pending, (state) => {
      state.items.status = 'pending';
      state.items.error = null;
    })
    .addCase(getInsightsItemsThunk.fulfilled, (state, action) => {
      state.items.status = 'idle';
      state.items.data = action.payload;
    })
    .addCase(getInsightsItemsThunk.rejected, (state, action) => {
      state.items.status = 'idle';
      state.items.error = action.error;
    });
}

function getTimeBasedInsightsBuild(builder: InsightsBuilder) {
  return builder
    .addCase(getTimeBasedInsightsThunk.pending, (state) => {
      state.timeSeries.status = 'pending';
      state.timeSeries.error = null;
    })
    .addCase(getTimeBasedInsightsThunk.fulfilled, (state, action) => {
      state.timeSeries.status = 'idle';
      state.timeSeries.data = action.payload;
    })
    .addCase(getTimeBasedInsightsThunk.rejected, (state, action) => {
      state.timeSeries.status = 'idle';
      state.timeSeries.error = action.error;
    });
}

function getTimeBasedInsightsByProductBuild(builder: InsightsBuilder) {
  return builder
    .addCase(getTimeBasedInsightsByProductThunk.pending, (state) => {
      state.timeSeriesByProduct.status = 'pending';
      state.timeSeriesByProduct.error = null;
    })
    .addCase(getTimeBasedInsightsByProductThunk.fulfilled, (state, action) => {
      state.timeSeriesByProduct.status = 'idle';
      state.timeSeriesByProduct.data = action.payload;
    })
    .addCase(getTimeBasedInsightsByProductThunk.rejected, (state, action) => {
      state.timeSeriesByProduct.status = 'idle';
      state.timeSeriesByProduct.error = action.error;
    });
}

function exportInsightsBuild(builder: InsightsBuilder) {
  return builder
    .addCase(exportInsightsThunk.pending, (state) => {
      state.exportInsights.status = 'pending';
      state.exportInsights.error = null;
    })
    .addCase(exportInsightsThunk.fulfilled, (state) => {
      state.exportInsights.status = 'idle';
    })
    .addCase(exportInsightsThunk.rejected, (state, action) => {
      state.exportInsights.status = 'idle';
      state.exportInsights.error = action.error;
    });
}

function getEstimatedValueBuild(builder: InsightsBuilder) {
  return builder
    .addCase(getEstimatedValueThunk.pending, (state) => {
      state.estimatedValue.status = 'pending';
      state.estimatedValue.error = null;
    })
    .addCase(getEstimatedValueThunk.fulfilled, (state, action) => {
      state.estimatedValue.status = 'idle';
      state.estimatedValue.data = action.payload;
    })
    .addCase(getEstimatedValueThunk.rejected, (state, action) => {
      state.estimatedValue.status = 'idle';
      state.estimatedValue.error = action.error;
    });
}

export function extraReducers(builder: InsightsBuilder) {
  getLiveInsightsBuild(builder);

  getInsightsItemsBuild(builder);

  getTimeBasedInsightsBuild(builder);
  getTimeBasedInsightsByProductBuild(builder);

  exportInsightsBuild(builder);

  getEstimatedValueBuild(builder);
}

import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Icon,
  LeadingInputBox,
  Text,
} from '@orbiapp/components';

import {
  JOB_CONTACT_EMAIL_MAX_LENGTH,
  JOB_CONTACT_NAME_MAX_LENGTH,
  JOB_CONTACT_PHONE_MAX_LENGTH,
} from '../../../../../models';
import { JobSelector, useSelector } from '../../../../../store';
import { getOptionalLabelText } from '../../../../../utils';

export function ContactDetails() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.6.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.6.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <ControlledInput
          name="contactName"
          maxLength={JOB_CONTACT_NAME_MAX_LENGTH}
          leadingElement={
            <LeadingInputBox>
              <Icon name="user-outline" />
            </LeadingInputBox>
          }
          labelTx="label.job-form.name"
          disabled={!canEditJob}
        />

        <ControlledInput
          name="contactEmail"
          maxLength={JOB_CONTACT_EMAIL_MAX_LENGTH}
          leadingElement={
            <LeadingInputBox>
              <Icon name="envelope-outline" />
            </LeadingInputBox>
          }
          labelTx="label.job-form.email"
          disabled={!canEditJob}
        />

        <ControlledInput
          name="contactPhone"
          maxLength={JOB_CONTACT_PHONE_MAX_LENGTH}
          leadingElement={
            <LeadingInputBox>
              <Icon name="phone-outline" />
            </LeadingInputBox>
          }
          label={getOptionalLabelText('label.job-form.phone-number')}
          disabled={!canEditJob}
        />
      </Box>
    </FormSection>
  );
}

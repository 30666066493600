import { Pagination } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { CompanyAdsOrderByKey, PartialCompanyAd } from '../../models';
import { getPageAndNextPage, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';
import { partialCompanyAdAdapter } from './company-ads.adapter';

export const getCompanyAdsThunk = createAsyncThunk<
  PartialCompanyAd[],
  Pagination<CompanyAdsOrderByKey>,
  ThunkApiConfig
>('company-ads/get-company-ads', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    partialCompanyAdAdapter
      .getSelectors()
      .selectAll(state.companyAds.companyAds.data),
    v1.companyAds.getCompanyAds,
    { pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

import styled, { css } from 'styled-components';

import { ControlsContainer } from '../../../../components/insights';

const InsightsControlsContainer = styled(ControlsContainer)`
  ${(props) => {
    const { theme } = props;

    return css`
      top: -16px;

      @media (min-width: ${theme.breakpoints.lg}px) {
        top: -32px;
      }
    `;
  }}
`;

export const Styled = { InsightsControlsContainer };

import {
  Box,
  Button,
  Confetti,
  Icon,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSide,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { NotOnboardedToolbar } from '../../../components';
import { formatIndustry } from '../../../models';
import {
  CompanyInvitationSelector,
  CompanySelector,
  useSelector,
} from '../../../store';

export function OnBoarded() {
  const companyInvitation = useSelector(CompanyInvitationSelector.selectData);
  const followerCount = useSelector(CompanySelector.selectFollowerCount);
  const logo = useSelector(CompanySelector.selectCompanyLogo);
  const industry = useSelector(CompanySelector.selectIndustry);
  const employeeCount = useSelector(CompanySelector.selectEmployeeCount);
  const companyName = useSelector(CompanySelector.selectCompanyName);

  if (!companyInvitation) {
    return <Navigate to="/" />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <Confetti />

      <SplitScreenContainer>
        <SplitScreenLeftSide
          avatarSrc={logo?.thumbnail64.url}
          avatarFallbackLetter={companyName?.charAt(0)}
          title={companyName ?? ''}
          blurredCards={[
            {
              titleTx: 'label.auth.sign-up.followers',
              subtitle: followerCount ?? 0,
              icon: 'user-group-outline',
            },
            {
              titleTx: 'label.auth.sign-up.industry',
              subtitle: formatIndustry(industry ?? 'accounting'),
              icon: 'building-storefront-outline',
            },
            {
              titleTx: 'label.auth.sign-up.company-size',
              subtitle: employeeCount ?? 0,
              icon: 'users-outline',
            },
          ]}
        />

        <SplitScreenContentContainer>
          <Icon name="check-circle-solid" size={100} color="onboardedIcon" />

          {companyInvitation?.userExists ? (
            <Text
              variant="titleMd"
              tx="label.auth.onboarded.added-to-workspace"
              color="signUpRightSideTitle"
              txArgs={{ companyName: companyName ?? '' }}
              textAlign="center"
            />
          ) : (
            <Text
              variant="titleMd"
              tx="label.auth.onboarded.title"
              color="signUpRightSideTitle"
              textAlign="center"
            />
          )}

          <Box width="100%">
            <Button
              variant="primary"
              tx="button.enter-dashboard"
              to="/insights"
              width="100%"
              large
            />
          </Box>
        </SplitScreenContentContainer>
      </SplitScreenContainer>
    </React.Fragment>
  );
}

import { PayloadAction } from '@reduxjs/toolkit';

import {
  partialCompanyQrAdapter,
  partialTalentPoolUserAdapter,
} from './connect.adapter';
import { initialConnectState } from './connect.initial';
import { ConnectState } from './connect.types';

function reset(state: ConnectState) {
  Object.assign(state, initialConnectState);
}

function clearPartialCompanyQrs(state: ConnectState) {
  partialCompanyQrAdapter.removeAll(state.partialCompanyQrs.data);
}

function clearCompanyQr(state: ConnectState) {
  state.companyQr.data = null;
}

function clearPartialTalentPoolUsers(state: ConnectState) {
  partialTalentPoolUserAdapter.removeAll(state.partialTalentPoolUsers.data);
}

function clearTalentPoolUser(state: ConnectState) {
  state.talentPoolUser.data = null;
}

function toggleUserIsStarred(
  state: ConnectState,
  action: PayloadAction<string>,
) {
  const user = partialTalentPoolUserAdapter
    .getSelectors()
    .selectById(state.partialTalentPoolUsers.data, action.payload);

  if (user) {
    partialTalentPoolUserAdapter.updateOne(state.partialTalentPoolUsers.data, {
      id: action.payload,
      changes: { isStarred: !user.isStarred },
    });
  }

  if (state.talentPoolUser.data?.userKey === action.payload) {
    state.talentPoolUser.data.isStarred = !state.talentPoolUser.data.isStarred;
  }
}

export const reducers = {
  reset,

  clearPartialCompanyQrs,
  clearCompanyQr,

  clearPartialTalentPoolUsers,
  clearTalentPoolUser,

  toggleUserIsStarred,
};

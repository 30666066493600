import { createEntityAdapter } from '@reduxjs/toolkit';

import { CompanyProfileDraft, PartialCompanyProfileDraft } from '../../models';

export const companyProfileDraftsAdapter = createEntityAdapter<
  CompanyProfileDraft | PartialCompanyProfileDraft,
  string
>({
  selectId: (post) => post.companyProfileDraftKey,
  sortComparer: (a, b) =>
    (b.editMeta.updatedAt ?? b.editMeta.createdAt) -
    (a.editMeta.updatedAt ?? a.editMeta.createdAt),
});

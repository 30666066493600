import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CompanyProfileDraft,
  PartialCompanyProfileDraft,
  UpdateCompanyProfileDraftParams,
  isCompanyProfileDraftAndNotPartialCompanyProfileDraft,
} from '../../models';
import { OrbiApi, v2 } from '../../services';
import { officeLocationsAdapter } from '../company/company.adapter';
import { setAlert } from '../global-ui-state';
import '../perks';
import { PerksSelector } from '../perks';
import { ThunkApiConfig } from '../store.types';
import { SubjectAreasSelector } from '../subject-areas';
import { companyProfileDraftsAdapter } from './company-profile-drafts.adapter';
import {
  indexedOfficeLocationsMapper,
  indexedPerksMapper,
  subjectAreasMapper,
} from './company-profile-drafts.mappers';

export const createCompanyProfileDraftThunk = createAsyncThunk<
  string,
  undefined,
  ThunkApiConfig
>(
  'companyProfileDrafts/createCompanyProfileDraftThunk',
  async (_, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.createCompanyProfileDraft,
      undefined,
    );

    if (res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(res);
    }

    return res.data;
  },
);

export const getCompanyProfileDraftsThunk = createAsyncThunk<
  PartialCompanyProfileDraft[],
  undefined,
  ThunkApiConfig
>('companyProfileDrafts/getCompanyProfileDraftsThunk', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.companyProfiles.getCompanyProfileDrafts,
    undefined,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getCompanyProfileDraftThunk = createAsyncThunk<
  CompanyProfileDraft,
  string,
  ThunkApiConfig
>(
  'companyProfileDrafts/getCompanyProfileDraftThunk',
  async (companyProfileDraftKey, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.getCompanyProfileDraft,
      companyProfileDraftKey,
    );

    if (res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(res);
    }

    return res.data;
  },
);

export const updateCompanyProfileDraftThunk = createAsyncThunk<
  Partial<CompanyProfileDraft>,
  UpdateCompanyProfileDraftParams,
  ThunkApiConfig
>(
  'companyProfileDrafts/updateCompanyProfileDraftThunk',
  async (updateCompanyProfileDraftParams, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.updateCompanyProfileDraft,
      updateCompanyProfileDraftParams,
    );

    if (res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(res);
    }

    const state = thunkAPI.getState();

    const companyProfileDraft = companyProfileDraftsAdapter
      .getSelectors()
      .selectById(
        state.companyProfileDrafts,
        updateCompanyProfileDraftParams.companyProfileDraftKey,
      );

    const updatedBy = state.account.account.data
      ? {
          firstName: state.account.account.data.firstName,
          lastName: state.account.account.data.lastName,
          profilePicture: null,
          userKey: state.account.account.data.userKey,
        }
      : null;

    if (
      updateCompanyProfileDraftParams.updateCompanyProfileDraft
        .coverImageBase64 === undefined &&
      updatedBy &&
      companyProfileDraft &&
      isCompanyProfileDraftAndNotPartialCompanyProfileDraft(companyProfileDraft)
    ) {
      const {
        locationData,
        perks,
        subjectAreaKeys,
        ...updateCompanyProfileDraftRest
      } = updateCompanyProfileDraftParams.updateCompanyProfileDraft;

      const newCompanyProfileDraft: Partial<CompanyProfileDraft> = {
        editMeta: {
          ...companyProfileDraft.editMeta,
          updatedAt: Date.now(),
          updatedBy,
        },
        ...updateCompanyProfileDraftRest,
      };

      if (locationData !== undefined) {
        newCompanyProfileDraft.locationData = locationData
          ? {
              workModels: locationData.workModels,
              officeLocations: locationData.officeLocations
                ? indexedOfficeLocationsMapper(
                    locationData.officeLocations,
                    officeLocationsAdapter
                      .getSelectors()
                      .selectAll(state.company.officeLocations.data),
                  )
                : null,
            }
          : null;
      }

      if (perks !== undefined) {
        newCompanyProfileDraft.perks = perks
          ? indexedPerksMapper(perks, PerksSelector.selectAll(state))
          : null;
      }

      if (subjectAreaKeys !== undefined) {
        newCompanyProfileDraft.subjectAreas = subjectAreaKeys
          ? subjectAreasMapper(
              subjectAreaKeys,
              SubjectAreasSelector.selectAll(state),
            )
          : null;
      }

      return newCompanyProfileDraft;
    }

    const _res = await OrbiApi.call(
      v2.companyProfiles.getCompanyProfileDraft,
      updateCompanyProfileDraftParams.companyProfileDraftKey,
    );

    if (_res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(_res);
    }

    return _res.data;
  },
);

export const deleteCompanyProfileDraftThunk = createAsyncThunk<
  undefined,
  string,
  ThunkApiConfig
>(
  'companyProfileDrafts/deleteCompanyProfileDraftThunk',
  async (companyProfileDraftKey, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.companyProfiles.deleteCompanyProfileDraft,
      companyProfileDraftKey,
    );

    if (res.kind !== 'ok') {
      thunkAPI.dispatch(setAlert('delete-profile-draft:error'));
      return thunkAPI.rejectWithValue(res);
    }

    thunkAPI.dispatch(setAlert('delete-profile-draft:success'));
    return;
  },
);

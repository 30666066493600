import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  subjectAreaAdapter,
  subjectAreaCategoriesAdapter,
} from './subject-areas.adapter';

export namespace SubjectAreaCategoriesSelector {
  const subjectAreaCategoriesSelectors =
    subjectAreaCategoriesAdapter.getSelectors<RootState>(
      (state) => state.subjectAreas.subjectAreaCategoriesState,
    );

  export const selectStatus = (state: RootState) =>
    state.subjectAreas.getSubjectAreaCategoriesStatus.status;

  export const selectAll = subjectAreaCategoriesSelectors.selectAll;

  export const selectById = subjectAreaCategoriesSelectors.selectById;

  export const selectSubjectAreaCategoryWithNameAndKey = createSelector(
    selectAll,
    (subjectAreaCategories) =>
      subjectAreaCategories.map((subjectAreaCategory) => ({
        name: subjectAreaCategory.name,
        subjectAreaCategoryKey: subjectAreaCategory.subjectAreaCategoryKey,
      })),
  );
}

export namespace SubjectAreasSelector {
  const subjectAreaSelectors = subjectAreaAdapter.getSelectors<RootState>(
    (state) => state.subjectAreas.subjectAreaState,
  );

  export const selectAll = subjectAreaSelectors.selectAll;

  export const selectById = subjectAreaSelectors.selectById;
}

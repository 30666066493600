import { getPerkCategoriesThunk } from './perks.actions';
import { perkCategoriesAdapter, perksAdapter } from './perks.adapter';
import { PerksBuilder } from './perks.types';

const getPerkCategoriesBuild = (builder: PerksBuilder) => {
  return builder
    .addCase(getPerkCategoriesThunk.fulfilled, (state, action) => {
      perkCategoriesAdapter.upsertMany(
        state.perkCategoriesState,
        action.payload,
      );

      const perks = action.payload
        .map((perkCategory) => perkCategory.perks)
        .flat();

      perksAdapter.upsertMany(state.perksState, perks);

      state.getPerkCategoriesStatus.status = 'idle';
    })
    .addCase(getPerkCategoriesThunk.pending, (state) => {
      state.getPerkCategoriesStatus.status = 'pending';
      state.getPerkCategoriesStatus.error = null;
    })
    .addCase(getPerkCategoriesThunk.rejected, (state, action) => {
      state.getPerkCategoriesStatus.status = 'idle';
      state.getPerkCategoriesStatus.error = action.error;
    });
};

export const extraReducers = (builder: PerksBuilder) => {
  getPerkCategoriesBuild(builder);
};

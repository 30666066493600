import {
  Address,
  Coordinates,
  SearchAddressesParams,
  SearchCoordinatesParams,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const getCoordinatesThunk = createAsyncThunk<
  Coordinates,
  SearchCoordinatesParams,
  ThunkApiConfig
>('search/coordinates', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchCoordinates, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getAddressesThunk = createAsyncThunk<
  Address[],
  SearchAddressesParams,
  ThunkApiConfig
>('search/addresses', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchAddresses, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

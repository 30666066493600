import {
  Box,
  BoxProps,
  Checkbox,
  FormSection,
  FormSectionHeader,
  Icon,
  IconButton,
  InputChip,
  InputChips,
  InputRefContext,
  InputRefProvider,
  LeadingInputBox,
  Menu,
  MenuDivider,
  MenuItem,
  Text,
  Tooltip,
  TrailingInputBox,
  createSearchContext,
  isTxString,
  useMenu,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

import { PickProfileModal } from '../../../../../components';
import {
  CreateJobForm,
  StudyLevel,
  SubjectArea,
  UpdateJobDraftSchema,
  studyLevels,
} from '../../../../../models';
import {
  SubjectAreaCategoriesSelector,
  SubjectAreasSelector,
  setAlert,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { getStudyLevelTx } from '../../../../../utils';
import { useSaveField } from '../create-job.helpers';
import { Styled } from './target-group-styled';

const SubjectAreaSearchContext = createSearchContext<SubjectArea>();
const StudyLevelSearchContext = createSearchContext<StudyLevel>();

const SUBJECT_AREAS_ITEM_HEIGHT = 42;
const MENU_MAX_HEIGHT = SUBJECT_AREAS_ITEM_HEIGHT * 9;

function SubjectAreaCategoryMenuItem(
  props: { subjectAreaCategoryKey: string } & BoxProps,
) {
  const { subjectAreaCategoryKey, ...rest } = props;

  const { setValue, watch } = useFormContext<CreateJobForm>();

  const subjectAreaCategory = useSelector((state) =>
    SubjectAreaCategoriesSelector.selectById(state, subjectAreaCategoryKey),
  );

  const subjectAreaKeys = watch('subjectAreaKeys');

  const isChecked = React.useMemo(() => {
    return !!subjectAreaCategory?.subjectAreas.every((subjectArea) =>
      subjectAreaKeys.includes(subjectArea.subjectAreaKey),
    );
  }, [subjectAreaKeys, subjectAreaCategory]);

  const inputRef = React.useContext(InputRefContext);

  const toggleSubjectAreaKeys = () => {
    if (isChecked) {
      const newValue = subjectAreaKeys.filter(
        (subjectAreaKey) =>
          !subjectAreaCategory?.subjectAreas.some(
            (subjectArea) => subjectArea.subjectAreaKey === subjectAreaKey,
          ),
      );

      setValue('subjectAreaKeys', newValue, {
        shouldDirty: true,
      });

      inputRef.current?.focus();

      return;
    }

    const newValue: string[] = [];

    subjectAreaCategory?.subjectAreas.forEach((subjectArea) => {
      if (!subjectAreaKeys.includes(subjectArea.subjectAreaKey)) {
        newValue.push(subjectArea.subjectAreaKey);
      }
    });

    setValue('subjectAreaKeys', [...subjectAreaKeys, ...newValue], {
      shouldDirty: true,
    });

    inputRef.current?.focus();
  };

  return (
    <MenuDivider
      flex
      flexAlign="center"
      gap={8}
      text={subjectAreaCategory?.name}
      pl={8}
      onClick={toggleSubjectAreaKeys}
      cursor="pointer"
      {...rest}
    >
      <Checkbox checked={isChecked} onChange={() => {}} />
    </MenuDivider>
  );
}

function SubjectAreaMenuItem(props: SubjectArea & BoxProps) {
  const { subjectAreaKey, name, ...rest } = props;

  const formContext = useFormContext<CreateJobForm>();

  const subjectAreaKeys = useWatch({
    control: formContext.control,
    name: 'subjectAreaKeys',
  });

  const isChecked = subjectAreaKeys?.includes(subjectAreaKey);

  const inputRef = React.useContext(InputRefContext);

  const toggleSubjectAreaKey = () => {
    formContext.setValue(
      'subjectAreaKeys',
      isChecked
        ? subjectAreaKeys.filter(
            (_subjectAreaKey) => _subjectAreaKey !== subjectAreaKey,
          )
        : subjectAreaKeys.concat(subjectAreaKey),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    inputRef.current?.focus();
  };

  return (
    <MenuItem
      width="100%"
      onClick={toggleSubjectAreaKey}
      gap={8}
      flex
      flexAlign="center"
      pl={32}
      {...rest}
    >
      <Checkbox onChange={toggleSubjectAreaKey} checked={isChecked} />

      <Text as="span" text={name} variant="bodyMd" />
    </MenuItem>
  );
}

function SubjectAreas() {
  const subjectAreas = useSelector(SubjectAreasSelector.selectAll);
  const subjectAreaCategories = useSelector(
    SubjectAreaCategoriesSelector.selectSubjectAreaCategoryWithNameAndKey,
  );

  const searchContext = React.useContext(
    SubjectAreaSearchContext.SearchContext,
  );

  const [searchString, setSearchString] = React.useState('');

  const inputRef = React.useContext(InputRefContext);
  const clickOutsideRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const formContext = useFormContext<CreateJobForm>();

  const saveSubjectAreaKeys = useSaveField('subjectAreaKeys', {
    schema: UpdateJobDraftSchema.subjectAreaKeys,
    defaultValue: formContext.getValues('subjectAreaKeys'),
  });

  const menuState = useMenu({
    clickOutsideRef,
    onClickOutside: saveSubjectAreaKeys,
  });

  const subjectAreaKeys = useWatch({
    control: formContext.control,
    name: 'subjectAreaKeys',
  });

  const subjectAreasMap = new Map(
    subjectAreas.map((subjectArea) => [
      subjectArea.subjectAreaKey,
      subjectArea.name,
    ]),
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.search(e.target.value);

    setSearchString(e.target.value);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<string>[] = subjectAreaKeys.map((subjectAreaKey) => ({
    id: subjectAreaKey,
    text: subjectAreasMap.get(subjectAreaKey)!,
    maxWidth: 'unset',
  }));

  const removeChip = (id: string) => {
    formContext.setValue(
      'subjectAreaKeys',
      subjectAreaKeys.filter((subjectAreaKey) => subjectAreaKey !== id),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    saveSubjectAreaKeys();
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();
    saveSubjectAreaKeys();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const menuIsOpen = menuState.isOpen && !!searchContext.result.length;

  const items = React.useMemo(() => {
    const itemsWithScore = subjectAreaCategories.flatMap(
      (subjectAreaCategory) => {
        const items = searchContext.result.filter(
          (value) =>
            value.item.subjectAreaCategoryKey ===
            subjectAreaCategory.subjectAreaCategoryKey,
        );

        const score = Math.max(...items.map((item) => item.score ?? 0));

        if (items.length === 0) {
          return {
            key: subjectAreaCategory.subjectAreaCategoryKey,
            name: subjectAreaCategory.name,
            items: [],
            score,
          };
        }

        return {
          score,
          name: subjectAreaCategory.name,
          key: subjectAreaCategory.subjectAreaCategoryKey,
          items: [
            {
              key: subjectAreaCategory.subjectAreaCategoryKey,
              name: subjectAreaCategory.name,
              isCategory: true,
            },
            ...items.map((item) => item),
          ],
        };
      },
    );

    return itemsWithScore
      .sort((a, b) => b.score - a.score)
      .flatMap((item) => item.items);
  }, [searchContext.result, subjectAreaCategories]);

  const rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    const item = items[index];

    if ('isCategory' in item) {
      return (
        <SubjectAreaCategoryMenuItem
          key={key}
          style={style}
          subjectAreaCategoryKey={item.key}
        />
      );
    }

    return <SubjectAreaMenuItem key={key} style={style} {...item.item} />;
  };

  const listHeight =
    items.length * SUBJECT_AREAS_ITEM_HEIGHT > MENU_MAX_HEIGHT
      ? MENU_MAX_HEIGHT
      : items.length * SUBJECT_AREAS_ITEM_HEIGHT;

  return (
    <Box relative ref={clickOutsideRef}>
      <InputChips
        hideErrorMessage={menuState.isOpen}
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={1000}
        value={searchString}
        chips={chips}
        ref={inputRef}
        showInput={menuState.isOpen || !!searchString}
        errorTx={
          isTxString(formContext.formState.errors.subjectAreaKeys?.message)
            ? formContext.formState.errors.subjectAreaKeys?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            <Tooltip
              placement="left"
              titleTx={
                menuState.isOpen
                  ? 'label.tooltip.collapse'
                  : 'label.tooltip.expand'
              }
            >
              <IconButton
                onClick={handleToggleMenu}
                icon={menuIsOpen ? 'chevron-up' : 'chevron-down'}
              />
            </Tooltip>
          </TrailingInputBox>
        }
        labelTx="label.job-form.select-subject-areas"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            height="fit-content"
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
          >
            <List
              containerStyle={{ width: '100%', maxWidth: '100%' }}
              style={{ width: '100%' }}
              width={1}
              height={listHeight}
              rowCount={items.length}
              rowHeight={SUBJECT_AREAS_ITEM_HEIGHT}
              rowRenderer={rowRenderer}
            />
          </Menu>
        }
      />
    </Box>
  );
}

function StudyLevelMenuItem(props: { studyLevel: StudyLevel }) {
  const { studyLevel } = props;

  const formContext = useFormContext<CreateJobForm>();

  const inputRef = React.useContext(InputRefContext);

  const selectedStudyLevels = useWatch({
    control: formContext.control,
    name: 'studyLevels',
  });

  const isChecked = selectedStudyLevels.includes(studyLevel);

  const toggleStudyLevel = () => {
    formContext.setValue(
      'studyLevels',
      isChecked
        ? selectedStudyLevels.filter(
            (_studyLevel) => _studyLevel !== studyLevel,
          )
        : selectedStudyLevels.concat(studyLevel),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    inputRef.current?.focus();
  };

  return (
    <MenuItem onClick={toggleStudyLevel} gap={8} flex flexAlign="center">
      <Checkbox onChange={toggleStudyLevel} checked={isChecked} />

      <Text as="span" tx={getStudyLevelTx(studyLevel)} variant="bodyMd" />
    </MenuItem>
  );
}

function renderStudyLevel(studyLevel: StudyLevel) {
  return <StudyLevelMenuItem key={studyLevel} studyLevel={studyLevel} />;
}

function StudyLevels() {
  const formContext = useFormContext<CreateJobForm>();

  const saveStudyLevels = useSaveField('studyLevels', {
    schema: UpdateJobDraftSchema.studyLevels,
    defaultValue: formContext.getValues('studyLevels'),
  });

  const [searchString, setSearchString] = React.useState('');

  const searchContext = React.useContext(StudyLevelSearchContext.SearchContext);

  const inputRef = React.useContext(InputRefContext);
  const clickOutsideRef = React.useRef<HTMLInputElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu({
    clickOutsideRef,
    onClickOutside: saveStudyLevels,
  });

  const selectedStudyLevels = useWatch({
    control: formContext.control,
    name: 'studyLevels',
  });

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchString(e.target.value);

    searchContext.search(e.target.value);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<string>[] = selectedStudyLevels.map((studyLevel) => ({
    id: studyLevel,
    tx: getStudyLevelTx(studyLevel),
    maxWidth: 'unset',
  }));

  const removeChip = (id: string) => {
    const newState = selectedStudyLevels.filter(
      (studyLevel) => studyLevel !== id,
    );

    formContext.setValue('studyLevels', newState, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();
    saveStudyLevels();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const menuIsOpen = menuState.isOpen && !!searchContext.result.length;

  const items = searchContext.result.map((item) => item.item);

  return (
    <Box ref={clickOutsideRef}>
      <InputChips
        hideErrorMessage={menuState.isOpen || !!searchString}
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={1000}
        value={searchString}
        showInput={menuState.isOpen || !!searchString}
        ref={inputRef}
        chips={chips}
        errorTx={
          isTxString(formContext.formState.errors.studyLevels?.message)
            ? formContext.formState.errors.studyLevels?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            <Tooltip
              placement="left"
              titleTx={
                menuState.isOpen
                  ? 'label.tooltip.collapse'
                  : 'label.tooltip.expand'
              }
            >
              <IconButton
                onClick={handleToggleMenu}
                icon={menuIsOpen ? 'chevron-up' : 'chevron-down'}
              />
            </Tooltip>
          </TrailingInputBox>
        }
        labelTx="label.job-form.select-study-levels"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            maxHeight={MENU_MAX_HEIGHT}
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
          >
            {items.map(renderStudyLevel)}
          </Menu>
        }
      />
    </Box>
  );
}

function _TargetGroup() {
  const subjectAreas = useSelector(SubjectAreasSelector.selectAll);

  const formContext = useFormContext<CreateJobForm>();

  const modalState = useModalState();

  const dispatch = useDispatch();

  const saveSubjectAreaKeys = useSaveField('subjectAreaKeys', {
    schema: UpdateJobDraftSchema.subjectAreaKeys,
    defaultValue: formContext.getValues('subjectAreaKeys'),
  });

  const handleUpdateSubjectAreas = (subjectAreaKeys: string[]) => {
    formContext.setValue('subjectAreaKeys', subjectAreaKeys);

    dispatch(setAlert('copy-subject-areas:success'));

    saveSubjectAreaKeys();
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.3.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />

        <Box>
          <Styled.DescriptionText
            tx="label.job-form.steps.3.subtitle"
            color="formSectionDescription"
            variant="bodyMd"
            pr={4}
          />

          <Styled.DescriptionLink
            onClick={modalState.openModal}
            tx="label.job-form.steps.3.link"
            variant="secondary"
          />

          <PickProfileModal
            isOpen={modalState.isOpen}
            closeModal={modalState.closeModal}
            onSave={handleUpdateSubjectAreas}
          />
        </Box>
      </FormSectionHeader>

      <SubjectAreaSearchContext.Provider
        keys={['name']}
        defaultValue={subjectAreas}
      >
        <InputRefProvider>
          <SubjectAreas />
        </InputRefProvider>
      </SubjectAreaSearchContext.Provider>

      <StudyLevelSearchContext.Provider defaultValue={[...studyLevels]}>
        <InputRefProvider>
          <StudyLevels />
        </InputRefProvider>
      </StudyLevelSearchContext.Provider>
    </FormSection>
  );
}

_TargetGroup.displayName = 'TargetGroup';
export const TargetGroup = React.memo(_TargetGroup);

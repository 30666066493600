export const PROFILE_ABOUT_MAX_LENGTH = 3000;

export const PROFILE_FUN_FACT_MAX_LENGTH = 100;
export const PROFILE_FUN_FACTS_MAX_COUNT = 5;

export const PROFILE_NAME_MAX_LENGTH = 50;

export const PROFILE_ONELINER_MAX_LENGTH = 110;

export const PROFILE_PERK_DESCRIPTION_MAX_LENGTH = 200;

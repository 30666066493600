import { PayloadAction } from '@reduxjs/toolkit';

import {
  CompanyProfile,
  ProfilesOrderByKey,
  isCompanyProfileAndNotPartialCompanyProfile,
  profilesSortableKeys,
} from '../../models';
import { INITIAL_STATUS_EXTENSION_STATE } from '../store.constants';
import { companyProfilesAdapter } from './company-profiles.adapter';
import { initialCompanyProfilesState } from './company-profiles.initial';
import {
  CompanyProfilesEntityState,
  CompanyProfilesState,
} from './company-profiles.types';

const reset = (state: CompanyProfilesState) => {
  Object.assign(state, initialCompanyProfilesState);
};

const removeOfficeLocation = (
  state: CompanyProfilesEntityState,
  action: PayloadAction<string>,
) => {
  const selectors = companyProfilesAdapter.getSelectors();

  const profiles = selectors.selectAll(state);

  const updates: {
    id: CompanyProfile['companyProfileKey'];
    changes: Pick<CompanyProfile, 'locationData'>;
  }[] = [];

  profiles.forEach((profile) => {
    if (!isCompanyProfileAndNotPartialCompanyProfile(profile)) {
      return;
    }

    if (!profile.locationData?.officeLocations?.length) {
      return;
    }

    const found = profile.locationData.officeLocations.find(
      (officeLocation) => officeLocation.officeLocationKey === action.payload,
    );

    if (!found) {
      return;
    }

    const officeLocations = profile.locationData.officeLocations
      .filter(
        (officeLocation) => officeLocation.officeLocationKey !== action.payload,
      )
      .map((officeLocation) => ({
        ...officeLocation,
        index:
          officeLocation.index > found.index
            ? officeLocation.index - 1
            : officeLocation.index,
      }));

    updates.push({
      id: profile.companyProfileKey,
      changes: {
        locationData: {
          officeLocations,
          workModels: profile.locationData.workModels,
        },
      },
    });
  });

  companyProfilesAdapter.updateMany(state, updates);
};

const isProfilesOrderByKey = (
  key: string | ProfilesOrderByKey,
): key is ProfilesOrderByKey =>
  Object.values(profilesSortableKeys).some(
    (profilesOrderByKey) => profilesOrderByKey === key,
  );

const resetGetCompanyProfileStatus = (state: CompanyProfilesEntityState) => {
  state.getCompanyProfileStatus = INITIAL_STATUS_EXTENSION_STATE;
};

const sortOnColumn = (
  state: CompanyProfilesEntityState,
  action: PayloadAction<string>,
) => {
  if (!isProfilesOrderByKey(action.payload)) {
    return;
  }

  const clickedOnAscSortOrderInUse =
    state.profilesSortState.sortOrder === 'asc' &&
    state.profilesSortState.orderByKey === action.payload;

  state.profilesSortState.sortOrder = clickedOnAscSortOrderInUse
    ? 'desc'
    : 'asc';

  state.profilesSortState.orderByKey = action.payload;
};

export const reducers = {
  reset,

  removeOfficeLocation,
  resetGetCompanyProfileStatus,
  sortOnColumn,
};

import Joi from 'joi';

import {
  CreateJobSchema,
  PickProfileSchema,
  UpdateJobSchema,
} from './job.schema';

export const CreateJobFormValidation = (options: {
  officeLocationKeys: string[];
}) =>
  Joi.object({
    contactEmail: CreateJobSchema.contactEmail,
    contactName: CreateJobSchema.contactName,
    contactPhone: CreateJobSchema.contactPhone,
    coverImage: CreateJobSchema.coverImage,
    description: CreateJobSchema.description,
    employmentType: CreateJobSchema.employmentType,
    endDate: CreateJobSchema.endDate,
    jobDraftKey: CreateJobSchema.jobDraftKey,
    originalJobUrl: CreateJobSchema.originalJobUrl,
    qualifications: CreateJobSchema.qualifications,
    startDate: CreateJobSchema.startDate,
    studyLevels: CreateJobSchema.studyLevels,
    subjectAreaKeys: CreateJobSchema.subjectAreaKeys,
    title: CreateJobSchema.title,
    budgetData: CreateJobSchema.budgetData,
    locationData: CreateJobSchema.locationData(options),
  });

export const PickProfileValidation = Joi.object({
  companyProfileKey: PickProfileSchema.companyProfileKey,
});

export const UpdateJobFormValidation = (options: {
  officeLocationKeys: string[];
  dailyBudget: number;
  totalBudget: number;
}) =>
  Joi.object({
    contactEmail: CreateJobSchema.contactEmail,
    contactName: CreateJobSchema.contactName,
    contactPhone: CreateJobSchema.contactPhone,
    coverImage: CreateJobSchema.coverImage,
    description: CreateJobSchema.description,
    employmentType: CreateJobSchema.employmentType,
    endDate: CreateJobSchema.endDate,
    originalJobUrl: CreateJobSchema.originalJobUrl,
    qualifications: CreateJobSchema.qualifications,
    startDate: CreateJobSchema.startDate,
    studyLevels: CreateJobSchema.studyLevels,
    subjectAreaKeys: CreateJobSchema.subjectAreaKeys,
    title: CreateJobSchema.title,
    budgetData: UpdateJobSchema.budgetData({
      dailyBudget: options.dailyBudget,
      totalBudget: options.totalBudget,
    }),
    locationData: CreateJobSchema.locationData({
      officeLocationKeys: options.officeLocationKeys,
    }),
  });

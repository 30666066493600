import {
  Box,
  Status,
  TableCell,
  Text,
  isInfinity,
  isUndefined,
  numberFormatter,
} from '@orbiapp/components';
import React from 'react';

interface MetricBreakdownTableCellProps {
  value: number;
  comparedValue?: number;
  unit?: string;

  width?: number | string;
}

function PercentChange(props: { value: number; comparedValue?: number }) {
  const { value, comparedValue } = props;

  if (isUndefined(comparedValue) || comparedValue === value) {
    return null;
  }

  const percent = Math.round(((value - comparedValue) / comparedValue) * 100);

  if (isInfinity(percent)) {
    return null;
  }

  if (percent === 0) {
    return <Status text="0%" variant="success" />;
  }

  if (percent > 0) {
    return <Status text={`+${percent}%`} variant="success" />;
  }

  return <Status text={`${percent}%`} variant="error" />;
}

export function MetricBreakdownTableCell(props: MetricBreakdownTableCellProps) {
  const { value, comparedValue, unit, width } = props;

  return (
    <TableCell width={width}>
      <Box width="100%" gap={8} flexAlign="center" flex>
        <Text
          variant="bodySm"
          text={`${numberFormatter.format(value)}${unit ?? ''}`}
          as="span"
        />

        {!isUndefined(comparedValue) && (
          <React.Fragment>
            <Text
              variant="bodySm"
              text={`(${numberFormatter.format(comparedValue)}${unit ?? ''})`}
              as="span"
              color="metricsBreakdownComparedValue"
            />

            <PercentChange value={value} comparedValue={comparedValue} />
          </React.Fragment>
        )}
      </Box>
    </TableCell>
  );
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { addressesAdapter } from './search.adapter';

export namespace AddressesSelector {
  const selectSelf = (state: RootState) => state.search.addresses;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = addressesAdapter.getSelectors(
    (state: RootState) => state.search.addresses.data,
  );

  export const selectAll = selectors.selectAll;
}

export namespace CoordinatesSelector {
  const selectSelf = (state: RootState) => state.search.coordinates;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

import {
  ControlledSelect,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';

import { employmentTypes } from '../../../../../models';
import { JobSelector, useSelector } from '../../../../../store';
import { getEmploymentTypeTx } from '../../../../../utils';

export function TypeOfPositions() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.5.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.5.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <ControlledSelect
        options={employmentTypes.map((employmentType) => ({
          tx: getEmploymentTypeTx(employmentType),
          value: employmentType,
        }))}
        name="employmentType"
        labelTx="label.job-form.employment-type"
        disabled={!canEditJob}
      />
    </FormSection>
  );
}

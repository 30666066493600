import { BoxProps } from '@orbiapp/components';

import { Styled } from './controls-container.styled';

export function ControlsContainer(props: BoxProps) {
  return (
    <Styled.Controls
      backgroundColor="insightsFilterHeaderBackground"
      flex
      p={8}
      zIndex={50}
      gap={8}
      top={0}
      {...props}
    />
  );
}

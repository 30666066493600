import {
  Alert,
  Box,
  Button,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  LAYOUT_Z_INDEX,
  Link,
  PaperContentContainer,
  Snackbar,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { NotOnboardedToolbar } from '../../../components';
import { LEARN_MORE_ABOUT_TEAM_ROLES_URL } from '../../../constants';
import { useInviteTeamMembers } from '../../../helpers';
import { CompanyInvitationSelector, useSelector } from '../../../store';

function InviteTeamMembersContent() {
  const navigate = useNavigate();

  const navigateToOnboarded = () => {
    navigate('/onboarded', { replace: true });
  };

  const {
    invites,
    formMethods,
    addInvite,
    everyEmailIsEmptyString,
    isLoading,
    sendInvites,
  } = useInviteTeamMembers({
    onSuccess: navigateToOnboarded,
  });

  return (
    <Box flex flexDirection="column" gap={32}>
      <Box flex flexJustify="between" flexAlign="center">
        <Text
          color="onboardingHeaderTextColor"
          tx="title.invite-team-members.dashboard"
          variant="titleMd"
          as="h1"
          textAlign="center"
        />

        <Button variant="tertiary" tx="button.skip" to="/onboarded" />
      </Box>

      <Box flex flexDirection="column" gap={32}>
        <FormProvider {...formMethods}>{invites}</FormProvider>

        <Box flex flexJustify="center">
          <Link
            tx="link.general.learn-more-about-team-roles"
            href={LEARN_MORE_ABOUT_TEAM_ROLES_URL}
            variant="secondary"
            target="_blank"
          />
        </Box>
      </Box>

      {formMethods.formState.isSubmitted && everyEmailIsEmptyString && (
        <Snackbar zIndex={LAYOUT_Z_INDEX.pageToolbar + 1} placement="top-end">
          <Alert variant="error" subtitleTx="errors.invitations.empty" />
        </Snackbar>
      )}

      <Box flex flexJustify="between" gap={16}>
        <Button
          onClick={addInvite}
          variant="secondary"
          tx="button.add-invite"
          disabled={isLoading}
        />
        <Button
          isLoading={isLoading}
          onClick={sendInvites}
          variant="primary"
          tx="button.invite"
        />
      </Box>
    </Box>
  );
}

export function InviteTeamMembers() {
  const companyInvitation = useSelector(CompanyInvitationSelector.selectData);

  if (!companyInvitation) {
    return <Navigate to="/" />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <PaperContentContainer>
        <InnerPaperContentContainer width={680} my="auto">
          <InnerPaperContentContainerSectionsContainer>
            <InnerPaperContentContainerSection p={32}>
              <InviteTeamMembersContent />
            </InnerPaperContentContainerSection>
          </InnerPaperContentContainerSectionsContainer>
        </InnerPaperContentContainer>
      </PaperContentContainer>
    </React.Fragment>
  );
}

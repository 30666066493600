import { Base64File, EditMeta, ImageData } from '@orbiapp/components';

import {
  CreateIndexedOfficeLocation,
  EmploymentType,
  IndexedOfficeLocation,
  SubjectArea,
  WorkModel,
} from '../shared';
import { CreateIndexedPerk, IndexedPerk } from '../shared/perks';

export const isCompanyProfileAndNotPartialCompanyProfile = (
  profile?: CompanyProfile | PartialCompanyProfile,
): profile is CompanyProfile => !!profile && 'employmentTypes' in profile;

export const isCompanyProfileDraftAndNotPartialCompanyProfileDraft = (
  profile?: CompanyProfileDraft | PartialCompanyProfileDraft,
): profile is CompanyProfileDraft => !!profile && 'employmentTypes' in profile;

export const isPartialCompanyProfileAndNotPartialCompanyProfileDraft = (
  profile: PartialCompanyProfile | PartialCompanyProfileDraft,
): profile is PartialCompanyProfile => 'companyProfileKey' in profile;

export const isCompanyProfileOrCompanyProfileDraft = (
  profile?:
    | CompanyProfile
    | CompanyProfileDraft
    | PartialCompanyProfile
    | PartialCompanyProfileDraft,
): profile is CompanyProfile | CompanyProfileDraft =>
  !!profile && 'employmentTypes' in profile;

export interface CompanyProfile {
  companyProfileKey: string;
  editMeta: EditMeta;

  index: number;

  about: string;
  contactEmail: string;
  contactPhone: string | null;
  coverImage: ImageData;
  coverImageBase64: Base64File;

  employmentTypes: EmploymentType[];
  funFacts: string[] | null;
  name: string;
  oneliner: string;

  perks: IndexedPerk[];
  subjectAreas: SubjectArea[];
  videoUrl: string | null;
  websiteUrl: string;

  locationData: {
    workModels: WorkModel[];
    officeLocations: IndexedOfficeLocation[] | null;
  };
}

export interface PartialCompanyProfile
  extends Pick<
    CompanyProfile,
    | 'companyProfileKey'
    | 'editMeta'
    | 'index'
    | 'about'
    | 'name'
    | 'subjectAreas'
  > {}

export interface CreateCompanyProfile {
  about: CompanyProfile['about'];
  contactEmail: CompanyProfile['contactEmail'];
  contactPhone: CompanyProfile['contactPhone'];
  coverImageBase64: CompanyProfile['coverImageBase64'];

  employmentTypes: CompanyProfile['employmentTypes'];
  funFacts: CompanyProfile['funFacts'];
  name: CompanyProfile['name'];
  oneliner: CompanyProfile['oneliner'];

  perks: CreateIndexedPerk[];
  subjectAreaKeys: string[];
  videoUrl: CompanyProfile['videoUrl'];
  websiteUrl: CompanyProfile['websiteUrl'];

  locationData: {
    workModels: CompanyProfile['locationData']['workModels'];
    officeLocations: CreateIndexedOfficeLocation[] | null;
  };

  companyProfileDraftKey: string | null;
}

export interface UpdateCompanyProfile
  extends Partial<Omit<CreateCompanyProfile, 'companyProfileDraftKey'>> {}

export interface UpdateCompanyProfileParams
  extends Pick<CompanyProfile, 'companyProfileKey'> {
  updateCompanyProfile: UpdateCompanyProfile;
}

export interface CompanyProfileDraft {
  companyProfileDraftKey: string;
  editMeta: EditMeta;

  about: CompanyProfile['about'] | null;
  contactEmail: CompanyProfile['contactEmail'] | null;
  contactPhone: CompanyProfile['contactPhone'] | null;
  coverImage: CompanyProfile['coverImage'] | null;
  coverImageBase64: CompanyProfile['coverImageBase64'] | null;

  employmentTypes: CompanyProfile['employmentTypes'] | null;
  funFacts: CompanyProfile['funFacts'] | null;
  name: CompanyProfile['name'] | null;
  oneliner: CompanyProfile['oneliner'] | null;

  perks: CompanyProfile['perks'] | null;
  subjectAreas: CompanyProfile['subjectAreas'] | null;
  videoUrl: CompanyProfile['videoUrl'] | null;
  websiteUrl: CompanyProfile['websiteUrl'] | null;

  locationData: CompanyProfile['locationData'] | null;
}

export interface PartialCompanyProfileDraft
  extends Pick<
    CompanyProfileDraft,
    'companyProfileDraftKey' | 'editMeta' | 'name' | 'subjectAreas'
  > {}

export interface UpdateCompanyProfileDraft {
  about?: CreateCompanyProfile['about'] | null;
  contactEmail?: CreateCompanyProfile['contactEmail'] | null;
  contactPhone?: CreateCompanyProfile['contactPhone'] | null;
  coverImageBase64?: CreateCompanyProfile['coverImageBase64'] | null;

  employmentTypes?: CreateCompanyProfile['employmentTypes'] | null;
  funFacts?: CreateCompanyProfile['funFacts'] | null;
  name?: CreateCompanyProfile['name'] | null;
  oneliner?: CreateCompanyProfile['oneliner'] | null;

  perks?: CreateCompanyProfile['perks'] | null;
  subjectAreaKeys?: CreateCompanyProfile['subjectAreaKeys'] | null;
  videoUrl?: CreateCompanyProfile['videoUrl'] | null;
  websiteUrl?: CreateCompanyProfile['websiteUrl'] | null;

  locationData?: CreateCompanyProfile['locationData'] | null;
}

export interface UpdateCompanyProfileDraftParams
  extends Pick<CompanyProfileDraft, 'companyProfileDraftKey'> {
  updateCompanyProfileDraft: UpdateCompanyProfileDraft;
}

export interface UpdateCompanyProfileIndexes {
  companyProfiles: {
    companyProfileKey: string;
    index: number;
  }[];
}

export interface CompanyProfileForm
  extends Required<UpdateCompanyProfileDraft> {
  draft: boolean;
}

export type ProfilesOrderByKey =
  | 'profiles'
  | 'priority'
  | 'status'
  | 'updatedBy'
  | 'updatedAt';

export const profilesSortableKeys: Record<
  ProfilesOrderByKey,
  ProfilesOrderByKey
> = {
  priority: 'priority',
  profiles: 'profiles',
  status: 'status',
  updatedBy: 'updatedBy',
  updatedAt: 'updatedAt',
};

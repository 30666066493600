import { Base64File, EditMeta, ImageData, Location } from '@orbiapp/components';

import { OfferCategory } from '../offer-category';

type OfferOneCodeType = {
  type: 'one';
  code: string;
};

type OfferManyCodesType = {
  type: 'many';

  files: {
    fileType: 'text/csv';
    fileName: string;
    codes: string[];
    consumedCodeCount: number;
  }[];
};

type OfferCodeType = OfferOneCodeType | OfferManyCodesType;

type OfferOnlineType = {
  type: 'online';

  link: string;
  code: OfferCodeType | null;
};

type OfferLocalType = {
  type: 'consumable';

  consumeWaitMinutes: number | null;
};

export type Offer = {
  offerKey: string;

  editMeta: EditMeta;

  offerCategory: OfferCategory;

  disabledAt: number | null;

  title: string;
  description: string;
  coverImage: ImageData;

  startDate: number;
  endDate: number | null;
  contactName: string;
  contactEmail: string;

  countryKeys: string[];

  locations: Location[] | null;
  requireStudentVerification: boolean;
} & (OfferOnlineType | OfferLocalType);

export interface PartialOffer
  extends Pick<
    Offer,
    | 'offerKey'
    | 'editMeta'
    | 'title'
    | 'contactName'
    | 'disabledAt'
    | 'startDate'
    | 'endDate'
  > {}

export interface CreatedOffer extends Pick<Offer, 'offerKey' | 'coverImage'> {}

export interface UpdatedOffer extends Pick<Offer, 'coverImage'> {}

export type OffersOrderByKey = keyof (Pick<
  Offer,
  'title' | 'contactName' | 'startDate' | 'endDate'
> &
  Pick<EditMeta, 'createdAt' | 'updatedAt' | 'updatedBy'>);

export const offersSortableKeys: Record<OffersOrderByKey, OffersOrderByKey> = {
  title: 'title',
  contactName: 'contactName',
  startDate: 'startDate',
  endDate: 'endDate',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  updatedBy: 'updatedBy',
};

export interface CreateOfferForm {
  offerCategoryKey: string;
  title: string;
  description: string;
  coverImage: Base64File | null;

  startDate: number;
  endDate: number | null;
  contactName: string;
  contactEmail: string;

  isConsumable: boolean;
  consumeWaitMinutes: number | null;

  link: string | null;

  code: string | null;
  files:
    | {
        fileType: 'text/csv';
        fileName: string;
        codes: string[];
      }[]
    | null;

  offerDraftKey: string;

  countryKeys: string[];

  locations: Location[];
  requireStudentVerification: boolean;
}

export interface UpdateOfferForm {
  offerCategoryKey: string;
  title: string;
  description: string;
  coverImage: Base64File;

  startDate: number;
  endDate: number | null;
  contactName: string;
  contactEmail: string;

  isConsumable: boolean;
  consumeWaitMinutes: number | null;

  link: string | null;

  code: string | null;
  files:
    | {
        fileType: 'text/csv';
        fileName: string;
        codes: string[];
      }[]
    | null;

  countryKeys: string[];

  locations: Location[];
  requireStudentVerification: boolean;
}

export type OfferForm = CreateOfferForm | UpdateOfferForm;

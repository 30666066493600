import { PayloadAction } from '@reduxjs/toolkit';

import { GlobalUiStateAlert } from '../../models';
import { initialGlobalUiState } from './global-ui-state.initial';
import { GlobalUiState } from './global-ui-state.types';

function reset(state: GlobalUiState) {
  Object.assign(state, initialGlobalUiState);
}

const setAlert = (
  state: GlobalUiState,
  action: PayloadAction<GlobalUiStateAlert>,
) => {
  state.alert = action.payload;
};

const clearAlert = (state: GlobalUiState) => {
  state.alert = null;
};

export const reducers = {
  reset,

  setAlert,
  clearAlert,
};

import { joiResolver } from '@hookform/resolvers/joi';
import {
  Avatar,
  Box,
  Chip,
  ContentSidebarContentContainer,
  ControlledTextarea,
  Icon,
  IconButton,
  SolidIconButton,
  Spinner,
  Text,
  Tooltip,
  TrailingTextareaBox,
  WyiswygEditContainer,
  WysiwygPreviewContainer,
  WysiwygProvider,
  flattenFieldErrorsObject,
  formatDate,
  getAvatarVariantFromString,
  translate,
  useWysiwyg,
} from '@orbiapp/components';
import { FormProvider, useForm } from 'react-hook-form';

import {
  SubjectArea,
  TalentPoolUser,
  USER_NOTE_MAX_LENGTH,
  UpdateTalentPoolUserNotesForm,
  UpdateUserNotesValidation,
} from '../../../../../../models';
import { Logger } from '../../../../../../services';
import {
  TalentPoolUserSelector,
  UpdateUserNotesSelector,
  getCompanyQrThunk,
  updateUserNotesThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import { getStudyLevelTx } from '../../../../../../utils';
import { StarUserIconButton } from '../../star-user-icon-button';
import { Styled } from './view-user-sidebar.styled';

function renderUserSubjectArea(subjectArea: SubjectArea) {
  return (
    <Chip
      key={subjectArea.subjectAreaKey}
      text={subjectArea.name}
      variant="primary"
    />
  );
}

function EditNotesForm() {
  const notes = useSelector(TalentPoolUserSelector.selectNotes);
  const updateUserNotesStatus = useSelector(
    UpdateUserNotesSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const wysiwyg = useWysiwyg();

  const formMethods = useForm<UpdateTalentPoolUserNotesForm>({
    resolver: joiResolver(UpdateUserNotesValidation),
    defaultValues: {
      notes: notes ?? '',
    },
  });

  const saveNotes = formMethods.handleSubmit(
    async (data) => {
      await dispatch(updateUserNotesThunk(data));
      wysiwyg.setModeToPreview();
    },
    (err) => {
      Logger.warning('connectEditUserNotes Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const resetNotes = () => formMethods.reset();

  const isLoading = updateUserNotesStatus === 'pending';

  return (
    <WyiswygEditContainer
      disableEnterPreviewMode={formMethods.formState.isDirty}
    >
      <FormProvider {...formMethods}>
        <ControlledTextarea
          name="notes"
          labelTx="label.connect.notes"
          maxLength={USER_NOTE_MAX_LENGTH}
          autoFocus
          minHeight={85}
          trailingElement={
            <TrailingTextareaBox>
              <Tooltip placement="left" titleTx="label.connect.reset-notes">
                <IconButton
                  disabled={isLoading}
                  icon="undo"
                  onClick={resetNotes}
                  aria-label={translate('label.connect.reset-notes')}
                />
              </Tooltip>
              <Tooltip placement="left" titleTx="label.connect.save-notes">
                <IconButton
                  icon="check"
                  isLoading={isLoading}
                  onClick={saveNotes}
                  aria-label={translate('label.connect.save-notes')}
                />
              </Tooltip>
            </TrailingTextareaBox>
          }
        />
      </FormProvider>
    </WyiswygEditContainer>
  );
}

function UserNotes() {
  const notes = useSelector(TalentPoolUserSelector.selectNotes);

  const wysiwyg = useWysiwyg();

  if (wysiwyg.mode === 'edit') {
    return <EditNotesForm />;
  }

  return (
    <WysiwygPreviewContainer
      px={0}
      py={0}
      labelTx="label.connect.notes"
      value={notes ?? undefined}
      emptyTextTx="label.connect.no-notes-provided"
      unsavedChangesTx="label.general.unsaved-changes"
      actions={
        <Tooltip placement="left" titleTx="label.connect.edit-notes">
          <SolidIconButton
            aria-label={translate('label.connect.edit-notes')}
            icon="pencil-square-outline"
            onClick={wysiwyg.setModeToEdit}
          />
        </Tooltip>
      }
    />
  );
}

function ViewUserSidebarContent(props: TalentPoolUser) {
  const {
    companyQr,
    createdAt,
    email,
    firstName,
    isStarred,
    lastName,
    profilePicture,
    segmentation,
    semester,
    source,
    studyLevel,
    subjectAreas,
    userKey,
    schoolName,
  } = props;

  const dispatch = useDispatch();

  const openQrCodeSidebar = () => {
    if (!companyQr?.companyQrKey) return;

    dispatch(getCompanyQrThunk(companyQr.companyQrKey));
  };

  return (
    <ContentSidebarContentContainer>
      <Box flex flexDirection="column" gap={8}>
        <Box gap={16} flex flexJustify="between">
          <Box
            whiteSpace="nowrap"
            overflow="hidden"
            gap={16}
            flex
            flexAlign="center"
            flexGrow={1}
          >
            <Avatar
              minWidth={24}
              minHeight={24}
              width={24}
              height={24}
              fallbackLetter={firstName[0]}
              variant={getAvatarVariantFromString(userKey)}
              src={profilePicture?.thumbnail64.url ?? undefined}
            />
            <Text
              text={`${firstName} ${lastName}`}
              variant="bodyLgBold"
              textOverflow="ellipsis"
              overflow="hidden"
            />
          </Box>

          <StarUserIconButton userKey={userKey} isStarred={isStarred} />
        </Box>

        <Box flex flexDirection="column" gap={8} role="list">
          <Box flex role="listitem" gap={16} flexAlign="center">
            <Icon color="connectViewUserIcon" name="calendar-days-outline" />
            <Box>
              <Text tx="label.connect.date-added" variant="bodySm" />
              <Text
                variant="bodySm"
                text={formatDate(createdAt, 'DD MMM YYYY, HH:mm')}
                color="connectViewUserDateAddedText"
              />
            </Box>
          </Box>

          <Box flex role="listitem" gap={16} flexAlign="center">
            <Icon color="connectViewUserIcon" name="building-library-outline" />
            <Text
              variant="bodySm"
              text={schoolName}
              color="connectViewUserDateAddedText"
            />
          </Box>

          <Box flex role="listitem" gap={16} flexAlign="center">
            <Icon color="connectViewUserIcon" name="envelope-outline" />
            <Styled.Link href={`mailto:${email}`}>
              <Text
                variant="bodySm"
                text={email}
                color="connectViewUserEmail"
              />
            </Styled.Link>
          </Box>

          {source === 'qr' ? (
            <Box flex role="listitem" gap={16} flexAlign="center">
              <Icon color="connectViewUserIcon" name="qr-code" />

              <Box overflow="hidden" gap={4} flex>
                <Text
                  color="connectViewUserConnectedViaText"
                  tx="label.connect.connected-via"
                  variant="bodySm"
                  whiteSpace="nowrap"
                />

                <Styled.LinkButton onClick={openQrCodeSidebar}>
                  <Text
                    variant="bodySm"
                    text={companyQr?.name}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  />
                </Styled.LinkButton>
              </Box>
            </Box>
          ) : (
            <Box flex role="listitem" gap={16} flexAlign="center">
              <Icon color="connectViewUserIcon" name="user-circle-outline" />

              <Text
                tx="label.connect.connected-via-profile"
                variant="bodySm"
                color="connectViewUserConnectedViaText"
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.connect.study-level"
            variant="bodySm"
            color="connectViewUserListItemTitle"
          />

          <Chip
            tx={getStudyLevelTx(studyLevel)}
            backgroundColor="connectViewUserStudyLevelBackground"
            borderColor="connectViewUserStudyLevelBorder"
            color="connectViewUserStudyLevelText"
          />
        </Box>

        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.connect.subject-areas"
            variant="bodySm"
            color="connectViewUserListItemTitle"
          />

          {subjectAreas.length ? (
            <Box flex flexWrap gap={8}>
              {subjectAreas.map(renderUserSubjectArea)}
            </Box>
          ) : (
            <Text
              tx="label.connect.no-subject-areas"
              color="inputText"
              variant="bodyMdItalic"
              wordBreak="break-word"
              whiteSpace="pre-line"
            />
          )}
        </Box>

        <Box flex flexDirection="column" gap={4}>
          <Text
            text={segmentation === 'semester' ? 'Semester' : 'Year'}
            variant="bodySm"
            color="connectViewUserListItemTitle"
          />

          <Chip
            text={
              segmentation === 'semester' ? semester : Math.ceil(semester / 2)
            }
            variant="secondary"
          />
        </Box>

        <WysiwygProvider>
          <UserNotes />
        </WysiwygProvider>
      </Box>
    </ContentSidebarContentContainer>
  );
}

export function ViewUserSidebar() {
  const talentPoolUser = useSelector(
    TalentPoolUserSelector.selectTalentPoolUser,
  );
  const talentPoolUserStatus = useSelector(TalentPoolUserSelector.selectStatus);

  if (talentPoolUserStatus === 'pending') {
    return (
      <ContentSidebarContentContainer>
        <Box py={32} flex flexJustify="center">
          <Spinner />
        </Box>
      </ContentSidebarContentContainer>
    );
  }

  if (!talentPoolUser) {
    return null;
  }

  return <ViewUserSidebarContent {...talentPoolUser} />;
}

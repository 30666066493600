import appQrCode from './app-qr-code.svg';
import chartTrendingUp from './chart-trending-up.png';
import companyAdBoosterCarrousel from './company-ad-booster-carrousel.png';
import companyAdBoosterEvent from './company-ad-booster-event.png';
import companyAdBoosterTicket from './company-ad-booster-ticket.png';
import connectModal1 from './connect-modal-1.png';
import connectModal2 from './connect-modal-2.png';
import connectModal3 from './connect-modal-3.png';
import inAssociationWithOrbi from './in-association-with-orbi.png';
import insightsV2Modal1 from './insights-v2-modal-1.png';
import insightsV2Modal2 from './insights-v2-modal-2.png';
import insightsV2Modal3 from './insights-v2-modal-3.png';
import locationsMapPreview from './locations_map_preview.svg';
import noImageFound from './no-image-found.svg';
import orbiLogoOrange from './orbi-logo-orange.svg';
import redeemOffer from './redeem-offer.png';

export const assets = {
  appQrCode,
  chartTrendingUp,
  insightsV2Modal: {
    insightsV2Modal1,
    insightsV2Modal2,
    insightsV2Modal3,
  },
  connectModal: {
    connectModal1,
    connectModal2,
    connectModal3,
  },
  companyAdBoosters: {
    carrousel: companyAdBoosterCarrousel,
    event: companyAdBoosterEvent,
    ticket: companyAdBoosterTicket,
  },
  locationsMapPreview,
  noImageFound,
  inAssociationWithOrbi,
  orbiLogoOrange,
  redeemOffer,
};

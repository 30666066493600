import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './connect.build';
import { initialConnectState } from './connect.initial';
import { reducers } from './connect.reducers';

const slice = createSlice({
  name: 'connect',
  initialState: initialConnectState,
  reducers,
  extraReducers,
});

export const connectActions = slice.actions;
export const connectReducer = slice.reducer;

import { INITIAL_DATA_STATE } from '../store.constants';
import { addressesAdapter } from './search.adapter';
import { SearchState } from './search.types';

export const initialSearchState: SearchState = {
  addresses: {
    ...INITIAL_DATA_STATE,
    data: addressesAdapter.getInitialState(),
  },
  coordinates: INITIAL_DATA_STATE,
};

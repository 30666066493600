import {
  Avatar,
  Box,
  BoxProps,
  Chip,
  Icon,
  IconName,
  SolidIconButton,
  TabButton,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { assets } from '../../../../assets';
import {
  CompanyProfileForm,
  CreateIndexedOfficeLocation,
  CreateIndexedPerk,
  EmploymentType,
} from '../../../../models';
import {
  CompanySelector,
  OfficeLocationsSelector,
  PerksSelector,
  SubjectAreasSelector,
  useSelector,
} from '../../../../store';
import {
  getEmploymentTypeChipProps,
  getYoutubeVideoId,
  isYoutubeUrl,
} from '../../../../utils';
import { Styled } from './styled';

function PlaceholderRows() {
  return (
    <Box flex flexDirection="column" gap={8}>
      <Styled.PlaceholderRow />
      <Styled.PlaceholderRow />
      <Styled.PlaceholderRow width="60%" />
    </Box>
  );
}

function renderTag(tag: string, index: number) {
  return <Chip key={`tag-${index}`} text={tag} variant="tertiary" />;
}

function Tags() {
  const { showAllPlaceholders } = React.useContext(PreviewContext);

  const tags = useSelector(CompanySelector.selectCompanyTags);

  const ref = React.useRef<HTMLDivElement>(null);

  const [showSeeMore, setShowSeeMore] = React.useState(false);

  React.useEffect(() => {
    setShowSeeMore(!!ref.current && ref.current.clientHeight > 80);
  }, [tags]);

  if (!tags?.length && !showAllPlaceholders) {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text tx="label.profile.preview.tags" variant="bodyMdBold" />

      <Box relative>
        <Box flex gap={8} flexWrap maxHeight={69.6} overflow="hidden">
          {tags?.map(renderTag) ?? (
            <React.Fragment>
              <Styled.PlaceholderArea width={122} height={30.8} />
              <Styled.PlaceholderArea width={121} height={30.8} />
              <Styled.PlaceholderArea width={84} height={30.8} />
              <Styled.PlaceholderArea width={97} height={30.8} />
            </React.Fragment>
          )}
        </Box>

        <Styled.HiddenBox
          flex
          gap={8}
          flexWrap
          ref={ref}
          maxHeight={85}
          absolute
          top={0}
        >
          {tags?.map(renderTag)}
        </Styled.HiddenBox>

        {showSeeMore && (
          <Text
            tx="label.profile.preview.view-all"
            variant="bodySm"
            color="profilePreviewLinks"
            textAlign="center"
            mt={16}
          />
        )}
      </Box>
    </Box>
  );
}

function SubjectArea({ subjectAreaKey }: { subjectAreaKey: string }) {
  const subjectArea = useSelector((state) =>
    SubjectAreasSelector.selectById(state, subjectAreaKey),
  );

  if (!subjectArea) {
    return null;
  }

  return <Chip variant="primary" text={subjectArea.name} />;
}

function renderSubjectArea(subjectAreaKey: string) {
  return <SubjectArea key={subjectAreaKey} subjectAreaKey={subjectAreaKey} />;
}

function SubjectAreas() {
  const { watch } = useFormContext<CompanyProfileForm>();
  const subjectAreaKeys = watch('subjectAreaKeys');

  const ref = React.useRef<HTMLDivElement>(null);

  const [showSeeMore, setShowSeeMore] = React.useState(false);

  React.useEffect(() => {
    setShowSeeMore(!!ref.current && ref.current.clientHeight > 80);
  }, [subjectAreaKeys]);

  return (
    <Box flex flexDirection="column" gap={16}>
      <Box flex flexAlign="center" flexJustify="between">
        <Text variant="bodyMdBold" tx="label.profile.preview.academic-fields" />
      </Box>

      <Box relative>
        <Box flex gap={8} flexWrap maxHeight={69.6} overflow="hidden">
          {subjectAreaKeys?.map(renderSubjectArea) ?? (
            <React.Fragment>
              <Styled.PlaceholderArea width={122} height={30.8} />
              <Styled.PlaceholderArea width={121} height={30.8} />
              <Styled.PlaceholderArea width={84} height={30.8} />
              <Styled.PlaceholderArea width={97} height={30.8} />
            </React.Fragment>
          )}
        </Box>

        <Styled.HiddenBox
          flex
          gap={8}
          flexWrap
          ref={ref}
          maxHeight={85}
          absolute
          top={0}
        >
          {subjectAreaKeys?.map(renderSubjectArea)}
        </Styled.HiddenBox>

        {showSeeMore && (
          <Text
            tx="label.profile.preview.view-all"
            variant="bodySm"
            color="profilePreviewLinks"
            textAlign="center"
            mt={16}
          />
        )}
      </Box>
    </Box>
  );
}

function renderEmploymentType(employmentType: EmploymentType) {
  return (
    <Chip
      key={employmentType}
      {...getEmploymentTypeChipProps(employmentType)}
    />
  );
}

function EmploymentTypes() {
  const { watch } = useFormContext<CompanyProfileForm>();
  const employmentTypes = watch('employmentTypes');

  return (
    <Box flex flexDirection="column" gap={16}>
      <Box flex flexAlign="center" flexJustify="between">
        <Text
          tx="label.profile.preview.type-of-positions"
          variant="bodyMdBold"
        />
      </Box>
      <Box flex gap={8} flexWrap>
        {employmentTypes?.map(renderEmploymentType) ?? (
          <React.Fragment>
            <Styled.PlaceholderArea width={84} height={30.8} />
            <Styled.PlaceholderArea width={84} height={30.8} />
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}

function Perk({ perkKey }: { perkKey: string }) {
  const perk = useSelector((state) => PerksSelector.selectById(state, perkKey));

  if (!perk) {
    return null;
  }

  return (
    <Chip
      variant="tertiary"
      height={64}
      flex
      flexJustify="center"
      borderColor="profilePreviewPerksBorder"
    >
      <Box flex flexDirection="column" flexAlign="center">
        <Text text={perk.emoji} variant="bodyLg" height={26} />
        <Text text={perk.name} variant="bodyMd" />
      </Box>
    </Chip>
  );
}

function renderPerk({ perkKey }: CreateIndexedPerk) {
  return <Perk key={perkKey} perkKey={perkKey} />;
}

const renderPerkPlacementChip = (width: number, index: number) => {
  return (
    <Chip
      key={`perk-placement-chip-${index}`}
      variant="tertiary"
      borderColor="profilePreviewPerksBorder"
      width={width}
      height={64}
      flex
      flexJustify="center"
      flexDirection="column"
      flexAlign="center"
      gap={8}
    >
      <Styled.PlaceholderRow width={20} height={20} />
      <Styled.PlaceholderRow width="100%" height={20} />
    </Chip>
  );
};

function Perks() {
  const { watch } = useFormContext<CompanyProfileForm>();
  const perks = watch('perks');

  const perksWithValidPerkKey = perks
    ? perks.filter((createIndexedPerk) => createIndexedPerk.perkKey.length > 0)
    : [];

  return (
    <Box flex flexDirection="column" gap={16}>
      <Box flex flexAlign="center" flexJustify="between">
        <Text variant="bodyMdBold" tx="label.profile.preview.perks" />
        {perksWithValidPerkKey.length > 5 && (
          <Box flex flexAlign="end" gap={8}>
            <Text
              tx="label.profile.preview.view-all"
              variant="bodySm"
              color="profilePreviewViewAllText"
            />
            <Icon
              name="arrow-right-circle-outline"
              color="profilePreviewViewAllIcon"
            />
          </Box>
        )}
      </Box>

      <Box flex gap={8} flexWrap>
        {perksWithValidPerkKey.length > 0
          ? perksWithValidPerkKey.slice(0, 5).map(renderPerk)
          : [114, 94, 104, 80, 106].map(renderPerkPlacementChip)}
      </Box>
    </Box>
  );
}

function Location({
  officeLocationKey,
  highlighted,
}: {
  officeLocationKey: string;
  highlighted: boolean;
}) {
  const location = useSelector((state) =>
    OfficeLocationsSelector.selectOfficeLocationById(state, officeLocationKey),
  );

  if (!location) {
    return null;
  }

  return (
    <Styled.Locaton
      flex
      flexDirection="column"
      gap={4}
      highlighted={highlighted}
    >
      {highlighted && (
        <Text
          tx="label.profile.preview.closest-location"
          variant="bodySmItalic"
          color="profilePreviewLocationAddress"
        />
      )}
      <Box flex gap={16} flexAlign="center" overflow="hidden">
        <Icon name="map-pin-outline" color="profilePreviewLocationIcon" />
        <Box flex flexDirection="column" gap={4} overflow="hidden">
          <Text
            text={location.label}
            variant="bodySm"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            color="profilePreviewLocationLabel"
          />
          <Text
            text={location.address}
            variant="bodySm"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            color="profilePreviewLocationAddress"
          />
        </Box>
      </Box>
    </Styled.Locaton>
  );
}

function Locations() {
  const { watch } = useFormContext<CompanyProfileForm>();
  const locationData = watch('locationData');

  if (
    locationData?.workModels.length === 1 &&
    locationData.workModels[0] === 'remote'
  ) {
    return null;
  }

  const locationsWithValidOfficeLocationKey = locationData?.officeLocations
    ? locationData.officeLocations.filter(
        (createIndexedOfficeLocation) =>
          createIndexedOfficeLocation.officeLocationKey.length > 0,
      )
    : [];

  function renderLocation(
    { officeLocationKey }: CreateIndexedOfficeLocation,
    index: number,
  ) {
    return (
      <Location
        key={officeLocationKey}
        officeLocationKey={officeLocationKey}
        highlighted={
          index === 0 && locationsWithValidOfficeLocationKey.length > 1
        }
      />
    );
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Box flex flexAlign="center" flexJustify="between">
        <Text tx="label.profile.preview.locations" variant="bodyMdBold" />
        {locationsWithValidOfficeLocationKey.length > 3 && (
          <Box flex flexAlign="end" gap={8}>
            <Text
              tx="label.profile.preview.view-all"
              variant="bodySm"
              color="profilePreviewViewAllText"
            />
            <Icon
              name="arrow-right-circle-outline"
              color="profilePreviewViewAllIcon"
            />
          </Box>
        )}
      </Box>

      {locationsWithValidOfficeLocationKey.length > 0 ? (
        <React.Fragment>
          <Styled.LocationsMapPreview
            flex
            flexAlign="center"
            flexJustify="center"
            relative
          >
            <Text
              tx="label.profile.preview.map-showing-the-closest-location"
              absolute
              variant="bodySmItalic"
              zIndex={1}
            />
            <img alt="" src={assets.locationsMapPreview} />
          </Styled.LocationsMapPreview>
          {locationsWithValidOfficeLocationKey.slice(0, 3).map(renderLocation)}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Styled.PlaceholderArea height={204} width="100%" />

          <Box flex gap={16} flexAlign="center">
            <Icon name="map-pin-outline" color="profilePreviewLocationIcon" />
            <Box width="100%">
              <Styled.PlaceholderRow width="30%" />
            </Box>
          </Box>

          <Box flex gap={16} flexAlign="center">
            <Icon name="map-pin-outline" color="profilePreviewLocationIcon" />
            <Box width="100%">
              <Styled.PlaceholderRow width="60%" />
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

function renderSocialMediaIcon(icon: IconName, index: number) {
  return <Icon key={`social-media-${index}`} name={icon} />;
}

function Details() {
  const { showAllPlaceholders } = React.useContext(PreviewContext);

  const { watch } = useFormContext<CompanyProfileForm>();
  const contactPhone = watch('contactPhone');

  const companyIndustry = useSelector(CompanySelector.selectCompanyIndustry);

  const companyEmployeeCount = useSelector(
    CompanySelector.selectCompanyEmployeeCount,
  );

  const facebookUrl = useSelector(CompanySelector.selectFacebookUrl);
  const instagramUrl = useSelector(CompanySelector.selectInstagramUrl);
  const linkedinUrl = useSelector(CompanySelector.selectLinkedinUrl);
  const tiktokUrl = useSelector(CompanySelector.selectTiktokUrl);
  const twitterUrl = useSelector(CompanySelector.selectTwitterUrl);
  const youtubeUrl = useSelector(CompanySelector.selectYoutubeUrl);

  const icons = React.useMemo(() => {
    const socialMedias: IconName[] = [];

    if (typeof facebookUrl === 'string') {
      socialMedias.push('facebook-colored');
    } else if (showAllPlaceholders) {
      socialMedias.push('facebook');
    }

    if (typeof instagramUrl === 'string') {
      socialMedias.push('instagram-colored');
    } else if (showAllPlaceholders) {
      socialMedias.push('instagram');
    }

    if (typeof linkedinUrl === 'string') {
      socialMedias.push('linkedin-colored');
    } else if (showAllPlaceholders) {
      socialMedias.push('linkedin');
    }

    if (typeof tiktokUrl === 'string') {
      socialMedias.push('tiktok-colored');
    } else if (showAllPlaceholders) {
      socialMedias.push('tiktok');
    }

    if (typeof twitterUrl === 'string') {
      socialMedias.push('x-colored');
    } else if (showAllPlaceholders) {
      socialMedias.push('x');
    }

    if (typeof youtubeUrl === 'string') {
      socialMedias.push('youtube-colored');
    } else if (showAllPlaceholders) {
      socialMedias.push('youtube');
    }

    return socialMedias;
  }, [
    facebookUrl,
    instagramUrl,
    linkedinUrl,
    tiktokUrl,
    twitterUrl,
    youtubeUrl,
    showAllPlaceholders,
  ]);

  const contactEmail = watch('contactEmail');
  const websiteUrl = watch('websiteUrl');

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text tx="label.profile.preview.details" variant="bodyMdBold" />
      <Box flex gap={16} flexAlign="center" flexJustify="start">
        <Icon name="envelope-outline" color="profilePreviewDetailIcons" />
        {contactEmail ? (
          <Styled.WordBreakText
            text={contactEmail}
            variant="bodySm"
            color="profilePreviewLinks"
          />
        ) : (
          <Box width="100%">
            <Styled.PlaceholderRow width="70%" />
          </Box>
        )}
      </Box>
      <Box flex gap={16} flexAlign="center">
        <Icon name="globe-outline" color="profilePreviewDetailIcons" />
        {websiteUrl ? (
          <Styled.WordBreakText
            text={websiteUrl}
            variant="bodySm"
            color="profilePreviewLinks"
          />
        ) : (
          <Box width="100%">
            <Styled.PlaceholderRow width="85%" />
          </Box>
        )}
      </Box>
      {contactPhone ? (
        <Box flex gap={16} flexAlign="center">
          <Icon name="phone-outline" color="profilePreviewDetailIcons" />
          <Text
            text={contactPhone}
            variant="bodySm"
            color="profilePreviewLinks"
          />
        </Box>
      ) : (
        showAllPlaceholders && (
          <Box flex gap={16} flexAlign="center">
            <Icon name="phone-outline" color="profilePreviewDetailIcons" />
            <Box width="100%">
              <Styled.PlaceholderRow width="77.5%" />
            </Box>
          </Box>
        )
      )}
      <Box flex gap={16} flexAlign="center">
        <Icon
          name="building-storefront-outline"
          color="profilePreviewDetailIcons"
        />
        {!!companyIndustry && (
          <Text
            text={`${companyIndustry.charAt(0).toUpperCase()}${companyIndustry
              .slice(1)
              .replaceAll('_', ' ')}`}
            variant="bodySm"
          />
        )}
      </Box>
      <Box flex gap={16} flexAlign="center">
        <Icon name="users-outline" color="profilePreviewDetailIcons" />
        {!!companyEmployeeCount && (
          <Text
            tx="label.profile.preview.employees"
            txArgs={{ companyEmployeeCount }}
            variant="bodySm"
          />
        )}
      </Box>

      {icons.length > 0 && (
        <Box flex gap={(360 - 64 - 6 * 24) / 5} px={16} pt={16}>
          {icons.map(renderSocialMediaIcon)}
        </Box>
      )}
    </Box>
  );
}

function renderFunFact(funFact: string, index: number) {
  return (
    <li key={`fun-fact-${index}`}>
      <Text
        text={funFact.length ? funFact : '.'}
        variant="bodyMd"
        mt={index === 0 ? 0 : 16}
        color={
          funFact.length > 0 ? undefined : 'profilePreviewSidebarBackground'
        }
        wordBreak="break-word"
        whiteSpace="pre-line"
      />
    </li>
  );
}

function FunFacts() {
  const { showAllPlaceholders } = React.useContext(PreviewContext);

  const { watch } = useFormContext<CompanyProfileForm>();

  const funFacts = watch('funFacts');

  if (!funFacts?.length && !showAllPlaceholders) {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text tx="label.profile.preview.fun-facts" variant="bodyMdBold" />
      {funFacts?.length ? (
        <Box pl={16}>
          <ul>{funFacts.map(renderFunFact)}</ul>
        </Box>
      ) : (
        <React.Fragment>
          <PlaceholderRows />
          <PlaceholderRows />
          <PlaceholderRows />
        </React.Fragment>
      )}
    </Box>
  );
}

function About() {
  const { showAllPlaceholders } = React.useContext(PreviewContext);

  const { watch } = useFormContext<CompanyProfileForm>();
  const videoUrl = watch('videoUrl');
  const about = watch('about');

  const ref = React.useRef<HTMLParagraphElement>(null);

  const [showSeeMore, setShowSeeMore] = React.useState(false);

  React.useEffect(() => {
    setShowSeeMore(!!ref.current && ref.current.clientHeight > 80);
  }, [about]);

  return (
    <Box flex flexDirection="column">
      {videoUrl && isYoutubeUrl(videoUrl) && getYoutubeVideoId(videoUrl) ? (
        <Styled.VideoIframe
          src={`https://www.youtube.com/embed/${getYoutubeVideoId(videoUrl)}`}
          title="YouTube Video iframe"
          allowFullScreen
        />
      ) : (
        <React.Fragment>
          <Text
            tx="label.profile.preview.about"
            variant="bodyMdBold"
            pb={showAllPlaceholders ? 16 : 8}
          />
          {showAllPlaceholders && (
            <Styled.PlaceholderArea height={150} width="100%" mb={16} />
          )}
        </React.Fragment>
      )}

      {about ? (
        <Styled.About relative>
          <Text
            text={about}
            variant="bodyMd"
            overflow="hidden"
            textOverflow="ellipsis"
            maxHeight={80}
            wordBreak="break-word"
            whiteSpace="pre-line"
          />
          <Text
            text={about}
            variant="bodyMd"
            maxHeight={85}
            ref={ref}
            absolute
            top={0}
            wordBreak="break-word"
            whiteSpace="pre-line"
          />
          {showSeeMore && (
            <Text
              tx="label.profile.preview.see-more"
              variant="bodyMd"
              color="profilePreviewLinks"
            />
          )}
        </Styled.About>
      ) : (
        <PlaceholderRows />
      )}
    </Box>
  );
}

function Header() {
  const companyName = useSelector(CompanySelector.selectCompanyName);

  const { watch } = useFormContext<CompanyProfileForm>();
  const oneliner = watch('oneliner');

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        text={companyName ?? ''}
        variant="titleSm"
        minHeight={26}
        wordBreak="break-word"
        whiteSpace="pre-line"
      />
      {oneliner ? (
        <Text
          text={oneliner}
          variant="bodyMd"
          wordBreak="break-word"
          whiteSpace="pre-line"
        />
      ) : (
        <PlaceholderRows />
      )}
    </Box>
  );
}

function Logo() {
  const logoUrl = useSelector(CompanySelector.selectCompanyLogo)?.original.url;

  return (
    <Box relative height={40}>
      <Box
        absolute
        top={-40}
        flex
        flexAlign="end"
        width="100%"
        flexJustify="between"
        px={16}
      >
        <Avatar src={logoUrl} width={80} height={80} />
        <Styled.FollowButton>
          <TabButton isActive={false} tx="label.profile.preview.follow" />
        </Styled.FollowButton>
      </Box>
    </Box>
  );
}

function CoverImage() {
  const { watch } = useFormContext<CompanyProfileForm>();

  return (
    <Styled.CoverImage base64File={watch('coverImageBase64')}>
      <Box flex px={16} py={24} gap={24}>
        <SolidIconButton icon="chevron-left" mr="auto" />
        <SolidIconButton icon="share-outline" />
        <SolidIconButton icon="globe-outline" />
        <SolidIconButton icon="ellipsis-horizontal" />
      </Box>
    </Styled.CoverImage>
  );
}

export const PreviewContext = React.createContext({
  showAllPlaceholders: true,
});

export function Preview() {
  return (
    <Styled.Preview
      width={360}
      minHeight={2200}
      backgroundColor="formHeaderBackground"
    >
      <Box>
        <CoverImage />
        <Logo />
        <Box flex flexDirection="column" p={16} gap={32} pb={32}>
          <Header />
          <About />
          <FunFacts />
          <Details />
          <Locations />
          <Perks />
          <EmploymentTypes />
          <SubjectAreas />
          <Tags />
        </Box>
      </Box>
    </Styled.Preview>
  );
}

export function ToggleButton(props: { expanded: boolean } & BoxProps) {
  const { expanded, ...rest } = props;

  return (
    <Styled.ToggleButton
      flex
      flexDirection="column"
      flexAlign="center"
      px={7}
      py={11}
      gap={4}
      {...rest}
    >
      <Icon
        name={
          expanded ? 'arrow-right-circle-outline' : 'arrow-left-circle-outline'
        }
      />
      <Text tx="label.profile.preview.toggle-button" variant="buttonSm" />
    </Styled.ToggleButton>
  );
}

interface EmailBadlyFormattedError {
  code: 0;
  message: string;
  metadata: null;
}

interface EmailNotFoundError {
  code: 1;
  message: string;
  metadata: null;
}

export type RequestResetPasswordEmailErrorResponse =
  | string
  | EmailBadlyFormattedError
  | EmailNotFoundError
  | undefined;

export namespace AuthErrors {
  export const password = {
    'string.empty': 'errors.password.empty',
  };
}

import { Address, useDebounce } from '@orbiapp/components';
import React from 'react';

import { getAddressesThunk, useDispatch } from '../store';

export function useLocationSearch(sessionToken: string) {
  const [searchResult, setSearchResult] = React.useState<Address[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const timeoutRef = React.useRef<number | null>(null);

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const search = (searchString: string) => {
    debounce(async () => {
      setIsLoading(true);

      const res = await dispatch(
        getAddressesThunk({
          sessionToken,
          searchString,
        }),
      );

      if (Array.isArray(res.payload)) {
        setSearchResult(res.payload);
      }

      setIsLoading(false);
    });

    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
  };

  return { searchResult, isLoading, search };
}

import { createEntityAdapter } from '@reduxjs/toolkit';

import { PartialCompanyQr, PartialTalentPoolUser } from '../../models';

export const partialCompanyQrAdapter = createEntityAdapter<
  PartialCompanyQr,
  string
>({
  selectId: (partialCompanyQr) => partialCompanyQr.companyQrKey,
});

export const partialTalentPoolUserAdapter = createEntityAdapter<
  PartialTalentPoolUser,
  string
>({
  selectId: (partialTalentPoolUser) => partialTalentPoolUser.userKey,
});

import { createAsyncThunk } from '@reduxjs/toolkit';

import { SubjectAreaCategory } from '../../models';
import { OrbiApi, v2 } from '../../services';
import { ThunkApiConfig } from '../store.types';
import { SubjectAreaCategoriesSelector } from './subject-areas.selectors';

export const getSubjectAreaCategoriesThunk = createAsyncThunk<
  SubjectAreaCategory[],
  undefined,
  ThunkApiConfig
>('subjectAreas/getSubjectAreaCategoriesThunk', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const subjectAreas = SubjectAreaCategoriesSelector.selectAll(state);
  if (subjectAreas.length) {
    return subjectAreas;
  }

  const res = await OrbiApi.call(
    v2.subjectAreaCategories.getSubjectAreaCategories,
    undefined,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

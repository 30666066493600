import Joi from 'joi';

import { UrlSchema } from '../../url';
import { OfferErrors } from '../offer/offer.errors';
import { CreateOfferSchema } from '../offer/offer.schema';

export namespace UpdateOfferDraftSchema {
  export const offerCategoryKey = CreateOfferSchema.offerCategoryKey.allow('');
  export const title = CreateOfferSchema.title.allow('');
  export const description = CreateOfferSchema.description.allow('');
  export const coverImage = CreateOfferSchema.coverImage.allow(null);

  export const startDate = CreateOfferSchema.startDate.allow(null);
  export const endDate = CreateOfferSchema.endDate;
  export const contactName = CreateOfferSchema.contactName.allow('');
  export const contactEmail = CreateOfferSchema.contactEmail.allow('');

  export const isConsumable = CreateOfferSchema.isConsumable;
  export const consumeWaitMinutes = CreateOfferSchema.consumeWaitMinutes;

  export const link = UrlSchema.required()
    .allow(null, '')
    .messages(OfferErrors.link);

  export const code = Joi.string()
    .trim()
    .required()
    .allow(null, '')
    .messages(OfferErrors.code);

  export const files = Joi.array()
    .required()
    .allow(null)
    .messages(OfferErrors.files);

  export const offerDraftKey = CreateOfferSchema.offerDraftKey;

  export const locations = CreateOfferSchema.locations;
  export const requireStudentVerification =
    CreateOfferSchema.requireStudentVerification;

  export const countryKeys = Joi.array().items(Joi.string()).required();
}

import { createSelector } from '@reduxjs/toolkit';

import {
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
  isPartialCompanyProfileAndNotPartialCompanyProfileDraft,
} from '../../models';
import { CompanyProfileDraftsSelector } from '../company-profile-drafts';
import { RootState } from '../store.types';
import { companyProfilesAdapter } from './company-profiles.adapter';
import { ProfileComparer, ProfilesSortState } from './company-profiles.types';

const compareNameAsc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) => (a.name ?? '').localeCompare(b.name ?? '', 'sv');

const compareNameDesc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) => (b.name ?? '').localeCompare(a.name ?? '', 'sv');

const comparePriorityAsc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) =>
  (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(a) ? a.index : -1) -
  (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(b) ? b.index : -1);

const comparePriorityDesc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) =>
  (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(b) ? b.index : -1) -
  (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(a) ? a.index : -1);

const compareStatusAsc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) =>
  (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(a)
    ? 'l'
    : 'd'
  ).localeCompare(
    isPartialCompanyProfileAndNotPartialCompanyProfileDraft(b) ? 'l' : 'd',
    'sv',
  );

const compareStatusDesc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) =>
  (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(b)
    ? 'l'
    : 'd'
  ).localeCompare(
    isPartialCompanyProfileAndNotPartialCompanyProfileDraft(a) ? 'l' : 'd',
    'sv',
  );

const compareUpdatedAtAsc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) =>
  (a.editMeta.updatedAt ?? a.editMeta.createdAt) -
  (b.editMeta.updatedAt ?? b.editMeta.createdAt);

const compareUpdatedAtDesc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) =>
  (b.editMeta.updatedAt ?? b.editMeta.createdAt) -
  (a.editMeta.updatedAt ?? a.editMeta.createdAt);

const compareUpdatedByAsc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) => (a.name ?? '').localeCompare(b.name ?? '', 'sv');

const compareUpdatedByDesc: ProfileComparer = (
  a: PartialCompanyProfile | PartialCompanyProfileDraft,
  b: PartialCompanyProfile | PartialCompanyProfileDraft,
) => (b.name ?? '').localeCompare(a.name ?? '', 'sv');

const getProfileComparer = (
  profilesSortState: ProfilesSortState,
): ProfileComparer => {
  switch (profilesSortState.orderByKey) {
    case 'profiles': {
      return profilesSortState.sortOrder === 'asc'
        ? compareNameAsc
        : compareNameDesc;
    }

    case 'priority': {
      return profilesSortState.sortOrder === 'asc'
        ? comparePriorityAsc
        : comparePriorityDesc;
    }

    case 'status': {
      return profilesSortState.sortOrder === 'asc'
        ? compareStatusAsc
        : compareStatusDesc;
    }

    case 'updatedAt': {
      return profilesSortState.sortOrder === 'asc'
        ? compareUpdatedAtAsc
        : compareUpdatedAtDesc;
    }

    case 'updatedBy': {
      return profilesSortState.sortOrder === 'asc'
        ? compareUpdatedByAsc
        : compareUpdatedByDesc;
    }
  }
};

export namespace CompanyProfilesSelector {
  const selectors = companyProfilesAdapter.getSelectors<RootState>(
    (state) => state.companyProfiles,
  );

  export const createCompanyProfileIsLoading = (state: RootState) =>
    state.companyProfiles.createCompanyProfileStatus.status === 'pending';

  export const deleteCompanyProfileIsLoading = (state: RootState) =>
    state.companyProfiles.deleteCompanyProfileStatus.status === 'pending';

  export const getCompanyProfilesIsLoading = (state: RootState) =>
    state.companyProfiles.getCompanyProfilesStatus.status === 'pending';

  export const getCompanyProfileError = (state: RootState) =>
    state.companyProfiles.getCompanyProfileStatus.error;

  export const getCompanyProfileIsLoading = (state: RootState) =>
    state.companyProfiles.getCompanyProfileStatus.status === 'pending';

  export const updateCompanyProfileIndexesIsLoading = (state: RootState) =>
    state.companyProfiles.updateCompanyProfileIndexesStatus.status ===
    'pending';

  export const updateCompanyProfileIsLoading = (state: RootState) =>
    state.companyProfiles.updateCompanyProfileStatus.status === 'pending';

  export const profilesSortState = (state: RootState) =>
    state.companyProfiles.profilesSortState;

  export const selectHasProfiles = (state: RootState) =>
    selectors.selectAll(state).length > 0;

  export const profilesPriorityList = createSelector(
    [selectors.selectAll],
    (companyProfiles) => {
      const _companyProfiles = companyProfiles
        .sort(comparePriorityAsc)
        .map(({ companyProfileKey, name }) => {
          return { name, id: companyProfileKey };
        });

      return _companyProfiles;
    },
  );

  export const profilesSortedAfterName = (state: RootState) => {
    const companyProfiles = selectors.selectAll(state);

    companyProfiles.sort(compareNameAsc);

    return companyProfiles;
  };

  export const profilesSortedAfterSortState = createSelector(
    [
      selectors.selectAll,
      CompanyProfileDraftsSelector.selectAll,
      (state: RootState) => state.companyProfiles.profilesSortState,
    ],
    (companyProfiles, companyProfileDrafts, profilesSortState) => {
      const profiles = [...companyProfileDrafts, ...companyProfiles];

      profiles.sort(getProfileComparer(profilesSortState));

      return profiles;
    },
  );

  export const selectById = selectors.selectById;

  export const getIndex0CompanyProfile = (state: RootState) => {
    const companyProfiles = selectors.selectAll(state);

    return companyProfiles.find((companyProfile) => companyProfile.index === 0);
  };

  export const selectStatus = (state: RootState) =>
    state.companyProfiles.getCompanyProfilesStatus.status;
}

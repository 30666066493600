import {
  Box,
  BreadcrumbsToolbar,
  ContentContainer,
  InnerContentContainerAlt,
  InnerPageContainer,
  PageContainer,
  Spinner,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  CompanyProfile,
  CompanyProfileDraft,
  PROFILE_NAME_MAX_LENGTH,
  UpdateCompanyProfileDraft,
} from '../../../../models';
import {
  createCompanyProfileDraftThunk,
  getCompanyProfileDraftThunk,
  getCompanyProfileThunk,
  store,
  updateCompanyProfileDraftThunk,
} from '../../../../store';

const createDraft = async () => {
  const { dispatch } = store;

  const res = await dispatch(createCompanyProfileDraftThunk());

  if (!res.payload) {
    return;
  }

  if (typeof res.payload !== 'string') {
    return;
  }

  return res.payload;
};

const duplicateProfileMapper = (
  companyProfile: CompanyProfile,
): UpdateCompanyProfileDraft => {
  const name = translate('label.general.copy-of', {
    name: companyProfile.name ?? translate('label.general.untitled-draft'),
  }).substring(0, PROFILE_NAME_MAX_LENGTH);

  return {
    about: companyProfile.about,
    contactEmail: companyProfile.contactEmail,
    contactPhone: companyProfile.contactPhone,
    coverImageBase64: companyProfile.coverImageBase64,
    employmentTypes: companyProfile.employmentTypes,
    funFacts: companyProfile.funFacts,
    name,
    oneliner: companyProfile.oneliner,
    perks: companyProfile.perks.map((perk) => ({
      description: perk.description,
      index: perk.index,
      perkKey: perk.perkKey,
    })),
    subjectAreaKeys: companyProfile.subjectAreas.flatMap(
      (subjectArea) => subjectArea.subjectAreaKey,
    ),
    videoUrl: companyProfile.videoUrl,
    websiteUrl: companyProfile.websiteUrl,
    locationData: {
      workModels: companyProfile.locationData.workModels,
      officeLocations:
        companyProfile.locationData.officeLocations?.map((officeLocation) => ({
          index: officeLocation.index,
          officeLocationKey: officeLocation.officeLocationKey,
        })) ?? null,
    },
  };
};

const duplicateProfileDraftMapper = (
  companyProfileDraft: CompanyProfileDraft,
): UpdateCompanyProfileDraft => {
  const name = translate('label.general.copy-of', {
    name: companyProfileDraft.name ?? translate('label.general.untitled-draft'),
  }).substring(0, PROFILE_NAME_MAX_LENGTH);

  return {
    ...companyProfileDraft,
    name,
  };
};

const duplicateProfile = async (companyProfileKey: string) => {
  const { dispatch } = store;

  const res = await dispatch(getCompanyProfileThunk(companyProfileKey));

  if (!res.payload) {
    return;
  }

  if (!('companyProfileKey' in res.payload)) {
    return;
  }

  const _res = await dispatch(createCompanyProfileDraftThunk());

  if (!_res.payload) {
    return;
  }

  if (typeof _res.payload !== 'string') {
    return;
  }

  const updateDraftRes = await dispatch(
    updateCompanyProfileDraftThunk({
      companyProfileDraftKey: _res.payload,
      updateCompanyProfileDraft: duplicateProfileMapper(res.payload),
    }),
  );

  if (!updateDraftRes.payload) {
    return;
  }

  if (!('companyProfileDraftKey' in updateDraftRes.payload)) {
    return;
  }

  return updateDraftRes.payload.companyProfileDraftKey;
};

const duplicateProfileDraft = async (companyProfileDraftKey: string) => {
  const { dispatch } = store;

  const res = await dispatch(
    getCompanyProfileDraftThunk(companyProfileDraftKey),
  );

  if (!res.payload) {
    return;
  }

  if (!('companyProfileDraftKey' in res.payload)) {
    return;
  }

  const _res = await dispatch(createCompanyProfileDraftThunk());

  if (!_res.payload) {
    return;
  }

  if (typeof _res.payload !== 'string') {
    return;
  }

  const updateDraftRes = await dispatch(
    updateCompanyProfileDraftThunk({
      companyProfileDraftKey: _res.payload,
      updateCompanyProfileDraft: duplicateProfileDraftMapper(res.payload),
    }),
  );

  if (!updateDraftRes.payload) {
    return;
  }

  if (!('companyProfileDraftKey' in updateDraftRes.payload)) {
    return;
  }

  return updateDraftRes.payload.companyProfileDraftKey;
};

export function CreateProfileDraft() {
  const params = useParams<
    { companyProfileKey: string } | { companyProfileDraftKey: string }
  >();

  const { key, copyDraft } =
    'companyProfileKey' in params
      ? { key: params.companyProfileKey, copyDraft: false }
      : 'companyProfileDraftKey' in params
      ? { key: params.companyProfileDraftKey, copyDraft: true }
      : { key: undefined, copyDraft: false };

  const [navigateTo, setNavigateTo] = React.useState<string>();

  const isDispatched = React.useRef(false);

  React.useEffect(() => {
    if (isDispatched.current) return;

    (async () => {
      const companyProfileDraftKey = await (key
        ? copyDraft
          ? duplicateProfileDraft(key)
          : duplicateProfile(key)
        : createDraft());

      const to = companyProfileDraftKey
        ? `/profiles/create-profile/${companyProfileDraftKey}`
        : `/profiles`;

      setNavigateTo(to);
    })();

    isDispatched.current = true;
  }, [copyDraft, key]);

  if (navigateTo) {
    return <Navigate to={navigateTo} replace />;
  }

  return (
    <PageContainer>
      <BreadcrumbsToolbar
        breadcrumbListItems={[
          { to: '/profiles', tx: 'label.breadcrumbs.profiles.profiles' },
        ]}
      />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainerAlt>
            <Box flex flexJustify="center" flexAlign="center" height="100%">
              <Spinner />
            </Box>
          </InnerContentContainerAlt>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

import { PayloadAction } from '@reduxjs/toolkit';

import { initialCompanyState } from './company.initial';
import { CompanyState } from './company.types';

const reset = (state: CompanyState) => {
  Object.assign(state, initialCompanyState);
};

const increaseUsedQrCodeCount = (
  state: CompanyState,
  action: PayloadAction<number>,
) => {
  if (state.company?.data?.moduleSettings.connectModule) {
    state.company.data.moduleSettings.connectModule.usedQrCodeCount +=
      action.payload;
  }
};

export const reducers = {
  reset,

  increaseUsedQrCodeCount,
};

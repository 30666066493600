import { ImageData } from '@orbiapp/components';

import { SegmentationType, StudyLevel, SubjectArea } from '../../shared';
import { PartialCompanyQr } from '../company-qr';

export interface TalentPoolUserStats {
  activeQrCodeCount: number;
  scanCount: number;
  talentPoolUserCount: number;
}

export interface TalentPoolUser {
  companyQr: PartialCompanyQr | null;
  createdAt: number;
  email: string;
  firstName: string;
  isStarred: boolean;
  lastName: string;
  profilePicture: ImageData | null;
  notes: string | null;
  segmentation: SegmentationType;
  semester: number;
  source: 'profile' | 'qr';
  studyLevel: StudyLevel;
  subjectAreas: SubjectArea[];
  userKey: string;
  schoolName: string;
}

export interface UpdateTalentPoolUserNotesForm {
  notes: string;
}

export interface UpdateTalentPoolUser
  extends Partial<UpdateTalentPoolUserNotesForm> {
  userKey: string;
  isStarred?: boolean;
}

export interface PartialTalentPoolUser
  extends Pick<
    TalentPoolUser,
    | 'userKey'
    | 'createdAt'
    | 'firstName'
    | 'lastName'
    | 'profilePicture'
    | 'email'
    | 'source'
    | 'companyQr'
    | 'isStarred'
  > {}

export type PartialTalentPoolUserOrderBy =
  | keyof Pick<PartialTalentPoolUser, 'createdAt' | 'email' | 'source'>
  | 'fullName';

export const talentPoolUserSortableKeys: Record<
  PartialTalentPoolUserOrderBy,
  PartialTalentPoolUserOrderBy
> = {
  createdAt: 'createdAt',
  email: 'email',
  source: 'source',
  fullName: 'fullName',
};

import {
  Box,
  ControlledInput,
  ControlledTextarea,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';

import { UploadCoverImage } from '../../../../../components';
import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_TITLE_MAX_LENGTH,
} from '../../../../../models';
import { JobSelector, useSelector } from '../../../../../store';

export function JobDetails() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.1.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.1.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <Box gap={16} flexDirection="column" flex>
        <UploadCoverImage disabled={!canEditJob} name="coverImage" />

        <ControlledInput
          labelTx="label.job-form.title"
          name="title"
          maxLength={JOB_TITLE_MAX_LENGTH}
          disabled={!canEditJob}
        />

        <ControlledTextarea
          labelTx="label.job-form.description"
          maxLength={JOB_DESCRIPTION_MAX_LENGTH}
          name="description"
          resize="vertical"
          minHeight={160}
          disabled={!canEditJob}
        />
      </Box>
    </FormSection>
  );
}

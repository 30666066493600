import { createEntityAdapter } from '@reduxjs/toolkit';

import { OfficeLocation } from '../../models';

export const officeLocationsAdapter = createEntityAdapter<
  OfficeLocation,
  string
>({
  selectId: (location) => location.officeLocationKey,
});

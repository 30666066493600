import { createSelector } from '@reduxjs/toolkit';

import { InsightsMetric } from '../../models';
import { RootState } from '../store.types';

export namespace LiveInsightsSelector {
  const selectSelf = (state: RootState) => state.insights.liveInsights;

  export const selectStatus = createSelector(selectSelf, (liveInsights) => {
    return liveInsights.status;
  });

  export const selectData = createSelector(selectSelf, (liveInsights) => {
    return liveInsights.data;
  });
}

export namespace InsightsTimeSeriesItemsSelector {
  const selectSelf = (state: RootState) => state.insights.items;

  export const selectStatus = createSelector(selectSelf, (insightsItems) => {
    return insightsItems.status;
  });

  export const selectData = createSelector(selectSelf, (insightsItems) => {
    return insightsItems.data;
  });

  const selectCompanyAdItems = createSelector(selectData, (insightItems) => {
    const items = insightItems?.filter((item) => item.type === 'company_ad');

    return items?.length ? items : null;
  });

  const selectCompanyProfileItems = createSelector(
    selectData,
    (insightItems) => {
      const items = insightItems?.filter(
        (item) => item.type === 'company_profile',
      );

      return items?.length ? items : null;
    },
  );

  const selectJobsItems = createSelector(selectData, (insightItems) => {
    const items = insightItems?.filter((item) => item.type === 'job');

    return items?.length ? items : null;
  });

  const selectOfferItems = createSelector(selectData, (insightItems) => {
    const items = insightItems?.filter((item) => item.type === 'offer');

    return items?.length ? items : null;
  });

  export const selectItemsByType = createSelector(
    [
      selectCompanyAdItems,
      selectCompanyProfileItems,
      selectJobsItems,
      selectOfferItems,
    ],
    (companyAdItems, companyProfileItems, jobItems, offerItems) => {
      return {
        companyAdItems,
        companyProfileItems,
        jobItems,
        offerItems,
      };
    },
  );
}

export namespace InsightsTimeSeriesConfigSelector {
  const selectSelf = (state: RootState) => state.insights.timeSeriesConfig;

  export const selectSelectedItems = createSelector(
    selectSelf,
    (timeSeriesConfig) => {
      return timeSeriesConfig.selectedItems;
    },
  );

  export const selectRange = createSelector(selectSelf, (timeSeriesConfig) => {
    return timeSeriesConfig.selectedRange;
  });

  export const selectCount = createSelector(
    selectSelectedItems,
    (selectedItems) => {
      return selectedItems?.length ?? 0;
    },
  );

  export const selectShowUnique = createSelector(
    selectSelf,
    (timeSeriesConfig) => {
      return timeSeriesConfig.showUnique;
    },
  );
}

export namespace InsightsTimeSeriesSelector {
  const selectSelf = (state: RootState) => state.insights.timeSeries;

  export const selectStatus = createSelector(selectSelf, (timeSeries) => {
    return timeSeries.status;
  });

  export const selectData = createSelector(selectSelf, (timeSeries) => {
    return timeSeries.data;
  });

  const selectTimeSeries = createSelector(selectData, (data) => {
    return data?.timeSeries ?? [];
  });

  export const selectTableEntries = createSelector(selectData, (data) => {
    return data?.tableEntries ?? [];
  });

  export const selectImpressions = createSelector(
    selectTimeSeries,
    (timeSeries) => timeSeries.filter((item) => item.metric === 'impressions'),
  );

  export const selectViews = createSelector(selectTimeSeries, (timeSeries) =>
    timeSeries.filter((item) => item.metric === 'views'),
  );

  export const selectEngagement = createSelector(
    selectTimeSeries,
    (timeSeries) => timeSeries.filter((item) => item.metric === 'engagement'),
  );

  export const selectByMetric = createSelector(
    [selectTimeSeries, (_, metric: InsightsMetric) => metric],
    (timeSeries, metric) => {
      switch (metric) {
        case 'impressions':
          return timeSeries.filter((item) => item.metric === 'impressions');
        case 'views':
          return timeSeries.filter((item) => item.metric === 'views');
        case 'engagement':
          return timeSeries.filter((item) => item.metric === 'engagement');
      }
    },
  );
}

export namespace InsightsTimeSeriesByProductSelector {
  const selectSelf = (state: RootState) => state.insights.timeSeriesByProduct;

  export const selectStatus = createSelector(selectSelf, (timeSeries) => {
    return timeSeries.status;
  });

  export const selectData = createSelector(selectSelf, (timeSeriesByMetric) => {
    return timeSeriesByMetric.data;
  });

  export const selectTimeSeries = createSelector(selectData, (data) => {
    return data?.timeSeries ?? [];
  });

  export const selectImpressions = createSelector(
    selectTimeSeries,
    (timeSeries) => timeSeries.filter((item) => item.metric === 'impressions'),
  );

  export const selectViews = createSelector(selectTimeSeries, (timeSeries) =>
    timeSeries.filter((item) => item.metric === 'views'),
  );

  export const selectEngagement = createSelector(
    selectTimeSeries,
    (timeSeries) => timeSeries.filter((item) => item.metric === 'engagement'),
  );

  export const selectTableEntries = createSelector(selectData, (data) => {
    return data?.tableEntries ?? [];
  });
}

export namespace InsightsTimeSeriesConfigByProductSelector {
  const selectSelf = (state: RootState) =>
    state.insights.timeSeriesConfigByProduct;

  export const selectRange = createSelector(selectSelf, (timeSeriesConfig) => {
    return timeSeriesConfig.selectedRange;
  });

  export const selectShowUnique = createSelector(
    selectSelf,
    (timeSeriesConfig) => {
      return timeSeriesConfig.showUnique;
    },
  );
}

export namespace ExportInsightsSelector {
  const selectSelf = (state: RootState) => state.insights.exportInsights;

  export const selectStatus = createSelector(selectSelf, (exportInsights) => {
    return exportInsights.status;
  });
}

export namespace EstimatedValueSelector {
  const selectSelf = (state: RootState) => state.insights.estimatedValue;

  export const selectStatus = createSelector(selectSelf, (estimatedValue) => {
    return estimatedValue.status;
  });

  export const selectData = createSelector(selectSelf, (estimatedValue) => {
    return estimatedValue.data;
  });
}

import {
  Box,
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  Button,
  Chip,
  Confirm,
  ContentContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  PageContainer,
  Status,
  StatusVariant,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Time,
  Tooltip,
  UpdatedAtTableCell,
  UpdatedByTableCell,
  formatDate,
  paginatorOptions,
  translate,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import { useDataGridPagination } from '../../../../helpers';
import {
  Job,
  JobsOrderByKey,
  PartialJob,
  PartialJobDraft,
  jobsSortableKeys,
} from '../../../../models';
import {
  DeleteJobDraftSelector,
  JobDraftsSelector,
  JobsSelector,
  deleteJobDraftThunk,
  getDraftsThunk,
  getJobsThunk,
  jobsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getEmploymentTypeChipProps } from '../../../../utils';

const JOBS_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/jobs', tx: 'label.breadcrumbs.jobs.jobs' },
];

const TABLE_COLUMN_WIDTHS = {
  title: 200,
  endDate: 150,
  type: 150,
  status: 200,
  updatedBy: 200,
  updatedAt: 150,
  actions: 40,
};

const DeleteJobDraftContext = React.createContext<
  ReturnType<typeof useConfirm<string>>
>({
  closeConfirm: () => {},
  confirmValue: null,
  isOpen: false,
  openConfirm: () => {},
});

function DeleteJobDraftProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const confirmState = useConfirm<string>();

  return (
    <DeleteJobDraftContext.Provider value={confirmState}>
      {children}
    </DeleteJobDraftContext.Provider>
  );
}

function getJobStatusOptions(partialJob: {
  dailyBudgetReachedAt: Job['dailyBudgetReachedAt'];
  endDate: Job['endDate'];
  startDate: Job['startDate'];
  isManuallyClosed: Job['isManuallyClosed'];
  totalBudgetReachedAt: Job['totalBudgetReachedAt'];
}): {
  variant: StatusVariant;
  tx: TxString;
} {
  const now = Date.now();
  const isExpired = now > partialJob.endDate;

  if (isExpired || partialJob.isManuallyClosed) {
    return {
      variant: 'error',
      tx: 'label.job-status.closed',
    };
  }

  if (partialJob.totalBudgetReachedAt) {
    return {
      variant: 'error',
      tx: 'label.job-status.total-budget-reached',
    };
  }

  if (
    partialJob.dailyBudgetReachedAt &&
    partialJob.dailyBudgetReachedAt.some(
      (timestamp) => timestamp > now - Time.Day,
    )
  ) {
    return {
      variant: 'info',
      tx: 'label.job-status.daily-budget-reached',
    };
  }

  if (partialJob.startDate > now) {
    return {
      variant: 'info',
      tx: 'label.job-status.scheduled',
    };
  }

  return {
    variant: 'success',
    tx: 'label.job-status.live',
  };
}

function renderJobTableRow(job: PartialJob, index: number) {
  return (
    <TableRow to={`/jobs/${job.jobKey}`} key={`job-table-row-${index}`}>
      <TableCell width={TABLE_COLUMN_WIDTHS.title} text={job.title} />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.endDate}
        text={formatDate(job.endDate, 'DD MMM YYYY')}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.type}>
        <Chip {...getEmploymentTypeChipProps(job.employmentType)} />
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status {...getJobStatusOptions(job)} />
      </TableCell>

      <UpdatedByTableCell
        fallbackTx="placeholder.unknown-user"
        editMeta={job.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedBy}
      />

      <UpdatedAtTableCell
        editMeta={job.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedAt}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
    </TableRow>
  );
}

function DeleteJobDraftConfirm() {
  const dispatch = useDispatch();

  const confirmState = React.useContext(DeleteJobDraftContext);

  const deleteJobDraftStatus = useSelector(DeleteJobDraftSelector.selectStatus);

  const deleteJobDraft = async () => {
    if (!confirmState.confirmValue) return;

    await dispatch(deleteJobDraftThunk(confirmState.confirmValue));

    confirmState.closeConfirm();
  };

  return (
    <Confirm
      cancelTx="prompt.delete-job-draft.cancel"
      confirmTx="prompt.delete-job-draft.confirm"
      isLoading={deleteJobDraftStatus === 'pending'}
      isOpen={confirmState.isOpen}
      messageTx="prompt.delete-job-draft.message"
      onCancel={confirmState.closeConfirm}
      onConfirm={deleteJobDraft}
      titleTx="prompt.delete-job-draft.title"
    />
  );
}

function JobDraftTableRow(props: PartialJobDraft) {
  const { title, endDate, employmentType, jobDraftKey, editMeta } = props;

  const confirmState = React.useContext(DeleteJobDraftContext);

  const openConfirm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    confirmState.openConfirm(jobDraftKey);
  };

  return (
    <React.Fragment>
      <TableRow to={`/jobs/create-job/${jobDraftKey}`}>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.title}
          text={title ?? translate('label.general.untitled-draft')}
        />

        <TableCell
          width={TABLE_COLUMN_WIDTHS.endDate}
          text={endDate ? formatDate(endDate, 'DD MMM YYYY') : ''}
        />

        <TableCell width={TABLE_COLUMN_WIDTHS.type}>
          {employmentType && (
            <Chip {...getEmploymentTypeChipProps(employmentType)} />
          )}
        </TableCell>

        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status variant="warning" tx="label.job-status.draft" />
        </TableCell>

        <UpdatedByTableCell
          fallbackTx="placeholder.unknown-user"
          editMeta={editMeta}
          width={TABLE_COLUMN_WIDTHS.updatedBy}
        />

        <UpdatedAtTableCell
          editMeta={editMeta}
          width={TABLE_COLUMN_WIDTHS.updatedAt}
        />

        <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
          <Box ml="auto">
            <Tooltip placement="left" titleTx="label.tooltip.delete">
              <IconButton onClick={openConfirm} icon="trash-outline" />
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function renderJobDraftTableRow(draft: PartialJobDraft) {
  return <JobDraftTableRow key={draft.jobDraftKey} {...draft} />;
}

function JobsTable() {
  const jobsStatus = useSelector(JobsSelector.selectStatus);
  const jobsData = useSelector(JobsSelector.selectData);
  const jobsPagination = useSelector(JobsSelector.selectPagination);
  const jobDrafts = useSelector(JobDraftsSelector.selectData);
  const jobDraftsStatus = useSelector(JobDraftsSelector.selectStatus);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<PartialJob, JobsOrderByKey>({
      data: jobsData,
      pagination: jobsPagination,
      reset: jobsActions.clearJobs,
      thunk: getJobsThunk,
    });

  const isLoading = jobsStatus === 'pending' || jobDraftsStatus === 'pending';
  const isEmpty = rows.length === 0 && jobDrafts.length === 0;

  if (isEmpty) {
    if (isLoading) {
      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.jobs.table.job" />
              <TableHead tx="label.jobs.table.ends" />
              <TableHead tx="label.jobs.table.employment-type" />
              <TableHead tx="label.jobs.table.status" />
            </TableRow>
          </TableHeader>
          <TableBody>
            <TablePlaceholderRows
              rowCount={10}
              layout={Object.values(TABLE_COLUMN_WIDTHS)}
            />
          </TableBody>
        </Table>
      );
    }

    return (
      <EmptyState
        titleTx="label.jobs.jobs-empty-state.title"
        to="/jobs/create-job"
        buttonTx="label.jobs.jobs-empty-state.button"
      />
    );
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={jobsPagination.orderBy}
          sortableColumns={Object.values(jobsSortableKeys)}
          sortOrder={jobsPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.jobs.table.job"
              name={jobsSortableKeys.title}
            />
            <TableHead
              tx="label.jobs.table.ends"
              name={jobsSortableKeys.endDate}
            />
            <TableHead tx="label.jobs.table.employment-type" />
            <TableHead tx="label.jobs.table.status" />
            <TableHead tx="label.jobs.table.updated-by" />
            <TableHead tx="label.jobs.table.updated-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          {jobDrafts.map(renderJobDraftTableRow)}
          {rows.map(renderJobTableRow)}
        </TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function JobsContent() {
  return (
    <DeleteJobDraftProvider>
      <DeleteJobDraftConfirm />

      <Box flexJustify="between" flexWrap gap={16} flex>
        <Text
          color="pageTitle"
          variant="titleMd"
          tx="title.jobs.dashboard"
          as="h1"
        />

        <Button
          variant="primary"
          tx="button.jobs.new-job"
          to="/jobs/create-job"
        />
      </Box>

      <JobsTable />
    </DeleteJobDraftProvider>
  );
}

export function Jobs() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getDraftsThunk());
  }, [dispatch]);

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={JOBS_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <JobsContent />
          </InnerContentContainer>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

import { initialAccountState } from './account.initial';
import { AccountState } from './account.types';

function reset(state: AccountState) {
  Object.assign(state, initialAccountState);
}

export const reducers = {
  reset,
};

import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute, UnauthenticatedRoute } from '../guards';
import {
  EnableCompanyAdsOutlet,
  EnableConnectOutlet,
  EnableJobsOutlet,
  EnableOffersOutlet,
  EnableProfilesOutlet,
  MainOutlet,
  SignedInOutlet,
} from '../outlets';
import {
  EmulateUser,
  NotFound,
  ResetPassword,
  SignOut,
  SignUp,
  SignUpFromInvitation,
} from '../pages/shared';
import {
  AccessDenied,
  AccessDeniedStudent,
  CompanyAds,
  Connect,
  CreateOffer,
  CreateOfferFromDraft,
  DuplicateOffer,
  EmailNotVerified,
  Fullbreakdown,
  InviteTeamMembers,
  OfferForm,
  OfferStats,
  Offers,
  OnBoarded,
  SettingsOutlet,
  SwitchWorkspace,
  ViewJobForm,
  ViewOffer,
  ViewProfileForm,
  ViewProfileStats,
} from '../pages/signed-in';
import {
  AccountSettings,
  CompanySettings,
  CreateJob,
  CreateJobFromDraft,
  CreateProfile,
  CreateProfileDraft,
  DuplicateJob,
  Insights,
  Jobs,
  Onboarding,
  Profiles,
  QrCodes,
  TalentPool,
  TeamSettings,
  ViewJob,
  ViewJobStats,
  ViewMetric,
  ViewProfile,
} from '../pages/signed-in';
import { SignIn } from '../pages/signed-out';

const insightsRouteObject: RouteObject = {
  path: '/insights',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { path: 'full-breakdown', element: <Fullbreakdown /> },
    { path: ':metric', element: <ViewMetric /> },

    { index: true, element: <Insights /> },
    { path: '*', element: <Navigate replace to="/insights" /> },
  ],
};

const profilesRouteObject: RouteObject = {
  path: '/profiles',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '*',
      element: <EnableProfilesOutlet />,
      children: [
        { path: 'create-profile', element: <CreateProfileDraft /> },
        {
          path: ':companyProfileKey',
          element: <ViewProfile />,
          children: [
            { index: true, element: <ViewProfileForm /> },
            { path: 'stats', element: <ViewProfileStats /> },
          ],
        },
        {
          path: 'create-profile/:companyProfileDraftKey',
          element: <CreateProfile />,
        },
        {
          path: 'create-profile/duplicate/:companyProfileKey',
          element: <CreateProfileDraft />,
        },
        {
          path: 'create-profile/duplicate-draft/:companyProfileDraftKey',
          element: <CreateProfileDraft />,
        },

        { index: true, element: <Profiles /> },
        { path: '*', element: <Navigate replace to="/profiles" /> },
      ],
    },
  ],
};

const jobsRouteObject: RouteObject = {
  path: '/jobs',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '*',
      element: <EnableJobsOutlet />,
      children: [
        { path: 'create-job', element: <CreateJob /> },
        {
          path: ':jobKey',
          element: <ViewJob />,
          children: [
            { index: true, element: <ViewJobForm /> },
            { path: 'stats', element: <ViewJobStats /> },
          ],
        },
        { path: 'duplicate-job/:jobKey', element: <DuplicateJob /> },
        { path: 'create-job/:jobDraftKey', element: <CreateJobFromDraft /> },

        { index: true, element: <Jobs /> },
        { path: '*', element: <Navigate replace to="/jobs" /> },
      ],
    },
  ],
};

const connectRouteObject: RouteObject = {
  path: '/connect',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '*',
      element: <EnableConnectOutlet />,
      children: [
        {
          path: '',
          element: <Connect />,
          children: [
            { path: 'talent-pool', element: <TalentPool /> },
            { path: 'qr-codes', element: <QrCodes /> },
            {
              index: true,
              element: <Navigate replace to="/connect/talent-pool" />,
            },
            {
              path: '*',
              element: <Navigate replace to="/connect/talent-pool" />,
            },
          ],
        },
      ],
    },
  ],
};

const companyAdsRouteObject: RouteObject = {
  path: '/company-ads',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '*',
      element: <EnableCompanyAdsOutlet />,
      children: [
        {
          index: true,
          element: <CompanyAds />,
        },
      ],
    },
  ],
};

const offersRouteObject: RouteObject = {
  path: '/offers',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '*',
      element: <EnableOffersOutlet />,
      children: [
        { path: 'create-offer', element: <CreateOffer /> },
        {
          path: ':offerKey',
          element: <ViewOffer />,
          children: [
            { index: true, element: <OfferForm /> },
            { path: 'stats', element: <OfferStats /> },
          ],
        },

        { path: 'duplicate-offer/:offerKey', element: <DuplicateOffer /> },
        {
          path: 'create-offer/:offerDraftKey',
          element: <CreateOfferFromDraft />,
        },

        { index: true, element: <Offers /> },
        { path: '*', element: <Navigate replace to="/offers" /> },
      ],
    },
  ],
};

const settingsRouteObject: RouteObject = {
  path: '/settings',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '',
      element: <SettingsOutlet />,
      children: [
        { path: 'account', element: <AccountSettings /> },
        { path: 'company', element: <CompanySettings /> },
        { path: 'team', element: <TeamSettings /> },

        { index: true, element: <Navigate replace to="account" /> },
      ],
    },
  ],
};

const onboardingRouteObject: RouteObject = {
  path: '/onboarding',
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      index: true,
      element: <Onboarding />,
    },
  ],
};

const onboardedRouteObject: RouteObject = {
  path: '/onboarded',
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      index: true,
      element: <OnBoarded />,
    },
  ],
};

const inviteTeamMembersRouteObject: RouteObject = {
  path: '/invite-team-members',
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      index: true,
      element: <InviteTeamMembers />,
    },
  ],
};

const accessDeniedRouteObject: RouteObject = {
  path: 'access-denied',
  element: (
    <AuthenticatedRoute>
      <AccessDenied />
    </AuthenticatedRoute>
  ),
};

const accessDeniedStudentRouteObject: RouteObject = {
  path: 'access-denied-student',
  element: (
    <AuthenticatedRoute>
      <AccessDeniedStudent />
    </AuthenticatedRoute>
  ),
};

const emailNotVerifiedRouteObject: RouteObject = {
  path: 'email-not-verified',
  element: (
    <AuthenticatedRoute>
      <EmailNotVerified />
    </AuthenticatedRoute>
  ),
};

const signUpRouteObject: RouteObject = {
  path: 'sign-up',
  children: [
    {
      index: true,
      element: <SignUp />,
    },
    {
      path: ':companyInvitationKey',
      element: <SignUpFromInvitation />,
    },
  ],
};

const signOutRouteObject: RouteObject = {
  path: 'sign-out',
  element: <SignOut />,
};

const signInRouteObject: RouteObject = {
  path: 'sign-in',
  element: (
    <UnauthenticatedRoute>
      <SignIn />
    </UnauthenticatedRoute>
  ),
};

const resetPasswordRouteObject: RouteObject = {
  path: 'reset-password',
  element: <ResetPassword />,
};

const emulateUserRouteObject: RouteObject = {
  path: 'emulate-user',
  children: [
    { index: true, element: <EmulateUser /> },
    { path: ':userKey', element: <EmulateUser /> },
  ],
};

const switchWorkspaceRouteObject: RouteObject = {
  path: 'workspace/:type/:workspaceKey',
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [{ index: true, element: <SwitchWorkspace /> }],
};

const fallbackRouteObject: RouteObject = {
  path: '',
  element: <Navigate replace to="/insights" />,
};

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <MainOutlet />,
    errorElement: <NotFound />,
    children: [
      /**
       * Can always be accessed
       */
      signUpRouteObject,
      signOutRouteObject,
      emulateUserRouteObject,
      resetPasswordRouteObject,

      /**
       * Can only be accessed when signed out
       */
      signInRouteObject,

      /**
       * Can only be accessed when signed in
       */
      insightsRouteObject,
      profilesRouteObject,
      jobsRouteObject,
      companyAdsRouteObject,
      connectRouteObject,
      offersRouteObject,
      settingsRouteObject,
      switchWorkspaceRouteObject,
      onboardingRouteObject,
      onboardedRouteObject,
      inviteTeamMembersRouteObject,
      accessDeniedRouteObject,
      accessDeniedStudentRouteObject,
      emailNotVerifiedRouteObject,
      fallbackRouteObject,
    ],
  },
];

import Joi from 'joi';

import { UpdateOfferDraftSchema } from './offer-draft.schema';

export const UpdateOfferDraftValidation = Joi.object({
  offerCategoryKey: UpdateOfferDraftSchema.offerCategoryKey,
  title: UpdateOfferDraftSchema.title,
  description: UpdateOfferDraftSchema.description,
  coverImage: UpdateOfferDraftSchema.coverImage,

  startDate: UpdateOfferDraftSchema.startDate,
  endDate: UpdateOfferDraftSchema.endDate,
  contactName: UpdateOfferDraftSchema.contactName,
  contactEmail: UpdateOfferDraftSchema.contactEmail,

  isConsumable: UpdateOfferDraftSchema.isConsumable,
  consumeWaitMinutes: UpdateOfferDraftSchema.consumeWaitMinutes,

  link: UpdateOfferDraftSchema.link,

  code: UpdateOfferDraftSchema.code,
  files: UpdateOfferDraftSchema.files,

  offerDraftKey: UpdateOfferDraftSchema.offerDraftKey,

  locations: UpdateOfferDraftSchema.locations,
  requireStudentVerification: UpdateOfferDraftSchema.requireStudentVerification,

  countryKeys: UpdateOfferDraftSchema.countryKeys,
});

import {
  Box,
  BreadcrumbsToolbar,
  ContentContainer,
  InnerPageContainer,
  LineChart,
  PageContainer,
  useXLabelMaxTicksLimit,
} from '@orbiapp/components';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  ControlsContainer,
  ExportDataButton,
  InsightsInnerContentContainer,
  SelectAndCompareRange,
  SelectProduct,
  ToggleUniqueMetrics,
} from '../../../../components/insights';
import {
  InsightsMetric,
  InsightsRange,
  SelectedInsightsItem,
  insightsMetrics,
} from '../../../../models';
import {
  InsightsTimeSeriesConfigSelector,
  InsightsTimeSeriesItemsSelector,
  InsightsTimeSeriesSelector,
  getInsightsItemsThunk,
  getTimeBasedInsightsThunk,
  insightsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import {
  getInsightsMetricDescriptionTx,
  getInsightsMetricTx,
  getLabelsAndDatasets,
  isAnyPending,
} from '../../../../utils';
import { Header } from './header';
import { MetricsBreakdown } from './metrics-breakdown';

function MetricChart(props: { metric: InsightsMetric }) {
  const { metric } = props;

  const insightsDataByMetric = useSelector((state) =>
    InsightsTimeSeriesSelector.selectByMetric(state, metric),
  );

  const rangeByMetric = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const insightsDataByMetricStatus = useSelector(
    InsightsTimeSeriesSelector.selectStatus,
  );
  const insightsItemsByMetricStatus = useSelector(
    InsightsTimeSeriesItemsSelector.selectStatus,
  );

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    insightsDataByMetric,
    rangeByMetric,
  );

  return (
    <LineChart
      isLoading={isAnyPending(
        insightsDataByMetricStatus,
        insightsItemsByMetricStatus,
      )}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="inherit"
      titleTx={getInsightsMetricTx(metric)}
      descriptionTx={getInsightsMetricDescriptionTx(metric)}
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
      placeholderTitleTx="label.insights.no-data-available"
      placeholderSubtitleTx="label.insights.try-updating-date-range"
      legendPosition="bottom"
    />
  );
}

export function ViewMetric() {
  const { metric } = useParams<{ metric: InsightsMetric }>();

  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const selectedItems = useSelector(
    InsightsTimeSeriesConfigSelector.selectSelectedItems,
  );
  const showUnique = useSelector(
    InsightsTimeSeriesConfigSelector.selectShowUnique,
  );
  const { companyAdItems, jobItems, companyProfileItems, offerItems } =
    useSelector(InsightsTimeSeriesItemsSelector.selectItemsByType);

  const selectedCount = useSelector(
    InsightsTimeSeriesConfigSelector.selectCount,
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!metric) return;

    dispatch(getInsightsItemsThunk());
    dispatch(getTimeBasedInsightsThunk({ metrics: [metric] }));
  }, [dispatch, metric]);

  if (!metric || !insightsMetrics.includes(metric)) {
    return <Navigate to="/insights" />;
  }

  const setSelectedItems = (items: SelectedInsightsItem[]) => {
    dispatch(insightsActions.setInsightsTimeSeriesConfigItems(items));

    dispatch(getTimeBasedInsightsThunk({ metrics: [metric] }));
  };

  const setInsightsDataRange = async (range: InsightsRange) => {
    dispatch(insightsActions.setInsightsTimeSeriesConfigRange(range));

    dispatch(getTimeBasedInsightsThunk({ metrics: [metric] }));
  };

  const toggleShowUnique = () => {
    dispatch(insightsActions.setInsightsTimeSeriesShowUnique(!showUnique));
    dispatch(getTimeBasedInsightsThunk({ metrics: [metric] }));
  };

  return (
    <PageContainer>
      <BreadcrumbsToolbar
        breadcrumbListItems={[
          { to: '/insights', tx: 'label.breadcrumbs.insights.insights' },
          { to: `/insights/${metric}`, tx: getInsightsMetricTx(metric) },
        ]}
      />

      <InnerPageContainer>
        <ContentContainer backgroundColor="formContainerBackground">
          <Box flexGrow={1} overflow="auto">
            <Header metric={metric} />

            <InsightsInnerContentContainer>
              <ControlsContainer>
                <ToggleUniqueMetrics
                  onToggle={toggleShowUnique}
                  isActive={showUnique}
                />
                <SelectProduct
                  defaultValue={selectedItems}
                  onApply={setSelectedItems}
                  selectedCount={selectedCount}
                  companyAdItems={companyAdItems}
                  jobItems={jobItems}
                  companyProfileItems={companyProfileItems}
                  offerItems={offerItems}
                />
                <SelectAndCompareRange
                  defaultValue={insightsDataRange}
                  onApply={setInsightsDataRange}
                  menuPlacement="right"
                />

                <ExportDataButton
                  metrics={[metric]}
                  items={selectedItems}
                  insightsDataRange={insightsDataRange}
                  showUnique={showUnique}
                />
              </ControlsContainer>

              <Box height={600}>
                <MetricChart metric={metric} />
              </Box>

              <MetricsBreakdown metric={metric} />
            </InsightsInnerContentContainer>
          </Box>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

import Joi from 'joi';

import { USER_NOTE_MAX_LENGTH } from './talent-pool.constants';

export namespace TalentPoolSchema {
  export const notes = Joi.string()
    .max(USER_NOTE_MAX_LENGTH)
    .allow('')
    .required();
}

import React from 'react';
import { useLocation } from 'react-router-dom';

import { connectActions, useDispatch } from '../../../../../store';

interface ConnectSidebarContextType {
  isOpen: boolean;
  closeSidebar: () => void;
  openSidebar: () => void;
}

export const ConnectSidebarContext =
  React.createContext<ConnectSidebarContextType>({
    isOpen: false,
    closeSidebar: () => {},
    openSidebar: () => {},
  });

export function ConnectSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const location = useLocation();

  const [value, setValue] = React.useState(location.search.includes('sidebar'));

  const dispatch = useDispatch();

  const closeSidebar = () => {
    setValue(false);
  };

  const openSidebar = () => {
    setValue(true);

    dispatch(connectActions.clearCompanyQr());
    dispatch(connectActions.clearTalentPoolUser());
  };

  return (
    <ConnectSidebarContext.Provider
      value={{
        isOpen: value,
        closeSidebar,
        openSidebar,
      }}
    >
      {children}
    </ConnectSidebarContext.Provider>
  );
}

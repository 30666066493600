import { INITIAL_DATA_STATE } from '../store.constants';
import { partialCompanyAdAdapter } from './company-ads.adapter';
import { CompanyAdsState } from './company-ads.types';

export const initialCompanyAdsState: CompanyAdsState = {
  companyAds: {
    ...INITIAL_DATA_STATE,
    data: partialCompanyAdAdapter.getInitialState(),
    pagination: {
      limit: 25,
      offset: 0,
      orderBy: 'activeFrom',
      sortOrder: 'desc',
    },
  },
};

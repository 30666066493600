import React from 'react';

import {
  ControlsContainer,
  ExportDataButton,
  InsightsInnerContentContainer,
  SelectAndCompareRange,
  ToggleUniqueMetrics,
} from '../../../../components/insights';
import { InsightsMetric, InsightsRange } from '../../../../models';
import {
  InsightsTimeSeriesConfigByProductSelector,
  ModuleSettingsSelector,
  OfferSelector,
  getTimeBasedInsightsByProductThunk,
  insightsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { Charts } from './charts';
import { MetricsBreakdown } from './metrics-breakdown';

const metrics = ['engagement', 'impressions', 'views'] as InsightsMetric[];

export function OfferStats() {
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigByProductSelector.selectRange,
  );
  const showUnique = useSelector(
    InsightsTimeSeriesConfigByProductSelector.selectShowUnique,
  );
  const offerKey = useSelector(OfferSelector.selectOfferKey);
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!offerKey || insightsTier !== 't2') return;
    dispatch(
      getTimeBasedInsightsByProductThunk({
        metrics,
        item: { type: 'offer', offerKey },
      }),
    );
  }, [dispatch, offerKey, insightsTier]);

  const setInsightsDataRange = async (range: InsightsRange) => {
    if (!offerKey) return;

    dispatch(insightsActions.setInsightsTimeSeriesConfigByProductRange(range));
    dispatch(
      getTimeBasedInsightsByProductThunk({
        metrics,
        item: { type: 'offer', offerKey },
      }),
    );
  };

  const toggleShowUnique = () => {
    if (!offerKey) return;

    dispatch(
      insightsActions.setInsightsTimeSeriesByProductShowUnique(!showUnique),
    );
    dispatch(
      getTimeBasedInsightsByProductThunk({
        metrics,
        item: { type: 'offer', offerKey },
      }),
    );
  };

  return (
    <InsightsInnerContentContainer>
      <ControlsContainer>
        <ToggleUniqueMetrics
          onToggle={toggleShowUnique}
          isActive={showUnique}
        />

        <SelectAndCompareRange
          defaultValue={insightsDataRange}
          onApply={setInsightsDataRange}
          menuPlacement="middle"
          isLocked={insightsTier !== 't2'}
        />

        {offerKey && (
          <ExportDataButton
            insightsDataRange={insightsDataRange}
            items={[{ type: 'offer', offerKey }]}
            metrics={metrics}
            showUnique={showUnique}
          />
        )}
      </ControlsContainer>

      <MetricsBreakdown />

      <Charts />
    </InsightsInnerContentContainer>
  );
}

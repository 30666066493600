import {
  BreadcrumbsToolbar,
  ContentContainer,
  InnerContentContainerAlt,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
} from '@orbiapp/components';
import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { isCompanyProfileAndNotPartialCompanyProfile } from '../../../../models';
import {
  CompanyProfilesSelector,
  OfficeLocationsSelector,
  PerkCategoriesSelector,
  SubjectAreaCategoriesSelector,
  getCompanyProfileThunk,
  getOfficeLocationsThunk,
  getPerkCategoriesThunk,
  getSubjectAreaCategoriesThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { ProfileHeader, ProfileProvider, ProfileSidebar } from '../components';

export function ViewProfile() {
  const params = useParams<{ companyProfileKey: string }>();

  const profile = useSelector((state) =>
    CompanyProfilesSelector.selectById(state, params.companyProfileKey ?? ''),
  );

  const error = useSelector(CompanyProfilesSelector.getCompanyProfileError);
  const perksStatus = useSelector(PerkCategoriesSelector.selectStatus);
  const subjectAreasStatus = useSelector(
    SubjectAreaCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(OfficeLocationsSelector.selectStatus);

  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    dispatch(getPerkCategoriesThunk());
    dispatch(getSubjectAreaCategoriesThunk());
    dispatch(getOfficeLocationsThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (isLoaded) return;

    if (isCompanyProfileAndNotPartialCompanyProfile(profile)) {
      setIsLoaded(true);
      return;
    }

    (async () => {
      if (!params.companyProfileKey) {
        setIsLoaded(true);
        return;
      }

      await dispatch(getCompanyProfileThunk(params.companyProfileKey));

      setIsLoaded(true);
    })();
  }, [dispatch, params.companyProfileKey, isLoaded, profile]);

  if (isLoaded && error) {
    return <Navigate to="/profiles" />;
  }

  if (
    !isLoaded ||
    !profile ||
    isAnyPending(perksStatus, subjectAreasStatus, locationsStatus)
  ) {
    return <LoadingContainer />;
  }

  return (
    <ProfileProvider
      formMode="viewProfile"
      isLoaded={isLoaded}
      profile={profile}
    >
      <PageContainer>
        <BreadcrumbsToolbar
          flexGrow={0.7}
          breadcrumbListItems={[
            { to: '/profiles', tx: 'label.breadcrumbs.profiles.profiles' },
            {
              to: `/profiles/${profile.companyProfileKey}`,
              text: profile.name,
              truncate: true,
            },
          ]}
          tabButtonListItems={[
            {
              to: `/profiles/${profile.companyProfileKey}`,
              tx: 'label.profiles.tab-buttons.profile',
            },
            {
              to: `/profiles/${profile.companyProfileKey}/stats`,
              tx: 'label.profiles.tab-buttons.stats',
            },
          ]}
        />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainerAlt>
              <ProfileHeader />

              <Outlet />
            </InnerContentContainerAlt>
          </ContentContainer>

          <ProfileSidebar />
        </InnerPageContainer>
      </PageContainer>
    </ProfileProvider>
  );
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { officeLocationsAdapter } from './company.adapter';

export namespace CreateCompanySelector {
  export const selectStatus = (state: RootState) =>
    state.company.createCompany.status;
}

export namespace ModuleSettingsSelector {
  const selectSelf = (state: RootState) =>
    state.company.company.data?.moduleSettings;

  export const selectData = selectSelf;

  export const selectEnableProfiles = createSelector(
    selectSelf,
    (moduleSettings) => Boolean(moduleSettings?.companyProfilesModule),
  );
  export const selectEnableJobs = createSelector(selectSelf, (moduleSettings) =>
    Boolean(moduleSettings?.jobsModule),
  );
  export const selectEnableConnect = createSelector(
    selectSelf,
    (moduleSettings) => Boolean(moduleSettings?.connectModule),
  );
  export const selectEnableCompanyAds = createSelector(
    selectSelf,
    (moduleSettings) => Boolean(moduleSettings?.companyAdsModule),
  );
  export const selectEnableOffers = createSelector(
    selectSelf,
    (moduleSettings) => Boolean(moduleSettings?.offersModule),
  );

  export const selectConnectModule = createSelector(
    selectSelf,
    (moduleSettings) => moduleSettings?.connectModule,
  );

  export const selectInsightsTier = createSelector(
    selectSelf,
    (moduleSettings) => moduleSettings?.insightsModule.tier ?? 't1',
  );
}

export namespace OfficeLocationsSelector {
  const selectors = officeLocationsAdapter.getSelectors();

  export const selectOfficeLocationById = createSelector(
    [
      (state: RootState) =>
        selectors.selectAll(state.company.officeLocations.data),
      (_: RootState, officeLocationKey: string) => officeLocationKey,
    ],
    (companyLocations, officeLocationKey) =>
      companyLocations.find(
        (companyLocation) =>
          companyLocation.officeLocationKey === officeLocationKey,
      ),
  );

  export const selectOfficeLocationKeys = createSelector(
    (state: RootState) =>
      selectors.selectAll(state.company.officeLocations.data),
    (officeLocations) =>
      officeLocations.map((officeLocation) => officeLocation.officeLocationKey),
  );

  export const selectOfficeLocations = (state: RootState) =>
    selectors.selectAll(state.company.officeLocations.data);

  export const selectStatus = (state: RootState) =>
    state.company.officeLocations.status;
}

export namespace CompanySelector {
  const selectSelf = (state: RootState) => state.company.company;

  export const selectStatus = createSelector(
    selectSelf,
    (company) => company.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (company) => company.data,
  );

  export const selectHasCompany = createSelector(selectData, (data) =>
    Boolean(data),
  );

  export const selectCompanyName = createSelector(
    selectData,
    (data) => data?.name ?? null,
  );

  export const selectCompanyLogo = createSelector(
    selectData,
    (data) => data?.logo ?? null,
  );

  export const selectCreatedAt = createSelector(
    selectData,
    (data) => data?.createdAt ?? null,
  );

  export const selectCompanyIndustry = createSelector(
    selectData,
    (data) => data?.industry ?? null,
  );

  export const selectCompanyEmployeeCount = createSelector(
    selectData,
    (data) => data?.employeeCount ?? null,
  );

  export const selectCurrency = createSelector(
    selectData,
    (data) => data?.currency ?? null,
  );

  export const selectCompanyTags = createSelector(
    selectData,
    (data) => data?.tags ?? [],
  );

  export const selectFacebookUrl = createSelector(
    selectData,
    (data) => data?.facebookUrl ?? null,
  );

  export const selectInstagramUrl = createSelector(
    selectData,
    (data) => data?.instagramUrl ?? null,
  );

  export const selectLinkedinUrl = createSelector(
    selectData,
    (data) => data?.linkedinUrl ?? null,
  );

  export const selectTiktokUrl = createSelector(
    selectData,
    (data) => data?.tiktokUrl ?? null,
  );

  export const selectTwitterUrl = createSelector(
    selectData,
    (data) => data?.twitterUrl ?? null,
  );

  export const selectYoutubeUrl = createSelector(
    selectData,
    (data) => data?.youtubeUrl ?? null,
  );

  export const selectFollowerCount = createSelector(
    selectData,
    (data) => data?.followerCount ?? null,
  );

  export const selectIndustry = createSelector(
    selectData,
    (data) => data?.industry ?? null,
  );

  export const selectEmployeeCount = createSelector(
    selectData,
    (data) => data?.employeeCount ?? null,
  );

  export const selectCompanyKey = createSelector(
    selectData,
    (data) => data?.companyKey ?? null,
  );
}

export namespace UpdateCompanySelector {
  export const selectStatus = (state: RootState) =>
    state.company.updateCompany.status;
}

import { BoxProps } from '@orbiapp/components';

import { Styled } from './insights-inner-content-container.styled';

export function InsightsInnerContentContainer(props: BoxProps) {
  return (
    <Styled.InsightsInnerContentContainer
      flex
      flexDirection="column"
      flexGrow={1}
      maxWidth="100%"
      mx="auto"
      width={1920}
      {...props}
    />
  );
}

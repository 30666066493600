import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './auth.build';
import { initialAuthState } from './auth.initial';
import { reducers } from './auth.reducers';

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers,
  extraReducers,
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

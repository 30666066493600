import { ContentSidebar, ExpandedQrCodeProvider } from '@orbiapp/components';
import React from 'react';

import { assets } from '../../../../../assets';
import {
  CompanyQrSelector,
  CompanySelector,
  TalentPoolUserSelector,
  connectActions,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { CreateQrCodeSidebar } from './create-qr-code-sidebar';
import { ConnectSidebarContext } from './sidebar.context';
import { ViewQrCodeSidebar } from './view-qr-code-sidebar';
import { ViewUserSidebar } from './view-user-sidebar';

export * from './sidebar.context';

function ContentSidebarContent() {
  const talentPoolUser = useSelector(
    TalentPoolUserSelector.selectTalentPoolUser,
  );
  const talentPoolUserStatus = useSelector(TalentPoolUserSelector.selectStatus);

  const companyQr = useSelector(CompanyQrSelector.selectCompanyQr);
  const companyQrStatus = useSelector(CompanyQrSelector.selectStatus);

  const connectSidebarContext = React.useContext(ConnectSidebarContext);

  if (companyQr || companyQrStatus === 'pending') {
    return <ViewQrCodeSidebar />;
  }

  if (talentPoolUser || talentPoolUserStatus === 'pending') {
    return <ViewUserSidebar />;
  }

  if (connectSidebarContext.isOpen) {
    return <CreateQrCodeSidebar />;
  }

  return null;
}

export function ConnectSidebar() {
  const talentPoolUser = useSelector(
    TalentPoolUserSelector.selectTalentPoolUser,
  );
  const talentPoolUserStatus = useSelector(TalentPoolUserSelector.selectStatus);
  const companyQr = useSelector(CompanyQrSelector.selectCompanyQr);
  const companyQrStatus = useSelector(CompanyQrSelector.selectStatus);
  const connectSidebarContext = React.useContext(ConnectSidebarContext);
  const link = useSelector(CompanyQrSelector.selectLink);
  const companyLogo = useSelector(CompanySelector.selectCompanyLogo);

  const dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch(connectActions.clearTalentPoolUser());
    dispatch(connectActions.clearCompanyQr());

    connectSidebarContext.closeSidebar();
  };

  const isOpen =
    !!talentPoolUser ||
    !!companyQr ||
    connectSidebarContext.isOpen ||
    talentPoolUserStatus === 'pending' ||
    companyQrStatus === 'pending';

  return (
    <ExpandedQrCodeProvider
      value={link ?? ''}
      closeTx="button.close"
      logoURL={companyLogo?.thumbnail1024.url ?? ''}
      footerLogoURL={assets.inAssociationWithOrbi}
    >
      <ContentSidebar onClose={closeSidebar} isOpen={isOpen} width={460}>
        <ContentSidebarContent />
      </ContentSidebar>
    </ExpandedQrCodeProvider>
  );
}

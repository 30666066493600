import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const DuplicateProfilesList = styled(Box)`
  overflow-x: hidden;
  overflow-y: auto;

  li {
    list-style-type: none;

    :first-child,
    :last-child {
      border-radius: 0px;
    }
  }

  ${(props) =>
    css`
      border: 1px solid ${props.theme.colors.duplicateProfileModalListBorder};
      border-radius: ${props.theme.borderRadii.s}px;
    `}
`;

const SelectProfileTypeCard = styled(Box)<{ toggled: boolean }>`
  border-radius: 16px;
  cursor: pointer;

  ${(props) =>
    css`
      ${props.toggled
        ? css`
            border: 2px solid ${props.theme.colors.selectionCardBorderToggled};
            background-color: ${props.theme.colors
              .selectionCardBackgroundToggled};
          `
        : css`
            border: 2px solid ${props.theme.colors.selectionCardBorder};
            background-color: ${props.theme.colors.selectionCardBackground};
          `}
    `}
`;

export const Styled = { DuplicateProfilesList, SelectProfileTypeCard };

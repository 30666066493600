import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeaderRow,
  Radio,
  Text,
  WrapBox,
} from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import {
  BudgetType,
  JOB_MAX_DAILY_BUDGET,
  JOB_MAX_TOTAL_BUDGET,
  JOB_MIN_DAILY_BUDGET,
  JOB_MIN_TOTAL_BUDGET,
  UpdateJobForm,
} from '../../../../../models';
import { JobSelector, useSelector } from '../../../../../store';

export function Budget() {
  const canEditJob = useSelector(JobSelector.selectCanEditJob);
  const jobTotalBudget = useSelector(JobSelector.selectTotalBudget);
  const jobDailyBudget = useSelector(JobSelector.selectDailyBudget);
  const jobBudgetType = useSelector(JobSelector.selectBudgetType);

  const formContext = useFormContext<UpdateJobForm>();

  const budgetData = formContext.watch('budgetData');

  const handleRadioClick = (budgetType: BudgetType) => () => {
    const options = {
      shouldValidate: formContext.formState.isSubmitted,
      shouldDirty: true,
    };

    formContext.setValue('budgetData.budgetType', budgetType);

    const budget = formContext.getValues();

    switch (budgetType) {
      case 'both':
        formContext.setValue(
          'budgetData.dailyBudget',
          budget.budgetData.dailyBudget ?? JOB_MIN_DAILY_BUDGET,
          options,
        );
        formContext.setValue(
          'budgetData.totalBudget',
          budget.budgetData.totalBudget ?? JOB_MIN_TOTAL_BUDGET,
          options,
        );
        break;
      case 'daily':
        formContext.setValue('budgetData.totalBudget', null, options);
        formContext.setValue(
          'budgetData.dailyBudget',
          budget.budgetData.dailyBudget ?? JOB_MIN_DAILY_BUDGET,
          options,
        );
        break;
      case 'total':
        formContext.setValue('budgetData.dailyBudget', null, options);
        formContext.setValue(
          'budgetData.totalBudget',
          budget.budgetData.totalBudget ?? JOB_MIN_TOTAL_BUDGET,
          options,
        );
        break;
    }
  };

  return (
    <FormSection>
      <FormSectionHeaderRow>
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.job-form.steps.7.title"
            variant="bodyMdBold"
            color="formSectionHeader"
          />
          <Text
            color="formSectionDescription"
            tx="label.job-form.steps.7.subtitle"
            variant="bodyMd"
          />
        </Box>
      </FormSectionHeaderRow>

      <Box gap={24} flex>
        <Radio
          checked={budgetData.budgetType === 'both'}
          dotPlacement="left"
          onChange={handleRadioClick('both')}
          tx="label.budget-type.both"
          disabled={jobBudgetType !== 'both' || !canEditJob}
        />

        <Radio
          checked={budgetData.budgetType === 'total'}
          dotPlacement="left"
          onChange={handleRadioClick('total')}
          tx="label.budget-type.total"
          disabled={jobBudgetType === 'daily' || !canEditJob}
        />

        <Radio
          checked={budgetData.budgetType === 'daily'}
          dotPlacement="left"
          onChange={handleRadioClick('daily')}
          tx="label.budget-type.daily"
          disabled={jobBudgetType === 'total' || !canEditJob}
        />
      </Box>

      <WrapBox breakpoint="sm" gap={32} flex>
        <ControlledInput
          disabled={budgetData.budgetType === 'daily' || !canEditJob}
          name="budgetData.totalBudget"
          labelTx="label.job-form.total-budget"
          deps={['budgetType.dailyBudget']}
          errorTxArgs={{
            minTotalBudget: jobTotalBudget ?? JOB_MIN_TOTAL_BUDGET,
            maxTotalBudget: JOB_MAX_DAILY_BUDGET,
          }}
          helperTx="label.job-form.total-budget-description"
        />

        <ControlledInput
          disabled={budgetData.budgetType === 'total' || !canEditJob}
          name="budgetData.dailyBudget"
          deps={['budgetType.totalBudget']}
          labelTx="label.job-form.daily-budget"
          helperTx="label.job-form.daily-budget-description"
          errorTxArgs={{
            minDailyBudget: jobDailyBudget ?? JOB_MIN_DAILY_BUDGET,
            maxDailyBudget: budgetData.totalBudget
              ? Math.min(budgetData.totalBudget, JOB_MAX_TOTAL_BUDGET)
              : JOB_MAX_TOTAL_BUDGET,
          }}
        />
      </WrapBox>
    </FormSection>
  );
}

import { createEntityAdapter } from '@reduxjs/toolkit';

import { PendingCompanyInvitation, User } from '../../models';

export const usersAdapter = createEntityAdapter<User, string>({
  selectId: (user) => user.userKey,
});

export const pendingInvitationsAdapter = createEntityAdapter<
  PendingCompanyInvitation,
  string
>({
  selectId: (invitation) => invitation.companyInvitationKey,
});

import { getSubjectAreaCategoriesThunk } from './subject-areas.actions';
import {
  subjectAreaAdapter,
  subjectAreaCategoriesAdapter,
} from './subject-areas.adapter';
import { SubjectAreasBuilder } from './subject-areas.types';

const getSubjectAreaCategoriesBuild = (builder: SubjectAreasBuilder) => {
  return builder
    .addCase(getSubjectAreaCategoriesThunk.fulfilled, (state, action) => {
      subjectAreaCategoriesAdapter.upsertMany(
        state.subjectAreaCategoriesState,
        action.payload,
      );

      const subjectAreas = action.payload
        .map((subjectAreaCategory) => subjectAreaCategory.subjectAreas)
        .flat();

      subjectAreaAdapter.upsertMany(state.subjectAreaState, subjectAreas);

      state.getSubjectAreaCategoriesStatus.status = 'idle';
    })
    .addCase(getSubjectAreaCategoriesThunk.pending, (state) => {
      state.getSubjectAreaCategoriesStatus.status = 'pending';
      state.getSubjectAreaCategoriesStatus.error = null;
    })
    .addCase(getSubjectAreaCategoriesThunk.rejected, (state, action) => {
      state.getSubjectAreaCategoriesStatus.status = 'idle';
      state.getSubjectAreaCategoriesStatus.error = action.error;
    });
};

export const extraReducers = (builder: SubjectAreasBuilder) => {
  getSubjectAreaCategoriesBuild(builder);
};

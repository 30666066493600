import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Radio,
  Text,
  WrapBox,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  BudgetType,
  CreateJobForm,
  JOB_MAX_TOTAL_BUDGET,
  JOB_MIN_DAILY_BUDGET,
  JOB_MIN_TOTAL_BUDGET,
  UpdateJobDraftSchema,
} from '../../../../../models';
import { useSaveField } from '../create-job.helpers';

function _Budget() {
  const formMethods = useFormContext<CreateJobForm>();

  const saveBudget = useSaveField('budgetData', {
    schema: UpdateJobDraftSchema.budgetData,
    triggerNames: ['budgetData.dailyBudget', 'budgetData.totalBudget'],
    defaultValue: formMethods.getValues('budgetData'),
  });

  const [totalBudget, budgetType] = useWatch({
    control: formMethods.control,
    name: ['budgetData.totalBudget', 'budgetData.budgetType'],
  });

  const handleRadioClick = (budgetType: BudgetType) => () => {
    const options = {
      shouldValidate: formMethods.formState.isSubmitted,
      shouldDirty: true,
    };

    formMethods.setValue('budgetData.budgetType', budgetType, options);

    const budget = formMethods.getValues('budgetData');

    switch (budgetType) {
      case 'both':
        formMethods.setValue(
          'budgetData.dailyBudget',
          budget.dailyBudget ?? JOB_MIN_DAILY_BUDGET,
          options,
        );
        formMethods.setValue(
          'budgetData.totalBudget',
          budget.totalBudget ?? JOB_MIN_TOTAL_BUDGET,
          options,
        );
        break;
      case 'daily':
        formMethods.setValue('budgetData.totalBudget', null, options);
        formMethods.setValue(
          'budgetData.dailyBudget',
          budget.dailyBudget ?? JOB_MIN_DAILY_BUDGET,
          options,
        );
        break;
      case 'total':
        formMethods.setValue('budgetData.dailyBudget', null, options);
        formMethods.setValue(
          'budgetData.totalBudget',
          budget.totalBudget ?? JOB_MIN_TOTAL_BUDGET,
          options,
        );
        break;
    }

    saveBudget();
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.7.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.7.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <Box gap={24} flex>
        <Radio
          checked={budgetType === 'both'}
          dotPlacement="left"
          onChange={handleRadioClick('both')}
          tx="label.budget-type.both"
        />

        <Radio
          checked={budgetType === 'total'}
          dotPlacement="left"
          onChange={handleRadioClick('total')}
          tx="label.budget-type.total"
        />

        <Radio
          checked={budgetType === 'daily'}
          dotPlacement="left"
          onChange={handleRadioClick('daily')}
          tx="label.budget-type.daily"
        />
      </Box>

      <WrapBox breakpoint="sm" gap={32} flex>
        <ControlledInput
          onBlur={saveBudget}
          disabled={budgetType === 'daily'}
          name="budgetData.totalBudget"
          labelTx="label.job-form.total-budget"
          errorTxArgs={{
            minTotalBudget: JOB_MIN_TOTAL_BUDGET,
            maxTotalBudget: JOB_MAX_TOTAL_BUDGET,
          }}
          helperTx="label.job-form.total-budget-description"
          deps={['budgetData.dailyBudget']}
        />

        <ControlledInput
          onBlur={saveBudget}
          deps={['budgetData.totalBudget']}
          disabled={budgetType === 'total'}
          name="budgetData.dailyBudget"
          helperTx="label.job-form.daily-budget-description"
          labelTx="label.job-form.daily-budget"
          errorTxArgs={{
            minDailyBudget: JOB_MIN_DAILY_BUDGET,
            maxDailyBudget: totalBudget
              ? Math.min(totalBudget, JOB_MAX_TOTAL_BUDGET)
              : JOB_MAX_TOTAL_BUDGET,
          }}
        />
      </WrapBox>
    </FormSection>
  );
}

_Budget.displayName = 'Budget';
export const Budget = React.memo(_Budget);

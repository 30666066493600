import {
  Box,
  ChartGrid,
  ChartGridItem,
  LineChart,
  Link,
  useXLabelMaxTicksLimit,
} from '@orbiapp/components';

import { BuyBoosterCard } from '../../../../../components/insights';
import {
  InsightsTimeSeriesConfigSelector,
  InsightsTimeSeriesItemsSelector,
  InsightsTimeSeriesSelector,
  useSelector,
} from '../../../../../store';
import {
  getInsightsMetricDescriptionTx,
  getLabelsAndDatasets,
  isAnyPending,
} from '../../../../../utils';

function ViewsChart() {
  const viewsData = useSelector(InsightsTimeSeriesSelector.selectViews);
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const insightsDataStatus = useSelector(
    InsightsTimeSeriesSelector.selectStatus,
  );
  const insightsItemsStatus = useSelector(
    InsightsTimeSeriesItemsSelector.selectStatus,
  );

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    viewsData,
    insightsDataRange,
  );

  return (
    <LineChart
      legendPosition="bottom"
      isLoading={isAnyPending(insightsDataStatus, insightsItemsStatus)}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="100%"
      descriptionTx={getInsightsMetricDescriptionTx('views')}
      footerElement={
        <Box flex flexJustify="end">
          <Link
            to="/insights/views"
            tx="link.general.expand"
            variant="secondary"
          />
        </Box>
      }
      titleTx="label.insights.views"
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
      placeholderTitleTx="label.insights.no-data-available"
      placeholderSubtitleTx="label.insights.try-updating-date-range"
    />
  );
}

function EngagementChart() {
  const engagementData = useSelector(
    InsightsTimeSeriesSelector.selectEngagement,
  );
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const insightsDataStatus = useSelector(
    InsightsTimeSeriesSelector.selectStatus,
  );
  const insightsItemsStatus = useSelector(
    InsightsTimeSeriesItemsSelector.selectStatus,
  );

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    engagementData,
    insightsDataRange,
  );

  return (
    <LineChart
      legendPosition="bottom"
      isLoading={isAnyPending(insightsDataStatus, insightsItemsStatus)}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="100%"
      footerElement={
        <Box flex flexJustify="end">
          <Link
            to="/insights/engagement"
            tx="link.general.expand"
            variant="secondary"
          />
        </Box>
      }
      titleTx="label.insights.engagement"
      descriptionTx={getInsightsMetricDescriptionTx('engagement')}
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
      placeholderTitleTx="label.insights.no-data-available"
      placeholderSubtitleTx="label.insights.try-updating-date-range"
    />
  );
}

function ImpressionsChart() {
  const impressionsData = useSelector(
    InsightsTimeSeriesSelector.selectImpressions,
  );
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const insightsDataStatus = useSelector(
    InsightsTimeSeriesSelector.selectStatus,
  );
  const insightsItemsStatus = useSelector(
    InsightsTimeSeriesItemsSelector.selectStatus,
  );

  const xLabelsMaxTicksLimit = useXLabelMaxTicksLimit();

  const { datasets, labels, comparedLabels } = getLabelsAndDatasets(
    impressionsData,
    insightsDataRange,
  );

  return (
    <LineChart
      legendPosition="bottom"
      isLoading={isAnyPending(insightsDataStatus, insightsItemsStatus)}
      xLabelMaxTicksLimit={xLabelsMaxTicksLimit}
      height="100%"
      footerElement={
        <Box flex flexJustify="end">
          <Link
            to="/insights/impressions"
            tx="link.general.expand"
            variant="secondary"
          />
        </Box>
      }
      titleTx="label.insights.impressions"
      descriptionTx={getInsightsMetricDescriptionTx('impressions')}
      datasets={datasets}
      labels={labels}
      comparedLabels={comparedLabels}
      placeholderTitleTx="label.insights.no-data-available"
      placeholderSubtitleTx="label.insights.try-updating-date-range"
    />
  );
}

export function Charts() {
  return (
    <ChartGrid>
      <ChartGridItem>
        <ViewsChart />
      </ChartGridItem>

      <ChartGridItem>
        <EngagementChart />
      </ChartGridItem>

      <ChartGridItem>
        <ImpressionsChart />
      </ChartGridItem>

      <ChartGridItem>
        <BuyBoosterCard />
      </ChartGridItem>
    </ChartGrid>
  );
}

import {
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  ContentContainer,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
  useCheckFeatureFlag,
} from '@orbiapp/components';
import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { JOB_MAX_TOTAL_BUDGET } from '../../../../models';
import {
  CompanySelector,
  CreateJobDraftSelector,
  DuplicateJobSelector,
  JobDraftSelector,
  OfficeLocationsSelector,
  SubjectAreaCategoriesSelector,
  createJobDraftThunk,
  duplicateJobThunk,
  getDraftThunk,
  getOfficeLocationsThunk,
  getSubjectAreaCategoriesThunk,
  jobsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { JobForm } from './create-job-form';

const CREATE_JOB_BREADCRUMBS: BreadcrumbListItem[] = [
  {
    to: '/jobs',
    tx: 'label.breadcrumbs.jobs.jobs',
  },
  {
    to: '/jobs/create-job',
    tx: 'label.breadcrumbs.jobs.create-job',
  },
];

export function CreateJobFromDraft() {
  const { jobDraftKey } = useParams<{ jobDraftKey: string }>();

  const dispatch = useDispatch();

  const getJobDraftError = useSelector(JobDraftSelector.selectError);
  const getJobDraftStatus = useSelector(JobDraftSelector.selectStatus);
  const companyStatus = useSelector(CompanySelector.selectStatus);
  const subjectAreaCategoriesStatus = useSelector(
    SubjectAreaCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(OfficeLocationsSelector.selectStatus);
  const defaultValues = useSelector(JobDraftSelector.selectDefaultValues);

  const enableJobBudget = useCheckFeatureFlag(
    'company_dashboard_enable_job_budget',
  );

  React.useEffect(() => {
    dispatch(getOfficeLocationsThunk());
    dispatch(getSubjectAreaCategoriesThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (!jobDraftKey) return;

    dispatch(getDraftThunk(jobDraftKey));

    return () => {
      dispatch(jobsActions.resetCreateJobForm());
    };
  }, [jobDraftKey, dispatch]);

  if (getJobDraftError) {
    return <Navigate to="/jobs" />;
  }

  if (
    !jobDraftKey ||
    !defaultValues ||
    isAnyPending(
      getJobDraftStatus,
      companyStatus,
      locationsStatus,
      subjectAreaCategoriesStatus,
    )
  ) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={CREATE_JOB_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <JobForm
            defaultValues={
              enableJobBudget
                ? defaultValues
                : {
                    ...defaultValues,
                    budgetData: {
                      budgetType: 'total',
                      dailyBudget: null,
                      totalBudget: JOB_MAX_TOTAL_BUDGET,
                    },
                  }
            }
          />
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

export function DuplicateJob() {
  const params = useParams<{ jobKey: string }>();
  const location = useLocation();

  const duplicateJobStatus = useSelector(DuplicateJobSelector.selectStatus);
  const duplicateJobError = useSelector(DuplicateJobSelector.selectError);
  const jobDraftKey = useSelector(JobDraftSelector.selectJobDraftKey);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!params.jobKey) return;

    dispatch(duplicateJobThunk(location.state?.job));
  }, [params.jobKey, location.state, dispatch]);

  if (duplicateJobError || !params.jobKey) {
    return <Navigate replace to="/jobs" />;
  }

  if (duplicateJobStatus === 'pending' || !jobDraftKey) {
    return <LoadingContainer />;
  }

  return <Navigate replace to={`/jobs/create-job/${jobDraftKey}`} />;
}

export function CreateJob() {
  const createJobDraftStatus = useSelector(CreateJobDraftSelector.selectStatus);
  const createJobDraftError = useSelector(CreateJobDraftSelector.selectError);
  const jobDraftKey = useSelector(JobDraftSelector.selectJobDraftKey);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(createJobDraftThunk());
  }, [dispatch]);

  if (createJobDraftError) {
    return <Navigate to="/jobs" />;
  }

  if (createJobDraftStatus === 'pending' || !jobDraftKey) {
    return <LoadingContainer />;
  }

  return <Navigate to={`/jobs/create-job/${jobDraftKey}`} />;
}

import {
  Box,
  ContentSidebarContentContainer,
  ContentSidebarFixed,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Preview, ToggleButton } from '../../preview';
import { ProfileContext } from '../profile-form';

export function ProfileSidebar() {
  const { sidebarIsOpen, closeSidebar, openSidebar } =
    React.useContext(ProfileContext);

  const location = useLocation();

  if (location.pathname.includes('/stats')) {
    return null;
  }

  return (
    <React.Fragment>
      <ToggleButton
        expanded={sidebarIsOpen}
        fixed
        right={sidebarIsOpen ? 460 : 0}
        onClick={sidebarIsOpen ? closeSidebar : openSidebar}
      />

      <ContentSidebarFixed
        isOpen={sidebarIsOpen}
        onClose={closeSidebar}
        width={460}
        backgroundColor="formContainerBackground"
        relative
      >
        <ContentSidebarContentContainer px={0} py={0} pb={64}>
          <Box
            flex
            flexDirection="column"
            minHeight={124}
            backgroundColor="formHeaderBackground"
            p={24}
            flexJustify="center"
          >
            <Box px={16}>
              <Text variant="bodyLgBold" tx="label.profiles.preview.title" />
              <Text variant="bodyMd" tx="label.profiles.preview.description" />
            </Box>
          </Box>

          <Box width="100%" flex flexJustify="center">
            <Preview />
          </Box>
        </ContentSidebarContentContainer>
      </ContentSidebarFixed>
    </React.Fragment>
  );
}

import { PayloadAction } from '@reduxjs/toolkit';

import { initialAuthState } from './auth.initial';
import { AuthState, AuthStatePayload } from './auth.types';

function reset(state: AuthState) {
  Object.assign(state, initialAuthState);
}

function setAuthState(
  state: AuthState,
  action: PayloadAction<Partial<AuthStatePayload>>,
) {
  state.authState.data = {
    ...state.authState.data,
    ...action.payload,
  };
}

function setIsEmulating(state: AuthState, action: PayloadAction<boolean>) {
  state.authState.data.isEmulating = action.payload;
}

export const reducers = {
  reset,

  setAuthState,
  setIsEmulating,
};

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { pendingInvitationsAdapter, usersAdapter } from './team.adapter';

export namespace CreateInvitationSelector {
  export const selectStatus = (state: RootState) =>
    state.team.createInvitation.status;

  export const selectData = (state: RootState) =>
    state.team.createInvitation.data;
}

export namespace CreateInvitationsSelector {
  export const selectStatus = (state: RootState) =>
    state.team.createInvitations.status;
}

export namespace DeleteInvitationSelector {
  export const selectStatus = (state: RootState) =>
    state.team.deleteInvitation.status;
}

export namespace CompanyInvitationSelector {
  export const selectStatus = (state: RootState) =>
    state.team.companyInvitation.status;

  export const selectData = (state: RootState) =>
    state.team.companyInvitation.data;

  export const selectError = (state: RootState) =>
    state.team.companyInvitation.error;

  export const selectIsExpired = createSelector(
    [selectData],
    (data) => data && Date.now() > data.expiresAt,
  );

  export const selectIsConsumed = createSelector(
    [selectData],
    (data) => data && data.consumedAt,
  );

  export const selectCompanyDetails = createSelector(
    [selectData],
    (data) => data?.companyDetails ?? null,
  );

  export const selectSignUpByInvitationContent = createSelector(
    [
      selectCompanyDetails,
      selectIsConsumed,
      selectIsExpired,
      selectStatus,
      selectError,
    ],
    (companyDetails, isConsumed, isExpired, teamInvitationStatus, error) => ({
      companyDetails,
      isConsumed: Boolean(isConsumed),
      isExpired,
      teamInvitationStatus,
      error,
    }),
  );
}

export namespace PendingInvitationsSelector {
  const selectors = pendingInvitationsAdapter.getSelectors(
    (state: RootState) => state.team.pendingInvitations.data,
  );

  export const selectStatus = (state: RootState) =>
    state.team.pendingInvitations.status;

  export const selectAll = selectors.selectAll;

  export const selectAllEmails = createSelector([selectAll], (pending) =>
    pending.map((invite) => invite.email),
  );
}

export namespace UsersSelector {
  const selectors = usersAdapter.getSelectors(
    (state: RootState) => state.team.users.data,
  );

  export const selectStatus = (state: RootState) => state.team.users.status;

  export const selectAll = selectors.selectAll;

  export const selectCount = selectors.selectTotal;

  export const selectAllEmails = createSelector(selectAll, (users) =>
    users.map((user) => user.email),
  );
}

export namespace RemoveUserSelector {
  export const selectStatus = (state: RootState) =>
    state.team.removeUser.status;
}

import {
  Avatar,
  Box,
  Button,
  Confirm,
  Icon,
  IconButton,
  Status,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
  translate,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DraftUpdatedAgoText, PublishButton } from '../../../../../components';
import { CreateJobForm } from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  CompanyProfilesSelector,
  CreateJobSelector,
  DeleteJobDraftSelector,
  JobDraftSelector,
  createJobThunk,
  deleteJobDraftThunk,
  globalUiStateActions,
  setAlert,
  useDispatch,
  useSelector,
} from '../../../../../store';

function _JobFormHeader() {
  const editMeta = useSelector(JobDraftSelector.selectEditMeta);
  const deleteJobDraftStatus = useSelector(DeleteJobDraftSelector.selectStatus);
  const jobDraftKey = useSelector(JobDraftSelector.selectJobDraftKey);
  const updatedAt = useSelector(JobDraftSelector.selectUpdatedAt);
  const createJobStatus = useSelector(CreateJobSelector.selectStatus);
  const hasProfiles = useSelector(CompanyProfilesSelector.selectHasProfiles);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirmState = useConfirm();

  const fullName = editMeta?.createdBy
    ? `${editMeta.createdBy.firstName} ${editMeta.createdBy?.lastName}`
    : translate('placeholder.unknown-user');

  const deleteJobDraft = async () => {
    if (!jobDraftKey) return;

    await dispatch(deleteJobDraftThunk(jobDraftKey));

    confirmState.closeConfirm();

    navigate('/jobs');
  };

  const publishJob = async (createJobForm: CreateJobForm) => {
    dispatch(globalUiStateActions.clearAlert());

    const res = await dispatch(createJobThunk(createJobForm));
    if (res.payload && 'jobKey' in res.payload) {
      navigate(`/jobs/${res.payload.jobKey}`, { replace: true });
    }
  };

  const handleError = (err: FieldErrors<CreateJobForm>) => {
    Logger.warning('createJob Validation', {
      err: flattenFieldErrorsObject(err),
    });

    dispatch(setAlert('error-in-form'));
  };

  return (
    <React.Fragment>
      <Confirm
        cancelTx="prompt.delete-job-draft.cancel"
        confirmTx="prompt.delete-job-draft.confirm"
        isLoading={deleteJobDraftStatus === 'pending'}
        isOpen={confirmState.isOpen}
        messageTx="prompt.delete-job-draft.message"
        onCancel={confirmState.closeConfirm}
        onConfirm={deleteJobDraft}
        titleTx="prompt.delete-job-draft.title"
      />

      <Box
        backgroundColor="formHeaderBackground"
        flex
        flexDirection="column"
        gap={12}
        py={16}
        px={32}
        width="100%"
      >
        <Box
          flex
          flexAlign="center"
          flexJustify="between"
          gap={24}
          width="100%"
          flexWrap
        >
          <Box flexGrow={1} gap={8} flex flexDirection="column">
            <Box gap={8} flexAlign="center" flex>
              <Tooltip placement="bottom" titleTx="label.tooltip.go-back">
                <IconButton to="/jobs" icon="chevron-left" />
              </Tooltip>

              <Text
                color="pageTitle"
                variant="titleMd"
                tx="title.jobs.create-job"
                as="h1"
              />
            </Box>
          </Box>

          <Box flexWrap flexAlign="center" gap={24} flex>
            <DraftUpdatedAgoText updatedAt={updatedAt} />
            <Button
              variant="destructive"
              tx="label.tooltip.delete"
              onClick={confirmState.openConfirm}
              icon="trash-outline"
            />
            {hasProfiles ? (
              <PublishButton
                onError={handleError}
                onSubmit={publishJob}
                isLoading={createJobStatus === 'pending'}
              />
            ) : (
              <Tooltip placement="left" titleTx="label.job-form.no-profiles">
                <Button
                  disabled
                  iconPlacement="right"
                  icon="chevron-down"
                  tx="button.publish"
                  variant="primary"
                />
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box gap={16} flexWrap flex flexJustify="between">
          <Box flexAlign="center" gap={16} flex>
            <Icon
              color="formHeaderEditedByMetaIcon"
              name="user-circle-outline"
            />
            <Text
              color="formHeaderEditedByMetaTitle"
              tx="label.job-form.created-by"
              variant="bodySm"
            />

            <Box flexAlign="center" gap={8} flex>
              <Avatar
                width={32}
                height={32}
                fallbackLetter={editMeta?.createdBy?.firstName[0]}
                src={editMeta?.createdBy?.profilePicture?.thumbnail64.url}
                variant={
                  editMeta?.updatedBy?.userKey
                    ? getAvatarVariantFromString(editMeta.updatedBy.userKey)
                    : undefined
                }
              />

              <Text
                color="formHeaderEditedByMeta"
                text={fullName}
                textTransform="capitalize"
                variant="bodySm"
              />
            </Box>
          </Box>

          <Status ml="auto" variant="warning" tx="label.job-status.draft" />
        </Box>
      </Box>
    </React.Fragment>
  );
}

_JobFormHeader.displayName = 'JobFormHeader';
export const JobFormHeader = React.memo(_JobFormHeader);

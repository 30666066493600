import {
  Image,
  Link,
  Toolbar,
  ToolbarContentContainer,
} from '@orbiapp/components';

import { assets } from '../../../assets';

export function SignedOutToolbar() {
  return (
    <Toolbar backgroundColor="toolbarBackground">
      <ToolbarContentContainer py={8} flexJustify="between" flexAlign="center">
        <Link to="/sign-in" plain>
          <Image alt="" src={assets.orbiLogoOrange} />
        </Link>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

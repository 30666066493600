import {
  getAuthAccountThunk,
  getCustomSignInTokenThunk,
  resetPasswordThunk,
  sendEmailVerificationThunk,
  signInThunk,
  signInWithCustomTokenThunk,
  signOutThunk,
} from './auth.actions';
import { AuthBuilder } from './auth.types';

const resetPasswordBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(resetPasswordThunk.fulfilled, (state) => {
      state.authState.status = 'idle';
    })
    .addCase(resetPasswordThunk.pending, (state) => {
      state.authState.status = 'pending';
      state.authState.error = null;
    })
    .addCase(resetPasswordThunk.rejected, (state, action) => {
      state.authState.status = 'idle';
      state.authState.error = action.error;
    });
};

const signInBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(signInThunk.fulfilled, (state) => {
      state.authState.status = 'idle';
    })
    .addCase(signInThunk.pending, (state) => {
      state.authState.status = 'pending';
      state.authState.error = null;
    })
    .addCase(signInThunk.rejected, (state, action) => {
      state.authState.status = 'idle';
      state.authState.error = action.error;
    });
};

const signInWithCustomTokenBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(signInWithCustomTokenThunk.fulfilled, (state) => {
      state.authState.status = 'idle';
    })
    .addCase(signInWithCustomTokenThunk.pending, (state) => {
      state.authState.status = 'pending';
      state.authState.error = null;
    })
    .addCase(signInWithCustomTokenThunk.rejected, (state, action) => {
      state.authState.status = 'idle';
      state.authState.error = action.error;
    });
};

const signOutBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(signOutThunk.fulfilled, (state) => {
      state.authState.status = 'idle';
      state.authState.data = {
        authenticated: false,
        initializing: false,
        isEmulating: false,
        accountVerified: false,
      };
    })
    .addCase(signOutThunk.pending, (state) => {
      state.authState.data.initializing = true;
      state.authState.error = null;
      state.authState.status = 'pending';
    })
    .addCase(signOutThunk.rejected, (state, action) => {
      state.authState.data.initializing = false;
      state.authState.error = action.error;
      state.authState.status = 'idle';
    });
};

const sendEmailVerificationBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(sendEmailVerificationThunk.fulfilled, (state) => {
      state.authState.status = 'idle';
    })
    .addCase(sendEmailVerificationThunk.pending, (state) => {
      state.authState.status = 'pending';
      state.authState.error = null;
    })
    .addCase(sendEmailVerificationThunk.rejected, (state, action) => {
      state.authState.status = 'idle';
      state.authState.error = action.error;
    });
};

const getCustomSignInTokenBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(getCustomSignInTokenThunk.fulfilled, (state) => {
      state.customSignInToken.status = 'idle';
    })
    .addCase(getCustomSignInTokenThunk.pending, (state) => {
      state.customSignInToken.status = 'pending';
      state.customSignInToken.error = null;
    })
    .addCase(getCustomSignInTokenThunk.rejected, (state, action) => {
      state.customSignInToken.status = 'idle';
      state.customSignInToken.error = action.error;
    });
};

const getAuthAccountBuild = (builder: AuthBuilder) => {
  return builder
    .addCase(getAuthAccountThunk.fulfilled, (state, action) => {
      state.authAccount.status = 'completed';
      state.authAccount.data = action.payload;
    })
    .addCase(getAuthAccountThunk.pending, (state) => {
      state.authAccount.status = 'pending';
      state.authAccount.error = null;
    })
    .addCase(getAuthAccountThunk.rejected, (state, action) => {
      state.authAccount.status = 'idle';
      state.authAccount.error = action.error;
    });
};

export const extraReducers = (builder: AuthBuilder) => {
  signInBuild(builder);
  signInWithCustomTokenBuild(builder);
  signOutBuild(builder);
  resetPasswordBuild(builder);
  sendEmailVerificationBuild(builder);
  getCustomSignInTokenBuild(builder);
  getAuthAccountBuild(builder);
};

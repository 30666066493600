import { createEntityAdapter } from '@reduxjs/toolkit';

import { PartialJob, PartialJobDraft } from '../../models';

export const partialJobDraftsAdapter = createEntityAdapter<
  PartialJobDraft,
  string
>({
  selectId: (partialJobDraft) => partialJobDraft.jobDraftKey,
});

export const partialJobsAdapter = createEntityAdapter<PartialJob, string>({
  selectId: (partialJob) => partialJob.jobKey,
});

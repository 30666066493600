import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './jobs.build';
import { initialJobsState } from './jobs.initial';
import { reducers } from './jobs.reducers';

const slice = createSlice({
  name: 'jobs',
  initialState: initialJobsState,
  reducers,
  extraReducers,
});

export const jobsActions = slice.actions;
export const jobsReducer = slice.reducer;

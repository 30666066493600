import {
  Dataset,
  isNumber,
  newDayjs,
  stringEllipsis,
} from '@orbiapp/components';
import dayjs from 'dayjs';

import {
  InsightsMetric,
  InsightsRange,
  TimeSeriesInsightsItem,
  TimeSeriesInsightsItems,
} from '../../models';
import {
  GetCustomInsightsPoints,
  GetInsightsPointsParams,
  GetPredefinedInsightsPoints,
} from './insights.types';

function getXUnitsUntil(
  until: dayjs.Dayjs,
  unitCount: number,
  unit: dayjs.QUnitType,
) {
  const lastXUnits: dayjs.Dayjs[] = [];

  const startOfNextPeriod = until.add(1, unit).startOf(unit);

  for (let i = 0; i <= unitCount; i++) {
    lastXUnits.push(startOfNextPeriod.subtract(i, unit));
  }

  return lastXUnits.toReversed();
}

export function getInsightDates(params: GetPredefinedInsightsPoints) {
  switch (params.interval) {
    case 'last_30_days':
      return getXUnitsUntil(params.dayjs, 30, 'day');

    case 'last_365_days':
      return getXUnitsUntil(params.dayjs, 365, 'day');

    case 'last_7_days':
      return getXUnitsUntil(params.dayjs, 7, 'day');

    case 'last_90_days':
      return getXUnitsUntil(params.dayjs, 90, 'day');

    case 'previous_month':
      const previousMonth = params.dayjs.subtract(1, 'month');
      const endOfPreviousMonth = previousMonth.endOf('month');

      return getXUnitsUntil(
        endOfPreviousMonth,
        previousMonth.daysInMonth(),
        'day',
      );

    case 'previous_quarter':
      const previousQuarter = params.dayjs.subtract(1, 'quarter');

      const startOfPreviousQuarter = previousQuarter.startOf('quarter');
      const endOfPreviousQuarter = previousQuarter.endOf('quarter');

      const daysInPreviousQuarter =
        1 + endOfPreviousQuarter.diff(startOfPreviousQuarter, 'days');

      return getXUnitsUntil(endOfPreviousQuarter, daysInPreviousQuarter, 'day');

    case 'previous_year':
      const previousYear = params.dayjs.subtract(1, 'year');

      const endOfPreviousYear = previousYear.endOf('year').endOf('month');

      return getXUnitsUntil(endOfPreviousYear, 12, 'month');

    case 'today':
      const startOfDay = params.dayjs.startOf('day');
      const endOfDay = params.dayjs.add(1, 'day').startOf('day');
      const diffHours = endOfDay.diff(startOfDay, 'hours');

      const today = [];

      for (let i = 1; i < diffHours; i++) {
        today.push(startOfDay.add(i, 'hour'));
      }

      return [
        startOfDay,
        ...today,
        endOfDay,
        endOfDay.add(1, 'hour').startOf('hour'),
      ];
  }
}

export function getInsightsMetricDescriptionTx(metric: InsightsMetric) {
  switch (metric) {
    case 'impressions':
      return 'label.insights.total-impressions-description';

    case 'engagement':
      return 'label.insights.total-engagements-description';

    case 'views':
      return 'label.insights.total-views-description';
  }
}

export function getProductLabelTx(
  type: TimeSeriesInsightsItem['type'],
): TxString {
  switch (type) {
    case 'company_profile':
      return 'label.product-type.profile';

    case 'job':
      return 'label.product-type.job';

    case 'company_ad':
      return 'label.product-type.booster';

    case 'offer':
      return 'label.product-type.offer';

    default:
      return 'label.product-type.total';
  }
}

export function getInsightsMetricTx(insightsMetric: InsightsMetric): TxString {
  switch (insightsMetric) {
    case 'engagement':
      return 'label.insights.engagement';

    case 'impressions':
      return 'label.insights.impressions';

    case 'views':
      return 'label.insights.views';
  }
}

function getCustomInsightDates(params: GetCustomInsightsPoints): dayjs.Dayjs[] {
  const diffDays = params.to.diff(params.from, 'days');

  if (diffDays === 0) {
    return getXUnitsUntil(params.to.endOf('day'), 24, 'hour');
  }

  return getXUnitsUntil(params.to, 1 + diffDays, 'day');
}

function getCompareInsightsDayjsInstance(
  params: GetPredefinedInsightsPoints,
): dayjs.Dayjs {
  if (params.compare === 'previous_year') {
    return params.dayjs.subtract(1, 'year');
  }

  switch (params.interval) {
    case 'today':
      return params.dayjs.subtract(1, 'day');

    case 'last_7_days':
      return params.dayjs.subtract(6, 'days');

    case 'last_30_days':
      return params.dayjs.subtract(29, 'days');

    case 'last_90_days':
      return params.dayjs.subtract(89, 'days');

    case 'last_365_days':
      return params.dayjs.subtract(364, 'days');

    case 'previous_month':
      return params.dayjs.subtract(1, 'month');

    case 'previous_quarter':
      return params.dayjs.subtract(1, 'quarter');

    case 'previous_year':
      return params.dayjs.subtract(1, 'year');
  }
}

export function getInsightsPoints(params: GetInsightsPointsParams) {
  if (params.interval !== 'custom') {
    const mainPeriod = getInsightDates(params);

    if (!params.compare) {
      return { mainPeriod, comparedPeriod: null };
    }

    const comparedPeriod = getInsightDates({
      ...params,
      dayjs: getCompareInsightsDayjsInstance(params),
    });

    return { mainPeriod, comparedPeriod };
  }

  const mainPeriod = getCustomInsightDates(params);

  if (!params.compare) {
    return { mainPeriod, comparedPeriod: null };
  }

  if (params.compare === 'previous_year') {
    const comparedPeriod = getInsightDates({
      dayjs: params.now,
      compare: null,
      interval: 'previous_year',
    });

    return { mainPeriod, comparedPeriod };
  }

  const comparedPeriod = getCustomInsightDates(params);

  return { mainPeriod, comparedPeriod };
}

export function getInsightsPointsParams(
  params: InsightsRange,
): GetInsightsPointsParams {
  if (params.interval === 'custom') {
    return {
      now: newDayjs(),
      interval: params.interval,
      compare: params.compare,
      from: newDayjs(params.fromDate),
      to: newDayjs(params.toDate),
    };
  }

  return {
    dayjs: newDayjs(),
    interval: params.interval,
    compare: params.compare,
  };
}

function getChartXLabelsDateFormat(interval: InsightsRange['interval']) {
  switch (interval) {
    case 'last_7_days':
    case 'last_30_days':
    case 'last_90_days':
    case 'previous_month':
      return 'D MMM';

    case 'last_365_days':
    case 'previous_quarter':
    case 'previous_year':
      return 'MMM';

    case 'today':
      return 'HH:00';
  }
}

function getDatasetLabels(timestamps: number[], range: InsightsRange) {
  if (range.interval !== 'custom') {
    return timestamps.map((timestamp) => {
      const formatted = newDayjs(timestamp).format(
        getChartXLabelsDateFormat(range.interval),
      );

      return formatted;
    });
  }

  const diffDays = newDayjs(range.toDate).diff(
    newDayjs(range.fromDate),
    'days',
  );

  let labelRange: InsightsRange['interval'] = 'last_365_days';
  if (diffDays === 0) {
    labelRange = 'today';
  } else if (diffDays < 7) {
    labelRange = 'last_7_days';
  } else if (diffDays < 30) {
    labelRange = 'last_30_days';
  } else if (diffDays < 90) {
    labelRange = 'last_90_days';
  }

  return timestamps.map((timestamp) =>
    newDayjs(timestamp).format(getChartXLabelsDateFormat(labelRange)),
  );
}

function getSliceIndex(points: { value: number; timestamp: number }[]) {
  let sliceIndex = points.length;

  const firstTimestamp = points[0].timestamp;
  const lastTimestamp = points[points.length - 2].timestamp;

  const diffDays = newDayjs(lastTimestamp).diff(firstTimestamp, 'days');

  if (diffDays) {
    return sliceIndex;
  }

  const today = newDayjs();
  if (!today.isSame(newDayjs(lastTimestamp), 'day')) {
    return sliceIndex;
  }

  const currentHours = newDayjs().hour();

  for (let i = 0; i < points.length; i++) {
    const pointHour = newDayjs(points[i].timestamp).hour();

    if (pointHour === currentHours) {
      sliceIndex = i;
      break;
    }
  }

  return sliceIndex;
}

export function getLabelsAndDatasets(
  timeSeriesInsightsItems: TimeSeriesInsightsItems[],
  insightsDataRange: InsightsRange,
): {
  datasets: Dataset[];
  labels: string[];
  comparedLabels?: string[];
} {
  let datasets: Dataset[] = [];

  const items = timeSeriesInsightsItems.flatMap((item) => item.items);
  const comparedItems = timeSeriesInsightsItems.flatMap(
    (item) =>
      item.itemsCompared?.filter((item) => item.points.length > 0) ?? [],
  );

  const timestamps = Array.from(
    new Set(
      timeSeriesInsightsItems.flatMap((timeSeriesInsightsItem) =>
        timeSeriesInsightsItem.items.flatMap((item) =>
          item.points.map((point) => point.timestamp),
        ),
      ),
    ),
  );
  const comparedTimestamps = Array.from(
    new Set(
      timeSeriesInsightsItems
        .flatMap((timeSeriesInsightsItem) =>
          timeSeriesInsightsItem.itemsCompared?.flatMap((item) =>
            item.points.map((point) => point.timestamp),
          ),
        )
        .filter(isNumber),
    ),
  );

  items.forEach((item, index) => {
    const sliceIndex = getSliceIndex(item.points);
    const data = item.points.map((point) => point.value).slice(0, sliceIndex);

    if (item.type === 'total') {
      datasets.push({ data, labelTx: 'label.select-products-menu.total' });
    } else {
      datasets.push({ data, label: stringEllipsis(item.label, 20) });
    }

    if (comparedItems.length > 0) {
      const comparedItem = comparedItems[index];
      const data = comparedItem.points.map((point) => point.value);

      if (comparedItem.type === 'total') {
        datasets.push({
          data,
          labelTx: 'label.select-products-menu.total',
          isCompared: true,
        });
      } else {
        datasets.push({
          data,
          label: stringEllipsis(comparedItem.label, 20),
          isCompared: true,
        });
      }
    }
  });

  const labels = getDatasetLabels(timestamps, insightsDataRange);
  const comparedLabels = getDatasetLabels(
    comparedTimestamps,
    insightsDataRange,
  );

  return {
    datasets,
    labels,
    comparedLabels: comparedLabels.length ? comparedLabels : undefined,
  };
}

import {
  getLastUsedWorkspace,
  getOnlyHasDepartmenWorkspaces,
  getOnlyHasStudentWorkspace,
  getWorkspacesOfType,
} from '@orbiapp/components';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';

export namespace AccountSelector {
  const selectSelf = (state: RootState) => state.account.account;

  export const selectStatus = createSelector(
    selectSelf,
    (account) => account.status,
  );

  const selectData = createSelector(selectSelf, (account) => account.data);

  export const selectEmail = createSelector(selectData, (data) => data?.email);

  export const selectUserKey = createSelector(
    selectData,
    (data) => data?.userKey,
  );

  export const selectFirstName = createSelector(
    selectData,
    (data) => data?.firstName,
  );

  export const selectLastName = createSelector(
    selectData,
    (data) => data?.lastName,
  );

  export const selectLanguage = createSelector(
    selectData,
    (data) => data?.language,
  );

  export const selectWorkspaces = createSelector(
    selectData,
    (data) => data?.workspaces,
  );

  export const selectFullName = createSelector(
    [selectFirstName, selectLastName],
    (firstName, lastName) =>
      firstName && lastName ? `${firstName} ${lastName}` : null,
  );

  export const selectSwitchableWorkspaces = createSelector(
    selectWorkspaces,
    (workspaces) =>
      getWorkspacesOfType(workspaces ?? [], 'company', 'department'),
  );

  export const selectOnlyHasStudentWorkspace = createSelector(
    selectWorkspaces,
    (workspaces) => getOnlyHasStudentWorkspace(workspaces ?? []),
  );

  export const selectOnlyHasDepartmentWorkspace = createSelector(
    selectSwitchableWorkspaces,
    (workspaces) => getOnlyHasDepartmenWorkspaces(workspaces),
  );

  export const selectLastUsedDepartmentWorkspaceKey = createSelector(
    selectSwitchableWorkspaces,
    (workspaces) =>
      getLastUsedWorkspace(workspaces, 'department')?.departmentKey,
  );

  export const selectCurrentWorkspace = createSelector(
    selectSwitchableWorkspaces,
    (workspaces) => getLastUsedWorkspace(workspaces, 'company'),
  );

  export const selectIsAdmin = createSelector(
    selectCurrentWorkspace,
    (workspace) => workspace?.role === 'admin',
  );

  export const selectIsMember = createSelector(
    selectCurrentWorkspace,
    (workspace) => workspace?.role === 'member',
  );

  export const selectRole = createSelector(
    selectCurrentWorkspace,
    (workspace) => workspace?.role,
  );

  export const selectHasCorrectRole = createSelector(
    selectIsAdmin,
    selectIsMember,
    (isAdmin, isMember) => isAdmin || isMember,
  );

  export const selectCurrentWorkspaceKey = createSelector(
    selectCurrentWorkspace,
    (workspace) => workspace?.companyKey ?? null,
  );

  export const selectProfilePicture = createSelector(
    selectData,
    (data) => data?.profilePicture,
  );
}

export namespace CreateAccountByInvitationSelector {
  const selectSelf = (state: RootState) =>
    state.account.createAccountByInvitation;

  export const selectStatus = createSelector(
    selectSelf,
    (createAccountByInvitation) => createAccountByInvitation.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (createAccountByInvitation) => createAccountByInvitation.data,
  );
}

export namespace UpdateAccountByInvitationSelector {
  export const selectStatus = (state: RootState) =>
    state.account.updateAccountByInvitation.status;
}

export namespace SignUpSelector {
  export const selectIsNotIdle = createSelector(
    [
      CreateAccountByInvitationSelector.selectStatus,
      UpdateAccountByInvitationSelector.selectStatus,
    ],
    (createAccountByInvitationStatus, updateAccountByInvitationStatus) => {
      return (
        createAccountByInvitationStatus !== 'idle' ||
        updateAccountByInvitationStatus !== 'idle'
      );
    },
  );
}

export namespace UpdateAccountSelector {
  export const selectStatus = (state: RootState) =>
    state.account.updateAccount.status;
}

export namespace SwitchWorkspaceSelector {
  export const selectError = (state: RootState) =>
    state.account.switchWorkspace.error;
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { PerkCategory } from '../../models';
import { OrbiApi, v2 } from '../../services';
import { ThunkApiConfig } from '../store.types';
import { PerkCategoriesSelector } from './perks.selectors';

export const getPerkCategoriesThunk = createAsyncThunk<
  PerkCategory[],
  undefined,
  ThunkApiConfig
>('perks/getPerkCategoriesThunk', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const perkCategories = PerkCategoriesSelector.selectAll(state);
  if (perkCategories.length) {
    return perkCategories;
  }

  const res = await OrbiApi.call(
    v2.perkCategories.getPerkCategories,
    undefined,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

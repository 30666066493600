import { RootState } from '../store.types';
import { companyProfileDraftsAdapter } from './company-profile-drafts.adapter';

export namespace CompanyProfileDraftsSelector {
  const selectors = companyProfileDraftsAdapter.getSelectors<RootState>(
    (state) => state.companyProfileDrafts,
  );

  export const createCompanyProfileDraftIsLoading = (state: RootState) =>
    state.companyProfileDrafts.createCompanyProfileDraftStatus.status ===
    'pending';

  export const deleteCompanyProfileDraftIsLoading = (state: RootState) =>
    state.companyProfileDrafts.deleteCompanyProfileDraftStatus.status ===
    'pending';

  export const getCompanyProfileDraftsIsLoading = (state: RootState) =>
    state.companyProfileDrafts.getCompanyProfileDraftsStatus.status ===
    'pending';

  export const getCompanyProfileDraftError = (state: RootState) =>
    state.companyProfileDrafts.getCompanyProfileDraftStatus.error;

  export const getCompanyProfileDraftIsLoading = (state: RootState) =>
    state.companyProfileDrafts.getCompanyProfileDraftStatus.status ===
    'pending';

  export const updateCompanyProfileDraftIsLoading = (state: RootState) =>
    state.companyProfileDrafts.updateCompanyProfileDraftStatus.status ===
    'pending';

  export const selectAll = selectors.selectAll;

  export const selectById = selectors.selectById;
}

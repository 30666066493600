import {
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  ContentContainer,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
  getFileFromUrl,
  useFetch,
} from '@orbiapp/components';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { CreateOfferForm } from '../../../../models';
import {
  CountriesSelector,
  CreateOfferDraftSelector,
  DuplicateOfferSelector,
  LocationsSelector,
  OfferCategoriesSelector,
  OfferDraftSelector,
  createOfferDraftThunk,
  duplicateOfferThunk,
  getCountriesThunk,
  getLocationsThunk,
  getOfferCategoriesThunk,
  getOfferDraftThunk,
  offersActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { OfferForm } from './create-offer-form';

const CREATE_OFFER_BREADCRUMBS: BreadcrumbListItem[] = [
  {
    to: '/offers',
    tx: 'label.breadcrumbs.offers.offers',
  },
  {
    to: '/offers/create-offer',
    tx: 'label.breadcrumbs.offers.create-offer',
  },
];

function useDefaultValues() {
  const offer = useSelector(OfferDraftSelector.selectData);

  const getDefaultValues =
    React.useCallback(async (): Promise<CreateOfferForm | null> => {
      if (!offer) {
        return null;
      }

      const coverImage = offer.coverImage
        ? await getFileFromUrl(offer.coverImage.original.url)
        : null;

      let consumeWaitMinutes: number | null = null;
      let files: CreateOfferForm['files'] = null;
      let link: string | null = null;
      let code: string | null = null;
      let isConsumable = false;

      if (offer.type === 'online') {
        link = offer.link;

        if (offer.code) {
          if (offer.code.type === 'many') {
            files = offer.code.files;
          } else {
            code = offer.code.code;
          }
        }
      }

      if (offer.type === 'consumable') {
        consumeWaitMinutes = offer.consumeWaitMinutes;
        isConsumable = true;
      }

      const createOfferForm: CreateOfferForm = {
        offerDraftKey: offer.offerDraftKey,
        code,
        consumeWaitMinutes,
        contactEmail: offer.contactEmail ?? '',
        contactName: offer.contactName ?? '',
        coverImage,
        description: offer.description ?? '',
        endDate: offer.endDate,
        files,
        isConsumable,
        link,
        locations: offer.locations ?? [],
        offerCategoryKey: offer.offerCategory?.offerCategoryKey ?? '',
        requireStudentVerification: offer.requireStudentVerification ?? true,
        startDate: Date.now(),
        title: offer.title ?? '',
        countryKeys: offer.countryKeys ?? [],
      };

      return createOfferForm;
    }, [offer]);

  const defaultValues = useFetch(getDefaultValues);

  return defaultValues;
}

export function CreateOfferFromDraft() {
  const { offerDraftKey } = useParams<{ offerDraftKey: string }>();

  const dispatch = useDispatch();

  const getOfferDraftError = useSelector(OfferDraftSelector.selectError);
  const getOfferDraftStatus = useSelector(OfferDraftSelector.selectStatus);
  const offerCategoriesStatus = useSelector(
    OfferCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(LocationsSelector.selectStatus);
  const countriesStatus = useSelector(CountriesSelector.selectStatus);

  const defaultValues = useDefaultValues();

  React.useEffect(() => {
    dispatch(getOfferCategoriesThunk());
  }, [dispatch]);

  React.useEffect(() => {
    if (!offerDraftKey) return;

    dispatch(getOfferDraftThunk(offerDraftKey));
    dispatch(getLocationsThunk());
    dispatch(getCountriesThunk());

    return () => {
      dispatch(offersActions.resetCreateOffer());
    };
  }, [offerDraftKey, dispatch]);

  if (getOfferDraftError) {
    return <Navigate to="/offers" />;
  }

  if (
    !offerDraftKey ||
    !defaultValues ||
    getOfferDraftStatus !== 'completed' ||
    isAnyPending(offerCategoriesStatus, locationsStatus, countriesStatus)
  ) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={CREATE_OFFER_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <OfferForm defaultValues={defaultValues} />
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

export function DuplicateOffer() {
  const { offerKey } = useParams<{ offerKey: string }>();

  const duplicateOfferStatus = useSelector(DuplicateOfferSelector.selectStatus);
  const duplicateOfferError = useSelector(DuplicateOfferSelector.selectError);
  const offerDraftKey = useSelector(DuplicateOfferSelector.selectData);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!offerKey) return;

    dispatch(duplicateOfferThunk(offerKey));
  }, [offerKey, dispatch]);

  if (duplicateOfferError || !offerKey) {
    return <Navigate replace to="/offers" />;
  }

  if (duplicateOfferStatus === 'pending' || !offerDraftKey) {
    return <LoadingContainer />;
  }

  return <Navigate replace to={`/offers/create-offer/${offerDraftKey}`} />;
}

export function CreateOffer() {
  const createOfferDraftStatus = useSelector(
    CreateOfferDraftSelector.selectStatus,
  );
  const createOfferDraftError = useSelector(
    CreateOfferDraftSelector.selectError,
  );
  const offerDraftKey = useSelector(CreateOfferDraftSelector.selectData);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(createOfferDraftThunk());
  }, [dispatch]);

  if (createOfferDraftError) {
    return <Navigate to="/offers" />;
  }

  if (createOfferDraftStatus === 'pending' || !offerDraftKey) {
    return <LoadingContainer />;
  }

  return <Navigate to={`/offers/create-offer/${offerDraftKey}`} />;
}

import {
  Box,
  Card,
  Link,
  Text,
  Trans,
  parseCurrency,
} from '@orbiapp/components';

import {
  LEARN_MORE_ABOUT_INSIGHTS_URL,
  LEARN_MORE_ABOUT_SPENT_VALUE_URL,
} from '../../../../../constants';
import {
  CompanySelector,
  EstimatedValueSelector,
  useSelector,
} from '../../../../../store';

export function Header() {
  const estimatedValue = useSelector(EstimatedValueSelector.selectData);
  const currency = useSelector(CompanySelector.selectCurrency);

  const spendValue = parseCurrency(
    Math.round(estimatedValue ?? 0),
    currency ?? 'SEK',
  );

  return (
    <Box flex gap={16} flexWrap>
      <Card minWidth={280} flexGrow={1} flexBasis={0}>
        <Text
          color="pageTitle"
          variant="titleMd"
          tx="title.insights.dashboard"
          as="h1"
        />

        <Text variant="bodyMd">
          <Trans
            i18nKey="label.insights.page-header-subtitle"
            components={{
              link: (
                <Link
                  style={{ display: 'inline-flex' }}
                  variant="secondary"
                  tx="label.insights.page-header-link"
                  href={LEARN_MORE_ABOUT_INSIGHTS_URL}
                  target="_blank"
                />
              ),
            }}
          />
        </Text>
      </Card>

      <Card flexGrow={1} flexBasis={0}>
        <Box gap={8} flex flexJustify="between" flexWrap>
          <Text
            whiteSpace="nowrap"
            variant="bodyMdBold"
            tx="label.insights.would-have-spent"
          />
          <Text
            variant="titleMd"
            tx="label.insights.estimated-spend"
            txArgs={{ amount: spendValue }}
            whiteSpace="nowrap"
          />
        </Box>

        <Box flex flexWrap>
          <Text variant="bodySm" color="cardHeaderSecondarySubtitle">
            <Trans
              i18nKey="label.insights.estimated-spend-description"
              components={{
                link: (
                  <Link
                    style={{ display: 'inline-block' }}
                    small
                    tx="link.general.learn-more"
                    variant="secondary"
                    href={LEARN_MORE_ABOUT_SPENT_VALUE_URL}
                  />
                ),
              }}
            />
          </Text>
        </Box>
      </Card>
    </Box>
  );
}

import {
  CreateIndexedOfficeLocation,
  CreateIndexedPerk,
  IndexedOfficeLocation,
  IndexedPerk,
  OfficeLocation,
  Perk,
  SubjectArea,
} from '../../models';

export const indexedOfficeLocationsMapper = (
  createIndexedOfficeLocations: CreateIndexedOfficeLocation[],
  allOfficeLocations: OfficeLocation[],
): IndexedOfficeLocation[] => {
  const indexedOfficeLocations: IndexedOfficeLocation[] = [];

  createIndexedOfficeLocations.forEach((createIndexedOfficeLocation) => {
    const officeLocation = allOfficeLocations.find(
      (officeLocation) =>
        officeLocation.officeLocationKey ===
        createIndexedOfficeLocation.officeLocationKey,
    );

    if (officeLocation) {
      indexedOfficeLocations.push({
        ...officeLocation,
        index: createIndexedOfficeLocation.index,
      });
    }
  });

  return indexedOfficeLocations;
};

export const indexedPerksMapper = (
  createIndexedPerks: CreateIndexedPerk[],
  allPerks: Perk[],
): IndexedPerk[] => {
  const indexedPerks: IndexedPerk[] = [];

  createIndexedPerks.forEach((createIndexedPerk) => {
    const perk = allPerks.find(
      (perk) => perk.perkKey === createIndexedPerk.perkKey,
    );

    if (perk) {
      indexedPerks.push({
        ...perk,
        index: createIndexedPerk.index,
        description: createIndexedPerk.description,
      });
    }
  });

  return indexedPerks;
};

export const subjectAreasMapper = (
  subjectAreaKeys: string[],
  allSubjectAreas: SubjectArea[],
): SubjectArea[] => {
  const subjectAreas: SubjectArea[] = [];

  subjectAreaKeys.forEach((subjectAreaKey) => {
    const subjectArea = allSubjectAreas.find(
      (subjectArea) => subjectArea.subjectAreaKey === subjectAreaKey,
    );

    if (subjectArea) {
      subjectAreas.push(subjectArea);
    }
  });

  return subjectAreas;
};

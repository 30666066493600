import { Box, useOnClickOutside } from '@orbiapp/components';
import React from 'react';

import { CompanyProfileForm } from '../../../../../models';
import { ProfileContext } from '../profile-form';

export function SaveFieldOnClickOutside(
  props: React.PropsWithChildren & {
    field: keyof CompanyProfileForm;
    disabled?: boolean;
  },
) {
  const { field, children, disabled } = props;

  const { saveField } = React.useContext(ProfileContext);

  const [fieldIsActive, setFieldIsActive] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);

  const handler = () => {
    if (fieldIsActive) {
      saveField(field);
      setFieldIsActive(false);
    }
  };

  useOnClickOutside({
    isActive: fieldIsActive,
    handler,
    ref,
  });

  const setFieldIsActiveTrue = () => {
    if (!fieldIsActive) {
      setFieldIsActive(true);
    }
  };

  return (
    <Box onClick={disabled ? undefined : setFieldIsActiveTrue} ref={ref}>
      {children}
    </Box>
  );
}

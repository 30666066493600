const youtubeUrlRegExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;

const youtubeVideoIdRegExp =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const isYoutubeUrl = (url: string) => {
  const match = url.match(youtubeUrlRegExp);

  return !!match && match[2].length === 11;
};

export function getYoutubeVideoId(url: string) {
  const match = url.match(youtubeVideoIdRegExp);

  if (match && match[7].length === 11) {
    return match[7];
  }
}

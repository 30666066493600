import {
  Box,
  ControlledInput,
  ControlledTextarea,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';
import React from 'react';

import { UploadCoverImage } from '../../../../../components';
import {
  PROFILE_ABOUT_MAX_LENGTH,
  PROFILE_NAME_MAX_LENGTH,
  PROFILE_ONELINER_MAX_LENGTH,
} from '../../../../../models';
import { ProfileContext } from './profile-form.context';

export function GeneralInformation() {
  const { saveField, formMode } = React.useContext(ProfileContext);

  const isDraft = formMode === 'createProfile';

  const saveCoverImage = () => {
    saveField('coverImageBase64', undefined, true);
  };

  const saveOneliner = () => {
    saveField('oneliner');
  };

  const saveAbout = () => {
    saveField('about');
  };

  const saveName = () => {
    saveField('name');
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.profile.general-information.header"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          tx="label.profile.general-information.description"
          variant="bodyMd"
          color="formSectionDescription"
        />
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <UploadCoverImage name="coverImageBase64" onChange={saveCoverImage} />

        <ControlledInput
          maxLength={PROFILE_NAME_MAX_LENGTH}
          labelTx="label.profile.general-information.inputs.name"
          name="name"
          onBlur={isDraft ? saveName : undefined}
        />

        <ControlledInput
          maxLength={PROFILE_ONELINER_MAX_LENGTH}
          labelTx="label.profile.general-information.inputs.one-liner"
          name="oneliner"
          onBlur={isDraft ? saveOneliner : undefined}
        />

        <ControlledTextarea
          maxLength={PROFILE_ABOUT_MAX_LENGTH}
          labelTx="label.profile.general-information.inputs.about"
          name="about"
          onBlur={isDraft ? saveAbout : undefined}
          resize="vertical"
          minHeight={160}
        />
      </Box>
    </FormSection>
  );
}

import {
  Account,
  EditMeta,
  getFileFromUrl,
  translate,
} from '@orbiapp/components';

import { OFFER_TITLE_MAX_LENGTH, Offer, UpdateOfferDraft } from '../models';

export function getOfferEditMeta(
  account: Account,
  currentMeta?: EditMeta,
): EditMeta {
  const now = Date.now();

  return {
    createdAt: currentMeta?.createdAt ?? now,
    updatedAt: now,
    createdBy: currentMeta?.createdBy ?? {
      firstName: account.firstName,
      lastName: account.lastName,
      userKey: account.userKey,
      profilePicture: account.profilePicture,
    },
    updatedBy: {
      firstName: account.firstName,
      lastName: account.lastName,
      userKey: account.userKey,
      profilePicture: account.profilePicture,
    },
  };
}

export async function getDuplicateOfferPayload(
  offer: Offer,
): Promise<UpdateOfferDraft> {
  const coverImage = await getFileFromUrl(offer.coverImage.original.url);

  const updateOfferDraft: UpdateOfferDraft = {
    offerCategoryKey: offer.offerCategory.offerCategoryKey,
    title: translate('label.general.copy-of', {
      name: offer.title,
    }).substring(0, OFFER_TITLE_MAX_LENGTH),
    description: offer.description,
    coverImage,

    endDate: offer.endDate && offer.endDate > Date.now() ? offer.endDate : null,
    contactName: offer.contactName,
    contactEmail: offer.contactEmail,
    locations: offer.locations,
    requireStudentVerification: offer.requireStudentVerification,
    countryKeys: offer.countryKeys,
  };

  if (offer.type === 'consumable') {
    return {
      ...updateOfferDraft,
      link: null,
      code: null,
      files: null,
      isConsumable: true,
      consumeWaitMinutes: offer.consumeWaitMinutes,
    };
  }

  const typeOnlineBase = {
    ...updateOfferDraft,
    link: offer.link,
    isConsumable: false,
    consumeWaitMinutes: null,
  };

  if (offer.code?.type === 'many') {
    return {
      ...typeOnlineBase,
      code: null,
      files: offer.code.files.map((code) => ({
        fileType: code.fileType,
        fileName: code.fileName,
        codes: code.codes,
      })),
    };
  }

  return {
    ...typeOnlineBase,
    code: offer.code?.code ?? null,
    files: null,
  };
}

import React from 'react';

import { useTheme } from '../../theme';
import { Scroll } from '../../utils';
import { Box, BoxProps } from '../box';
import { IconButton } from '../icon-button';
import { Text, TextProps } from '../text';
import { Styled } from './modal.styled';
import { ModalProps } from './modal.types';

const MODAL_PADDING = 32;

const ModalContext = React.createContext<{
  modalContentRef: React.RefObject<HTMLDivElement>;
  modalFooterRef: React.RefObject<HTMLDivElement>;
  modalHeaderRef: React.RefObject<HTMLDivElement>;
}>({
  modalContentRef: React.createRef<HTMLDivElement>(),
  modalFooterRef: React.createRef<HTMLDivElement>(),
  modalHeaderRef: React.createRef<HTMLDivElement>(),
});

function ModalProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const modalContentRef = React.useRef<HTMLDivElement>(null);
  const modalFooterRef = React.useRef<HTMLDivElement>(null);
  const modalHeaderRef = React.useRef<HTMLDivElement>(null);

  return (
    <ModalContext.Provider
      value={{
        modalContentRef,
        modalFooterRef,
        modalHeaderRef,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export function useModalState() {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = React.useCallback(() => setIsOpen(true), []);
  const closeModal = React.useCallback(() => setIsOpen(false), []);

  return { isOpen, openModal, closeModal };
}

export function ModalHeaderContainer(props: BoxProps) {
  const modalContext = React.useContext(ModalContext);

  return (
    <Styled.ModalHeaderContainer
      p={MODAL_PADDING}
      ref={modalContext.modalHeaderRef}
      relative
      sticky
      top={0}
      zIndex={10002}
      {...props}
    />
  );
}

export function ModalContentContainer(props: BoxProps) {
  const modalContext = React.useContext(ModalContext);

  const theme = useTheme();

  const handleScroll = React.useCallback(() => {
    const modalContentElement = modalContext.modalContentRef.current;
    const modalFooterElement = modalContext.modalFooterRef.current;
    const modalHeaderElement = modalContext.modalHeaderRef.current;

    if (!modalContentElement) return;

    if (modalHeaderElement) {
      const isAtTop = Scroll.isAtTop(modalContentElement);

      modalHeaderElement.style.boxShadow = isAtTop
        ? 'none'
        : theme.elevation.strong;
    }

    if (modalFooterElement) {
      const isAtBottom = Scroll.isAtBottom(modalContentElement);

      modalFooterElement.style.boxShadow = isAtBottom
        ? 'none'
        : theme.elevation.strong;
    }
  }, [
    modalContext.modalContentRef,
    modalContext.modalFooterRef,
    modalContext.modalHeaderRef,
    theme.elevation.strong,
  ]);

  React.useEffect(() => {
    const modalContentElement = modalContext.modalContentRef.current;
    if (!modalContentElement) return;

    const observer = new ResizeObserver(handleScroll);

    modalContentElement.addEventListener('scroll', handleScroll);
    observer.observe(modalContentElement);

    handleScroll();

    return () => {
      observer.disconnect();
      modalContentElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, modalContext.modalContentRef]);

  return (
    <Box
      flex
      flexDirection="column"
      flexGrow={1}
      flexJustify="between"
      height="100%"
      maxHeight="inherit"
      overflow="auto"
      ref={modalContext.modalContentRef}
      {...props}
    />
  );
}

export function ModalFooterContainer(props: BoxProps) {
  const modalContext = React.useContext(ModalContext);

  return (
    <Styled.ModalFooterContainer
      bottom={0}
      flex
      flexAlign="center"
      flexJustify="between"
      p={MODAL_PADDING}
      ref={modalContext.modalFooterRef}
      relative
      sticky
      zIndex={10002}
      gap={16}
      {...props}
    />
  );
}

export function ModalBodyContainer(props: BoxProps) {
  return (
    <Box
      flex
      flexDirection="column"
      flexGrow={1}
      gap={16}
      px={MODAL_PADDING}
      py={8}
      {...props}
    />
  );
}

export function ModalTitle(props: TextProps) {
  return <Text color="modalTitle" variant="titleMd" as="h3" {...props} />;
}

export function ModalSubtitle(props: TextProps) {
  return <Text color="modalSubtitle" variant="bodyMd" {...props} />;
}

export function Modal(props: ModalProps) {
  const { children, isOpen, onClose, zIndex = 1001, ...rest } = props;

  return (
    <ModalProvider>
      <Styled.ModalBackdrop
        fixed
        height="100%"
        width="100%"
        inset
        isOpen={isOpen}
        zIndex={zIndex}
        onClick={onClose}
      />

      <Styled.Modal
        fixed
        flex
        flexDirection="column"
        isOpen={isOpen}
        flexGrow={1}
        overflow="hidden"
        zIndex={zIndex + 1}
        {...rest}
      >
        <Box absolute right={16} top={16} zIndex={10003}>
          <IconButton onClick={onClose} icon="x-mark" />
        </Box>

        {children}
      </Styled.Modal>
    </ModalProvider>
  );
}

import {
  COVER_IMAGE_CRITERIA,
  FileType,
  LOGO_CRITERIA,
} from '@orbiapp/components';
import Joi from 'joi';

export namespace CoverImageSchema {
  export const base64 = Joi.string().required();
  export const fileSize = Joi.number()
    .max(COVER_IMAGE_CRITERIA.maxSize)
    .required();
  export const fileName = Joi.string().required();
  export const fileType = Joi.valid(...Object.values(FileType)).required();
}

export namespace LogoImageSchema {
  export const base64 = Joi.string().required();
  export const fileSize = Joi.number().max(LOGO_CRITERIA.maxSize).required();
  export const fileName = Joi.string().required();
  export const fileType = Joi.valid(...Object.values(FileType)).required();
}

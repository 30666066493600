import { Icon, Link, Switch, Text, Tooltip, Trans } from '@orbiapp/components';

import { useOrbiBusinessCalendar } from '../../../helpers';
import { ModuleSettingsSelector, useSelector } from '../../../store';
import { Styled } from './toggle-unique-metrics.styled';

export function ToggleUniqueMetrics(props: {
  onToggle: () => void;
  isActive: boolean;
}) {
  const { onToggle, isActive } = props;

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  if (insightsTier !== 't2') {
    return (
      <Tooltip
        placement="right"
        clickable
        contentElement={
          <Text variant="bodySm" color="tooltipLabel">
            <Trans
              i18nKey="label.upgrade-account-to-unlock"
              components={{
                link: (
                  <Link
                    style={{ display: 'inline-block' }}
                    small
                    variant="quaternary"
                    tx="label.connect.contact-sales"
                    href={orbiBusinessCalendarUrl}
                  />
                ),
              }}
            />
          </Text>
        }
      >
        <Styled.ToggleUniqueMetrics
          flexJustify="start"
          flexAlign="center"
          py={8}
          px={16}
          height="100%"
        >
          <Switch
            disabled
            tx="label.toggle-unique-metrics"
            onClick={() => {}}
          />

          <Icon
            name="lock-closed-solid"
            color="insightsFilterHeaderUniqueMetricsIcon"
          />
        </Styled.ToggleUniqueMetrics>
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="top" titleTx="label.toggle-unique-metrics-description">
      <Styled.ToggleUniqueMetrics
        flexJustify="start"
        flexAlign="center"
        height="100%"
        py={8}
        px={16}
        onClick={onToggle}
      >
        <Switch
          checked={isActive}
          tx="label.toggle-unique-metrics"
          onClick={() => {}}
        />
      </Styled.ToggleUniqueMetrics>
    </Tooltip>
  );
}

import {
  AlertProvider,
  OrbiLoader,
  SidebarProvider,
  useSignInWithCustomToken,
} from '@orbiapp/components';
import * as Firebase from 'firebase/auth';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { GlobalSnackbar } from '../components';
import { LanguageConfig } from '../i18n';
import { ErrorBoundary } from '../pages/shared';
import { routes } from '../routes';
import { firebase, onAuthStateChanged } from '../services/firebase/firebase';
import { signInWithCustomTokenThunk, store, useDispatch } from '../store';
import { theme } from '../theme';

function AppContent() {
  const dispatch = useDispatch();

  const canContinue = useSignInWithCustomToken(async (customToken: string) => {
    await dispatch(signInWithCustomTokenThunk(customToken));
  });

  if (!canContinue) {
    return <OrbiLoader />;
  }

  return <RouterProvider router={createBrowserRouter(routes)} />;
}

function App() {
  Firebase.getAuth(firebase).onAuthStateChanged(onAuthStateChanged);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={LanguageConfig.i18n}>
            <AlertProvider>
              <SidebarProvider>
                <GlobalSnackbar />

                <AppContent />
              </SidebarProvider>
            </AlertProvider>
          </I18nextProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;

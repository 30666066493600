import { Link, Text } from '@orbiapp/components';
import styled from 'styled-components';

const DescriptionText = styled(Text)`
  display: inline;
`;

const DescriptionLink = styled(Link)`
  display: inline-block;
`;

export const Styled = { DescriptionText, DescriptionLink };

import { TLDS } from '@orbiapp/components';
import Joi from 'joi';

import { EMAIL_MAX_LENGTH } from './email.constants';

export const EmailSchema = Joi.string()
  .email({ tlds: { allow: TLDS } })
  .max(EMAIL_MAX_LENGTH)
  .required()
  .messages({
    'string.already-exists': 'errors.email.already-invited',
    'string.already-pending': 'errors.email.already-pending',
    'string.email': 'errors.email.invalid',
    'string.empty': 'errors.email.empty',
    'string.max': 'errors.email.max',
    'string.pattern.base': 'errors.email.invalid',
    'string.base': 'errors.email.empty',
    'string.unique': 'errors.email.unique',
  });

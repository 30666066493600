import { initialCompanyAdsState } from './company-ads.initial';
import { CompanyAdsState } from './company-ads.types';

function reset(state: CompanyAdsState) {
  Object.assign(state, initialCompanyAdsState);
}

function clearCompanyAds(state: CompanyAdsState) {
  state.companyAds.data = initialCompanyAdsState.companyAds.data;
}

export const reducers = {
  reset,

  clearCompanyAds,
};

import Joi from 'joi';

import { EmailSchema } from '../../email';
import { CoverImageValidation } from '../../file';
import { PhoneSchema } from '../../phone';
import { employmentTypes, studyLevelSchema, workModels } from '../../shared';
import { UrlSchema } from '../../url';
import {
  JOB_CONTACT_NAME_MAX_LENGTH,
  JOB_CONTACT_PHONE_MAX_LENGTH,
  JOB_MAX_DAILY_BUDGET,
  JOB_MAX_TOTAL_BUDGET,
  JOB_MIN_DAILY_BUDGET,
  JOB_MIN_TOTAL_BUDGET,
  JOB_QUALIFICATIONS_MAX_COUNT,
  JOB_QUALIFICATION_MAX_LENGTH,
  JOB_TITLE_MAX_LENGTH,
} from './job.constants';
import { JobErrors, PickProfileErrors } from './job.errors';
import { budgetTypes } from './job.model';

export namespace CreateJobSchema {
  const officeLocationSchema = (options: { officeLocationKeys: string[] }) =>
    Joi.object({
      index: Joi.number().required(),
      officeLocationKey: Joi.string()
        .valid(...options.officeLocationKeys)
        .required()
        .messages(JobErrors.officeLocationKey),
    })
      .options({ abortEarly: true })
      .messages(JobErrors.officeLocationSchema);

  export const contactEmail = EmailSchema.required();

  export const contactPhone = PhoneSchema.max(JOB_CONTACT_PHONE_MAX_LENGTH)
    .allow(null, '')
    .required()
    .messages(JobErrors.contactPhone);

  export const coverImage = CoverImageValidation.required().messages(
    JobErrors.coverImage,
  );

  export const contactName = Joi.string()
    .max(JOB_CONTACT_NAME_MAX_LENGTH)
    .required()
    .messages(JobErrors.contactName);

  export const description = Joi.string()
    .required()
    .messages(JobErrors.description);

  export const employmentType = Joi.string()
    .valid(...employmentTypes)
    .allow(null)
    .required()
    .messages(JobErrors.employmentType);

  export const endDate = Joi.number()
    .min(Joi.ref('startDate'))
    .required()
    .messages(JobErrors.endDate);

  export const jobDraftKey = Joi.string().allow(null).required();

  export const originalJobUrl = UrlSchema.allow(null, '')
    .required()
    .messages(JobErrors.originalJobUrl);

  export const qualifications = Joi.array()
    .items(Joi.string().max(JOB_QUALIFICATION_MAX_LENGTH))
    .unique((a, b) => a === b)
    .max(JOB_QUALIFICATIONS_MAX_COUNT)
    .required()
    .messages(JobErrors.qualifications);

  export const startDate = Joi.number()
    .required()
    .messages(JobErrors.startDate);

  export const studyLevels = Joi.array()
    .items(studyLevelSchema.required())
    .unique((a, b) => a === b)
    .required()
    .messages(JobErrors.studyLevels);

  export const subjectAreaKeys = Joi.array()
    .items(Joi.string().required())
    .unique((a, b) => a === b)
    .required()
    .messages(JobErrors.subjectAreaKeys);

  export const title = Joi.string()
    .max(JOB_TITLE_MAX_LENGTH)
    .required()
    .messages(JobErrors.title);

  export const officeLocations = (options: { officeLocationKeys: string[] }) =>
    Joi.when('workModel', {
      is: 'remote',
      then: Joi.array()
        .items(officeLocationSchema(options))
        .unique((a, b) => a.index === b.index)
        .allow(null)
        .required(),
      otherwise: Joi.array()
        .items(officeLocationSchema(options).required())
        .unique((a, b) => a.index === b.index)
        .required(),
    }).messages(JobErrors.officeLocations);

  export const workModel = Joi.string()
    .valid(...workModels)
    .required()
    .messages(JobErrors.workModel);

  export const locationData = (options: { officeLocationKeys: string[] }) =>
    Joi.object({
      officeLocations: officeLocations(options),
      workModel,
    });

  export const budgetData = Joi.object({
    budgetType: Joi.string().valid(...budgetTypes),
    dailyBudget: Joi.when('budgetType', {
      is: 'daily',
      then: Joi.number()
        .integer()
        .min(JOB_MIN_DAILY_BUDGET)
        .max(JOB_MAX_DAILY_BUDGET)
        .required(),
    })
      .when('budgetType', {
        is: 'total',
        then: Joi.allow(null).required(),
      })
      .when('budgetType', {
        is: 'both',
        then: Joi.number()
          .integer()
          .min(JOB_MIN_DAILY_BUDGET)
          .max(JOB_MAX_DAILY_BUDGET)
          .custom((value, helpers) => {
            const budget: {
              totalBudget: string;
              dailyBudget: string;
            } = helpers.state.ancestors[0];

            const totalBudget = parseInt(budget.totalBudget);
            const dailyBudget = parseInt(budget.dailyBudget);

            if (dailyBudget > totalBudget) {
              return helpers.error('any.ref');
            }

            return value;
          })
          .required(),
      })
      .messages(JobErrors.dailyBudget),
    totalBudget: Joi.when('budgetType', {
      is: 'daily',
      then: Joi.allow(null).required(),
      otherwise: Joi.number()
        .integer()
        .min(JOB_MIN_TOTAL_BUDGET)
        .max(JOB_MAX_TOTAL_BUDGET)
        .required(),
    }).messages(JobErrors.totalBudget),
  });
}

export namespace UpdateJobSchema {
  export const budgetData = (options: {
    totalBudget: number;
    dailyBudget: number;
  }) =>
    Joi.object({
      budgetType: Joi.string().valid(...budgetTypes),
      dailyBudget: Joi.when('budgetType', {
        is: 'daily',
        then: Joi.number()
          .integer()
          .min(options.dailyBudget)
          .max(JOB_MAX_DAILY_BUDGET)
          .required(),
      })
        .when('budgetType', {
          is: 'total',
          then: Joi.allow(null).required(),
        })
        .when('budgetType', {
          is: 'both',
          then: Joi.number()
            .integer()
            .min(options.dailyBudget)
            .max(JOB_MAX_DAILY_BUDGET)
            .custom((value, helpers) => {
              const budget: {
                totalBudget: string;
                dailyBudget: string;
              } = helpers.state.ancestors[0];

              const totalBudget = parseInt(budget.totalBudget);
              const dailyBudget = parseInt(budget.dailyBudget);

              if (dailyBudget > totalBudget) {
                return helpers.error('any.ref');
              }

              return value;
            })
            .required(),
        })
        .messages(JobErrors.dailyBudget),
      totalBudget: Joi.when('budgetType', {
        is: 'daily',
        then: Joi.allow(null).required(),
        otherwise: Joi.number()
          .integer()
          .min(options.totalBudget)
          .max(JOB_MAX_TOTAL_BUDGET)
          .required(),
      }).messages(JobErrors.totalBudget),
    });
}

export namespace PickProfileSchema {
  export const companyProfileKey = Joi.string()
    .required()
    .messages(PickProfileErrors.companyProfileKey);
}

import {
  Box,
  Button,
  Link,
  ResponsiveBox,
  SolidIconButton,
  Text,
} from '@orbiapp/components';
import React from 'react';

import { LEARN_MORE_ABOUT_CONNECT_URL } from '../../../../../constants';
import { ConnectSidebarContext } from '../sidebar';

export function PageHeader() {
  const { openSidebar } = React.useContext(ConnectSidebarContext);

  return (
    <Box flexAlign="start" gap={16} flex flexJustify="between">
      <Box flexDirection="column" flex gap={10}>
        <Text
          color="pageTitle"
          variant="titleMd"
          tx="title.connect.dashboard"
          as="h1"
        />

        <Box flexWrap gap={4} flexAlign="center" flex>
          <Text tx="label.connect.page-header-subtitle" />
          <Link
            variant="secondary"
            tx="label.connect.page-header-link"
            href={LEARN_MORE_ABOUT_CONNECT_URL}
            target="_blank"
          />
        </Box>
      </Box>

      <ResponsiveBox
        md={
          <Button
            onClick={openSidebar}
            variant="primary"
            tx="label.connect.new-qr-code"
            whiteSpace="nowrap"
          />
        }
      >
        <SolidIconButton icon="plus-circle-outline" onClick={openSidebar} />
      </ResponsiveBox>
    </Box>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './insights.build';
import { initialInsightsState } from './insights.initial';
import { reducers } from './insights.reducers';

const slice = createSlice({
  name: 'insights',
  initialState: initialInsightsState,
  reducers,
  extraReducers,
});

export const insightsActions = slice.actions;
export const insightsReducer = slice.reducer;

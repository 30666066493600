import { SubjectArea, SubjectAreaCategory } from '../../../../../models';
import {
  SubjectAreaCategoryDto,
  SubjectAreaDto,
} from './subject-area-categories.dto';

const subjectAreaMapper = (subjectAreaDto: SubjectAreaDto): SubjectArea => ({
  name: subjectAreaDto.name,
  subjectAreaCategoryKey: subjectAreaDto.subjectAreaCategoryKey,
  subjectAreaKey: subjectAreaDto.subjectAreaKey,
});

export const subjectAreaCategoryMapper = (
  subjectAreaCategoryDto: SubjectAreaCategoryDto,
): SubjectAreaCategory => ({
  name: subjectAreaCategoryDto.name,
  subjectAreaCategoryKey: subjectAreaCategoryDto.subjectAreaCategoryKey,
  subjectAreas: subjectAreaCategoryDto.subjectAreas
    .map(subjectAreaMapper)
    .sort((a, b) => a.name.localeCompare(b.name)),
});

import { INITIAL_DATA_STATE } from '../store.constants';
import { partialJobDraftsAdapter, partialJobsAdapter } from './jobs.adapter';
import { JobsState } from './jobs.types';

export const initialJobsState: JobsState = {
  createJobDraft: INITIAL_DATA_STATE,
  createJobDefaultValues: null,
  jobDraft: INITIAL_DATA_STATE,
  jobDrafts: {
    ...INITIAL_DATA_STATE,
    data: partialJobDraftsAdapter.getInitialState(),
  },
  updateJobDraft: INITIAL_DATA_STATE,
  deleteJobDraft: INITIAL_DATA_STATE,

  createJob: INITIAL_DATA_STATE,
  job: INITIAL_DATA_STATE,
  jobs: {
    ...INITIAL_DATA_STATE,
    data: partialJobsAdapter.getInitialState(),
    show: 'all',
    pagination: {
      limit: 25,
      offset: 0,
      orderBy: 'createdAt',
      sortOrder: 'desc',
    },
  },
  updateJob: INITIAL_DATA_STATE,
  closeJob: INITIAL_DATA_STATE,
  duplicateJob: INITIAL_DATA_STATE,
};

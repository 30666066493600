import {
  Avatar,
  Box,
  EditMeta,
  EditedByMeta,
  FormHeader,
  Icon,
  IconButton,
  Status,
  Text,
  Tooltip,
  formatDate,
  getAvatarVariantFromString,
  translate,
} from '@orbiapp/components';
import React from 'react';

import { OfferSelector, useSelector } from '../../../../../store';

function OfferFormHeaderUpdateStatus() {
  const endDate = useSelector(OfferSelector.selectEndDate);
  const startDate = useSelector(OfferSelector.selectStartDate);
  const disabledAt = useSelector(OfferSelector.selectDisabledAt);

  if (!startDate) {
    return null;
  }

  const now = Date.now();

  if (endDate && now > endDate) {
    return <Status variant="error" tx="label.offers-status.closed" />;
  }

  if (startDate > now) {
    return (
      <Status
        variant="info"
        tx="label.offers-status.scheduled-at"
        txArgs={{
          date: formatDate(startDate, 'DD MMM YYYY HH:mm'),
        }}
      />
    );
  }

  if (disabledAt) {
    return <Status variant="error" tx="label.offers-status.paused" />;
  }

  return <Status variant="success" tx="label.offers-status.live" />;
}

function OfferFormHeaderEditMeta({ editMeta }: { editMeta: EditMeta | null }) {
  if (!editMeta) {
    return null;
  }

  const { createdAt, createdBy, updatedAt, updatedBy } = editMeta;

  const {
    at,
    atTx,
    by,
    byTx,
  }: {
    at: number;
    by: EditedByMeta | null;
    atTx: TxString;
    byTx: TxString;
  } = updatedAt
    ? {
        at: updatedAt,
        by: updatedBy,
        atTx: 'label.offer-form.updated-at',
        byTx: 'label.offer-form.updated-by',
      }
    : {
        at: createdAt,
        by: createdBy,
        atTx: 'label.offer-form.created-at',
        byTx: 'label.offer-form.created-by',
      };

  const fullName = by
    ? `${by.firstName} ${by.lastName}`
    : translate('placeholder.unknown-user');

  const variant = by?.userKey
    ? getAvatarVariantFromString(by.userKey)
    : undefined;

  return (
    <Box flexWrap flexAlign="center" gap={16} flex>
      <Box flex flexAlign="center" mr={48} gap={16}>
        <Icon color="formHeaderEditedByMetaIcon" name="user-circle-outline" />
        <Text color="formHeaderEditedByMetaTitle" tx={byTx} variant="bodySm" />

        <Box flexAlign="center" gap={8} flex>
          <Avatar
            variant={variant}
            width={32}
            height={32}
            fallbackLetter={by?.firstName[0]}
            src={by?.profilePicture?.thumbnail64.url}
          />

          <Text
            color="formHeaderEditedByMeta"
            text={fullName}
            textTransform="capitalize"
            variant="bodySm"
          />
        </Box>
      </Box>

      <Box flex flexAlign="center" gap={16}>
        <Icon name="clock-outline" color="formHeaderEditedByMetaIcon" />
        <Text variant="bodySm" color="formHeaderEditedByMetaTitle" tx={atTx} />
        <Text variant="bodySm" text={formatDate(at, 'DD MMM YYYY HH:mm:ss')} />
      </Box>
    </Box>
  );
}

export function OfferFormHeader({
  children,
  editMeta,
  isCreateOffer,
}: {
  children?: React.ReactNode;
  editMeta: EditMeta | null;
  isCreateOffer?: boolean;
}) {
  return (
    <FormHeader flex flexDirection="column" gap={12} width="100%">
      <Box
        flex
        flexAlign="center"
        flexJustify="between"
        gap={24}
        width="100%"
        flexWrap
      >
        <Box flexGrow={1} gap={8} flex flexDirection="column">
          <Box gap={8} flexAlign="center" flex>
            <Tooltip placement="bottom" titleTx="label.tooltip.go-back">
              <IconButton to="/offers" icon="chevron-left" />
            </Tooltip>

            <Text
              color="pageTitle"
              variant="titleMd"
              tx={
                isCreateOffer
                  ? 'title.offers.create-offer'
                  : 'title.offers.edit-offer'
              }
              as="h1"
            />
          </Box>
        </Box>
        <Box flexAlign="center" flexWrap flex gap={16}>
          {children}
        </Box>
      </Box>

      <Box gap={16} flexWrap flex flexJustify="between">
        <OfferFormHeaderEditMeta editMeta={editMeta} />
        {isCreateOffer ? (
          <Status ml="auto" variant="warning" tx="label.offer-status.draft" />
        ) : (
          <OfferFormHeaderUpdateStatus />
        )}
      </Box>
    </FormHeader>
  );
}

import { Box, CardHeader, numberFormatter } from '@orbiapp/components';
import React from 'react';

import { TalentPoolUserStatsSelector, useSelector } from '../../../../../store';
import { Styled } from './info-header.styled';

export function StatsCards() {
  const talentPoolUserStats = useSelector(
    TalentPoolUserStatsSelector.selectTalentPoolUserStats,
  );
  const talentPoolUserStatsStatus = useSelector(
    TalentPoolUserStatsSelector.selectStatus,
  );

  const isLoading = talentPoolUserStatsStatus === 'pending';

  return (
    <Box gap={16} flex flexWrap>
      <Styled.StatsCard py={8} px={16} maxWidth="100%">
        <CardHeader
          titleTx="label.connect.total-scans"
          isLoading={isLoading}
          subtitleText={numberFormatter.format(
            talentPoolUserStats?.scanCount ?? 0,
          )}
        />
      </Styled.StatsCard>

      <Styled.StatsCard py={8} px={16} maxWidth="100%">
        <CardHeader
          titleTx="label.connect.total-talent"
          isLoading={isLoading}
          subtitleText={numberFormatter.format(
            talentPoolUserStats?.talentPoolUserCount ?? 0,
          )}
        />
      </Styled.StatsCard>

      <Styled.StatsCard py={8} px={16} maxWidth="100%">
        <CardHeader
          titleTx="label.connect.active-qr-codes"
          isLoading={isLoading}
          subtitleText={numberFormatter.format(
            talentPoolUserStats?.activeQrCodeCount ?? 0,
          )}
        />
      </Styled.StatsCard>
    </Box>
  );
}

export function InfoHeader(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <Styled.InfoHeader gap={16} flex>
      {children}
    </Styled.InfoHeader>
  );
}

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './company-profiles.build';
import { initialCompanyProfilesState } from './company-profiles.initial';
import { reducers } from './company-profiles.reducers';

const companyProfilesSlice = createSlice({
  name: 'companyProfiles',
  initialState: initialCompanyProfilesState,
  reducers,
  extraReducers,
});

export const companyProfilesActions = companyProfilesSlice.actions;
export const companyProfilesReducer = companyProfilesSlice.reducer;

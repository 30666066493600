import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './company-profile-drafts.build';
import { initialCompanyProfileDraftsState } from './company-profile-drafts.initial';
import { reducers } from './company-profile-drafts.reducers';

const companyProfileDraftsSlice = createSlice({
  name: 'companyProfileDrafts',
  initialState: initialCompanyProfileDraftsState,
  reducers,
  extraReducers,
});

export const companyProfileDraftsReducer = companyProfileDraftsSlice.reducer;

import { ORBI_BUSINESS_CALENDAR_URL } from '../constants';
import { AccountSelector, useSelector } from '../store';

export function useOrbiBusinessCalendar() {
  const email = useSelector(AccountSelector.selectEmail);
  const userKey = useSelector(AccountSelector.selectUserKey);

  const orbiBusinessCalendarUrl =
    email?.length && userKey?.length
      ? `${ORBI_BUSINESS_CALENDAR_URL}?utm_campaign=company_dashboard&utm_medium=${userKey}&email=${email}`
      : ORBI_BUSINESS_CALENDAR_URL;

  return { orbiBusinessCalendarUrl };
}

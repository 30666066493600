import { Box } from '../box';
import {
  GetLinearProgressColorReturn,
  LinearProgressProps,
} from './linear-progress.types';

function getRadialProgressColors(
  variant: LinearProgressProps['variant'],
): GetLinearProgressColorReturn {
  switch (variant) {
    case 'success':
      return {
        progressColor: 'linearProgressSuccessProgress',
        trackColor: 'linearProgressSuccessTrack',
      };

    case 'danger':
      return {
        progressColor: 'linearProgressDangerProgress',
        trackColor: 'linearProgressDangerTrack',
      };

    case 'warning':
      return {
        progressColor: 'linearProgressWarningProgress',
        trackColor: 'linearProgressWarningTrack',
      };
  }
}

export function LinearProgress(props: LinearProgressProps) {
  const { percentage, variant, ...rest } = props;

  const colors = getRadialProgressColors(variant);

  return (
    <Box
      r={8}
      height={8}
      relative
      backgroundColor={colors.trackColor}
      {...rest}
    >
      <Box
        absolute
        left={0}
        r={8}
        height="100%"
        width={`${percentage}%`}
        backgroundColor={colors.progressColor}
      />
    </Box>
  );
}

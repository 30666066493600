export namespace CompanyProfileErrors {
  export const about = {
    'string.base': 'errors.description.empty',
  };

  export const coverImage = {
    'object.base': 'errors.cover-image.empty',
  };

  export const employmentTypes = {
    'array.base': 'errors.employment-types.empty',
  };

  export const funFacts = {
    'string.empty': 'errors.fun-fact.empty',
    'array.unique': 'errors.fun-facts.unique',
  };

  export const name = {
    'string.base': 'errors.name.empty',
    'string.empty': 'errors.name.empty',
  };

  export const oneliner = {
    'string.base': 'errors.oneliner.empty',
  };

  export const perkPerkKey = {
    'string.empty': 'errors.perks.perk-key',
  };

  export const perks = {
    'string.empty': 'errors.perks.description',
    'array.base': 'errors.perks.empty',
    'array.includesRequiredUnknowns': 'errors.perks.empty',
  };

  export const subjectAreaKeys = {
    'array.base': 'errors.subject-areas.empty',
  };

  export const locationData = {
    'object.base': 'errors.location-data.empty',
  };

  export const officeLocation = {
    'string.empty': 'errors.office-locations.invalid',
  };

  export const officeLocations = {
    'array.base': 'errors.office-locations.empty',
    'array.includesRequiredUnknowns': 'errors.office-locations.empty',
  };

  export const workModels = {
    'array.includesRequiredUnknowns': 'errors.location-data.empty',
  };
}

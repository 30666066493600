import { RootState } from '../store.types';
import { perkCategoriesAdapter, perksAdapter } from './perks.adapter';

export namespace PerkCategoriesSelector {
  const perkCategoriesSelectors = perkCategoriesAdapter.getSelectors<RootState>(
    (state) => state.perks.perkCategoriesState,
  );
  export const selectStatus = (state: RootState) =>
    state.perks.getPerkCategoriesStatus.status;

  export const selectAll = perkCategoriesSelectors.selectAll;

  export const selectById = perkCategoriesSelectors.selectById;
}

export namespace PerksSelector {
  const perksSelectors = perksAdapter.getSelectors<RootState>(
    (state) => state.perks.perksState,
  );

  export const selectAll = perksSelectors.selectAll;

  export const selectById = perksSelectors.selectById;
}

import { isUndefined } from '@orbiapp/components';

import {
  CompanyQr,
  CreateCompanyQr,
  PartialCompanyQr,
  UpdateCompanyQr,
} from '../../../../../../models';
import {
  CompanyQrDto,
  CreateCompanyQrDto,
  PartialCompanyQrDto,
  UpdateCompanyQrDto,
} from './company-qr.dto';

export function companyQrMapper(companyQr: CompanyQrDto): CompanyQr {
  return {
    companyQrKey: companyQr.companyQrKey,
    createdAt: companyQr.createdAt,
    description: companyQr.description,
    editMeta: companyQr.editMeta,
    endDate: companyQr.endDate,
    link: null,
    name: companyQr.name,
    scanCount: companyQr.scanCount,
    startDate: companyQr.startDate,
    connectCount: companyQr.connectCount,
  };
}

export function partialCompanyQrMapper(
  partialCompanyQr: PartialCompanyQrDto,
): PartialCompanyQr {
  return {
    companyQrKey: partialCompanyQr.companyQrKey,
    createdAt: partialCompanyQr.createdAt,
    endDate: partialCompanyQr.endDate,
    name: partialCompanyQr.name,
    scanCount: partialCompanyQr.scanCount,
    startDate: partialCompanyQr.startDate,
    connectCount: partialCompanyQr.connectCount,
  };
}

export function createCompanyQrMapper(
  createCompanyQr: CreateCompanyQr,
): CreateCompanyQrDto {
  return {
    description: createCompanyQr.description?.length
      ? createCompanyQr.description
      : null,
    name: createCompanyQr.name,
    startDate: createCompanyQr.startDate,
  };
}

export function updateCompanyQrMapper(
  updateCompanyQr: UpdateCompanyQr,
): UpdateCompanyQrDto {
  const updateCompanyQrDto: UpdateCompanyQrDto = {};

  if (!isUndefined(updateCompanyQr.description)) {
    updateCompanyQrDto.description = updateCompanyQr.description || null;
  }

  if (updateCompanyQr.name?.length) {
    updateCompanyQrDto.name = updateCompanyQr.name;
  }

  if (updateCompanyQr.startDate) {
    updateCompanyQrDto.startDate = updateCompanyQr.startDate;
  }

  return updateCompanyQrDto;
}

import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './company.build';
import { initialCompanyState } from './company.initial';
import { reducers } from './company.reducers';

const companySlice = createSlice({
  name: 'users',
  initialState: initialCompanyState,
  reducers,
  extraReducers,
});

export const companyActions = companySlice.actions;
export const companyReducer = companySlice.reducer;

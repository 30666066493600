const workModelOnsite = 'onsite';
const workModelHybrid = 'hybrid';
export const workModelRemote = 'remote';

export const workModels = [
  workModelOnsite,
  workModelHybrid,
  workModelRemote,
] as const;

export type WorkModel = (typeof workModels)[number];

import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  ControlledInput,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  Input,
  PaperContentContainer,
  Text,
  WrapBox,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import { FormProvider, useForm } from 'react-hook-form';

import { NavBlocker, UnsavedChangesBox } from '../../../../components';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  UpdateAccountForm,
  UpdateAccountValidation,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  AccountSelector,
  UpdateAccountSelector,
  updateAccountThunk,
  useDispatch,
  useSelector,
} from '../../../../store';

function ProfileDetails() {
  const email = useSelector(AccountSelector.selectEmail);

  return (
    <InnerPaperContentContainerSection>
      <Box flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column" gap={4}>
          <Text
            tx="label.account-settings.profile-details.header"
            variant="bodyMdBold"
          />

          <Text
            tx="label.account-settings.profile-details.description"
            variant="bodyMd"
            color="formSectionDescription"
          />
        </Box>

        <Box flex flexDirection="column" width="100%" gap={8}>
          <WrapBox breakpoint="sm" width="100%" flex gap={8}>
            <ControlledInput
              maxLength={FIRST_NAME_MAX_LENGTH}
              labelTx="label.account-settings.account-info.first-name"
              name="firstName"
            />

            <ControlledInput
              maxLength={LAST_NAME_MAX_LENGTH}
              labelTx="label.account-settings.account-info.last-name"
              name="lastName"
            />
          </WrapBox>

          <Input
            value={email}
            readOnly
            labelTx="label.account-settings.account-info.email"
            disabled
          />
        </Box>
      </Box>
    </InnerPaperContentContainerSection>
  );
}

export function AccountSettings() {
  const firstName = useSelector(AccountSelector.selectFirstName);
  const lastName = useSelector(AccountSelector.selectLastName);
  const updateAccountStatus = useSelector(UpdateAccountSelector.selectStatus);

  const dispatch = useDispatch();

  const formMethods = useForm<UpdateAccountForm>({
    resolver: joiResolver(UpdateAccountValidation),
    defaultValues: {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
    },
  });

  const reset = () => {
    formMethods.reset();
  };

  const updateUser = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(updateAccountThunk(data));
      if (res.meta.requestStatus === 'fulfilled') {
        formMethods.reset(data);
      }
    },
    (err) => {
      Logger.warning('updateUser Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <FormProvider {...formMethods}>
      <NavBlocker shouldBlock={formMethods.formState.isDirty} />

      <PaperContentContainer>
        <InnerPaperContentContainer>
          {(formMethods.formState.isDirty ||
            updateAccountStatus === 'pending') && (
            <UnsavedChangesBox
              onRevert={reset}
              onSave={updateUser}
              isLoading={updateAccountStatus === 'pending'}
            />
          )}

          <InnerPaperContentContainerSectionsContainer>
            <ProfileDetails />
          </InnerPaperContentContainerSectionsContainer>
        </InnerPaperContentContainer>
      </PaperContentContainer>
    </FormProvider>
  );
}

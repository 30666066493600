import { Time } from '@orbiapp/components';

import {
  createInvitationThunk,
  createInvitationsThunk,
  deleteInvitationThunk,
  getCompanyInvitationThunk,
  getPendingInvitationsThunk,
  getUsersThunk,
  removeUserThunk,
} from './team.actions';
import { pendingInvitationsAdapter, usersAdapter } from './team.adapter';
import { TeamBuilder } from './team.types';

const createInvitationBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(createInvitationThunk.fulfilled, (state, action) => {
      state.createInvitation.status = 'idle';
      state.createInvitation.data = action.payload;
      pendingInvitationsAdapter.addOne(state.pendingInvitations.data, {
        companyInvitationKey: action.payload,
        email: action.meta.arg.email,
        role: 'admin',
        expiresAt: Date.now() + 2 * Time.Week,
      });
    })
    .addCase(createInvitationThunk.pending, (state) => {
      state.createInvitation.status = 'pending';
      state.createInvitation.error = null;
    })
    .addCase(createInvitationThunk.rejected, (state, action) => {
      state.createInvitation.status = 'idle';
      state.createInvitation.error = action.error;
    });
};

const createInvitationsBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(createInvitationsThunk.fulfilled, (state, action) => {
      state.createInvitations.status = 'idle';
      pendingInvitationsAdapter.addMany(
        state.pendingInvitations.data,
        action.payload,
      );
    })
    .addCase(createInvitationsThunk.pending, (state) => {
      state.createInvitations.status = 'pending';
      state.createInvitations.error = null;
    })
    .addCase(createInvitationsThunk.rejected, (state, action) => {
      state.createInvitations.status = 'idle';
      state.createInvitations.error = action.error;
    });
};

const deleteInvitationBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(deleteInvitationThunk.fulfilled, (state, action) => {
      state.deleteInvitation.status = 'idle';

      pendingInvitationsAdapter.removeOne(
        state.pendingInvitations.data,
        action.meta.arg,
      );
    })
    .addCase(deleteInvitationThunk.pending, (state) => {
      state.deleteInvitation.status = 'pending';
      state.deleteInvitation.error = null;
    })
    .addCase(deleteInvitationThunk.rejected, (state, action) => {
      state.deleteInvitation.status = 'idle';
      state.deleteInvitation.error = action.error;
    });
};

const getCompanyInvitationBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(getCompanyInvitationThunk.fulfilled, (state, action) => {
      state.companyInvitation.status = 'completed';
      state.companyInvitation.data = action.payload;
    })
    .addCase(getCompanyInvitationThunk.pending, (state) => {
      state.companyInvitation.status = 'pending';
      state.companyInvitation.error = null;
    })
    .addCase(getCompanyInvitationThunk.rejected, (state, action) => {
      state.companyInvitation.status = 'idle';
      state.companyInvitation.error = action.error;
    });
};

const getPendingInvitationsBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(getPendingInvitationsThunk.fulfilled, (state, action) => {
      state.pendingInvitations.status = 'idle';

      pendingInvitationsAdapter.setAll(
        state.pendingInvitations.data,
        action.payload,
      );
    })
    .addCase(getPendingInvitationsThunk.pending, (state) => {
      state.pendingInvitations.status = 'pending';
      state.pendingInvitations.error = null;
    })
    .addCase(getPendingInvitationsThunk.rejected, (state, action) => {
      state.pendingInvitations.status = 'idle';
      state.pendingInvitations.error = action.error;
    });
};

const getUsersBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(getUsersThunk.fulfilled, (state, action) => {
      state.users.status = 'idle';

      usersAdapter.setAll(state.users.data, action.payload);
    })
    .addCase(getUsersThunk.pending, (state) => {
      state.users.status = 'pending';
      state.users.error = null;
    })
    .addCase(getUsersThunk.rejected, (state, action) => {
      state.users.status = 'idle';
      state.users.error = action.error;
    });
};

const removeUserBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(removeUserThunk.fulfilled, (state, action) => {
      state.removeUser.status = 'idle';

      usersAdapter.removeOne(state.users.data, action.meta.arg);
    })
    .addCase(removeUserThunk.pending, (state) => {
      state.removeUser.status = 'pending';
      state.removeUser.error = null;
    })
    .addCase(removeUserThunk.rejected, (state, action) => {
      state.removeUser.status = 'idle';
      state.removeUser.error = action.error;
    });
};

export const extraReducers = (builder: TeamBuilder) => {
  createInvitationBuild(builder);
  createInvitationsBuild(builder);
  deleteInvitationBuild(builder);
  getCompanyInvitationBuild(builder);
  getPendingInvitationsBuild(builder);
  getUsersBuild(builder);
  removeUserBuild(builder);
};

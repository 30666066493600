import { isUndefined } from '@orbiapp/components';

import {
  PartialTalentPoolUser,
  TalentPoolUser,
  TalentPoolUserStats,
  UpdateTalentPoolUser,
} from '../../../../../../models';
import {
  PartialTalentPoolUserDto,
  TalentPoolUserDto,
  TalentPoolUserStatsDto,
  UpdateUserNotesDto,
} from './talent-pool.dto';

export function talentPoolUserStatsMapper(
  talentPoolUserStatsDto: TalentPoolUserStatsDto,
): TalentPoolUserStats {
  return {
    activeQrCodeCount: talentPoolUserStatsDto.activeQrCodeCount,
    scanCount: talentPoolUserStatsDto.scanCount,
    talentPoolUserCount: talentPoolUserStatsDto.talentPoolUserCount,
  };
}

export function talentPoolUserMapper(
  talentPoolUserDto: TalentPoolUserDto,
): TalentPoolUser {
  return {
    companyQr: talentPoolUserDto.companyQr,
    createdAt: talentPoolUserDto.createdAt,
    email: talentPoolUserDto.email,
    firstName: talentPoolUserDto.firstName,
    lastName: talentPoolUserDto.lastName,
    profilePicture: talentPoolUserDto.profilePicture,
    notes: talentPoolUserDto.notes,
    segmentation: talentPoolUserDto.segmentation,
    semester: talentPoolUserDto.semester,
    source: talentPoolUserDto.source,
    studyLevel: talentPoolUserDto.studyLevel,
    subjectAreas: talentPoolUserDto.subjectAreas,
    userKey: talentPoolUserDto.userKey,
    isStarred: talentPoolUserDto.isStarred,
    schoolName: talentPoolUserDto.schoolName,
  };
}

export function partialTalentPoolUserMapper(
  partialTalentPoolUserDto: PartialTalentPoolUserDto,
): PartialTalentPoolUser {
  return {
    companyQr: partialTalentPoolUserDto.companyQr,
    createdAt: partialTalentPoolUserDto.createdAt,
    email: partialTalentPoolUserDto.email,
    firstName: partialTalentPoolUserDto.firstName,
    lastName: partialTalentPoolUserDto.lastName,
    profilePicture: partialTalentPoolUserDto.profilePicture,
    source: partialTalentPoolUserDto.source,
    userKey: partialTalentPoolUserDto.userKey,
    isStarred: partialTalentPoolUserDto.isStarred,
  };
}

export function updateTalentPoolUserMapper(
  updateTalentPoolUserForm: UpdateTalentPoolUser,
): UpdateUserNotesDto {
  const updateUserNotesDto: UpdateUserNotesDto = {};

  if (!isUndefined(updateTalentPoolUserForm.notes)) {
    updateUserNotesDto.notes = updateTalentPoolUserForm.notes.length
      ? updateTalentPoolUserForm.notes
      : null;
  }

  if (!isUndefined(updateTalentPoolUserForm.isStarred)) {
    updateUserNotesDto.isStarred = updateTalentPoolUserForm.isStarred;
  }

  return updateUserNotesDto;
}

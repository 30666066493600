import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Box,
  BreadcrumbsToolbar,
  Button,
  Chip,
  Confirm,
  ContentContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  Input,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  PageContainer,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Tooltip,
  UpdatedAtTableCell,
  UpdatedByTableCell,
  translate,
  useConfirm,
  useModalState,
} from '@orbiapp/components';
import React from 'react';

import { CreateProfileModal } from '../../../../components';
import {
  CompanyProfile,
  PartialCompanyProfile,
  PartialCompanyProfileDraft,
  SubjectArea,
  isPartialCompanyProfileAndNotPartialCompanyProfileDraft,
  profilesSortableKeys,
} from '../../../../models';
import {
  CompanyProfileDraftsSelector,
  CompanyProfilesSelector,
  companyProfilesActions,
  deleteCompanyProfileDraftThunk,
  deleteCompanyProfileThunk,
  getCompanyProfileDraftsThunk,
  updateCompanyProfileIndexesThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { Styled } from './styled';

const TABLE_COLUMN_WIDTHS = {
  profiles: 125,
  priority: 25,
  subjects: 200,
  status: 25,
  updatedBy: 150,
  updatedAt: 50,
  actions: 25,
};

const MAX_VISIBLE_SUBJECT_AREAS = 2;
const MAX_VISIBLE_PROFILES_IN_PRIORITY_LIST = 5;

const UpdatePriorityContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isOpen: false,
  setIsOpen: () => {},
});

function UpdatePriorityProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <UpdatePriorityContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </UpdatePriorityContext.Provider>
  );
}

const ConfirmDeletePartialCompanyProfileDraftContext = React.createContext<{
  closeModal: () => void;
  isOpen: boolean;
  openModal: (partialCompanyProfileDraft: PartialCompanyProfileDraft) => void;
  partialCompanyProfileDraft: PartialCompanyProfileDraft | null;
}>({
  closeModal: () => {},
  isOpen: false,
  openModal: () => {},
  partialCompanyProfileDraft: null,
});

function ConfirmDeletePartialCompanyProfileDraftProvider(
  props: React.PropsWithChildren,
) {
  const { children } = props;

  const { closeConfirm, isOpen, openConfirm } =
    useConfirm<PartialCompanyProfileDraft>();

  const [partialCompanyProfileDraft, setPartialCompanyProfileDraft] =
    React.useState<PartialCompanyProfileDraft | null>(null);

  const openModal = (
    partialCompanyProfileDraft: PartialCompanyProfileDraft,
  ) => {
    setPartialCompanyProfileDraft(partialCompanyProfileDraft);
    openConfirm(partialCompanyProfileDraft);
  };

  return (
    <ConfirmDeletePartialCompanyProfileDraftContext.Provider
      value={{
        closeModal: closeConfirm,
        isOpen,
        openModal,
        partialCompanyProfileDraft,
      }}
    >
      {children}
    </ConfirmDeletePartialCompanyProfileDraftContext.Provider>
  );
}

const ConfirmDeletePartialCompanyProfileContext = React.createContext<{
  closeModal: () => void;
  isOpen: boolean;
  openModal: (partialCompanyProfile: PartialCompanyProfile) => void;
  partialCompanyProfile: PartialCompanyProfile | null;
}>({
  closeModal: () => {},
  isOpen: false,
  openModal: () => {},
  partialCompanyProfile: null,
});

function ConfirmDeletePartialCompanyProfileProvider(
  props: React.PropsWithChildren,
) {
  const { children } = props;

  const { closeConfirm, isOpen, openConfirm } =
    useConfirm<PartialCompanyProfile>();

  const [partialCompanyProfile, setPartialCompanyProfile] =
    React.useState<PartialCompanyProfile | null>(null);

  const openModal = (partialCompanyProfile: PartialCompanyProfile) => {
    setPartialCompanyProfile(partialCompanyProfile);
    openConfirm(partialCompanyProfile);
  };

  return (
    <ConfirmDeletePartialCompanyProfileContext.Provider
      value={{
        closeModal: closeConfirm,
        isOpen,
        openModal,
        partialCompanyProfile,
      }}
    >
      {children}
    </ConfirmDeletePartialCompanyProfileContext.Provider>
  );
}

function renderSubjectAreaChip(subjectArea: SubjectArea) {
  return (
    <Chip
      key={subjectArea.subjectAreaKey}
      text={subjectArea.name}
      variant="secondary"
      overflow="hidden"
      textOverflow="ellipsis"
    />
  );
}

function SubjectAreaChips({
  subjectAreas,
}: Pick<CompanyProfile, 'subjectAreas'>) {
  const visibleSubjectAreas = subjectAreas.slice(0, MAX_VISIBLE_SUBJECT_AREAS);

  return (
    <Box flex flexAlign="center" gap={8} overflow="hidden">
      <Box flex gap={8} overflow="hidden">
        {visibleSubjectAreas.map(renderSubjectAreaChip)}
      </Box>
      {subjectAreas.length > MAX_VISIBLE_SUBJECT_AREAS && (
        <Text
          text={`+${subjectAreas.length - MAX_VISIBLE_SUBJECT_AREAS}`}
          variant="bodySm"
        />
      )}
    </Box>
  );
}

function ProfileTableRow({
  profile,
}: {
  profile: PartialCompanyProfileDraft | PartialCompanyProfile;
}) {
  const confirmDeletePartialCompanyProfile = React.useContext(
    ConfirmDeletePartialCompanyProfileContext,
  );

  const confirmDeletePartialCompanyProfileDraft = React.useContext(
    ConfirmDeletePartialCompanyProfileDraftContext,
  );

  const stageForRemoval = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (isPartialCompanyProfileAndNotPartialCompanyProfileDraft(profile)) {
      confirmDeletePartialCompanyProfile.openModal(profile);

      return;
    }

    confirmDeletePartialCompanyProfileDraft.openModal(profile);
  };

  const highlight = isPartialCompanyProfileAndNotPartialCompanyProfileDraft(
    profile,
  )
    ? confirmDeletePartialCompanyProfile.isOpen &&
      confirmDeletePartialCompanyProfile.partialCompanyProfile
        ?.companyProfileKey === profile.companyProfileKey
    : confirmDeletePartialCompanyProfileDraft.isOpen &&
      confirmDeletePartialCompanyProfileDraft.partialCompanyProfileDraft
        ?.companyProfileDraftKey === profile.companyProfileDraftKey;

  return (
    <TableRow
      highlight={highlight}
      to={
        isPartialCompanyProfileAndNotPartialCompanyProfileDraft(profile)
          ? `/profiles/${profile.companyProfileKey}`
          : `/profiles/create-profile/${profile.companyProfileDraftKey}`
      }
    >
      <TableCell
        text={profile.name ?? translate('label.general.untitled-draft')}
        width={TABLE_COLUMN_WIDTHS.profiles}
      />

      <TableCell
        text={
          isPartialCompanyProfileAndNotPartialCompanyProfileDraft(profile)
            ? profile.index + 1
            : ''
        }
        width={TABLE_COLUMN_WIDTHS.priority}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.subjects}>
        <SubjectAreaChips subjectAreas={profile.subjectAreas ?? []} />
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        {isPartialCompanyProfileAndNotPartialCompanyProfileDraft(profile) ? (
          <Status variant="success" tx="label.profiles.profile-status.live" />
        ) : (
          <Status variant="warning" tx="label.profiles.profile-status.draft" />
        )}
      </TableCell>

      <UpdatedByTableCell
        fallbackTx="placeholder.unknown-user"
        editMeta={profile.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedBy}
      />

      <UpdatedAtTableCell
        editMeta={profile.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedAt}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight hoverCell>
        <Box ml="auto">
          <Tooltip placement="left" titleTx="label.tooltip.delete">
            <IconButton onClick={stageForRemoval} icon="trash-outline" />
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderProfileTableRow(
  profile: PartialCompanyProfileDraft | PartialCompanyProfile,
) {
  const profileKey = isPartialCompanyProfileAndNotPartialCompanyProfileDraft(
    profile,
  )
    ? profile.companyProfileKey
    : profile.companyProfileDraftKey;

  return <ProfileTableRow key={profileKey} profile={profile} />;
}

function ProfilesTable() {
  const getCompanyProfileDraftsIsLoading = useSelector(
    CompanyProfileDraftsSelector.getCompanyProfileDraftsIsLoading,
  );

  const getCompanyProfilesIsLoading = useSelector(
    CompanyProfilesSelector.getCompanyProfilesIsLoading,
  );

  const sortedProfiles = useSelector(
    CompanyProfilesSelector.profilesSortedAfterSortState,
  );

  const profilesSortState = useSelector(
    CompanyProfilesSelector.profilesSortState,
  );

  const dispatch = useDispatch();

  const onSort = (clickedColumnKey: string) => {
    dispatch(companyProfilesActions.sortOnColumn(clickedColumnKey));
  };

  if (sortedProfiles.length === 0) {
    if (getCompanyProfileDraftsIsLoading || getCompanyProfilesIsLoading) {
      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.profiles.table.profile" />
              <TableHead tx="label.profiles.table.priority" />
              <TableHead tx="label.profiles.table.subjects" />
              <TableHead tx="label.profiles.table.status" />
              <TableHead tx="label.profiles.table.updated-by" />
              <TableHead tx="label.profiles.table.updated-at" />
              <TableHead fixedRight />
            </TableRow>
          </TableHeader>
          <TableBody>
            <TablePlaceholderRows
              rowCount={10}
              layout={Object.values(TABLE_COLUMN_WIDTHS)}
            />
          </TableBody>
        </Table>
      );
    }

    return (
      <EmptyState
        titleTx="label.profiles.empty-state.title"
        buttonTx="label.profiles.empty-state.button"
        to="/profiles/create-profile"
      />
    );
  }

  return (
    <Table>
      <TableHeader
        onSort={onSort}
        orderBy={profilesSortState.orderByKey}
        sortableColumns={Object.values(profilesSortableKeys)}
        sortOrder={profilesSortState.sortOrder}
      >
        <TableRow>
          <TableHead
            tx="label.profiles.table.profile"
            name={profilesSortableKeys.profiles}
          />
          <TableHead
            tx="label.profiles.table.priority"
            name={profilesSortableKeys.priority}
          />
          <TableHead tx="label.profiles.table.subjects" />
          <TableHead
            tx="label.profiles.table.status"
            name={profilesSortableKeys.status}
          />
          <TableHead
            tx="label.profiles.table.updated-by"
            name={profilesSortableKeys.updatedBy}
          />
          <TableHead
            tx="label.profiles.table.updated-at"
            name={profilesSortableKeys.updatedAt}
          />
          <TableHead fixedRight />
        </TableRow>
      </TableHeader>
      <TableBody>{sortedProfiles.map(renderProfileTableRow)}</TableBody>
    </Table>
  );
}

function ProfilesContent() {
  const { setIsOpen } = React.useContext(UpdatePriorityContext);

  const { isOpen, closeModal, openModal } = useModalState();

  const openUpdatePriorityModal = () => setIsOpen(true);

  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          color="pageTitle"
          tx="title.profiles.dashboard-new"
          variant="titleMd"
          as="h1"
        />

        <Box flex gap={24}>
          <Button
            variant="secondary"
            tx="button.profiles.update-priority"
            onClick={openUpdatePriorityModal}
          />

          <Button
            variant="primary"
            tx="button.profiles.create"
            onClick={openModal}
          />
        </Box>
      </Box>

      <ProfilesTable />

      <CreateProfileModal isOpen={isOpen} closeModal={closeModal} />
    </React.Fragment>
  );
}

function PriorityListItem(props: { id: string; name: string }) {
  const { id, name } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box flex style={style} ref={setNodeRef} flexAlign="center">
      <IconButton
        ml={24}
        cursor={isDragging ? 'grabbing' : 'grab'}
        icon="drag"
        zIndex={1}
        {...attributes}
        {...listeners}
      />
      <Input
        labelTx="label.profiles.update-priority.list-item-header"
        value={name}
      />
    </Box>
  );
}

function renderPriorityListItem({ id, name }: { id: string; name: string }) {
  return <PriorityListItem key={`profile-item-${id}`} name={name} id={id} />;
}

function UpdatePriorityModal() {
  const { isOpen, setIsOpen } = React.useContext(UpdatePriorityContext);
  const closeModal = () => setIsOpen(false);

  const profilesPriorityList = useSelector(
    CompanyProfilesSelector.profilesPriorityList,
  );

  const updateCompanyProfileIndexesIsLoading = useSelector(
    CompanyProfilesSelector.updateCompanyProfileIndexesIsLoading,
  );

  const [items, setItems] = React.useState(profilesPriorityList);

  const onDragEnd = (e: DragEndEvent) => {
    if (e.active.id !== e.over?.id) {
      const oldIndex = items.findIndex((item) => item.id === e.active.id);
      const newIndex = items.findIndex((item) => item.id === e.over?.id);

      const newList = arrayMove(items, oldIndex, newIndex);

      setItems(newList);
    }
  };

  const dispatch = useDispatch();

  const updateCompanyProfileIndexes = async () => {
    const companyProfiles = items.map((item, index) => ({
      index,
      companyProfileKey: item.id,
    }));

    const res = await dispatch(
      updateCompanyProfileIndexesThunk({ companyProfiles }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      closeModal();
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  React.useEffect(() => {
    if (isOpen && !updateCompanyProfileIndexesIsLoading) {
      setItems(profilesPriorityList);
    }
  }, [isOpen, profilesPriorityList, updateCompanyProfileIndexesIsLoading]);

  return (
    <Modal width={500} isOpen={isOpen} onClose={closeModal}>
      <ModalContentContainer>
        <ModalHeaderContainer pb={16}>
          <Box flex flexDirection="column" gap={16}>
            <ModalTitle tx="label.profiles.update-priority.title" />
            <Text
              color="updatePriorityModalDescription"
              tx="label.profiles.update-priority.description"
              variant="bodyMd"
            />
          </Box>
        </ModalHeaderContainer>

        <ModalBodyContainer py={0}>
          <Styled.PriorityList
            pt={4}
            height={MAX_VISIBLE_PROFILES_IN_PRIORITY_LIST * 62 + 6}
          >
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={onDragEnd}
              sensors={sensors}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.map(renderPriorityListItem)}
              </SortableContext>
            </DndContext>
          </Styled.PriorityList>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button tx="button.cancel" variant="tertiary" onClick={closeModal} />
          <Button
            tx="button.update"
            variant="primary"
            onClick={updateCompanyProfileIndexes}
            isLoading={updateCompanyProfileIndexesIsLoading}
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </Modal>
  );
}

function ConfirmDeletePartialCompanyProfileDraftModal() {
  const dispatch = useDispatch();

  const deleteCompanyProfileDraftIsLoading = useSelector(
    CompanyProfileDraftsSelector.deleteCompanyProfileDraftIsLoading,
  );

  const confirmDeletePartialCompanyProfileDraft = React.useContext(
    ConfirmDeletePartialCompanyProfileDraftContext,
  );

  const deleteProfileDraft = async () => {
    if (!confirmDeletePartialCompanyProfileDraft.partialCompanyProfileDraft) {
      return;
    }

    const res = await dispatch(
      deleteCompanyProfileDraftThunk(
        confirmDeletePartialCompanyProfileDraft.partialCompanyProfileDraft
          .companyProfileDraftKey,
      ),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      confirmDeletePartialCompanyProfileDraft.closeModal();
    }
  };

  return (
    <Confirm
      cancelTx="prompt.delete-company-profile-draft.cancel"
      confirmTx="prompt.delete-company-profile-draft.confirm"
      isLoading={deleteCompanyProfileDraftIsLoading}
      isOpen={confirmDeletePartialCompanyProfileDraft.isOpen}
      messageTx="prompt.delete-company-profile-draft.message"
      messageTxArgs={{
        name:
          confirmDeletePartialCompanyProfileDraft?.partialCompanyProfileDraft
            ?.name ?? translate('label.general.untitled-draft'),
      }}
      onCancel={confirmDeletePartialCompanyProfileDraft.closeModal}
      onConfirm={deleteProfileDraft}
      titleTx="prompt.delete-company-profile-draft.title"
    />
  );
}

function ConfirmDeletePartialCompanyProfileModal() {
  const dispatch = useDispatch();

  const deleteCompanyProfileIsLoading = useSelector(
    CompanyProfilesSelector.deleteCompanyProfileIsLoading,
  );

  const confirmDeletePartialCompanyProfile = React.useContext(
    ConfirmDeletePartialCompanyProfileContext,
  );

  const deleteProfile = async () => {
    if (!confirmDeletePartialCompanyProfile.partialCompanyProfile) {
      return;
    }

    const res = await dispatch(
      deleteCompanyProfileThunk(
        confirmDeletePartialCompanyProfile.partialCompanyProfile
          .companyProfileKey,
      ),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      confirmDeletePartialCompanyProfile.closeModal();
    }
  };

  return (
    <Confirm
      cancelTx="prompt.delete-company-profile.cancel"
      confirmTx="prompt.delete-company-profile.confirm"
      isLoading={deleteCompanyProfileIsLoading}
      isOpen={confirmDeletePartialCompanyProfile.isOpen}
      messageTx="prompt.delete-company-profile.message"
      messageTxArgs={{
        name:
          confirmDeletePartialCompanyProfile?.partialCompanyProfile?.name ??
          translate('label.general.untitled-draft'),
      }}
      onCancel={confirmDeletePartialCompanyProfile.closeModal}
      onConfirm={deleteProfile}
      titleTx="prompt.delete-company-profile.title"
    />
  );
}

export function Profiles() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCompanyProfileDraftsThunk());
  }, [dispatch]);

  return (
    <PageContainer>
      <BreadcrumbsToolbar
        breadcrumbListItems={[
          { to: '/profiles', tx: 'label.breadcrumbs.profiles.profiles' },
        ]}
      />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <UpdatePriorityProvider>
              <ConfirmDeletePartialCompanyProfileDraftProvider>
                <ConfirmDeletePartialCompanyProfileProvider>
                  <ProfilesContent />

                  <UpdatePriorityModal />
                  <ConfirmDeletePartialCompanyProfileDraftModal />
                  <ConfirmDeletePartialCompanyProfileModal />
                </ConfirmDeletePartialCompanyProfileProvider>
              </ConfirmDeletePartialCompanyProfileDraftProvider>
            </UpdatePriorityProvider>
          </InnerContentContainer>
        </ContentContainer>

        {/*  Implement later: <ProfilesSidebar /> */}
      </InnerPageContainer>
    </PageContainer>
  );
}

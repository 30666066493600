import { company } from './company';
import { companyProfiles } from './company-profiles';
import { connect } from './connect';
import { jobs } from './jobs';
import { perkCategories } from './perk-categories';
import { subjectAreaCategories } from './subject-area-categories';

export const v2 = {
  company,
  companyProfiles,
  jobs,
  perkCategories,
  subjectAreaCategories,
  connect,
};

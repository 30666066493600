import { joiResolver } from '@hookform/resolvers/joi';
import {
  Button,
  ControlledSelect,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  OptionProps,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import { FormProvider, useForm } from 'react-hook-form';

import { PickProfileForm, PickProfileValidation } from '../../models';
import { Logger } from '../../services';
import { CompanyProfilesSelector, useSelector } from '../../store';

export function PickProfileModal(props: {
  onSave: (subjectAreaKeys: string[]) => void;
  isOpen: boolean;
  closeModal: () => void;
}) {
  const { onSave, isOpen, closeModal } = props;

  const formMethods = useForm<PickProfileForm>({
    resolver: joiResolver(PickProfileValidation),
    defaultValues: {
      companyProfileKey: '',
    },
  });

  const profiles = useSelector(CompanyProfilesSelector.profilesSortedAfterName);

  const options: OptionProps[] = !profiles.length
    ? [
        {
          value: ' ',
          tx: 'label.pick-profile-modal.no-profiles',
          notPickable: true,
        },
      ]
    : profiles.map((profile) => ({
        value: profile.companyProfileKey,
        text: profile.name,
        subtitle: profile.subjectAreas
          .map((subjectArea) => subjectArea.name)
          .join(', '),
      }));

  const handleConfirm = formMethods.handleSubmit(
    (data) => {
      const profile = profiles.find(
        (profile) => profile.companyProfileKey === data.companyProfileKey,
      );
      if (!profile) return;

      onSave(
        profile.subjectAreas.map((subjectArea) => subjectArea.subjectAreaKey),
      );
      closeModal();
      formMethods.reset();
    },
    (err) => {
      Logger.warning('pickProfileModal Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <Modal width={600} height={600} isOpen={isOpen} onClose={closeModal}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle tx="label.pick-profile-modal.title" />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <FormProvider {...formMethods}>
            <ControlledSelect
              name="companyProfileKey"
              labelTx="label.pick-profile-modal.select"
              options={options}
            />
          </FormProvider>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button onClick={closeModal} tx="button.cancel" variant="tertiary" />
          <Button
            onClick={handleConfirm}
            tx="button.confirm"
            variant="primary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </Modal>
  );
}

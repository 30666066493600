import { ChartOptions } from 'chart.js';
import 'chart.js/auto';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { translate } from '../../../i18n';
import { theme } from '../../../theme';
import { isNumber, numberFormatter } from '../../../utils';
import { Box } from '../../box';
import { Button } from '../../button';
import { Card, CardHeader } from '../../card';
import { Icon } from '../../icon';
import { PlaceholderChart } from '../../placeholder-bar';
import { Text } from '../../text';
import { colors, compareColors } from './line-chart.constants';
import { LineChartBodyProps, LineChartProps } from './line-chart.types';
import { externalTooltipHandler } from './line-chart.utils';

const fontSettings: ChartOptions<'line'>['font'] = {
  family: 'RobotoFlex-Body',
  size: 13,
};

function ChartBody(props: LineChartBodyProps) {
  const {
    datasets,
    labels,

    tooltipTransformer,

    xLabelMaxTicksLimit,

    placeholderButtonHref,
    placeholderButtonText,
    placeholderButtonTo,
    placeholderButtonTx,
    placeholderButtonTxArgs,
    placeholderButtonVariant,

    placeholderIcon = 'presentation-chart-bar-outline',
    placeholderIconColor = 'chartEmptyStateIcon',
    placeholderIconSize = 24,

    placeholderTitle,
    placeholderTitleTx,
    placeholderTitleTxArgs,

    placeholderSubtitle,
    placeholderSubtitleTx,
    placeholderSubtitleTxArgs,

    isLoading,

    comparedLabels,

    legendPosition,
  } = props;

  const [hiddenLines, setHiddenLines] = React.useState<number[]>([]);

  React.useEffect(() => {
    setHiddenLines([]);
  }, [datasets]);

  if (isLoading) {
    return <PlaceholderChart width="100%" height="100%" />;
  }

  const isEmpty = datasets.every((dataset) =>
    dataset.data.every((value) => value === 0),
  );

  const isComparing = datasets.some((dataset) => dataset.isCompared);

  const data: React.ComponentProps<typeof Line>['data'] = isEmpty
    ? { datasets: [], labels: [] }
    : {
        labels,
        datasets: datasets.map((dataset, i) => {
          const color = isComparing
            ? compareColors[i % compareColors.length]
            : colors[i % colors.length];

          return {
            segment: {
              borderDash: dataset.isCompared ? () => [5, 5] : undefined,
            },
            borderColor: color,
            backgroundColor: color,
            borderWidth: 2,
            hidden: hiddenLines.includes(i),
            data: dataset.data,
            pointBackgroundColor: color,
            pointRadius: 0,
            label: dataset.labelTx
              ? translate(dataset.labelTx, dataset.labelTxArgs ?? {}).toString()
              : dataset.label,
          };
        }),
      };

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: tooltipTransformer
        ? {
            mode: 'nearest',
            intersect: false,
            backgroundColor: theme.colors['chartTooltipBackground'],
            titleColor: theme.colors['chartTooltipColor'],
            bodyColor: theme.colors['chartTooltipColor'],
            borderColor: theme.colors['chartTooltipBorder'],
            borderWidth: 1,
            padding: 12,
            titleMarginBottom: 8,
            bodySpacing: 8,
            usePointStyle: true,
            callbacks: {
              title() {
                return '';
              },
              label: tooltipTransformer,
            },
          }
        : {
            enabled: false,
            position: 'nearest',
            external(ctx) {
              externalTooltipHandler(ctx, {
                isComparing,
                comparedLabels,
              });
            },
          },
      legend: {
        display: !isEmpty,
        position: legendPosition,
        labels: {
          font: fontSettings,
          useBorderRadius: true,
          borderRadius: 5,
          boxWidth: 12,
          boxHeight: 12,
          pointStyle: 'circle',
          color: theme.colors['chartLabelColor'],
          filter(legendItem) {
            if (!isNumber(legendItem.datasetIndex)) return false;

            return !datasets[legendItem.datasetIndex].isCompared;
          },
          generateLabels(chart) {
            return chart.data.datasets.map((dataset, i) => {
              const color = isComparing
                ? compareColors[i % compareColors.length]
                : colors[i % colors.length];

              return {
                index: i,
                datasetIndex: i,
                text: dataset.label ?? '',
                fillStyle: color,
                borderRadius: 6,
                strokeStyle: color,
                hidden: hiddenLines?.includes(i),
              };
            });
          },
        },
        onClick(_, legendItem) {
          const index = legendItem.datasetIndex!;
          const comparedDataset = datasets[index + 1];

          if (comparedDataset && comparedDataset.isCompared) {
            if (
              hiddenLines.includes(index) &&
              hiddenLines.includes(index + 1)
            ) {
              setHiddenLines((prev) =>
                prev.filter((i) => i !== index && i !== index + 1),
              );
              return;
            }

            setHiddenLines((prev) => [...prev, index, index + 1]);
            return;
          }

          if (hiddenLines.includes(index)) {
            setHiddenLines((prev) => prev.filter((i) => i !== index));
            return;
          }

          setHiddenLines((prev) => [...prev, index]);
        },
      },
    },
    normalized: true,
    elements: { line: { tension: 0.4 } },
    interaction: { intersect: false, mode: 'nearest', axis: 'x' },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          align: 'inner',
          color: theme.colors['chartLabelColor'],
          maxRotation: 0,
          minRotation: 0,
          font: fontSettings,
          maxTicksLimit: xLabelMaxTicksLimit ?? 6,
          autoSkip: true,
        },
      },
      y: {
        beginAtZero: true,
        grid: { color: theme.colors['chartGridLinesColor'] },
        ticks: {
          color: theme.colors['chartLabelColor'],
          font: fontSettings,
          maxTicksLimit: 5,
          autoSkip: true,
          precision: 0,
          callback(tickValue) {
            return numberFormatter.format(parseInt(tickValue.toString()));
          },
        },
      },
    },
  };

  return (
    <Box relative overflow="hidden" flexGrow={1}>
      {isEmpty &&
        (placeholderTitleTx ||
          placeholderTitle ||
          placeholderSubtitle ||
          placeholderSubtitleTx) && (
          <Box absolute flexGrow={1} inset={0} pt={24} pr={8} pb={40} pl={32}>
            <Box
              flex
              flexAlign="center"
              flexJustify="center"
              flexDirection="column"
              gap={16}
              width="100%"
              height="100%"
              backgroundColor="chartEmptyStateBackground"
              px={24}
            >
              <Icon
                size={placeholderIconSize}
                name={placeholderIcon}
                color={placeholderIconColor}
              />

              <Box flex flexDirection="column" gap={4}>
                {(placeholderTitle || placeholderTitleTx) && (
                  <Text
                    color="chartEmptyStateText"
                    text={placeholderTitle}
                    textAlign="center"
                    tx={placeholderTitleTx}
                    txArgs={placeholderTitleTxArgs}
                    variant="bodyMd"
                  />
                )}

                {(placeholderSubtitle || placeholderSubtitleTx) && (
                  <Text
                    color="chartEmptyStateText"
                    text={placeholderSubtitle}
                    textAlign="center"
                    tx={placeholderSubtitleTx}
                    txArgs={placeholderSubtitleTxArgs}
                    variant="bodySm"
                  />
                )}
              </Box>

              {placeholderButtonVariant && (
                <Button
                  href={placeholderButtonHref}
                  text={placeholderButtonText}
                  to={placeholderButtonTo}
                  tx={placeholderButtonTx}
                  txArgs={placeholderButtonTxArgs}
                  variant={placeholderButtonVariant}
                />
              )}
            </Box>
          </Box>
        )}

      <Line data={data} options={options} />
    </Box>
  );
}

export function LineChart(props: LineChartProps) {
  const {
    actionElement,
    footerElement,
    datasets,
    tooltipTransformer,
    labels,
    comparedLabels,
    xLabelMaxTicksLimit,

    avatarFallbackLetter,
    avatarSrc,
    avatarVariant,

    subtitle,
    subtitleText,
    subtitleTx,
    subtitleTxArgs,

    description,
    descriptionTx,
    descriptionTxArgs,

    title,
    titleTx,
    titleTxArgs,

    placeholderButtonHref,
    placeholderButtonText,
    placeholderButtonTo,
    placeholderButtonTx,
    placeholderButtonTxArgs,
    placeholderButtonVariant,

    placeholderIcon,
    placeholderIconColor,
    placeholderIconSize,

    placeholderTitle,
    placeholderTitleTx,
    placeholderTitleTxArgs,

    placeholderSubtitle,
    placeholderSubtitleTx,
    placeholderSubtitleTxArgs,

    statusVariant,
    statusVariantText,
    statusVariantTextVariant,
    statusVariantTx,
    statusVariantTxArgs,

    unit,
    unitPosition,
    unitTx,

    isLoading,

    legendPosition,

    ...rest
  } = props;

  return (
    <Card {...rest}>
      <CardHeader
        avatarFallbackLetter={avatarFallbackLetter}
        avatarSrc={avatarSrc}
        avatarVariant={avatarVariant}
        actionElement={actionElement}
        description={description}
        descriptionTx={descriptionTx}
        descriptionTxArgs={descriptionTxArgs}
        title={title}
        titleTx={titleTx}
        titleTxArgs={titleTxArgs}
        statusVariant={statusVariant}
        statusVariantText={statusVariantText}
        statusVariantTextVariant={statusVariantTextVariant}
        statusVariantTx={statusVariantTx}
        statusVariantTxArgs={statusVariantTxArgs}
        subtitle={subtitle}
        subtitleText={subtitleText}
        subtitleTx={subtitleTx}
        subtitleTxArgs={subtitleTxArgs}
        unit={unit}
        unitPosition={unitPosition}
        unitTx={unitTx}
        isLoading={isLoading}
      />

      <ChartBody
        datasets={datasets}
        labels={labels}
        comparedLabels={comparedLabels}
        placeholderButtonHref={placeholderButtonHref}
        placeholderButtonText={placeholderButtonText}
        placeholderButtonTo={placeholderButtonTo}
        placeholderButtonTx={placeholderButtonTx}
        placeholderButtonTxArgs={placeholderButtonTxArgs}
        placeholderButtonVariant={placeholderButtonVariant}
        placeholderIcon={placeholderIcon}
        placeholderIconColor={placeholderIconColor}
        placeholderIconSize={placeholderIconSize}
        placeholderSubtitle={placeholderSubtitle}
        placeholderSubtitleTx={placeholderSubtitleTx}
        placeholderSubtitleTxArgs={placeholderSubtitleTxArgs}
        placeholderTitle={placeholderTitle}
        placeholderTitleTx={placeholderTitleTx}
        placeholderTitleTxArgs={placeholderTitleTxArgs}
        tooltipTransformer={tooltipTransformer}
        xLabelMaxTicksLimit={xLabelMaxTicksLimit}
        isLoading={isLoading}
        legendPosition={legendPosition}
      />

      {footerElement}
    </Card>
  );
}

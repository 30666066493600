import { TimeSeriesConfig } from '../../models';
import { INITIAL_DATA_STATE } from '../store.constants';
import { InsightsState } from './insights.types';

const timeSeriesConfig: TimeSeriesConfig = {
  selectedItems: [{ type: 'total' }],
  selectedRange: {
    compare: null,
    interval: 'last_30_days',
  },
  showUnique: false,
};

export const initialInsightsState: InsightsState = {
  liveInsights: INITIAL_DATA_STATE,

  items: INITIAL_DATA_STATE,
  timeSeriesConfig,
  timeSeries: INITIAL_DATA_STATE,

  timeSeriesByProduct: INITIAL_DATA_STATE,
  timeSeriesConfigByProduct: timeSeriesConfig,

  exportInsights: INITIAL_DATA_STATE,

  estimatedValue: INITIAL_DATA_STATE,
};

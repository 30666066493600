import { FirebaseError } from '@firebase/util';
import { GeneralApiProblem } from '@orbiapp/components';
import * as Sentry from '@sentry/browser';

import { AlertType } from '../../models';

interface HandleFirebaseError {
  alertType: AlertType;
  rejectValue: GeneralApiProblem;
}

export const handleFirebaseError = (
  firebaseError: FirebaseError,
): HandleFirebaseError => {
  Sentry.addBreadcrumb({
    data: {
      code: firebaseError.code,
      message: firebaseError.message,
      name: firebaseError.name,
    },
  });

  switch (firebaseError.code) {
    case 'auth/user-not-found':
      /*
        Thrown if there is no user corresponding to the given email.
      */
      return {
        alertType: 'sign-in',
        rejectValue: { kind: 'bad-data' },
      };

    case 'auth/wrong-password':
      /*
        Thrown if the password is invalid for the given email, 
        or the account corresponding to the email does not have a password set.
      */
      return {
        alertType: 'sign-in',
        rejectValue: { kind: 'bad-data' },
      };

    case 'auth/user-disabled':
      /*
        Thrown if the user account has been disabled by an administrator. 
        Accounts can be enabled or disabled in the Firebase Console, 
        the Auth section and Users subsection.
      */
      return {
        alertType: 'firebase/user-disabled',
        rejectValue: { kind: 'bad-data' },
      };

    case 'auth/too-many-requests':
      /*
        Thrown if requests are blocked from a device due to unusual activity. 
        Trying again after some delay would unblock.
      */
      return {
        alertType: 'firebase/too-many-requests',
        rejectValue: { kind: 'bad-data' },
      };

    case 'auth/network-request-failed':
      /*
        Thrown if a network error (such as timeout, 
        interrupted connection or unreachable host) has occurred.
      */
      return {
        alertType: 'firebase/network-request-failed',
        rejectValue: { kind: 'bad-data' },
      };
  }

  return {
    alertType: 'unknown',
    rejectValue: { kind: 'bad-request' },
  };
};

import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const PriorityList = styled(Box)`
  overflow-x: hidden;
  overflow-y: auto;

  input {
    border: unset;
    pointer-events: none;
  }

  ${(props) =>
    css`
      border: 1px solid ${props.theme.colors.updatePriorityModalListBorder};
      border-radius: ${props.theme.borderRadii.s}px;
    `}
`;

export const Styled = { PriorityList };

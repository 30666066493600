import {
  ControlledDatePicker,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  IconButton,
  InputChip,
  InputChips,
  Text,
  Tooltip,
  TrailingInputBox,
  isTxString,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  CreateJobForm,
  JOB_ORIGINAL_URL_MAX_LENGTH,
  JOB_QUALIFICATIONS_MAX_COUNT,
  JOB_QUALIFICATION_MAX_LENGTH,
  UpdateJobDraftSchema,
} from '../../../../../models';
import { getOptionalLabelText } from '../../../../../utils';
import { useSaveField } from '../create-job.helpers';

const AddQualification = () => {
  const formContext = useFormContext<CreateJobForm>();

  const [qualification, setQualification] = React.useState('');

  const saveQualifications = useSaveField('qualifications', {
    schema: UpdateJobDraftSchema.qualifications,
    defaultValue: formContext.getValues('qualifications'),
  });

  const qualifications = useWatch({
    control: formContext.control,
    name: 'qualifications',
  });

  const handleAddChip = (value: string) => {
    if (qualifications?.includes(value)) return;

    formContext.setValue(
      'qualifications',
      (qualifications ?? []).concat(value),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
    setQualification('');
  };

  const handlePlusClick = () => {
    if (!qualification) return;

    handleAddChip(qualification);
  };

  const handleRemoveChip = (id: number) => {
    formContext.setValue(
      'qualifications',
      qualifications?.filter((_, index) => index !== id) ?? [],
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
  };

  const handleInputChipValueChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setQualification(e.target.value);
  };

  const chips: InputChip<number>[] = (qualifications ?? []).map(
    (qualification, index) => ({
      text: qualification,
      id: index,
      maxWidth: '65ch',
    }),
  );

  return (
    <InputChips
      onBlur={saveQualifications}
      value={qualification}
      chips={chips}
      showInput={!!qualification}
      label={getOptionalLabelText('label.job-form.qualifications')}
      errorTx={
        qualifications?.includes(qualification)
          ? 'errors.qualification.qualification-already-exists'
          : isTxString(formContext.formState.errors.qualifications?.message)
          ? formContext.formState.errors.qualifications?.message
          : undefined
      }
      maxChips={JOB_QUALIFICATIONS_MAX_COUNT}
      maxLength={JOB_QUALIFICATION_MAX_LENGTH}
      onAddChip={handleAddChip}
      onRemoveChip={handleRemoveChip}
      onChange={handleInputChipValueChange}
      trailingElement={
        <TrailingInputBox>
          <Tooltip placement="left" titleTx="label.tooltip.add">
            <IconButton
              disabled={chips.length >= JOB_QUALIFICATIONS_MAX_COUNT}
              icon="plus-circle-outline"
              onClick={handlePlusClick}
            />
          </Tooltip>
        </TrailingInputBox>
      }
    />
  );
};

function _AdditionalInformation() {
  const formContext = useFormContext<CreateJobForm>();

  const saveLastApplicationDate = useSaveField('endDate', {
    schema: UpdateJobDraftSchema.endDate,
    defaultValue: formContext.getValues('endDate'),
  });
  const saveExternalJobUrl = useSaveField('originalJobUrl', {
    schema: UpdateJobDraftSchema.originalJobUrl,
    defaultValue: formContext.getValues('originalJobUrl'),
  });

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.2.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.2.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <ControlledDatePicker
        labelTx="label.job-form.last-application-date"
        name="endDate"
        onBlur={saveLastApplicationDate}
        type="datetime-local"
      />

      <ControlledInput
        label={getOptionalLabelText('label.job-form.original-job-url')}
        maxLength={JOB_ORIGINAL_URL_MAX_LENGTH}
        name="originalJobUrl"
        onBlur={saveExternalJobUrl}
      />

      <AddQualification />
    </FormSection>
  );
}

_AdditionalInformation.displayName = 'AdditionalInformation';
export const AdditionalInformation = React.memo(_AdditionalInformation);

import {
  Box,
  Button,
  Icon,
  Image,
  Link,
  ResponsiveBox,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSideAltAppUser,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { assets } from '../../../assets';
import { NotOnboardedToolbar } from '../../../components';
import { OPEN_APP_URL, ORBI_URL } from '../../../constants';
import { AccountSelector, useSelector } from '../../../store';

export function AccessDeniedStudent() {
  const onlyHasStudentWorkspace = useSelector(
    AccountSelector.selectOnlyHasStudentWorkspace,
  );

  if (!onlyHasStudentWorkspace) {
    return <Navigate to="/insights" />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <SplitScreenContainer>
        <SplitScreenLeftSideAltAppUser />

        <SplitScreenContentContainer>
          <Icon
            name="question-mark-circle-solid"
            size={100}
            color="accessDeniedStudentIcon"
          />

          <Box flex flexDirection="column" gap={8}>
            <Text
              color="accessDeniedTitle"
              variant="titleMd"
              tx="label.auth.access-denied-student.title"
              textAlign="center"
            />

            <ResponsiveBox
              sm={
                <Text
                  color="accessDeniedSubtitle"
                  tx="label.auth.access-denied-student.subtitle"
                  textAlign="center"
                />
              }
            >
              <Text
                color="accessDeniedSubtitle"
                tx="label.auth.access-denied-student.subtitle-alt"
                textAlign="center"
              />
            </ResponsiveBox>

            <ResponsiveBox
              sm={
                <Image
                  width={250}
                  height={250}
                  mx="auto"
                  src={assets.appQrCode}
                  alt=""
                />
              }
            >
              <Box width="100%">
                <Link href={OPEN_APP_URL} plain>
                  <Button
                    variant="primary"
                    tx="button.enter-the-app"
                    mt={32}
                    width="100%"
                    large
                  />
                </Link>
              </Box>
            </ResponsiveBox>
          </Box>

          <Box absolute bottom={40}>
            <Link
              tx="link.auth.back-to-orbi"
              href={ORBI_URL}
              variant="secondary"
              small
            />
          </Box>
        </SplitScreenContentContainer>
      </SplitScreenContainer>
    </React.Fragment>
  );
}

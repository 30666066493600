import { Banner, LAYOUT_Z_INDEX } from '@orbiapp/components';
import React from 'react';

import {
  CompanyProfilesSelector,
  ModuleSettingsSelector,
  useSelector,
} from '../../store';

export function NoProfilesBanner() {
  const hasProfiles = useSelector(CompanyProfilesSelector.selectHasProfiles);
  const companyProfilesStatus = useSelector(
    CompanyProfilesSelector.selectStatus,
  );
  const enableProfiles = useSelector(
    ModuleSettingsSelector.selectEnableProfiles,
  );

  const [hasDismissed, setHasDismissed] = React.useState(false);

  if (
    !enableProfiles ||
    hasProfiles ||
    hasDismissed ||
    companyProfilesStatus === 'pending'
  ) {
    return null;
  }

  const handleDismiss = () => {
    setHasDismissed(true);
  };

  return (
    <Banner
      buttonTo="/profiles/create-profile"
      buttonTx="label.no-profiles-banner.button"
      messageTx="label.no-profiles-banner.subtitle"
      onClose={handleDismiss}
      titleTx="label.no-profiles-banner.title"
      variant="information"
      zIndex={LAYOUT_Z_INDEX.pageToolbar}
    />
  );
}

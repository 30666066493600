import { createEntityAdapter } from '@reduxjs/toolkit';

import { PartialCompanyAd } from '../../models';

export const partialCompanyAdAdapter = createEntityAdapter<
  PartialCompanyAd,
  string
>({
  selectId: (paritalCompanyAd) => paritalCompanyAd.companyAdKey,
});

import { Location } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const getLocationsThunk = createAsyncThunk<
  Location[],
  undefined,
  ThunkApiConfig
>('locations/get-locations', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.locations.getLocations, undefined);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

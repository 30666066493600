import {
  Box,
  Checkbox,
  Icon,
  Link,
  Menu,
  MenuDivider,
  MenuFooter,
  MenuItem,
  ResponsiveBox,
  Sheet,
  Status,
  Text,
  TinySelectInput,
  Tooltip,
  Trans,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import ReactDOM from 'react-dom';

import { useOrbiBusinessCalendar } from '../../../helpers';
import { InsightsItem, SelectedInsightsItem } from '../../../models';
import { ModuleSettingsSelector, useSelector } from '../../../store';
import { Styled } from './select-product-menu.styled';

const MAX_NUMBER_OF_SELECTED_ITEMS = 8;

interface SelectProductProps {
  defaultValue: SelectedInsightsItem[];
  onApply: (items: SelectedInsightsItem[]) => void;
  selectedCount: number;
  companyAdItems: InsightsItem[] | null;
  jobItems: InsightsItem[] | null;
  companyProfileItems: InsightsItem[] | null;
  offerItems: InsightsItem[] | null;
}

interface InsightsMenuItemProps {
  label: string;
  isSelected: boolean;
  disabled: boolean;
  toggleMenuItem: () => void;
}

interface SelectProductMenuProps
  extends Omit<SelectProductProps, 'defaultValue'> {
  selectedProducts: SelectedInsightsItem[];
  setSelectedProducts: React.Dispatch<
    React.SetStateAction<SelectedInsightsItem[]>
  >;
}

function InsightsMenuItem(props: InsightsMenuItemProps) {
  const { label, isSelected, disabled, toggleMenuItem } = props;

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const menuItemRef = React.useRef<HTMLDivElement>(null);

  if (insightsTier !== 't2') {
    const tooltipContentElement = (
      <Text variant="bodySm" color="tooltipLabel">
        <Trans
          i18nKey="label.upgrade-account-to-unlock"
          components={{
            link: (
              <Link
                style={{ display: 'inline-block' }}
                small
                variant="quaternary"
                tx="label.connect.contact-sales"
                href={orbiBusinessCalendarUrl}
              />
            ),
          }}
        />
      </Text>
    );

    return (
      <Tooltip
        clickable
        placement="right"
        contentElement={tooltipContentElement}
      >
        <MenuItem ref={menuItemRef}>
          <Box flexAlign="center" flex gap={8}>
            <Box>
              <Checkbox disabled checked={false} onChange={() => {}} />
            </Box>

            <Text
              color="checkboxLabelDisabled"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              flexGrow={1}
              as="span"
              variant="bodyMd"
              text={label}
            />

            <Icon
              minWidth={24}
              minHeight={24}
              name="lock-closed-solid"
              color="talentPoolUsersStatsCardIcon"
            />
          </Box>
        </MenuItem>
      </Tooltip>
    );
  }

  return (
    <MenuItem disabled={disabled} onClick={toggleMenuItem}>
      <Box flexAlign="center" flex gap={8}>
        <Box>
          <Checkbox
            disabled={disabled}
            checked={isSelected}
            onChange={() => {}}
          />
        </Box>

        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          flexGrow={1}
          as="span"
          variant="bodyMd"
          text={label}
        />

        {isSelected && (
          <Icon
            name="check-circle-solid"
            size={20}
            color="menuItemSelectedIcon"
          />
        )}
      </Box>
    </MenuItem>
  );
}

function SelectProductMenu(props: SelectProductMenuProps) {
  const {
    onApply,
    companyAdItems,
    companyProfileItems,
    offerItems,
    jobItems,
    selectedCount,
    selectedProducts,
    setSelectedProducts,
  } = props;

  const menuState = useMenu();

  const handleApply = () => {
    menuState.closeMenu();

    onApply(selectedProducts);
  };

  const toggleTotal = () => {
    setSelectedProducts((prev) => {
      if (prev?.find((item) => item.type === 'total')) {
        return prev?.filter((item) => item.type !== 'total');
      }

      return [...(prev ?? []), { type: 'total' }];
    });
  };

  const totalIsSelected = !!selectedProducts?.find(
    (item) => item.type === 'total',
  );

  const renderInsightsMenuItem = (item: InsightsItem, index: number) => {
    const isSelected = !!selectedProducts.find((selectedItem) => {
      switch (selectedItem.type) {
        case 'company_ad':
          return (
            selectedItem.type === item.type &&
            selectedItem.companyAdKey === item.companyAdKey
          );

        case 'company_profile':
          return (
            selectedItem.type === item.type &&
            selectedItem.companyProfileKey === item.companyProfileKey
          );

        case 'job':
          return (
            selectedItem.type === item.type &&
            selectedItem.jobKey === item.jobKey
          );

        case 'offer':
          return (
            selectedItem.type === item.type &&
            selectedItem.offerKey === item.offerKey
          );

        default:
          return false;
      }
    });

    const disabled =
      !isSelected && selectedProducts.length >= MAX_NUMBER_OF_SELECTED_ITEMS;

    const toggleMenuItem = () => {
      setSelectedProducts((prev) => {
        if (!isSelected) {
          return [...(prev ?? []), item];
        }

        return prev?.filter((selectedItem) => {
          switch (selectedItem.type) {
            case 'company_ad':
              return (
                selectedItem.type !== item.type ||
                selectedItem.companyAdKey !== item.companyAdKey
              );

            case 'company_profile':
              return (
                selectedItem.type !== item.type ||
                selectedItem.companyProfileKey !== item.companyProfileKey
              );

            case 'job':
              return (
                selectedItem.type !== item.type ||
                selectedItem.jobKey !== item.jobKey
              );

            case 'offer':
              return (
                selectedItem.type !== item.type ||
                selectedItem.offerKey !== item.offerKey
              );

            default:
              return true;
          }
        });
      });
    };

    return (
      <InsightsMenuItem
        disabled={disabled}
        isSelected={isSelected}
        key={`insights-item-${index}`}
        label={item.label}
        toggleMenuItem={toggleMenuItem}
      />
    );
  };

  return (
    <Box relative ref={menuState.menuRef}>
      <TinySelectInput
        py={8}
        px={16}
        isOpen={menuState.isOpen}
        onClick={menuState.toggleMenu}
        height="100%"
      >
        <Icon
          color="selectInsightsDateRangeIcon"
          size={22}
          name="table-cells-outline"
        />

        <Box>
          <Text
            tx="label.select-products-menu.items-selected"
            txArgs={{ count: selectedCount }}
            variant="bodySm"
            color="inputText"
          />
        </Box>

        <Icon
          size={22}
          color="selectInsightsDateRangeChevron"
          name={menuState.isOpen ? 'chevron-up' : 'chevron-down'}
        />
      </TinySelectInput>

      <Menu
        maxHeight={500}
        width={340}
        absolute
        top="100%"
        mt={8}
        isOpen={menuState.isOpen}
      >
        <Box
          flex
          flexJustify="between"
          backgroundColor="menuItemBackground"
          p={8}
          flexAlign="center"
        >
          <Text
            variant="bodySm"
            tx="label.select-products-menu.select-up-to"
            txArgs={{
              maxCount: MAX_NUMBER_OF_SELECTED_ITEMS,
            }}
          />
          <Status
            variant="info"
            tx="label.select-products-menu.selected-count"
            txArgs={{
              count: selectedProducts.length,
              maxCount: MAX_NUMBER_OF_SELECTED_ITEMS,
            }}
          />
        </Box>

        <MenuItem
          disabled={
            selectedProducts.length === MAX_NUMBER_OF_SELECTED_ITEMS &&
            !totalIsSelected
          }
          onClick={toggleTotal}
        >
          <Box gap={8} flex>
            <Checkbox checked={totalIsSelected} onChange={() => {}} />

            <Box>
              <Text tx="label.select-products-menu.total" />
              <Text
                tx="label.select-products-menu.total-description"
                variant="bodySm"
              />
            </Box>
          </Box>
        </MenuItem>

        {companyProfileItems && (
          <React.Fragment>
            <MenuDivider tx="label.select-products-menu.profiles" />
            {companyProfileItems?.map(renderInsightsMenuItem)}
          </React.Fragment>
        )}

        {jobItems && (
          <React.Fragment>
            <MenuDivider tx="label.select-products-menu.jobs" />
            {jobItems?.map(renderInsightsMenuItem)}
          </React.Fragment>
        )}

        {companyAdItems && (
          <React.Fragment>
            <MenuDivider tx="label.select-products-menu.boosters" />
            {companyAdItems?.map(renderInsightsMenuItem)}
          </React.Fragment>
        )}

        {offerItems && (
          <React.Fragment>
            <MenuDivider tx="label.select-products-menu.offers" />
            {offerItems?.map(renderInsightsMenuItem)}
          </React.Fragment>
        )}

        <MenuFooter
          primaryButtonDisabled={selectedProducts.length === 0}
          primaryButtonOnClick={handleApply}
          primaryButtonTx="button.apply"
          secondaryButtonOnClick={menuState.closeMenu}
          secondaryButtonTx="button.cancel"
        />
      </Menu>
    </Box>
  );
}

function SelectProductSheet(props: SelectProductMenuProps) {
  const {
    onApply,
    companyAdItems,
    companyProfileItems,
    jobItems,
    offerItems,
    selectedCount,
    selectedProducts,
    setSelectedProducts,
  } = props;

  const menuState = useMenu();

  const handleApply = () => {
    menuState.closeMenu();

    onApply(selectedProducts);
  };

  const toggleTotal = () => {
    setSelectedProducts((prev) => {
      if (prev?.find((item) => item.type === 'total')) {
        return prev?.filter((item) => item.type !== 'total');
      }

      return [...(prev ?? []), { type: 'total' }];
    });
  };

  const totalIsSelected = !!selectedProducts?.find(
    (item) => item.type === 'total',
  );

  const renderInsightsMenuItem = (item: InsightsItem, index: number) => {
    const isSelected = !!selectedProducts.find((selectedItem) => {
      switch (selectedItem.type) {
        case 'company_ad':
          return (
            selectedItem.type === item.type &&
            selectedItem.companyAdKey === item.companyAdKey
          );

        case 'company_profile':
          return (
            selectedItem.type === item.type &&
            selectedItem.companyProfileKey === item.companyProfileKey
          );

        case 'job':
          return (
            selectedItem.type === item.type &&
            selectedItem.jobKey === item.jobKey
          );

        case 'offer':
          return (
            selectedItem.type === item.type &&
            selectedItem.offerKey === item.offerKey
          );

        default:
          return false;
      }
    });

    const disabled =
      !isSelected && selectedProducts.length >= MAX_NUMBER_OF_SELECTED_ITEMS;

    const toggleMenuItem = () => {
      setSelectedProducts((prev) => {
        if (!isSelected) {
          return [...(prev ?? []), item];
        }

        return prev?.filter((selectedItem) => {
          switch (selectedItem.type) {
            case 'company_ad':
              return (
                selectedItem.type !== item.type ||
                selectedItem.companyAdKey !== item.companyAdKey
              );

            case 'company_profile':
              return (
                selectedItem.type !== item.type ||
                selectedItem.companyProfileKey !== item.companyProfileKey
              );

            case 'job':
              return (
                selectedItem.type !== item.type ||
                selectedItem.jobKey !== item.jobKey
              );

            case 'offer':
              return (
                selectedItem.type !== item.type ||
                selectedItem.offerKey !== item.offerKey
              );

            default:
              return true;
          }
        });
      });
    };

    return (
      <InsightsMenuItem
        disabled={disabled}
        isSelected={isSelected}
        key={`insights-item-${index}`}
        label={item.label}
        toggleMenuItem={toggleMenuItem}
      />
    );
  };

  return (
    <React.Fragment>
      <TinySelectInput
        py={8}
        px={16}
        isOpen={menuState.isOpen}
        onClick={menuState.toggleMenu}
        height="100%"
        width="100%"
      >
        <Icon
          color="selectInsightsDateRangeIcon"
          size={22}
          name="table-cells-outline"
        />

        <Box>
          <Text
            tx="label.select-products-menu.items-selected"
            txArgs={{ count: selectedCount }}
            variant="bodySm"
            color="inputText"
          />
        </Box>

        <Icon
          size={22}
          color="selectInsightsDateRangeChevron"
          name={menuState.isOpen ? 'chevron-up' : 'chevron-down'}
        />
      </TinySelectInput>

      {ReactDOM.createPortal(
        <Sheet isOpen={menuState.isOpen} onClose={menuState.closeMenu}>
          <Box
            height="100%"
            overflow="hidden"
            flexGrow={1}
            flex
            flexDirection="column"
          >
            <Box
              flex
              flexJustify="between"
              backgroundColor="menuItemBackground"
              p={8}
              flexAlign="center"
              shape={{ tl: 16, tr: 16 }}
            >
              <Text
                variant="bodySm"
                tx="label.select-products-menu.select-up-to"
                txArgs={{
                  maxCount: MAX_NUMBER_OF_SELECTED_ITEMS,
                }}
              />
              <Status
                variant="info"
                tx="label.select-products-menu.selected-count"
                txArgs={{
                  count: selectedProducts.length,
                  maxCount: MAX_NUMBER_OF_SELECTED_ITEMS,
                }}
              />
            </Box>

            <Styled.SheetList overflow="auto" flexGrow={1}>
              <MenuItem
                disabled={
                  selectedProducts.length === MAX_NUMBER_OF_SELECTED_ITEMS &&
                  !totalIsSelected
                }
                onClick={toggleTotal}
              >
                <Box gap={8} flex>
                  <Checkbox checked={totalIsSelected} onChange={() => {}} />

                  <Box>
                    <Text tx="label.select-products-menu.total" />
                    <Text
                      tx="label.select-products-menu.total-description"
                      variant="bodySm"
                    />
                  </Box>
                </Box>
              </MenuItem>

              {companyProfileItems && (
                <React.Fragment>
                  <MenuDivider tx="label.select-products-menu.profiles" />
                  {companyProfileItems?.map(renderInsightsMenuItem)}
                </React.Fragment>
              )}

              {jobItems && (
                <React.Fragment>
                  <MenuDivider tx="label.select-products-menu.jobs" />
                  {jobItems?.map(renderInsightsMenuItem)}
                </React.Fragment>
              )}

              {companyAdItems && (
                <React.Fragment>
                  <MenuDivider tx="label.select-products-menu.boosters" />
                  {companyAdItems?.map(renderInsightsMenuItem)}
                </React.Fragment>
              )}

              {offerItems && (
                <React.Fragment>
                  <MenuDivider tx="label.select-products-menu.offers" />
                  {offerItems?.map(renderInsightsMenuItem)}
                </React.Fragment>
              )}
            </Styled.SheetList>

            <MenuFooter
              primaryButtonOnClick={handleApply}
              primaryButtonTx="button.apply"
              secondaryButtonOnClick={menuState.closeMenu}
              secondaryButtonTx="button.cancel"
            />
          </Box>
        </Sheet>,
        document.body,
      )}
    </React.Fragment>
  );
}

export function SelectProduct(props: SelectProductProps) {
  const {
    defaultValue,
    onApply,
    companyAdItems,
    companyProfileItems,
    offerItems,
    jobItems,
    selectedCount,
  } = props;

  const [selectedProducts, setSelectedProducts] = React.useState(defaultValue);

  React.useEffect(() => {
    setSelectedProducts(defaultValue);
  }, [defaultValue]);

  return (
    <ResponsiveBox
      xs={
        <SelectProductMenu
          onApply={onApply}
          companyAdItems={companyAdItems}
          companyProfileItems={companyProfileItems}
          jobItems={jobItems}
          selectedCount={selectedCount}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          offerItems={offerItems}
        />
      }
    >
      <SelectProductSheet
        onApply={onApply}
        companyAdItems={companyAdItems}
        companyProfileItems={companyProfileItems}
        jobItems={jobItems}
        selectedCount={selectedCount}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        offerItems={offerItems}
      />
    </ResponsiveBox>
  );
}

import Joi from 'joi';

import { UrlSchema } from '../url';
import { CreateCompanyForm, UpdateCompanyForm } from './company.model';
import { CompanySchema } from './company.schema';

export const CreateCompanyValidation = Joi.object<CreateCompanyForm>({
  employeeCount: CompanySchema.employeeCount,
  industry: CompanySchema.industry,
  logo: CompanySchema.logo,
  name: CompanySchema.name,
});

export const UpdateCompanyFormValidation = Joi.object<UpdateCompanyForm>({
  employeeCount: CompanySchema.employeeCount,
  industry: CompanySchema.industry,
  logo: CompanySchema.logo,
  name: CompanySchema.name,
  tags: CompanySchema.tags,
  locations: CompanySchema.locations,
  facebookUrl: UrlSchema.allow(null, ''),
  instagramUrl: UrlSchema.allow(null, ''),
  linkedinUrl: UrlSchema.allow(null, ''),
  tiktokUrl: UrlSchema.allow(null, ''),
  twitterUrl: UrlSchema.allow(null, ''),
  youtubeUrl: UrlSchema.allow(null, ''),
});

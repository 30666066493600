import { Icon, IconButton, translate } from '@orbiapp/components';
import React from 'react';

import {
  toggleTalentPoolUserIsStarredThunk,
  useDispatch,
} from '../../../../../store';

export function StarUserIconButton(props: {
  userKey: string;
  isStarred: boolean;
}) {
  const { userKey, isStarred } = props;

  const dispatch = useDispatch();

  const toggleStarUser: React.MouseEventHandler<HTMLElement> = async (e) => {
    e.stopPropagation();

    dispatch(toggleTalentPoolUserIsStarredThunk(userKey));
  };

  return (
    <IconButton
      onClick={toggleStarUser}
      aria-label={translate(
        isStarred ? 'label.connect.unstar-user' : 'label.connect.star-user',
      )}
    >
      <Icon
        zIndex={2}
        absolute
        name={isStarred ? 'star-solid' : 'star-outline'}
        color={
          isStarred
            ? 'connectTalentPoolUserStarredIconStarred'
            : 'connectTalentPoolUserStarredIcon'
        }
      />
    </IconButton>
  );
}

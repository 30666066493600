import {
  Avatar,
  Box,
  Chip,
  EmptyState,
  InnerContentContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  formatDate,
  getAvatarVariantFromString,
  paginatorOptions,
  translate,
} from '@orbiapp/components';
import React from 'react';

import { useDataGridPagination } from '../../../../helpers';
import {
  PartialTalentPoolUser,
  PartialTalentPoolUserOrderBy,
  talentPoolUserSortableKeys,
} from '../../../../models';
import {
  ModuleSettingsSelector,
  PartialTalentPoolUsersSelector,
  TalentPoolUserSelector,
  connectActions,
  getPartialTalentPoolUsersThunk,
  getTalentPoolUserThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import {
  ConnectLicenseStatusChip,
  ConnectSidebarContext,
  InfoHeader,
  PageHeader,
  StarUserIconButton,
  StatsCards,
} from '../components';

const TABLE_COLUMN_WIDTHS = {
  name: 150,
  email: 150,
  source: 200,
  dateAdded: 100,
  isStarred: 60,
};

function PartialTalentPoolUserTableRow(
  props: PartialTalentPoolUser & { index: number },
) {
  const {
    firstName,
    lastName,
    profilePicture,
    email,
    source,
    createdAt,
    userKey,
    companyQr,
    index,
    isStarred,
  } = props;

  const talentPoolUser = useSelector(
    TalentPoolUserSelector.selectTalentPoolUser,
  );

  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  const dispatch = useDispatch();

  const { closeSidebar } = React.useContext(ConnectSidebarContext);

  const highlight = talentPoolUser?.userKey === userKey;

  const selectUser = async () => {
    if (highlight) return;

    await dispatch(getTalentPoolUserThunk(userKey));

    closeSidebar();
  };

  return (
    <TableRow
      blur={!connectModule?.talentPoolIsEnabled && index >= 2}
      highlight={highlight}
      key={userKey}
      onClick={selectUser}
    >
      <TableCell width={TABLE_COLUMN_WIDTHS.name}>
        <Box
          overflow="hidden"
          textOverflow="ellipsis"
          flex
          gap={8}
          flexAlign="center"
        >
          <Avatar
            minWidth={40}
            fallbackLetter={firstName[0]}
            variant={getAvatarVariantFromString(userKey)}
            src={profilePicture?.thumbnail64.url ?? undefined}
          />

          <Text variant="bodySm" as="span" text={`${firstName} ${lastName}`} />
        </Box>
      </TableCell>

      <TableCell text={email} width={TABLE_COLUMN_WIDTHS.email} />

      <TableCell width={TABLE_COLUMN_WIDTHS.source}>
        <Chip
          text={
            source === 'qr'
              ? companyQr?.name
              : translate('label.connect.profile')
          }
          variant="secondary"
        />
      </TableCell>

      <TableCell
        width={TABLE_COLUMN_WIDTHS.dateAdded}
        text={formatDate(createdAt, 'DD MMM YYYY, HH:mm')}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.isStarred} fixedRight>
        <Box ml="auto">
          <StarUserIconButton userKey={userKey} isStarred={isStarred} />
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderPartialTalentPoolUserTableRow(
  partialTalentPoolUser: PartialTalentPoolUser,
  index: number,
) {
  return (
    <PartialTalentPoolUserTableRow
      key={partialTalentPoolUser.userKey}
      index={index}
      {...partialTalentPoolUser}
    />
  );
}

const defaultTableHeader = (
  <TableHeader>
    <TableRow>
      <TableHead tx="label.connect.name" />
      <TableHead tx="label.connect.email" />
      <TableHead tx="label.connect.source" />
      <TableHead tx="label.connect.date-added" />
      <TableHead fixedRight />
    </TableRow>
  </TableHeader>
);

function TalentPoolTable() {
  const talentPoolUsers = useSelector(PartialTalentPoolUsersSelector.selectAll);
  const status = useSelector(PartialTalentPoolUsersSelector.selectStatus);
  const pagination = useSelector(
    PartialTalentPoolUsersSelector.selectPagination,
  );
  const connectModule = useSelector(ModuleSettingsSelector.selectConnectModule);

  const { openSidebar } = React.useContext(ConnectSidebarContext);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<PartialTalentPoolUser, PartialTalentPoolUserOrderBy>({
      data: talentPoolUsers,
      pagination,
      reset: connectActions.clearPartialTalentPoolUsers,
      thunk: getPartialTalentPoolUsersThunk,
    });

  const isLoading = status === 'pending';
  const isEmpty = rows.length === 0;

  if (isEmpty) {
    if (isLoading) {
      return (
        <Table>
          {defaultTableHeader}

          <TableBody data-testid="talent-pool-loading-state-table-body">
            <TablePlaceholderRows
              rowCount={10}
              layout={Object.values(TABLE_COLUMN_WIDTHS)}
            />
          </TableBody>
        </Table>
      );
    }

    return (
      <EmptyState
        titleTx="label.connect.no-talents-available"
        buttonTx="label.connect.new-qr-code"
        buttonOnClick={openSidebar}
      />
    );
  }

  return (
    <React.Fragment>
      <Table>
        {connectModule?.talentPoolIsEnabled ? (
          <TableHeader
            sortableColumns={Object.values(talentPoolUserSortableKeys)}
            onSort={onSort}
            orderBy={pagination.orderBy}
            sortOrder={pagination.sortOrder}
          >
            <TableRow>
              <TableHead
                tx="label.connect.name"
                name={talentPoolUserSortableKeys.fullName}
              />
              <TableHead
                tx="label.connect.email"
                name={talentPoolUserSortableKeys.email}
              />
              <TableHead
                tx="label.connect.source"
                name={talentPoolUserSortableKeys.source}
              />
              <TableHead
                tx="label.connect.date-added"
                name={talentPoolUserSortableKeys.createdAt}
              />
              <TableHead fixedRight />
            </TableRow>
          </TableHeader>
        ) : (
          defaultTableHeader
        )}

        <TableBody data-testid="talent-pool-table-body">
          {rows.map(renderPartialTalentPoolUserTableRow)}
        </TableBody>
      </Table>

      {connectModule?.talentPoolIsEnabled && (
        <TablePagination
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPageSizeChange={onPageSizeChange}
          onPaginate={onPaginate}
          pageSize={paginatorProps.pageSize}
          paginatorOptions={paginatorOptions}
          tx="label.general.rows-per-page"
        />
      )}
    </React.Fragment>
  );
}

export function TalentPool() {
  return (
    <InnerContentContainer>
      <PageHeader />

      <InfoHeader>
        <StatsCards />

        <ConnectLicenseStatusChip />
      </InfoHeader>

      <TalentPoolTable />
    </InnerContentContainer>
  );
}

import Joi from 'joi';

import { LogoImageValidation } from '../file';
import { LocationSchema } from '../shared';
import {
  COMPANY_NAME_MAX_LENGTH,
  TAGS_MAX_COUNT,
  TAG_MAX_LENGTH,
} from './company.constants';
import { CompanyErrors } from './company.errors';
import { employeeCountList } from './employee-count.model';
import { industryList } from './industry.model';

export namespace CompanySchema {
  export const employeeCount = Joi.string()
    .valid(...employeeCountList)
    .required()
    .messages(CompanyErrors.employeeCount);

  export const industry = Joi.string()
    .valid(...industryList)
    .required()
    .messages(CompanyErrors.industry);

  export const logo = LogoImageValidation.required().messages(
    CompanyErrors.logo,
  );

  export const name = Joi.string()
    .max(COMPANY_NAME_MAX_LENGTH)
    .required()
    .messages(CompanyErrors.companyName);

  export const tags = Joi.array()
    .items(Joi.string().max(TAG_MAX_LENGTH).required())
    .required()
    .unique((a: string, b: string) => a.toLowerCase() === b.toLowerCase())
    .max(TAGS_MAX_COUNT)
    .allow(null)
    .messages(CompanyErrors.tags);

  export const locations = Joi.array().items(
    Joi.object({
      officeLocationKey: Joi.string().required(),
      address: LocationSchema.address,
      label: LocationSchema.label,
      latitude: LocationSchema.latitude,
      longitude: LocationSchema.longitude,
      placeId: LocationSchema.placeId,
    }),
  );
}

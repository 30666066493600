import { useFormContext } from 'react-hook-form';

import { CreateOfferForm } from '../../../../models';

export function useOfferFormsSteps() {
  const formMethods = useFormContext<CreateOfferForm>();

  const isConsumable = formMethods.watch('isConsumable');

  if (isConsumable) {
    return {
      generalInfo: 1,
      contactDetails: 2,
      category: 3,
      typeOfOffer: 5,
      locations: 6,
      verification: 7,
      expirationDate: 8,
    } as const;
  }

  return {
    generalInfo: 1,
    contactDetails: 2,
    category: 3,
    typeOfOffer: 5,
    discountCodes: 6,
    locations: 7,
    verification: 8,
    expirationDate: 9,
  } as const;
}

import {
  Box,
  ControlledInput,
  ControlledTextarea,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { UploadCoverImage } from '../../../../../components';
import {
  CreateJobForm,
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_TITLE_MAX_LENGTH,
  UpdateJobDraftSchema,
  isCompanyProfileAndNotPartialCompanyProfile,
} from '../../../../../models';
import {
  CompanyProfilesSelector,
  getCompanyProfileThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { useSaveField } from '../create-job.helpers';

function _JobDetails() {
  const formContext = useFormContext<CreateJobForm>();

  const saveCoverImage = useSaveField('coverImage', {
    schema: UpdateJobDraftSchema.coverImage,
    defaultValue: formContext.getValues('coverImage'),
  });
  const saveDescription = useSaveField('description', {
    schema: UpdateJobDraftSchema.description,
    defaultValue: formContext.getValues('description'),
  });

  const saveTitle = useSaveField('title', {
    schema: UpdateJobDraftSchema.title,
    defaultValue: formContext.getValues('title'),
  });

  const getCompanyProfileIsPending = useSelector(
    CompanyProfilesSelector.getCompanyProfileIsLoading,
  );

  const index0CompanyProfile = useSelector(
    CompanyProfilesSelector.getIndex0CompanyProfile,
  );

  const dispatch = useDispatch();

  const coverImage = formContext.watch('coverImage');

  React.useEffect(() => {
    if (getCompanyProfileIsPending) return;

    if (coverImage) return;

    if (!index0CompanyProfile) return;

    if (isCompanyProfileAndNotPartialCompanyProfile(index0CompanyProfile)) {
      formContext.setValue(
        'coverImage',
        index0CompanyProfile.coverImageBase64,
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );

      saveCoverImage();

      return;
    }

    dispatch(getCompanyProfileThunk(index0CompanyProfile.companyProfileKey));
  }, [
    index0CompanyProfile,
    coverImage,
    dispatch,
    formContext,
    getCompanyProfileIsPending,
    saveCoverImage,
  ]);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.job-form.steps.1.title"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          color="formSectionDescription"
          tx="label.job-form.steps.1.subtitle"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <Box gap={16} flexDirection="column" flex>
        <UploadCoverImage onChange={saveCoverImage} name="coverImage" />

        <ControlledInput
          labelTx="label.job-form.title"
          name="title"
          maxLength={JOB_TITLE_MAX_LENGTH}
          onBlur={saveTitle}
        />

        <ControlledTextarea
          labelTx="label.job-form.description"
          maxLength={JOB_DESCRIPTION_MAX_LENGTH}
          name="description"
          onBlur={saveDescription}
          resize="vertical"
          minHeight={160}
        />
      </Box>
    </FormSection>
  );
}

_JobDetails.displayName = 'JobDetails';
export const JobDetails = React.memo(_JobDetails);

import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Icon,
  LeadingInputBox,
  Text,
} from '@orbiapp/components';
import React from 'react';

import { getOptionalLabelText } from '../../../../../utils';
import { ProfileContext } from './profile-form.context';

export function Links() {
  const { saveField, formMode } = React.useContext(ProfileContext);

  const saveVideoURL = () => {
    saveField('videoUrl');
  };

  const saveWebsiteURL = () => {
    saveField('websiteUrl');
  };

  const isDraft = formMode === 'createProfile';

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.profile.links.header"
          variant="bodyMdBold"
          color="formSectionHeader"
        />
        <Text
          tx="label.profile.links.description"
          variant="bodyMd"
          color="formSectionDescription"
        />
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <ControlledInput
          labelTx="label.profile.links.inputs.websites"
          leadingElement={
            <LeadingInputBox>
              <Icon name="globe-globe-americas-outline" />
            </LeadingInputBox>
          }
          name="websiteUrl"
          onBlur={isDraft ? saveWebsiteURL : undefined}
        />

        <ControlledInput
          label={getOptionalLabelText('label.profile.links.inputs.youtube')}
          leadingElement={
            <LeadingInputBox>
              <Icon name="youtube-colored" />
            </LeadingInputBox>
          }
          name="videoUrl"
          onBlur={isDraft ? saveVideoURL : undefined}
        />
      </Box>
    </FormSection>
  );
}

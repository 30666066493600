export const employmentTypes = [
  'fulltime',
  'parttime',
  'summer_job',
  'thesis',
  'internship',
  'apprenticeship',
] as const;

export type EmploymentType = (typeof employmentTypes)[number];

import React from 'react';
import { useTheme } from 'styled-components';

import { Theme } from '../theme';

function getXLabelMaxTicksLimit(breakpoints: Theme['breakpoints']) {
  const windowWidth = window.innerWidth;

  if (windowWidth > breakpoints.lg) {
    return 6;
  }

  if (windowWidth > breakpoints.md) {
    return 5;
  }

  if (windowWidth > breakpoints.sm) {
    return 4;
  }

  return 4;
}

export function useXLabelMaxTicksLimit() {
  const [xLabelMaxTicksLimit, setXLabelMaxTicksLimit] = React.useState(6);

  const theme = useTheme();

  React.useEffect(() => {
    const handleResize = () => {
      setXLabelMaxTicksLimit(getXLabelMaxTicksLimit(theme.breakpoints));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [theme.breakpoints]);

  return xLabelMaxTicksLimit;
}

import { CreateIndexedOfficeLocation } from '../models';

export function sortAndMapLocations<
  T extends { index: number; officeLocationKey: string },
>(locations: T[] | null): CreateIndexedOfficeLocation[] | null {
  if (!locations) {
    return null;
  }

  return [...locations]
    .sort((a, b) => a.index - b.index)
    .map((location, index) => ({
      officeLocationKey: location.officeLocationKey,
      index,
    }));
}

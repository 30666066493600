import { PayloadAction } from '@reduxjs/toolkit';

import {
  CompanyProfileDraft,
  isCompanyProfileDraftAndNotPartialCompanyProfileDraft,
} from '../../models';
import { INITIAL_STATUS_EXTENSION_STATE } from '../store.constants';
import { companyProfileDraftsAdapter } from './company-profile-drafts.adapter';
import { initialCompanyProfileDraftsState } from './company-profile-drafts.initial';
import {
  CompanyProfileDraftsEntityState,
  CompanyProfileDraftsState,
} from './company-profile-drafts.types';

const reset = (state: CompanyProfileDraftsState) => {
  Object.assign(state, initialCompanyProfileDraftsState);
};

const removeOfficeLocation = (
  state: CompanyProfileDraftsEntityState,
  action: PayloadAction<string>,
) => {
  const selectors = companyProfileDraftsAdapter.getSelectors();

  const profileDrafts = selectors.selectAll(state);

  const updates: {
    id: CompanyProfileDraft['companyProfileDraftKey'];
    changes: Pick<CompanyProfileDraft, 'locationData'>;
  }[] = [];

  profileDrafts.forEach((profileDraft) => {
    if (!isCompanyProfileDraftAndNotPartialCompanyProfileDraft(profileDraft)) {
      return;
    }

    if (!profileDraft.locationData?.officeLocations?.length) {
      return;
    }

    const found = profileDraft.locationData.officeLocations.find(
      (officeLocation) => officeLocation.officeLocationKey === action.payload,
    );

    if (!found) {
      return;
    }

    const officeLocations = profileDraft.locationData.officeLocations
      .filter(
        (officeLocation) => officeLocation.officeLocationKey !== action.payload,
      )
      .map((officeLocation) => ({
        ...officeLocation,
        index:
          officeLocation.index > found.index
            ? officeLocation.index - 1
            : officeLocation.index,
      }));

    updates.push({
      id: profileDraft.companyProfileDraftKey,
      changes: {
        locationData: {
          officeLocations,
          workModels: profileDraft.locationData.workModels,
        },
      },
    });
  });

  companyProfileDraftsAdapter.updateMany(state, updates);
};

const resetGetCompanyProfileDraftStatus = (
  state: CompanyProfileDraftsEntityState,
) => {
  state.getCompanyProfileDraftStatus = INITIAL_STATUS_EXTENSION_STATE;
};

export const reducers = {
  reset,

  removeOfficeLocation,
  resetGetCompanyProfileDraftStatus,
};

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  partialCompanyQrAdapter,
  partialTalentPoolUserAdapter,
} from './connect.adapter';

export namespace CreateCompanyQrSelector {
  export const selectCreateCompanyQrKey = (state: RootState) =>
    state.connect.createCompanyQr.data;

  export const selectStatus = (state: RootState) =>
    state.connect.createCompanyQr.status;

  export const selectError = (state: RootState) =>
    state.connect.createCompanyQr.error;
}

export namespace GetCompanyQrLinkSelector {
  export const selectStatus = (state: RootState) =>
    state.connect.getCompanyQrLink.status;

  export const selectError = (state: RootState) =>
    state.connect.getCompanyQrLink.error;
}

export namespace CompanyQrSelector {
  const selectSelf = (state: RootState) => state.connect.companyQr;

  export const selectStatus = createSelector(
    selectSelf,
    (companyQr) => companyQr.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (companyQr) => companyQr.error,
  );

  const selectData = (state: RootState) => state.connect.companyQr.data;

  export const selectCompanyQr = selectData;

  export const selectCompanyQrName = createSelector(
    selectData,
    (data) => data?.name ?? null,
  );

  export const selectCompanyQrStartDate = createSelector(
    selectData,
    (data) => data?.startDate ?? null,
  );

  export const selectCompanyQrDescription = createSelector(
    selectData,
    (data) => data?.description ?? null,
  );

  export const selectLink = createSelector(
    selectData,
    (data) => data?.link ?? null,
  );
}

export namespace PartialCompanyQrsSelector {
  const selectors = partialCompanyQrAdapter.getSelectors();

  export const selectAll = (state: RootState) =>
    selectors.selectAll(state.connect.partialCompanyQrs.data);

  export const selectPagination = (state: RootState) =>
    state.connect.partialCompanyQrs.pagination;

  export const selectStatus = (state: RootState) =>
    state.connect.partialCompanyQrs.status;

  export const selectError = (state: RootState) =>
    state.connect.partialCompanyQrs.error;
}

export namespace UpdateCompanyQrSelector {
  export const selectStatus = (state: RootState) =>
    state.connect.updateCompanyQr.status;

  export const selectError = (state: RootState) =>
    state.connect.updateCompanyQr.error;
}

export namespace TalentPoolUserStatsSelector {
  export const selectTalentPoolUserStats = (state: RootState) =>
    state.connect.talentPoolUserStats.data;

  export const selectStatus = (state: RootState) =>
    state.connect.talentPoolUserStats.status;

  export const selectError = (state: RootState) =>
    state.connect.talentPoolUserStats.error;
}

export namespace TalentPoolUserSelector {
  export const selectTalentPoolUser = (state: RootState) =>
    state.connect.talentPoolUser.data;

  export const selectNotes = (state: RootState) =>
    state.connect.talentPoolUser.data?.notes ?? null;

  export const selectStatus = (state: RootState) =>
    state.connect.talentPoolUser.status;

  export const selectError = (state: RootState) =>
    state.connect.talentPoolUser.error;
}

export namespace PartialTalentPoolUsersSelector {
  const selectors = partialTalentPoolUserAdapter.getSelectors();

  export const selectAll = (state: RootState) =>
    selectors.selectAll(state.connect.partialTalentPoolUsers.data);

  export const selectPagination = (state: RootState) =>
    state.connect.partialTalentPoolUsers.pagination;

  export const selectStatus = (state: RootState) =>
    state.connect.partialTalentPoolUsers.status;

  export const selectError = (state: RootState) =>
    state.connect.partialTalentPoolUsers.error;
}

export namespace UpdateUserNotesSelector {
  export const selectStatus = (state: RootState) =>
    state.connect.updateUserNotes.status;

  export const selectError = (state: RootState) =>
    state.connect.updateUserNotes.error;
}

export namespace ToggleUserIsStarredSelector {
  export const selectStatus = (state: RootState) =>
    state.connect.toggleTalentPoolUserIsStarred.status;

  export const selectError = (state: RootState) =>
    state.connect.toggleTalentPoolUserIsStarred.error;
}

import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const ProductStatsContainerGrid = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      max-width: 100%;
      width: 100%;
      gap: 16px;

      display: flex;
      flex-direction: column;

      @media (min-width: ${theme.breakpoints.xs}px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    `;
  }}
`;

const TablePoolUserTableRow = styled(Box)<{ shouldBlur: boolean }>`
  ${(props) => {
    const { shouldBlur } = props;

    if (shouldBlur) {
      return css`
        filter: blur(5px);
      `;
    }

    return css``;
  }}
`;

export const Styled = {
  ProductStatsContainerGrid,
  TablePoolUserTableRow,
};

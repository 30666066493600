import {
  Box,
  Calendar,
  CalendarOnChange,
  Divider,
  Icon,
  Input,
  Link,
  MenuFooter,
  MenuItem,
  ResponsiveBox,
  Sheet,
  Switch,
  Text,
  Time,
  TinySelectInput,
  Tooltip,
  Trans,
  formatDate,
  newDayjs,
  translate,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import ReactDOM from 'react-dom';

import { useOrbiBusinessCalendar } from '../../../helpers';
import {
  InsightsCompareInterval,
  InsightsInterval,
  InsightsRange,
  insightsCompareInterval,
  insightsInterval,
} from '../../../models';
import { getInsightsPoints, getInsightsPointsParams } from '../../../utils';
import { Styled } from './select-and-compare-menu.styled';
import {
  SelectAndCompareRangeMenuProps,
  SelectAndCompareRangeProps,
} from './select-and-compare-menu.types';

function getInsightsIntervalTx(
  interval: InsightsInterval | InsightsCompareInterval,
): TxString {
  switch (interval) {
    case 'last_30_days':
      return 'label.insights-data-range.last-30-days';

    case 'last_365_days':
      return 'label.insights-data-range.last-365-days';

    case 'last_7_days':
      return 'label.insights-data-range.last-7-days';

    case 'last_90_days':
      return 'label.insights-data-range.last-90-days';

    case 'previous_month':
      return 'label.insights-data-range.previous-month';

    case 'previous_period':
      return 'label.insights-data-range.previous-period';

    case 'previous_quarter':
      return 'label.insights-data-range.previous-quarter';

    case 'previous_year':
      return 'label.insights-data-range.previous-year';

    case 'today':
      return 'label.insights-data-range.today';

    default:
      return 'label.insights-data-range.custom';
  }
}

function ToggleButton(props: {
  isOpen: boolean;
  onClick: () => void;
  insightsRange: InsightsRange;
  isLocked: boolean;
}) {
  const { isOpen, onClick, insightsRange, isLocked } = props;

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  if (isLocked) {
    return (
      <Tooltip
        height="100%"
        placement="right"
        clickable
        contentElement={
          <Text variant="bodySm" color="tooltipLabel">
            <Trans
              i18nKey="label.upgrade-account-to-unlock"
              components={{
                link: (
                  <Link
                    style={{ display: 'inline-block' }}
                    small
                    variant="quaternary"
                    tx="label.connect.contact-sales"
                    href={orbiBusinessCalendarUrl}
                  />
                ),
              }}
            />
          </Text>
        }
      >
        <TinySelectInput py={8} px={16} height="100%" width="100%">
          <Icon
            color="insightsFilterHeaderUniqueMetricsIcon"
            size={22}
            name="calendar-days-outline"
          />

          <Text
            tx={getInsightsIntervalTx(insightsRange.interval)}
            variant="bodySm"
            color="inputTextDisabled"
          />

          <Icon
            size={22}
            name="lock-closed-solid"
            color="insightsFilterHeaderUniqueMetricsIcon"
          />
        </TinySelectInput>
      </Tooltip>
    );
  }

  return (
    <TinySelectInput
      py={8}
      px={16}
      isOpen={isOpen}
      onClick={onClick}
      height="100%"
      width="100%"
    >
      <Icon
        color="selectInsightsDateRangeIcon"
        size={22}
        name="calendar-days-outline"
      />

      <Box>
        <Text
          tx={getInsightsIntervalTx(insightsRange.interval)}
          variant="bodySm"
          color="inputText"
        />

        {!!insightsRange.compare && (
          <Text
            variant="bodyXs"
            tx="label.insights-data-range.compare-range"
            txArgs={{
              interval: translate(getInsightsIntervalTx(insightsRange.compare)),
            }}
          />
        )}
      </Box>

      <Icon
        size={22}
        color="selectInsightsDateRangeChevron"
        name={isOpen ? 'chevron-up' : 'chevron-down'}
      />
    </TinySelectInput>
  );
}

function SelectAndCompareRangeMenu(props: SelectAndCompareRangeMenuProps) {
  const {
    state,
    setState,
    onApply,
    defaultValue,
    menuPlacement,
    isLocked = false,
  } = props;

  const menuState = useMenu();
  const menuRef = React.useRef<HTMLDivElement>(null);

  const nextDateBeingPicked = React.useRef<'fromDate' | 'toDate'>('fromDate');

  const fromDateGreaterThanToDate = state.fromDate > state.toDate;

  const toggleCompare = () => {
    setState((prev) => ({
      ...prev,
      compare: prev.compare ? null : 'previous_period',
    }));
  };

  const setFromDate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.valueAsNumber) return;

    setState((prev) => ({
      ...prev,
      interval: 'custom',
      fromDate: newDayjs(e.target.valueAsNumber),
      toDate: newDayjs(prev?.toDate || e.target.valueAsNumber + Time.Day),
    }));
  };

  const setToDate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.valueAsNumber) return;

    setState((prev) => ({
      ...prev,
      interval: 'custom',
      fromDate: newDayjs(prev?.fromDate || e.target.valueAsNumber - Time.Day),
      toDate: newDayjs(e.target.valueAsNumber),
    }));
  };

  const handleApply = async () => {
    onApply(
      state.interval === 'custom'
        ? {
            compare: state.compare,
            fromDate: state.fromDate.valueOf(),
            toDate: state.toDate.valueOf(),
            interval: 'custom',
          }
        : { interval: state.interval, compare: state.compare },
    );

    menuState.closeMenu();
  };

  const handleCalendarChange: CalendarOnChange = (value) => {
    if (Array.isArray(value) || !value) return;

    if (nextDateBeingPicked.current === 'fromDate') {
      setState((prev) => ({
        ...prev,
        interval: 'custom',
        fromDate: newDayjs(value.getTime()),
        toDate: newDayjs(prev.toDate),
      }));

      nextDateBeingPicked.current = 'toDate';
      return;
    }

    if (nextDateBeingPicked.current === 'toDate') {
      setState((prev) => ({
        ...prev,
        interval: 'custom',
        fromDate: newDayjs(prev.fromDate),
        toDate: newDayjs(value.getTime()),
      }));

      nextDateBeingPicked.current = 'fromDate';
    }
  };

  const renderMenuItem = (interval: InsightsInterval) => {
    const selectInsightsInterval = () => {
      if (interval !== 'custom') {
        const points = getInsightsPoints(
          getInsightsPointsParams({
            compare: state.compare,
            interval,
          }),
        );

        const fromDate = points.mainPeriod[0];
        const toDate = points.mainPeriod[points.mainPeriod.length - 1].subtract(
          1,
          'day',
        );

        setState((prev) => ({ ...prev, fromDate, toDate }));
      }

      const compare =
        interval === 'previous_year' && state.compare === 'previous_year'
          ? null
          : state.compare;

      setState((prev) => ({ ...prev, interval, compare }));
    };

    return (
      <MenuItem onClick={selectInsightsInterval} key={interval}>
        <Box gap={4} flex flexJustify="between" flexAlign="center">
          <Text
            as="span"
            tx={getInsightsIntervalTx(interval)}
            variant="bodyMd"
            whiteSpace="nowrap"
          />

          <Icon
            name="check-circle-solid"
            size={20}
            color={
              state.interval === interval
                ? 'menuItemSelectedIcon'
                : 'menuItemIcon'
            }
          />
        </Box>
      </MenuItem>
    );
  };

  const renderCompareMenuItem = (compare: InsightsCompareInterval) => {
    const selectInsightsCompareInterval = () => {
      setState((prev) => ({ ...prev, compare }));
    };

    return (
      <MenuItem
        disabled={
          state.interval === 'previous_year' && compare === 'previous_year'
        }
        onClick={selectInsightsCompareInterval}
        key={compare}
      >
        <Box gap={4} flex flexJustify="between" flexAlign="center">
          <Text
            as="span"
            tx={getInsightsIntervalTx(compare)}
            variant="bodyMd"
            whiteSpace="nowrap"
          />

          <Icon
            name="check-circle-solid"
            size={20}
            color={
              state.compare === compare
                ? 'menuItemSelectedIcon'
                : 'menuItemIcon'
            }
          />
        </Box>
      </MenuItem>
    );
  };

  return (
    <Box relative ref={menuState.menuRef}>
      <ToggleButton
        insightsRange={defaultValue}
        isOpen={menuState.isOpen}
        onClick={menuState.toggleMenu}
        isLocked={isLocked}
      />

      <Styled.SelectRangeMenu
        ref={menuRef}
        absolute
        top="100%"
        mt={8}
        isOpen={menuState.isOpen}
        menuPlacement={menuPlacement}
      >
        <Box backgroundColor="menuItemBackground" flex>
          <Box flexGrow={1} flex flexDirection="column">
            <React.Fragment>
              {insightsInterval.map(renderMenuItem)}

              <Divider />

              <MenuItem>
                <Switch
                  checked={!!state.compare}
                  switchPlacement="right"
                  tx="label.insights-data-range.compare"
                  onClick={toggleCompare}
                />
              </MenuItem>

              {state.compare &&
                insightsCompareInterval.map(renderCompareMenuItem)}
            </React.Fragment>
          </Box>

          <Box flex p={8} flexDirection="column" gap={16} width={400}>
            <Input
              labelTx="label.connect.start-date"
              type="date"
              value={formatDate(state.fromDate, 'YYYY-MM-DD')}
              onChange={setFromDate}
              max={formatDate(state.toDate, 'YYYY-MM-DD')}
              hideExtraTrailingElement
            />
            <Input
              labelTx="label.connect.end-date"
              type="date"
              value={formatDate(state.toDate, 'YYYY-MM-DD')}
              onChange={setToDate}
              max={formatDate(Date.now(), 'YYYY-MM-DD')}
              min={formatDate(state.fromDate, 'YYYY-MM-DD')}
              hideExtraTrailingElement
            />

            <Box mx="auto">
              <Calendar
                onChange={handleCalendarChange}
                value={[state.fromDate.toDate(), state.toDate.toDate()]}
              />
            </Box>
          </Box>
        </Box>

        <MenuFooter
          primaryButtonTooltipTx={
            fromDateGreaterThanToDate
              ? 'errors.select-custom-insights-date.start-date'
              : undefined
          }
          primaryButtonTooltipPlacement="top"
          primaryButtonDisabled={fromDateGreaterThanToDate}
          primaryButtonOnClick={handleApply}
          primaryButtonTx="button.apply"
          secondaryButtonOnClick={menuState.closeMenu}
          secondaryButtonTx="button.cancel"
        />
      </Styled.SelectRangeMenu>
    </Box>
  );
}

function SelectAndCompareRangeSheet(
  props: Omit<SelectAndCompareRangeMenuProps, 'menuPlacement'>,
) {
  const { state, setState, onApply, defaultValue, isLocked = false } = props;

  const menuState = useMenu();

  const fromDateGreaterThanToDate = state.fromDate > state.toDate;

  const handleApply = async () => {
    onApply(
      state.interval === 'custom'
        ? {
            compare: state.compare,
            fromDate: state.fromDate.valueOf(),
            toDate: state.toDate.valueOf(),
            interval: 'custom',
          }
        : { interval: state.interval, compare: state.compare },
    );

    menuState.closeMenu();
  };

  const toggleCompare = () => {
    setState((prev) => ({
      ...prev,
      compare: prev.compare ? null : 'previous_period',
    }));
  };

  const renderMenuItem = (interval: InsightsInterval) => {
    if (interval === 'custom') {
      return null;
    }

    const selectInsightsInterval = () => {
      const points = getInsightsPoints(
        getInsightsPointsParams({
          compare: state.compare,
          interval,
        }),
      );

      const compare =
        interval === 'previous_year' && state.compare === 'previous_year'
          ? null
          : state.compare;

      setState((prev) => ({
        ...prev,
        interval,
        compare,
        fromDate: points.mainPeriod[0],
        toDate: points.mainPeriod[points.mainPeriod.length - 1],
      }));
    };

    return (
      <MenuItem onClick={selectInsightsInterval} key={interval}>
        <Box gap={4} flex flexJustify="between" flexAlign="center">
          <Text
            as="span"
            tx={getInsightsIntervalTx(interval)}
            variant="bodyMd"
            whiteSpace="nowrap"
          />

          <Icon
            name="check-circle-solid"
            size={20}
            color={
              state.interval === interval
                ? 'menuItemSelectedIcon'
                : 'menuItemIcon'
            }
          />
        </Box>
      </MenuItem>
    );
  };

  const renderCompareMenuItem = (compare: InsightsCompareInterval) => {
    const selectInsightsCompareInterval = () => {
      setState((prev) => ({ ...prev, compare }));
    };

    return (
      <MenuItem
        disabled={
          state.interval === 'previous_year' && compare === 'previous_year'
        }
        onClick={selectInsightsCompareInterval}
        key={compare}
      >
        <Box gap={4} flex flexJustify="between" flexAlign="center">
          <Text
            as="span"
            tx={getInsightsIntervalTx(compare)}
            variant="bodyMd"
            whiteSpace="nowrap"
          />

          <Icon
            name="check-circle-solid"
            size={20}
            color={
              state.compare === compare
                ? 'menuItemSelectedIcon'
                : 'menuItemIcon'
            }
          />
        </Box>
      </MenuItem>
    );
  };

  return (
    <React.Fragment>
      <ToggleButton
        insightsRange={defaultValue}
        isOpen={menuState.isOpen}
        onClick={menuState.toggleMenu}
        isLocked={isLocked}
      />

      {ReactDOM.createPortal(
        <Sheet isOpen={menuState.isOpen} onClose={menuState.closeMenu}>
          <Box
            shape={{ tl: 16, tr: 16 }}
            flex
            flexDirection="column"
            flexGrow={1}
            height="100%"
          >
            <Styled.SheetList flexGrow={1} flex flexDirection="column">
              {insightsInterval.map(renderMenuItem)}

              <Divider />

              <MenuItem>
                <Switch
                  checked={!!state.compare}
                  switchPlacement="right"
                  tx="label.insights-data-range.compare"
                  onClick={toggleCompare}
                />
              </MenuItem>

              {state.compare &&
                insightsCompareInterval.map(renderCompareMenuItem)}
            </Styled.SheetList>

            <MenuFooter
              primaryButtonTooltipTx={
                fromDateGreaterThanToDate
                  ? 'errors.select-custom-insights-date.start-date'
                  : undefined
              }
              primaryButtonTooltipPlacement="top"
              primaryButtonDisabled={fromDateGreaterThanToDate}
              primaryButtonOnClick={handleApply}
              primaryButtonTx="button.apply"
              secondaryButtonOnClick={menuState.closeMenu}
              secondaryButtonTx="button.cancel"
            />
          </Box>
        </Sheet>,
        document.body,
      )}
    </React.Fragment>
  );
}

export function SelectAndCompareRange(props: SelectAndCompareRangeProps) {
  const { defaultValue, menuPlacement, isLocked, onApply } = props;

  const [state, setState] = React.useState({
    interval: defaultValue.interval,
    compare: defaultValue.compare,

    ...(defaultValue.interval === 'custom'
      ? {
          fromDate: newDayjs(defaultValue.fromDate),
          toDate: newDayjs(defaultValue.toDate),
        }
      : {
          fromDate: newDayjs(Date.now() - Time.Day),
          toDate: newDayjs(Date.now()),
        }),
  });

  return (
    <ResponsiveBox
      xs={
        <SelectAndCompareRangeMenu
          defaultValue={defaultValue}
          onApply={onApply}
          state={state}
          setState={setState}
          menuPlacement={menuPlacement}
          isLocked={isLocked}
        />
      }
    >
      <SelectAndCompareRangeSheet
        defaultValue={defaultValue}
        onApply={onApply}
        state={state}
        setState={setState}
        isLocked={isLocked}
      />
    </ResponsiveBox>
  );
}

import styled, { css } from 'styled-components';

import { Box } from '../box';

const SheetDisc = styled(Box)`
  ${(props) => {
    const { theme } = props;
    return css`
      position: absolute;
      background-color: ${theme.colors.sheetDisc};
      height: 4px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 100px;
      width: 32px;
      display: block;
      margin-top: 16px;
      top: 0;
      z-index: 5;
    `;
  }}
`;

const Sheet = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;
    return css`
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: 0;
      max-width: 100%;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      transform-origin: bottom;
      transition: height ${theme.transitions.fast};
      pointer-events: none;
      background-color: ${theme.colors.sheetBackground};

      ${isOpen &&
      css`
        pointer-events: auto;
        height: 90%;
      `};
    `;
  }}
`;

export const Styled = { Sheet, SheetDisc };

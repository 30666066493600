import { countOccurances } from '@orbiapp/components';
import Joi from 'joi';

import { EmailSchema } from '../email';
import { CreateInvitationForm, EmailValidationOptions } from './team.model';

export namespace TeamSchema {
  export const email = (options: EmailValidationOptions) =>
    EmailSchema.required().custom((value, helpers) => {
      if (options.existingTeamMemberEmails.includes(value)) {
        return helpers.error('string.already-exists');
      }

      if (options.pendingTeamMemberEmails.includes(value)) {
        return helpers.error('string.already-pending');
      }

      return value;
    });

  export const invitations = (options: EmailValidationOptions) =>
    Joi.array()
      .items(
        Joi.object({
          email: email(options)
            .allow('')
            .custom((value, helpers) => {
              const invitations: CreateInvitationForm[] =
                helpers.state.ancestors[1].filter(
                  (invitation: CreateInvitationForm) => invitation.email,
                );

              const occurances = countOccurances(invitations, 'email');
              if (occurances[value] > 1) {
                return helpers.error('string.unique');
              }

              return value;
            }),
        }),
      )
      .required();
}

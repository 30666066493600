import Joi from 'joi';

export const studyLevels = [
  'associate_degree',
  'bachelor',
  'master',
  'phd',
] as const;

export type StudyLevel = (typeof studyLevels)[number];

export const studyLevelSchema = Joi.string().valid(...studyLevels);

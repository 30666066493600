import { PublishEvent } from '@orbiapp/components';

import { Logger } from '../logger';

// https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events

// Event names must be 40 characters or fewer, can only contain alpha-numeric characters and underscores, and must start with an alphabetic character.
// Parameter names including item parameters must be 40 characters or fewer, can only contain alpha-numeric characters and underscores, and must start with an alphabetic character.
// Parameter values including item parameter values must be 100 characters or fewer.

export namespace Analytics {
  type TrackEvent = PublishEvent;

  type EventData = {
    param1?: string;
    param2?: string;
    param3?: string;
  };

  export function track(event: TrackEvent, eventData?: EventData) {
    try {
      const data: Record<string, any> = {
        event: `cd_${event}`,
      };

      if (eventData) {
        data['eventData'] = eventData;
      }

      const noTagsFired = window.dataLayer.push(data);

      if (noTagsFired) {
        throw new Error('Failed to push data to dataLayer');
      }
    } catch (err) {
      Logger.error('Failed to track event', { event, err });
    }
  }
}

export const ORBI_URL = 'https://orbiapp.io';

export const CREATE_ACCOUNT_URL = 'https://orbiapp.io/sign-up';

export const OPEN_APP_URL = 'https://onelink.to/6at797';

// 'https://orbiapp.io/meetings/rebecca-granlund';
export const ORBI_BUSINESS_CALENDAR_URL =
  'https://orbiapp.io/meetings/niklas-holmander/orbi-business-calendar';

export const LEARN_MORE_ABOUT_CONNECT_URL =
  'https://orbiapp.io/knowledge/connect-data';

export const LEARN_MORE_ABOUT_INSIGHTS_URL =
  'https://orbiapp.io/knowledge/business/insights';

export const LEARN_MORE_ABOUT_TEAM_ROLES_URL =
  'https://orbiapp.io/knowledge/how-to-manage-your-team-roles';

export const LEARN_MORE_ABOUT_WORKSPACES_URL =
  'https://orbiapp.io/knowledge/what-is-workspaces';

export const TERMS_AND_CONDITIONS_URL =
  'https://orbiapp.io/knowledge/terms-and-conditions';

export const LEARN_MORE_ABOUT_SPENT_VALUE_URL =
  'https://orbiapp.io/knowledge/how-do-we-calculate-your-estimated-value';

import { Perk, PerkCategory } from '../../../../../models';
import { PerkCategoryDto, PerkDto } from './perk-categories.dto';

const perkMapper = (perkDto: PerkDto): Perk => ({
  name: perkDto.name,
  perkCategoryKey: perkDto.perkCategoryKey,
  perkKey: perkDto.perkKey,
  emoji: perkDto.emoji,
  emojiAndName: `${perkDto.emoji} ${perkDto.name}`,
});

export const perkCategoryMapper = (
  perkCategoryDto: PerkCategoryDto,
): PerkCategory => ({
  name: perkCategoryDto.name,
  perkCategoryKey: perkCategoryDto.perkCategoryKey,
  perks: perkCategoryDto.perks
    .map(perkMapper)
    .sort((a, b) => a.name.localeCompare(b.name)),
});

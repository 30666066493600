export const JOB_CONTACT_NAME_MAX_LENGTH = 50;
export const JOB_CONTACT_PHONE_MAX_LENGTH = 50;
export const JOB_CONTACT_EMAIL_MAX_LENGTH = 254;

export const JOB_DESCRIPTION_MAX_LENGTH = 5000;

export const JOB_QUALIFICATION_MAX_LENGTH = 200;
export const JOB_QUALIFICATIONS_MAX_COUNT = 10;

export const JOB_TITLE_MAX_LENGTH = 100;

export const JOB_ORIGINAL_URL_MAX_LENGTH = 2000;

export const JOB_MIN_DAILY_BUDGET = 200;
export const JOB_MAX_DAILY_BUDGET = 999999;
export const JOB_MIN_TOTAL_BUDGET = 1000;
export const JOB_MAX_TOTAL_BUDGET = 999999;

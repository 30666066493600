import styled, { css } from 'styled-components';

const Link = styled.a`
  ${(props) => {
    const { theme } = props;

    return css`
      color: ${theme.colors['connectViewUserQrCodeText']};
      text-decoration: none;

      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `;
  }}
`;

const LinkButton = styled.button`
  ${(props) => {
    const { theme } = props;

    return css`
      all: unset;
      overflow: hidden;
      color: ${theme.colors['connectViewUserQrCodeText']};

      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `;
  }}
`;

export const Styled = { Link, LinkButton };

import { PartialCompanyAd } from '../../../../../models';
import { PartialCompanyAdDto } from './company-ads.dto';

export function partialCompanyAdMapper(
  partialCompanyAdDto: PartialCompanyAdDto,
): PartialCompanyAd {
  return {
    activeFrom: partialCompanyAdDto.activeFrom,
    activeTo: partialCompanyAdDto.activeTo,
    companyAdKey: partialCompanyAdDto.companyAdKey,
    placement: partialCompanyAdDto.placement,
    title: partialCompanyAdDto.title,
  };
}

import { Box, Button, Card, Image, Text } from '@orbiapp/components';

import { assets } from '../../../assets';
import { useOrbiBusinessCalendar } from '../../../helpers';

export function BuyBoosterCard() {
  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  return (
    <Card height="100%">
      <Box
        height="100%"
        flexAlign="center"
        textAlign="center"
        flexJustify="center"
        flex
        flexDirection="column"
        gap={16}
      >
        <Image src={assets.chartTrendingUp} width={200} />

        <Box flex flexDirection="column" gap={8}>
          <Text variant="bodyLgBold" tx="label.buy-booster-ad.title" />
          <Text variant="bodyMd" tx="label.buy-booster-ad.subtitle" />
        </Box>

        <Button
          href={orbiBusinessCalendarUrl}
          variant="primary"
          tx="label.connect.contact-sales"
        />
      </Box>
    </Card>
  );
}

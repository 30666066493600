import {
  ClientProblem,
  GeneralApiProblem,
  GeneralApiResult,
  GeneralApiResultWithData,
  GetPageAndNextPage,
  Pagination,
  clientProblems,
} from '@orbiapp/components';
import { ApiErrorResponse, ApiResponse } from 'apisauce';
import axios from 'axios';

import { firebaseConfig } from '../../config';
import { getDynamicLinkOptions } from '../../utils';
import { Logger } from '../logger';

export const isClientProblem = <T>(
  result: GeneralApiResult<T>,
): result is ClientProblem<T> => clientProblems.includes(result.kind as any);

export const getGeneralApiProblem = <T>(
  response: ApiErrorResponse<T>,
): GeneralApiProblem<T> => {
  if (response.problem === 'CLIENT_ERROR') {
    let message: string | T | undefined;

    if (
      response.data &&
      typeof response.data === 'object' &&
      'error' in response.data
    ) {
      message = response.data.error as T;
    }

    switch (response.status) {
      case 400:
        return { kind: 'bad-request', message };
      case 401:
        return { kind: 'unauthorized', message };
      case 403:
        return { kind: 'forbidden', message };
      case 404:
        return { kind: 'not-found', message };
      case 429:
        return { kind: 'too-many-requests', message };
      default:
        return { kind: 'rejected', message };
    }
  }

  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return { kind: 'cannot-connect', temporary: true };
    case 'NETWORK_ERROR':
      return { kind: 'cannot-connect', temporary: true };
    case 'TIMEOUT_ERROR':
      return { kind: 'timeout', temporary: true };
    case 'SERVER_ERROR':
      return { kind: 'server' };
    case 'UNKNOWN_ERROR':
      return { kind: 'unknown', temporary: true };
    case 'CANCEL_ERROR':
      return { kind: 'cancelled' };
    default:
      throw new Error(`Unhandled case: ${response.problem}`);
  }
};

export async function getPageAndNextPage<
  OrderBy,
  DataType,
  Params extends { pagination: Pagination<OrderBy> },
>(
  currentState: DataType[],
  resolver: (params: Params) => Promise<GeneralApiResultWithData<DataType[]>>,
  params: Params,
): Promise<GetPageAndNextPage<DataType>> {
  const { pagination } = params;

  const nextPageOffset = pagination.offset + pagination.limit;

  if (currentState.length > nextPageOffset) {
    return [
      { kind: 'ok', data: [] },
      { kind: 'ok', data: [] },
    ];
  }

  const currentPageFirstPageData = currentState.slice(
    pagination.offset,
    pagination.offset + pagination.limit,
  );
  let firstPageData: GeneralApiResultWithData<DataType[]> = {
    kind: 'ok',
    data: currentPageFirstPageData,
  };
  if (currentPageFirstPageData.length === 0) {
    firstPageData = await resolver(params);
  }

  const currentSecondPageData = currentState.slice(
    pagination.offset + pagination.limit,
    pagination.offset + pagination.limit * 2,
  );
  let secondPageData: GeneralApiResultWithData<DataType[]> = {
    kind: 'ok',
    data: currentSecondPageData,
  };
  if (
    firstPageData.kind === 'ok' &&
    firstPageData.data.length === pagination.limit
  ) {
    secondPageData = await resolver({
      ...params,
      pagination: { ...pagination, offset: nextPageOffset },
    });
  }

  return [firstPageData, secondPageData];
}

export function getApiLogExtras(res: ApiResponse<any>) {
  const value: Record<string, any> = {
    error: res.data?.error,

    originalError: res.originalError?.message,

    problem: res.problem,
    status: res.status,

    url: res.config?.url,
    method: res.config?.method,
    timeout: res.config?.timeout,

    'x-emulate-user': res.headers?.['x-emulate-user'],
    'x-orb-trace': res.headers?.['x-orb-trace'],
  };

  return { value };
}

export async function createDynamicLink(
  data:
    | {
        key: string;
        page: 'company';
        companyQrKey: string;
      }
    | {
        key: string;
        page: 'offers';
        ownerType: 'company';
      },
) {
  try {
    Logger.addBreadcrumb('info', 'api', 'createDynamicLink', {
      data,
    });

    const res = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,
      getDynamicLinkOptions(data),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (res.status !== 200) {
      throw new Error('Error creating dynamic link');
    }

    return res;
  } catch (err) {
    throw err;
  }
}

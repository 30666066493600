import { createEntityAdapter } from '@reduxjs/toolkit';

import { Perk, PerkCategory } from '../../models';

export const perkCategoriesAdapter = createEntityAdapter<PerkCategory, string>({
  selectId: (perkCategory) => perkCategory.perkCategoryKey,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const perksAdapter = createEntityAdapter<Perk, string>({
  selectId: (perk) => perk.perkKey,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

import { Base64File, EditMeta, ImageData } from '@orbiapp/components';

import {
  CreateIndexedOfficeLocation,
  EmploymentType,
  IndexedOfficeLocation,
  StudyLevel,
  SubjectArea,
  WorkModel,
} from '../../shared';

export const budgetTypes = ['both', 'total', 'daily'] as const;

export type BudgetType = (typeof budgetTypes)[number];

export interface Job {
  contactEmail: string;
  contactName: string;
  contactPhone: string | null;
  coverImage: ImageData;
  coverImageBase64: Base64File | null;
  dailyBudgetReachedAt: number[] | null;
  description: string;
  editMeta: EditMeta;
  employmentType: EmploymentType;
  endDate: number;
  isManuallyClosed: boolean;
  jobKey: string;
  originalJobUrl: string | null;
  qualifications: string[] | null;
  startDate: number;
  studyLevels: StudyLevel[];
  subjectAreas: SubjectArea[];
  title: string;
  totalBudgetReachedAt: number | null;
  budgetData: {
    budgetType: BudgetType;
    dailyBudget: number | null;
    totalBudget: number | null;
  };
  locationData: {
    officeLocations: IndexedOfficeLocation[] | null;
    workModel: WorkModel;
  };
  stats: { clickCount: number; totalBudgetSpend: number };
}

export interface PartialJob
  extends Pick<
    Job,
    | 'jobKey'
    | 'startDate'
    | 'endDate'
    | 'title'
    | 'employmentType'
    | 'editMeta'
    | 'dailyBudgetReachedAt'
    | 'totalBudgetReachedAt'
    | 'isManuallyClosed'
  > {}

export interface CreateJobForm {
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  coverImage: Base64File | null;
  description: string;
  employmentType: EmploymentType;
  endDate: number;
  jobDraftKey: string | null;
  originalJobUrl: string | null;
  qualifications: string[] | null;
  startDate: number;
  studyLevels: StudyLevel[];
  subjectAreaKeys: string[];
  title: string;
  budgetData: {
    budgetType: BudgetType;
    dailyBudget: number | null;
    totalBudget: number | null;
  };
  locationData: {
    workModel: WorkModel;
    officeLocations: CreateIndexedOfficeLocation[] | null;
  };
}

export interface UpdateJobForm {
  contactEmail: string;
  contactName: string;
  contactPhone: string | null;
  coverImage: Base64File | null;
  description: string;
  employmentType: EmploymentType;
  endDate: number;
  originalJobUrl: string | null;
  qualifications: string[] | null;
  startDate: number;
  studyLevels: StudyLevel[];
  subjectAreaKeys: string[];
  title: string;
  budgetData: {
    budgetType: BudgetType;
    dailyBudget: number | null;
    totalBudget: number | null;
  };
  locationData: {
    workModel: WorkModel;
    officeLocations: CreateIndexedOfficeLocation[] | null;
  };
}

export type JobsOrderByKey =
  | keyof Pick<Job, 'employmentType' | 'title' | 'endDate'>
  | 'createdAt';

export type JobsFilterBy = 'all' | 'active' | 'closed';

export const jobsSortableKeys: Record<JobsOrderByKey, JobsOrderByKey> = {
  employmentType: 'employmentType',
  title: 'title',
  endDate: 'endDate',
  createdAt: 'createdAt',
};

export interface PickProfileForm {
  companyProfileKey: string;
}

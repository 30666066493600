import {
  Box,
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  Button,
  ContentContainer,
  ImageSliderModal,
  ImageSliderModalSlide,
  InnerContentContainer,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
  translate,
} from '@orbiapp/components';
import React from 'react';

import { assets } from '../../../../assets';
import {
  ExportDataButton,
  SelectAndCompareRange,
  SelectProduct,
  ToggleUniqueMetrics,
} from '../../../../components/insights';
import { LEARN_MORE_ABOUT_INSIGHTS_URL } from '../../../../constants';
import { useOrbiBusinessCalendar } from '../../../../helpers';
import {
  InsightsMetric,
  InsightsRange,
  SelectedInsightsItem,
} from '../../../../models';
import {
  InsightsTimeSeriesConfigSelector,
  InsightsTimeSeriesItemsSelector,
  LiveInsightsSelector,
  ModuleSettingsSelector,
  getEstimatedValueThunk,
  getInsightsItemsThunk,
  getLiveInsightsThunk,
  getTimeBasedInsightsThunk,
  insightsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { Charts } from './charts';
import { Header } from './header';
import { Styled } from './insights.styled';
import { MetricsBreakdown } from './metrics-breakdown';
import { ProductStats } from './product-stats';

const INSIGHTS_BREADCRUMBS: BreadcrumbListItem[] = [
  { to: '/insights', tx: 'label.breadcrumbs.insights.insights' },
];

const metrics = ['engagement', 'impressions', 'views'] as InsightsMetric[];

const welcomeToInsightsModalSlides: ImageSliderModalSlide[] = [
  {
    type: 'image',
    src: assets.insightsV2Modal.insightsV2Modal1,
    alt: translate('label.insights-v2-welcome-modal.slide-1-message'),
    descriptionTx: 'label.insights-v2-welcome-modal.slide-1-message',
  },
  {
    type: 'image',
    src: assets.insightsV2Modal.insightsV2Modal2,
    alt: translate('label.insights-v2-welcome-modal.slide-2-message'),
    descriptionTx: 'label.insights-v2-welcome-modal.slide-2-message',
  },
];

const welcomeToInsightsModalSlidesT1 = welcomeToInsightsModalSlides.concat({
  type: 'image',
  src: assets.insightsV2Modal.insightsV2Modal3,
  alt: translate('label.insights-v2-welcome-modal.slide-3-message-t1'),
  descriptionTx: 'label.insights-v2-welcome-modal.slide-3-message-t1',
});

const welcomeToInsightsModalSlidesT2 = welcomeToInsightsModalSlides.concat({
  type: 'image',
  src: assets.insightsV2Modal.insightsV2Modal3,
  alt: translate('label.insights-v2-welcome-modal.slide-3-message-t2'),
  descriptionTx: 'label.insights-v2-welcome-modal.slide-3-message-t2',
});

function WelcomeToInsightsModal() {
  const insightsTier = useSelector(ModuleSettingsSelector.selectInsightsTier);

  const { orbiBusinessCalendarUrl } = useOrbiBusinessCalendar();

  const [isOpen, setIsOpen] = React.useState(
    !localStorage.getItem('insightsV2Modal'),
  );

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('insightsV2Modal', 'false');
  };

  return (
    <ImageSliderModal
      width={800}
      isOpen={isOpen}
      onClose={handleClose}
      slides={
        insightsTier === 't1'
          ? welcomeToInsightsModalSlidesT1
          : welcomeToInsightsModalSlidesT2
      }
      titleTx="label.insights-welcome-modal.title"
      footerElement={
        <Box flex gap={16}>
          <Button
            variant="secondary"
            tx="button.learn-more"
            href={LEARN_MORE_ABOUT_INSIGHTS_URL}
            target="_blank"
          />
          {insightsTier === 't1' && (
            <Button
              variant="primary"
              tx="button.upgrade-your-account"
              href={orbiBusinessCalendarUrl}
              target="_blank"
            />
          )}
        </Box>
      }
    />
  );
}

export function Insights() {
  const liveInsightsStatus = useSelector(LiveInsightsSelector.selectStatus);
  const insightsDataRange = useSelector(
    InsightsTimeSeriesConfigSelector.selectRange,
  );
  const selectedItems = useSelector(
    InsightsTimeSeriesConfigSelector.selectSelectedItems,
  );
  const showUnique = useSelector(
    InsightsTimeSeriesConfigSelector.selectShowUnique,
  );
  const { companyAdItems, jobItems, companyProfileItems, offerItems } =
    useSelector(InsightsTimeSeriesItemsSelector.selectItemsByType);

  const selectedCount = useSelector(
    InsightsTimeSeriesConfigSelector.selectCount,
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getInsightsItemsThunk());
    dispatch(getLiveInsightsThunk());
    dispatch(getTimeBasedInsightsThunk({ metrics }));
    dispatch(getEstimatedValueThunk());
  }, [dispatch]);

  if (liveInsightsStatus === 'pending') {
    return <LoadingContainer />;
  }

  const setSelectedItems = (items: SelectedInsightsItem[]) => {
    dispatch(insightsActions.setInsightsTimeSeriesConfigItems(items));

    dispatch(getTimeBasedInsightsThunk({ metrics }));
  };

  const setInsightsDataRange = async (range: InsightsRange) => {
    dispatch(insightsActions.setInsightsTimeSeriesConfigRange(range));

    await dispatch(getInsightsItemsThunk());
    dispatch(getTimeBasedInsightsThunk({ metrics }));
  };

  const toggleShowUnique = () => {
    dispatch(insightsActions.setInsightsTimeSeriesShowUnique(!showUnique));

    dispatch(getTimeBasedInsightsThunk({ metrics }));
  };

  return (
    <PageContainer>
      <WelcomeToInsightsModal />

      <BreadcrumbsToolbar breadcrumbListItems={INSIGHTS_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer backgroundColor="formContainerBackground">
          <InnerContentContainer>
            <Header />

            <ProductStats />

            <Styled.InsightsControlsContainer>
              <ToggleUniqueMetrics
                onToggle={toggleShowUnique}
                isActive={showUnique}
              />
              <SelectProduct
                defaultValue={selectedItems}
                onApply={setSelectedItems}
                selectedCount={selectedCount}
                companyAdItems={companyAdItems}
                jobItems={jobItems}
                companyProfileItems={companyProfileItems}
                offerItems={offerItems}
              />
              <SelectAndCompareRange
                defaultValue={insightsDataRange}
                onApply={setInsightsDataRange}
                menuPlacement="right"
              />

              <ExportDataButton
                metrics={metrics}
                items={selectedItems}
                insightsDataRange={insightsDataRange}
                showUnique={showUnique}
              />
            </Styled.InsightsControlsContainer>

            <MetricsBreakdown />

            <Charts />
          </InnerContentContainer>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}

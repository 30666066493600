export namespace OfferErrors {
  export const offerCategoryKey = {
    'string.empty': 'errors.offers-category-key.empty',
  };

  export const title = {
    'string.empty': 'errors.title.empty',
  };

  export const description = {
    'string.empty': 'errors.description.empty',
  };

  export const coverImage = {
    'any.required': 'errors.cover-image.empty',
    'object.base': 'errors.cover-image.empty',
    'any.only': 'errors.cover-image.empty',
  };

  export const startDate = {
    'number.max': 'errors.start-date.max',
    'number.min': 'errors.start-date.min',
    'number.base': 'errors.start-date.min',
  };

  export const endDate = {
    'number.min': 'errors.end-date.min',
    'number.base': 'errors.end-date.min',
    'any.ref': 'errors.end-date.before-start',
  };

  export const contactName = {
    'string.empty': 'errors.name.empty',
  };

  export const link = {
    'string.uri': 'errors.url.invalid',
    'string.base': 'errors.url.invalid',
  };

  export const code = {
    'string.empty': 'errors.code.empty',
  };

  export const files = {
    'array.min': 'errors.files.min',
  };

  export const countryKeys = {
    'array.min': 'errors.countries.min',
    'array.includesRequiredUnknowns': 'errors.countries.min',
  };
}

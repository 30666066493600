import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './subject-areas.build';
import { initialSubjectAreasState } from './subject-areas.initial';
import { reducers } from './subject-areas.reducers';

const subjectAreasSlice = createSlice({
  name: 'subjectAreas',
  initialState: initialSubjectAreasState,
  reducers,
  extraReducers,
});

export const subjectAreasReducer = subjectAreasSlice.reducer;

import { FormBox } from '@orbiapp/components';

import {
  ContactDetails,
  Countries,
  DiscountCodes,
  EndDate,
  GeneralInformation,
  Locations,
  OfferCategories,
  StudentVerification,
  TypeOfOffer,
} from '../components';

export function OfferForm() {
  return (
    <FormBox>
      <GeneralInformation />

      <ContactDetails />

      <OfferCategories />

      <Countries />

      <TypeOfOffer />

      <DiscountCodes />

      <Locations />

      <StudentVerification />

      <EndDate />
    </FormBox>
  );
}

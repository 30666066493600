import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { partialJobDraftsAdapter, partialJobsAdapter } from './jobs.adapter';

export namespace CreateJobDraftSelector {
  const selectSelf = (state: RootState) => state.jobs.createJobDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);

  export const selectJobDraftKey = createSelector(
    selectSelf,
    (state) => state.data ?? null,
  );
}

export namespace JobDraftsSelector {
  const jobDraftsSelectors = partialJobDraftsAdapter.getSelectors(
    (state: RootState) => state.jobs.jobDrafts.data,
  );

  const selectSelf = (state: RootState) => state.jobs.jobDrafts;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = jobDraftsSelectors.selectAll;
}

export namespace JobDraftSelector {
  const selectSelf = (state: RootState) => state.jobs.jobDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);

  export const selectDefaultValues = (state: RootState) =>
    state.jobs.createJobDefaultValues;

  export const selectJobDraftKey = createSelector(
    selectSelf,
    (state) => state.data?.jobDraftKey ?? null,
  );

  export const selectUpdatedAt = createSelector(
    selectSelf,
    (state) => state.data?.editMeta.updatedAt ?? null,
  );

  export const selectEditMeta = createSelector(
    selectSelf,
    (state) => state.data?.editMeta ?? null,
  );
}

export namespace UpdateJobDraftSelector {
  const selectSelf = (state: RootState) => state.jobs.updateJobDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace DeleteJobDraftSelector {
  const selectSelf = (state: RootState) => state.jobs.deleteJobDraft;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace CreateJobSelector {
  const selectSelf = (state: RootState) => state.jobs.createJob;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

export namespace JobSelector {
  const selectSelf = (state: RootState) => state.jobs.job;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = createSelector(selectSelf, (state) => state.data);

  export const selectTitle = createSelector(
    selectSelf,
    (state) => state.data?.title ?? null,
  );

  export const selectDailyBudgetReachedAt = createSelector(
    selectSelf,
    (state) => state.data?.dailyBudgetReachedAt ?? null,
  );

  export const selectIsManuallyClosed = createSelector(
    selectSelf,
    (state) => state.data?.isManuallyClosed ?? false,
  );

  export const totalBudgetReachedAt = createSelector(
    selectSelf,
    (state) => state.data?.totalBudgetReachedAt ?? null,
  );

  export const selectCoverImage = createSelector(
    selectSelf,
    (state) => state.data?.coverImageBase64 ?? null,
  );

  export const selectOriginalJobUrl = createSelector(
    selectSelf,
    (state) => state.data?.originalJobUrl ?? null,
  );

  export const selectQualifications = createSelector(
    selectSelf,
    (state) => state.data?.qualifications ?? null,
  );

  export const selectCanEditJob = createSelector(selectSelf, (state) => {
    if (!state.data?.endDate) {
      return false;
    }

    if (state.data.isManuallyClosed) {
      return false;
    }

    return Date.now() < state.data.endDate;
  });

  export const selectEditMeta = createSelector(
    selectSelf,
    (state) => state.data?.editMeta ?? null,
  );

  export const selectStudyLevels = createSelector(
    selectSelf,
    (state) => state.data?.studyLevels ?? null,
  );

  export const selectSubjectAreaKeys = createSelector(
    selectSelf,
    (state) =>
      state.data?.subjectAreas.map(
        (subjectArea) => subjectArea.subjectAreaKey,
      ) ?? null,
  );

  export const selectContactName = createSelector(
    selectSelf,
    (state) => state.data?.contactName ?? null,
  );

  export const selectStats = createSelector(
    selectSelf,
    (state) => state.data?.stats ?? null,
  );

  export const selectContactEmail = createSelector(
    selectSelf,
    (state) => state.data?.contactEmail ?? null,
  );

  export const selectContactPhone = createSelector(
    selectSelf,
    (state) => state.data?.contactPhone ?? null,
  );

  export const selectDescription = createSelector(
    selectSelf,
    (state) => state.data?.description ?? null,
  );

  export const selectWorkModel = createSelector(
    selectSelf,
    (state) => state.data?.locationData.workModel ?? null,
  );

  export const selectEndDate = createSelector(
    selectSelf,
    (state) => state.data?.endDate ?? null,
  );

  export const selectStartDate = createSelector(
    selectSelf,
    (state) => state.data?.startDate ?? null,
  );

  export const selectEmploymentType = createSelector(
    selectSelf,
    (state) => state.data?.employmentType ?? null,
  );

  export const selectLocations = createSelector(
    selectSelf,
    (state) => state.data?.locationData.officeLocations ?? null,
  );

  export const selectTotalBudget = createSelector(
    selectSelf,
    (state) => state.data?.budgetData.totalBudget ?? null,
  );

  export const selectDailyBudget = createSelector(
    selectSelf,
    (state) => state.data?.budgetData.dailyBudget ?? null,
  );

  export const selectBudgetType = createSelector(
    selectSelf,
    (state) => state.data?.budgetData.budgetType ?? null,
  );

  export const selectJobKey = createSelector(
    selectSelf,
    (state) => state.data?.jobKey ?? null,
  );
}

export namespace JobsSelector {
  const jobsSelectors = partialJobsAdapter.getSelectors(
    (state: RootState) => state.jobs.jobs.data,
  );

  const selectSelf = (state: RootState) => state.jobs.jobs;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = jobsSelectors.selectAll;

  export const selectPagination = createSelector(
    selectSelf,
    (state) => state.pagination,
  );

  export const selectShow = createSelector(selectSelf, (state) => state.show);
}

export namespace UpdateJobSelector {
  const selectSelf = (state: RootState) => state.jobs.updateJob;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

export namespace CloseJobSelector {
  const selectSelf = (state: RootState) => state.jobs.closeJob;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );
}

export namespace DuplicateJobSelector {
  const selectSelf = (state: RootState) => state.jobs.duplicateJob;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);
}

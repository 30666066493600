import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { partialCompanyAdAdapter } from './company-ads.adapter';

export namespace CompanyAdsSelector {
  const companyAdsSelectors = partialCompanyAdAdapter.getSelectors(
    (state: RootState) => state.companyAds.companyAds.data,
  );

  const selectSelf = (state: RootState) => state.companyAds.companyAds;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectError = createSelector(selectSelf, (state) => state.error);

  export const selectData = companyAdsSelectors.selectAll;

  export const selectPagination = createSelector(
    selectSelf,
    (state) => state.pagination,
  );
}

import { createEntityAdapter } from '@reduxjs/toolkit';

import { CompanyProfile, PartialCompanyProfile } from '../../models';

export const companyProfilesAdapter = createEntityAdapter<
  CompanyProfile | PartialCompanyProfile,
  string
>({
  selectId: (post) => post.companyProfileKey,
  sortComparer: (a, b) => a.index - b.index,
});

import { getCompanyAdsThunk } from './company-ads.actions';
import { partialCompanyAdAdapter } from './company-ads.adapter';
import { CompanyAdsbuilder } from './company-ads.types';

function getCompanyAdsBuild(builder: CompanyAdsbuilder) {
  return builder
    .addCase(getCompanyAdsThunk.pending, (state, action) => {
      state.companyAds.status = 'pending';
      state.companyAds.error = null;
      state.companyAds.pagination = action.meta.arg;
    })
    .addCase(getCompanyAdsThunk.fulfilled, (state, action) => {
      state.companyAds.status = 'completed';
      partialCompanyAdAdapter.setMany(state.companyAds.data, action.payload);
    })
    .addCase(getCompanyAdsThunk.rejected, (state, action) => {
      state.companyAds.status = 'idle';
      state.companyAds.error = action.error;
    });
}

export function extraReducers(builder: CompanyAdsbuilder) {
  getCompanyAdsBuild(builder);
}

import { stringHasLengthOrNull } from '@orbiapp/components';

import { UpdateCompanyForm } from '../../../../../models';
import { UpdateCompanyDto } from './company.dto';

export const updateCompanyMapper = (
  updateCompany: UpdateCompanyForm,
): UpdateCompanyDto => ({
  employeeCount: updateCompany.employeeCount,
  industry: updateCompany.industry,
  logo: updateCompany.logo,
  name: updateCompany.name,
  tags: updateCompany.tags,
  facebookUrl: stringHasLengthOrNull(updateCompany.facebookUrl),
  instagramUrl: stringHasLengthOrNull(updateCompany.instagramUrl),
  linkedinUrl: stringHasLengthOrNull(updateCompany.linkedinUrl),
  tiktokUrl: stringHasLengthOrNull(updateCompany.tiktokUrl),
  twitterUrl: stringHasLengthOrNull(updateCompany.twitterUrl),
  youtubeUrl: stringHasLengthOrNull(updateCompany.youtubeUrl),
});

import { TxArgs, translate } from '@orbiapp/components';

import { EmploymentType, StudyLevel, WorkModel } from '../models';

export function getOptionalLabelText(tx: TxString, txArgs?: TxArgs) {
  return translate('label.general.optional-label', {
    label: translate(tx, txArgs),
  });
}

export function getStudyLevelTx(studyLevel: StudyLevel): TxString {
  switch (studyLevel) {
    case 'associate_degree':
      return 'label.study-levels.associate-degree';

    case 'bachelor':
      return 'label.study-levels.bachelor';

    case 'master':
      return 'label.study-levels.master';

    case 'phd':
      return 'label.study-levels.phd';
  }
}

export function getEmploymentTypeTx(employmentType: EmploymentType): TxString {
  switch (employmentType) {
    case 'apprenticeship':
      return 'label.employment-types.apprenticeship';

    case 'fulltime':
      return 'label.employment-types.fulltime';

    case 'internship':
      return 'label.employment-types.internship';

    case 'parttime':
      return 'label.employment-types.parttime';

    case 'summer_job':
      return 'label.employment-types.summer-job';

    case 'thesis':
      return 'label.employment-types.thesis';
  }
}

export function getWorkModelTx(workModel: WorkModel): TxString {
  switch (workModel) {
    case 'hybrid':
      return 'label.work-models.hybrid';

    case 'remote':
      return 'label.work-models.remote';

    case 'onsite':
      return 'label.work-models.onsite';
  }
}

import Joi from 'joi';

import { EmailSchema } from '../../email';
import { CoverImageValidation } from '../../file';
import { LocationSchema } from '../../shared';
import {
  OFFER_CONTACT_NAME_MAX_LENGTH,
  OFFER_DESCRIPTION_MAX_LENGTH,
  OFFER_TITLE_MAX_LENGTH,
} from './offer.constants';
import { OfferErrors } from './offer.errors';

export namespace CreateOfferSchema {
  export const offerCategoryKey = Joi.string()
    .required()
    .messages(OfferErrors.offerCategoryKey);

  export const title = Joi.string()
    .trim()
    .max(OFFER_TITLE_MAX_LENGTH)
    .required()
    .messages(OfferErrors.title);

  export const description = Joi.string()
    .trim()
    .max(OFFER_DESCRIPTION_MAX_LENGTH)
    .required()
    .messages(OfferErrors.description);

  export const coverImage = CoverImageValidation.required().messages(
    OfferErrors.coverImage,
  );

  export const contactName = Joi.string()
    .trim()
    .max(OFFER_CONTACT_NAME_MAX_LENGTH)
    .required()
    .messages(OfferErrors.contactName);

  export const contactEmail = EmailSchema.required();

  export const startDate = Joi.number()
    .required()
    .messages(OfferErrors.startDate);

  export const endDate = Joi.number()
    .min(Joi.ref('startDate'))
    .allow(null)
    .required()
    .messages(OfferErrors.endDate);

  export const isConsumable = Joi.boolean().required();

  export const link = Joi.when('isConsumable', {
    is: true,
    then: Joi.valid(null).required(),
    otherwise: Joi.string().uri().required(),
  })
    .required()
    .messages(OfferErrors.link);

  export const consumeWaitMinutes = Joi.when('isConsumable', {
    is: true,
    then: Joi.number().allow(null).required(),
    otherwise: Joi.valid(null).required(),
  }).required();

  export const code = Joi.string()
    .trim()
    .required()
    .allow(null)
    .messages(OfferErrors.code);

  export const files = Joi.array()
    .required()
    .min(1)
    .allow(null)
    .messages(OfferErrors.files);

  export const offerDraftKey = Joi.string().required();

  export const locations = Joi.array()
    .items(
      Joi.object({
        latitude: LocationSchema.latitude,
        longitude: LocationSchema.longitude,
        address: LocationSchema.address,
        label: LocationSchema.label,
      }),
    )
    .required();

  export const requireStudentVerification = Joi.boolean().required();

  export const countryKeys = Joi.array()
    .items(Joi.string().required())
    .required()
    .messages(OfferErrors.countryKeys);
}

import { createEntityAdapter } from '@reduxjs/toolkit';

import { OfferCategory, PartialOffer, PartialOfferDraft } from '../../models';

export const partialOfferDraftsAdapter = createEntityAdapter<
  PartialOfferDraft,
  string
>({
  selectId: (partialOfferDraft) => partialOfferDraft.offerDraftKey,
});

export const partialOffersAdapter = createEntityAdapter<PartialOffer, string>({
  selectId: (partialOffer) => partialOffer.offerKey,
});

export const offerCategoriesAdapter = createEntityAdapter<
  OfferCategory,
  string
>({
  selectId: (offerCategory) => offerCategory.offerCategoryKey,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

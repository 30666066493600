import Joi from 'joi';

import {
  COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH,
  COMPANY_QR_CODE_NAME_MAX_LENGTH,
} from './company-qr.constants';
import { CreateCompanyQrErrors } from './company-qr.errors';

export namespace CreateCompanyQrSchema {
  export const name = Joi.string()
    .max(COMPANY_QR_CODE_NAME_MAX_LENGTH)
    .required()
    .messages(CreateCompanyQrErrors.name);

  export const startDate = Joi.number().required();

  export const description = Joi.string()
    .max(COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH)
    .allow('')
    .required();
}

export namespace UpdateCompanyQrSchema {
  export const name = Joi.string()
    .max(COMPANY_QR_CODE_NAME_MAX_LENGTH)
    .required()
    .messages(CreateCompanyQrErrors.name);

  export const description = Joi.string()
    .max(COMPANY_QR_CODE_DESCRIPTION_MAX_LENGTH)
    .allow('')
    .required();

  export const startDate = Joi.number().required();
}

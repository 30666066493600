import {
  createCompanyThunk,
  getCompanyThunk,
  getOfficeLocationsThunk,
  updateCompanyThunk,
} from './company.actions';
import { officeLocationsAdapter } from './company.adapter';
import { CompanyBuilder } from './company.types';

const getCompanyBuild = (builder: CompanyBuilder) => {
  return builder
    .addCase(getCompanyThunk.fulfilled, (state, action) => {
      state.company.status = 'completed';
      state.company.data = action.payload;
    })
    .addCase(getCompanyThunk.pending, (state) => {
      state.company.status = 'pending';
      state.company.error = null;
    })
    .addCase(getCompanyThunk.rejected, (state, action) => {
      state.company.status = 'idle';
      state.company.error = action.error;
    });
};

const createCompanyBuild = (builder: CompanyBuilder) => {
  return builder
    .addCase(createCompanyThunk.fulfilled, (state) => {
      state.createCompany.status = 'completed';
    })
    .addCase(createCompanyThunk.pending, (state) => {
      state.createCompany.status = 'pending';
      state.createCompany.error = null;
    })
    .addCase(createCompanyThunk.rejected, (state, action) => {
      state.createCompany.status = 'idle';
      state.createCompany.error = action.error;
    });
};

const getOfficeLocationsBuild = (builder: CompanyBuilder) => {
  return builder
    .addCase(getOfficeLocationsThunk.fulfilled, (state, action) => {
      officeLocationsAdapter.setAll(state.officeLocations.data, action.payload);
      state.officeLocations.status = 'completed';
    })
    .addCase(getOfficeLocationsThunk.pending, (state) => {
      state.officeLocations.status = 'pending';
      state.officeLocations.error = null;
    })
    .addCase(getOfficeLocationsThunk.rejected, (state, action) => {
      state.officeLocations.status = 'idle';
      state.officeLocations.error = action.error;
    });
};

const updateCompanyBuild = (builder: CompanyBuilder) => {
  return builder
    .addCase(updateCompanyThunk.fulfilled, (state, action) => {
      state.updateCompany.status = 'idle';

      if (!state.company.data || !action.payload) {
        return;
      }

      officeLocationsAdapter.setAll(
        state.officeLocations.data,
        action.payload.updateCompany.locations,
      );

      state.company.data = {
        ...state.company.data,
        facebookUrl: action.payload.updateCompany.facebookUrl,
        industry: action.payload.updateCompany.industry,
        instagramUrl: action.payload.updateCompany.instagramUrl,
        linkedinUrl: action.payload.updateCompany.linkedinUrl,
        logo: action.payload.logo,
        name: action.payload.updateCompany.name,
        tags: action.payload.updateCompany.tags,
        tiktokUrl: action.payload.updateCompany.tiktokUrl,
        twitterUrl: action.payload.updateCompany.twitterUrl,
        youtubeUrl: action.payload.updateCompany.youtubeUrl,
      };
    })
    .addCase(updateCompanyThunk.pending, (state) => {
      state.updateCompany.status = 'pending';
      state.updateCompany.error = null;
    })
    .addCase(updateCompanyThunk.rejected, (state, action) => {
      state.updateCompany.status = 'idle';
      state.updateCompany.error = action.error;
    });
};

export const extraReducers = (builder: CompanyBuilder) => {
  createCompanyBuild(builder);
  getCompanyBuild(builder);
  getOfficeLocationsBuild(builder);
  updateCompanyBuild(builder);
};

import { initialJobsState } from './jobs.initial';
import { JobsState } from './jobs.types';

function reset(state: JobsState) {
  Object.assign(state, initialJobsState);
}

function clearJobs(state: JobsState) {
  state.jobs.data = initialJobsState.jobs.data;
}

function resetCreateJobForm(state: JobsState) {
  state.createJob.data = initialJobsState.createJob.data;
  state.createJob.error = initialJobsState.createJob.error;
  state.createJob.status = initialJobsState.createJob.status;

  state.createJobDefaultValues = initialJobsState.createJobDefaultValues;
  state.createJobDraft = initialJobsState.createJobDraft;
  state.duplicateJob = initialJobsState.duplicateJob;
  state.jobDraft = initialJobsState.jobDraft;
  state.updateJobDraft = initialJobsState.updateJobDraft;
}

function resetJob(state: JobsState) {
  state.job.data = initialJobsState.job.data;
}

export const reducers = {
  reset,

  clearJobs,
  resetCreateJobForm,
  resetJob,
};

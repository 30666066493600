import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetTimeBasedInsights,
  InsightsItem,
  InsightsMetric,
  LiveInsights,
  TimeBasedInsights,
} from '../../models';
import { OrbiApi, v1 } from '../../services';
import { getInsightsPoints, getInsightsPointsParams } from '../../utils';
import { ThunkApiConfig } from '../store.types';

export const getLiveInsightsThunk = createAsyncThunk<
  LiveInsights,
  undefined,
  ThunkApiConfig
>('insights/get-live-insights', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.insights.liveInsights.data) {
    return state.insights.liveInsights.data;
  }

  const res = await OrbiApi.call(v1.insights.getInsightsLiveData, undefined);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getInsightsItemsThunk = createAsyncThunk<
  InsightsItem[],
  undefined,
  ThunkApiConfig
>('insights/get-items', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.insights.items.data) {
    return state.insights.items.data;
  }

  const res = await OrbiApi.call(v1.insights.getInsightsItems, undefined);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getTimeBasedInsightsThunk = createAsyncThunk<
  TimeBasedInsights,
  { metrics: GetTimeBasedInsights['metrics']; all?: boolean },
  ThunkApiConfig
>('insights/get-time-series', async (params, thunkAPI) => {
  const state = thunkAPI.getState();

  const timeSeriesConfig = state.insights.timeSeriesConfig;

  const insightsPoints = getInsightsPoints(
    getInsightsPointsParams(timeSeriesConfig.selectedRange),
  );

  const res = await OrbiApi.call(v1.insights.getTimeBasedInsights, {
    items: params.all ? 'all' : timeSeriesConfig.selectedItems,
    metrics: params.metrics,
    showUnique: timeSeriesConfig.showUnique,
    mainPeriod: insightsPoints.mainPeriod,
    comparedPeriod: insightsPoints.comparedPeriod,
    include: ['table_breakdown', 'time_series'],
  });

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getTimeBasedInsightsByProductThunk = createAsyncThunk<
  TimeBasedInsights,
  {
    metrics: InsightsMetric[];
    item:
      | { type: 'job'; jobKey: string }
      | { type: 'company_profile'; companyProfileKey: string }
      | { type: 'offer'; offerKey: string };
  },
  ThunkApiConfig
>('insights/get-time-series-by-product', async (params, thunkAPI) => {
  const state = thunkAPI.getState();

  const timeSeriesConfigByProduct = state.insights.timeSeriesConfigByProduct;

  const insightsPoints = getInsightsPoints(
    getInsightsPointsParams(timeSeriesConfigByProduct.selectedRange),
  );

  const res = await OrbiApi.call(v1.insights.getTimeBasedInsights, {
    items: [params.item],
    metrics: params.metrics,
    showUnique: timeSeriesConfigByProduct.showUnique,
    mainPeriod: insightsPoints.mainPeriod,
    comparedPeriod: insightsPoints.comparedPeriod,
    include: ['table_breakdown', 'time_series'],
  });

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const exportInsightsThunk = createAsyncThunk<
  Blob,
  GetTimeBasedInsights,
  ThunkApiConfig
>('insights/export', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.insights.exportInsightsData, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const getEstimatedValueThunk = createAsyncThunk<
  any,
  undefined,
  ThunkApiConfig
>('insights/estimated-value', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.insights.getEstimatedValue, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

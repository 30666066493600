import { Time } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CompanyInvitation,
  CreateInvitationForm,
  InviteTeamMembersForm,
  PendingCompanyInvitation,
  User,
} from '../../models';
import { OrbiApi, v3 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';

export const createInvitationThunk = createAsyncThunk<
  string,
  CreateInvitationForm,
  ThunkApiConfig
>('team/create-invitation', async (createInvitationParams, thunkAPI) => {
  const res = await OrbiApi.call(
    v3.team.createInvitation,
    createInvitationParams,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('create-invitation:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('create-invitation:error'));
  return thunkAPI.rejectWithValue(res);
});

export const createInvitationsThunk = createAsyncThunk<
  PendingCompanyInvitation[],
  InviteTeamMembersForm,
  ThunkApiConfig
>('team/create-invitations', async (inviteTeamMembersFormParams, thunkAPI) => {
  const res = await Promise.all(
    inviteTeamMembersFormParams.invitations.map((invite) =>
      OrbiApi.call(v3.team.createInvitation, invite),
    ),
  );

  if (!res.every((r) => r.kind === 'ok')) {
    thunkAPI.dispatch(setAlert('create-invitations:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const pendingInvitations: PendingCompanyInvitation[] = [];
  res.forEach((invitation, index) => {
    if (invitation.kind === 'ok') {
      pendingInvitations.push({
        companyInvitationKey: invitation.data,
        email: inviteTeamMembersFormParams.invitations[index].email,
        expiresAt: Date.now() + 2 * Time.Week,
        role: 'admin',
      });
    }
  });

  thunkAPI.dispatch(setAlert('create-invitations:success'));
  return pendingInvitations;
});

export const deleteInvitationThunk = createAsyncThunk<
  undefined,
  string,
  ThunkApiConfig
>('team/delete-invitation', async (companyInvitationKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v3.team.deleteInvitation,
    companyInvitationKey,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('delete-invitation:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('delete-invitation:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getCompanyInvitationThunk = createAsyncThunk<
  CompanyInvitation,
  string,
  ThunkApiConfig
>('team/get-company-invitation', async (companyInvitationKey, thunkAPI) => {
  const state = thunkAPI.getState();

  if (state.team.companyInvitation.data) {
    return state.team.companyInvitation.data;
  }

  const res = await OrbiApi.call(
    v3.team.getCompanyInvitationThunk,
    companyInvitationKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getPendingInvitationsThunk = createAsyncThunk<
  PendingCompanyInvitation[],
  undefined,
  ThunkApiConfig
>('team/get-invitation', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v3.team.getPendingInvitations, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getUsersThunk = createAsyncThunk<
  User[],
  undefined,
  ThunkApiConfig
>('team/get-users', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v3.team.getUsers, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const removeUserThunk = createAsyncThunk<void, string, ThunkApiConfig>(
  'team/remove-user',
  async (userKey, thunkAPI) => {
    const res = await OrbiApi.call(v3.team.removeUser, userKey);

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('remove-user:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('remove-user:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

import React from 'react';
import { Navigate } from 'react-router-dom';

import { AuthStateSelector, useSelector } from '../store';

type RouteProps = React.PropsWithChildren<{
  redirectTo?: string;
}>;

export function AuthenticatedRoute(props: RouteProps) {
  const { children, redirectTo = '/sign-in' } = props;

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  if (!authenticated) {
    return (
      <Navigate
        to={redirectTo}
        state={{ returnTo: window.location.pathname }}
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export function UnauthenticatedRoute(props: RouteProps) {
  const { children, redirectTo = '/insights' } = props;

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  if (authenticated) {
    return <Navigate to={redirectTo} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
